import NftCard from "../components/nftCard";
import Slider from "react-slick";
import { GlobalContext } from "../context/Provider";
import { useContext, useEffect, useState } from "react";
import staticAction from "../context/actions/auth/staticAction";
import { EXPLORE_LANDING_MARKET_PLACE, EXPLORE_NFT_TYPE_MARKETPLACE } from "../context/actionTypes";
import henceforthApi from "../utils/henceforthApi";
import { useLocation, useNavigate } from "react-router-dom";
import { exploreDefaultSlider } from "../utils/work_contant";
import All from "../assets/images/icons/all.png";

const MarketplaceHome = () => {
    const navigate = useNavigate()
    const location = useLocation()
    var query = new URLSearchParams(location.search);

    const { authState, staticDispatch, elenToUsd, categories, loading, setLoading, scrollToTop } = useContext(GlobalContext)
    const [loadMore, setLoadMore] = useState(null)
    const [state, setState] = useState({
        nfts: [],
        total_count: 0,
        page: 0
    })
    const [weeklySelection, setWeeklySelection] = useState({
        nfts: [],
        total_count: 0
    })
    henceforthApi.setToken(authState.access_token)

    const initialiseAllData = async (page) => {
        setLoading(true)
        let q = query.toString()
        try {
            let apiRes = await henceforthApi.NFT.explore(EXPLORE_NFT_TYPE_MARKETPLACE, page, q)
            let data = apiRes.data
            if (page) {
                let oldData = state.nfts
                let newData = [...oldData, ...data.nfts]
                debugger
                setState({
                    ...state,
                    nfts: newData,
                    page
                })
            } else {
                setState({
                    ...state,
                    ...data,
                    page
                })
            }
            setLoading(false)
        } catch (error) {
            console.log("error", error);
            setLoading(false)
        }
    }
    const initialiseWeeklySelection = async () => {
        try {
            let apiRes = await henceforthApi.NFT.weeklySelection(EXPLORE_NFT_TYPE_MARKETPLACE)
            let data = apiRes.data
            setWeeklySelection(data)
        } catch (error) {

        }
    }

    const initialiseFev = async (index, _id, is_favourite) => {
        if (authState.access_token) {
            try {

                let items = {
                    nft_id: _id,
                    language: "ENGLISH"
                }
                let data = []
                data = state.nfts.filter(res => res._id === _id)
                if (data.length) {
                    state.nfts[index]["is_favourite"] = is_favourite
                    if (is_favourite) {
                        state.nfts[index]["total_favourites"] = data[0].total_favourites + 1
                    } else {
                        state.nfts[index]["total_favourites"] = data[0].total_favourites - 1
                    }
                    setState({ ...state })
                }
                data = weeklySelection.nfts.filter(res => res._id === _id)
                if (data.length) {
                    weeklySelection.nfts[index]["is_favourite"] = is_favourite
                    if (is_favourite) {
                        weeklySelection.nfts[index]["total_favourites"] = data[0].total_favourites + 1
                    } else {
                        weeklySelection.nfts[index]["total_favourites"] = data[0].total_favourites - 1
                    }
                    setWeeklySelection({ ...weeklySelection })
                }
                if (is_favourite) {
                    await henceforthApi.NFT.addToFev(items)
                } else {
                    await henceforthApi.NFT.removeFromFev(items)
                }
            } catch (error) {
                console.log("error", error);
                debugger
            }
        }
    }

    useEffect(() => {
        staticAction.changeExplore(EXPLORE_LANDING_MARKET_PLACE)(staticDispatch);
        scrollToTop()
    }, [])

    useEffect(() => {
        initialiseWeeklySelection()
    }, [])

    useEffect(() => {
        initialiseAllData(0)
    }, [query.get("category_id"), query.get("type")])

    const changeCategory = (value) => {
        if (query.get('category_id') !== value) {
            query.set("category_id", value)
        } else {
            query.delete("category_id")
        }
        navigate({ search: query.toString() })
    }
    const changeType = (value) => {
        if (value) {
            query.set("type", value)
        } else {
            query.delete("type")
        }
        navigate({ search: query.toString() })
    }
    useEffect(() => {
        let page = state.page
        if ((window.innerHeight + document.documentElement.scrollTop) + 400 >= document.scrollingElement.scrollHeight) {
            if (state.nfts.length) {
                if (!loading) {
                    if (state.total_count != state.nfts.length) {
                        console.log("Loading......");
                        initialiseAllData(Number(page) + 1)
                    } else {
                        console.log("Data ended");
                    }
                }
            }

        }
    }, [loadMore])

    useEffect(() => {
        window.addEventListener("scroll", setLoadMore)
        return () =>
            window.removeEventListener("scroll", setLoadMore)
    }, [])

    return (
        <>
            <section className="py-4 pb-md-4 pb-2">
                <div className="container">
                    <div className="trending-slider">
                        {categories.map((res) => (
                            <div key={res._id} onClick={() => changeCategory(res._id)} className={`${query.get("category_id") == res._id ? 'selected' : ''} t-name d-flex justify-content-start align-items-center text-nowrap cursor-pointer`}>
                                <img src={String(res.image).includes('https://') ? res.image : `${henceforthApi.API_FILE_ROOT_ORIGINAL}${res.image}`} className="me-2" alt="all" />{res.name}</div>
                        ))}
                    </div>
                </div>
            </section>
            {!query.has("category_id") &&
                <section className="live-auctions section-spacing pb-lg-3 pb-0 pt-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12  mb-md-4 mb-3">
                                <h2 className="fw-bold mb-3 title-line position-relative"><span className="bg-white d-inline pe-3"><span className="text-gradient">Everlens Weekly Selection</span></span></h2>
                            </div>
                            <Slider {...exploreDefaultSlider(Array.isArray(weeklySelection.nfts) && weeklySelection.nfts.length)} className={`everlens-slider  ${weeklySelection.nfts.length <= 3 ? "space" : ""}`}>
                                {Array.isArray(weeklySelection.nfts) && weeklySelection.nfts.map((res, index) => {
                                    return <NftCard key={res._id} currentUser={authState._id} elenToUsd={elenToUsd} {...res} staticDispatch={staticDispatch} initialiseFev={initialiseFev} index={index} />
                                })}
                            </Slider>
                        </div>
                    </div>
                </section>}
            <section className="live-auctions section-spacing pt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12  mb-md-4 mb-3">
                            <div className="dropdown">
                                <h2 className="fw-bold mb-3 title-line position-relative" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><span className="bg-white d-inline pe-3"><span className="text-gradient d-inline-flex align-items-center">
                                    {query.get("type") == "ALL" ? 'Explore' : query.get("type") == "NEW" ? 'New' : query.get("type") == "SOLD" ? 'Sold' : query.get("type") == "PRICE_HIGH_TO_LOW" ? 'Price -- High to Low' : query.get("type") == "PRICE_LOW_TO_HIGH" ? 'Price -- Low to High' : query.get("type") == "AUCTION" ? 'Auction' : query.get("type") == "FIXED_PRICE" ? 'Fixed Price' : query.get("type") == "FOLLOWERS" ? 'Followers' : 'Explore'}
                                    <i className="fa-solid fa-chevron-down ms-2 fs-5"></i></span></span></h2>
                                <ul className="dropdown-menu common-dropdown" aria-labelledby="dropdownMenuButton1">
                                    <li onClick={() => changeType("ALL")}><a className="dropdown-item fw-medium" >Explore</a></li>
                                    <li onClick={() => changeType("NEW")}><a className="dropdown-item fw-medium" >New</a></li>
                                    <li onClick={() => changeType("SOLD")}><a className="dropdown-item fw-medium" >Sold</a></li>
                                    <li onClick={() => changeType("PRICE_HIGH_TO_LOW")}><a className="dropdown-item fw-medium" >Price -- High to Low</a></li>
                                    <li onClick={() => changeType("PRICE_LOW_TO_HIGH")}><a className="dropdown-item fw-medium" >Price -- Low to High</a></li>
                                    <li onClick={() => changeType("ENDED_AUCTION")}><a className="dropdown-item fw-medium" >Ended auctions</a></li>
                                    <li onClick={() => changeType("ACTIVE_AUCTION")}><a className="dropdown-item fw-medium" >Active auctions</a></li>
                                    <li onClick={() => changeType("FIXED_PRICE")}><a className="dropdown-item fw-medium" >Fixed Price</a></li>
                                    <li onClick={() => changeType("FOLLOWERS")}><a className="dropdown-item fw-medium" >Followers</a></li>

                                </ul>
                            </div>
                        </div>
                        {state.nfts.map((res, index) => {
                            return <div key={res._id} className="col-lg-4 col-xl-3 col-md-6  mb-3 px-2 position-relative"><NftCard currentUser={authState._id} elenToUsd={elenToUsd} {...res} staticDispatch={staticDispatch} initialiseFev={initialiseFev} index={index} /></div>
                        })}
                    </div>
                </div>
            </section>
        </>
    )
}
export default MarketplaceHome;