import NftCard from "../components/nftCard";
import Slider from "react-slick";
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useContext, useEffect, useState } from "react";
import henceforthApi from "../utils/henceforthApi";
import { toast } from "react-toastify";
import { GlobalContext } from "../context/Provider";
import { ERROR_UNAUTHORIZED, EXPLORE_NFT_TYPE_MARKETPLACE, EXPLORE_NFT_TYPE_SOCIAL } from "../context/actionTypes";

const Home = () => {

    const { authState, staticDispatch, elenToUsd, logOutNow } = useContext(GlobalContext)
    const location = useLocation()
    const [marketPlaceNft, setMarketPlaceNft] = useState({
        nfts: [],
        total_count: 0
    })
    const [socialPlaceNft, setSocialPlaceNft] = useState({
        nfts: [],
        total_count: 0
    })

    henceforthApi.setToken(authState.access_token)

    const initialiseFev = async (index, _id, is_favourite) => {
        if (authState.access_token) {
            try {

                let items = {
                    nft_id: _id,
                    language: "ENGLISH"
                }
                let data = []
                data = marketPlaceNft.nfts.filter(res => res._id === _id)
                if (data.length) {
                    marketPlaceNft.nfts[index]["is_favourite"] = is_favourite
                    if (is_favourite) {
                        marketPlaceNft.nfts[index]["total_favourites"] = data[0].total_favourites + 1
                    } else {
                        marketPlaceNft.nfts[index]["total_favourites"] = data[0].total_favourites - 1
                    }
                    setMarketPlaceNft({ ...marketPlaceNft })
                }
                data = socialPlaceNft.nfts.filter(res => res._id === _id)
                if (data.length) {
                    socialPlaceNft.nfts[index]["is_favourite"] = is_favourite
                    if (is_favourite) {
                        socialPlaceNft.nfts[index]["total_favourites"] = data[0].total_favourites + 1
                    } else {
                        socialPlaceNft.nfts[index]["total_favourites"] = data[0].total_favourites - 1
                    }
                    setSocialPlaceNft({ ...socialPlaceNft })
                }
                if (is_favourite) {
                    await henceforthApi.NFT.addToFev(items)
                } else {
                    await henceforthApi.NFT.removeFromFev(items)
                }
            } catch (error) {
                console.log("error", error);
            }
        }
    }

    var settings = {
        arrows: false,
        dosts: false,
        centerMode: true,
        centerPadding: '90px',
        slidesToShow: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    centerPadding: '40px',
                },
            },

            {
                breakpoint: 576,
                settings: {
                    centerPadding: '20px',
                },
            },
        ],
    };

    const initialiseMarketPlaceNft = async () => {
        try {
            let apiRes = await henceforthApi.NFT.explore(EXPLORE_NFT_TYPE_MARKETPLACE)
            let data = apiRes.data
            setMarketPlaceNft(data)
        } catch (error) {

        }
    }
    const initialiseSocialNft = async () => {
        try {
            let apiRes = await henceforthApi.NFT.explore(EXPLORE_NFT_TYPE_SOCIAL)
            let data = apiRes.data
            setSocialPlaceNft(data)
        } catch (error) {
            if (error.response.body.error == ERROR_UNAUTHORIZED) {
                logOutNow()
            } else {
                toast.error(error.response.body.error_description)
            }
        }
    }

    useEffect(() => {
        initialiseMarketPlaceNft()
    }, [])
    useEffect(() => {
        initialiseSocialNft()
    }, [])
    return <>
        <section className="banner position-relative">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="banner-col first ps-0">
                            <div className="top-text">
                                <h3 className="fw-semi-bold text-theme big-font-38 mb-1">Explore</h3>
                                <h2 className="fw-bold text-theme big-font-45 mb-0">Everlens Social</h2>
                            </div>

                            <Slider {...settings} className="banner-slider">
                                {Array.isArray(socialPlaceNft.nfts) && socialPlaceNft.nfts.map((res, index) => {
                                    return <div><div className={`slider-box ${res.erc === 1155 ? 'multiple' : ''}`}><NftCard currentUser={authState._id} elenToUsd={elenToUsd} {...res} staticDispatch={staticDispatch} initialiseFev={initialiseFev} index={index} /></div></div>
                                })}
                            </Slider>

                            <div className="text-center">
                                <Link to="/social?type=ALL">
                                    <button className="btn btn-primary explore-btn">EXPLORE EVERLENS SOCIAL</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="banner-col second pe-0">
                            <div className="top-text">
                                <h3 className="fw-semi-bold text-theme big-font-38 mb-1">Explore</h3>
                                <h2 className="fw-bold text-theme big-font-45 mb-0">Everlens Marketplace</h2>
                            </div>
                            <Slider {...settings} className="banner-slider">
                                {Array.isArray(marketPlaceNft.nfts) && marketPlaceNft.nfts.map((res, index) => {
                                    return <div><div className={`slider-box ${res.erc === 1155 ? 'multiple' : ''}`}><NftCard currentUser={authState._id} elenToUsd={elenToUsd} {...res} staticDispatch={staticDispatch} initialiseFev={initialiseFev} index={index} /></div></div>
                                })}
                            </Slider>
                            <div className="text-center">
                                <Link to="/marketplace?type=ALL">
                                    <button className="btn btn-primary explore-btn">EXPLORE EVERLENS MARKETPLACE</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="live-auctions section-spacing">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 mb-4">
                        <h2 className="fw-bold mb-lg-3 mb-2 title-line position-relative"><span className="bg-white d-inline pe-3"><span className="text-gradient">Everlens Social</span></span></h2>
                        <p className="text-gray para-text">Create, buy and sell NFTs easily directly from your social network accounts (Instagram, TikTok and more to come…).</p>
                    </div>
                    {Array.isArray(socialPlaceNft.nfts) && socialPlaceNft.nfts.map((res, index) => {
                        return <div key={res._id} className="col-lg-4 col-xl-3 col-md-6  mb-3 px-2"> <NftCard currentUser={authState._id} elenToUsd={elenToUsd} {...res} staticDispatch={staticDispatch} initialiseFev={initialiseFev} index={index} /></div>
                    })}
                    <div className="text-center mt-md-4 mt-3">
                        <Link to="/social?type=ALL">
                            <button className="btn btn-primary text-uppercase">
                                EXPLORE EVERLENS SOCIAL
                            </button>
                        </Link>

                    </div>
                </div>
            </div>
        </section>
        <section className="live-auctions section-spacing pt-lg-5 pt-2">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 mb-4">
                        <h2 className="fw-bold mb-lg-3 mb-2 title-line position-relative"><span className="bg-white d-inline pe-3"><span className="text-gradient">Everlens Marketplace</span></span></h2>
                        <p className="text-gray para-text">Create, buy and sell NFT collections.</p>
                        {/* <p className="text-gray para-text">Discover new ways to create NFTs . Mint, buy and sell one-of-kind digital items.</p> */}
                    </div>

                    {Array.isArray(marketPlaceNft.nfts) && marketPlaceNft.nfts.map((res, index) => {
                        return <div key={res._id} className="col-lg-4 col-xl-3 col-md-6  mb-3 px-2"> <NftCard currentUser={authState._id} elenToUsd={elenToUsd} {...res} staticDispatch={staticDispatch} initialiseFev={initialiseFev} index={index} /></div>
                    })}
                    <div className="text-center mt-md-4 mt-3">
                        <Link to="/marketplace?type=ALL">
                            <button className="btn btn-primary text-uppercase">
                                EXPLORE EVERLENS MARKETPLACE
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    </>
}
export default Home;