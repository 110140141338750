import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "../components/BootstrapCompo";
import { ERROR_UNAUTHORIZED } from "../context/actionTypes";
import { GlobalContext } from "../context/Provider";
import henceforthApi from "../utils/henceforthApi";
import henceforthValidations from "../utils/henceforthValidations";

export default () => {
    const navigate = useNavigate()
    const location = useLocation()
    var query = new URLSearchParams(location.search);
    const { authState, accounts, scrollToTop, } = useContext(GlobalContext)

    const [currentPassword, setCurrentPassword] = useState("")
    const [password, setPassword] = useState("")
    const [cnfPassword, setCnfPassword] = useState("")
    const [loading, setLoading] = useState(false)

    const [passwordToggle1, setPasswordToggle1] = useState(false)
    const [passwordToggle2, setPasswordToggle2] = useState(false)
    const [passwordToggle3, setPasswordToggle3] = useState(false)

    henceforthApi.setToken(authState.access_token)

    const onChangePassword = async () => {
        if (!currentPassword) {
            return toast.warn("Please enter current password")
        } else if (!password) {
            return toast.warn("Please enter password")
        } else if (!henceforthValidations.strongPassword(password)) {
            return toast.warn("Please enter stronger password. Try a mix of letters, numbers and symbols.")
        } else if (!cnfPassword) {
            return toast.warn("Please enter confirm password")
        } else if (password !== cnfPassword) {
            return toast.warn("Password and confirm password don't match.")
        }
        try {
            let items = {
                old_password: currentPassword,
                new_password: cnfPassword,
                language: "ENGLISH"
            }
            setLoading(true)
            let apiRes = await henceforthApi.Auth.changePassword(items)
            toast.success(apiRes.message)
            setLoading(false)
            window.history.back()
        } catch (error) {
            setLoading(false)
            if (error.response && error.response.body) {
                if (error.response.body.error == ERROR_UNAUTHORIZED) {
                    navigate(`/signin?redirect=${location.pathname}`, { replace: true })
                } else {
                    toast.error(error.response.body.error_description)
                }
            }
        }

    }

    useEffect(() => {
        if (authState.access_token) {
            scrollToTop()
        } else {
            navigate(`/signin?redirect=${location.pathname}`, { replace: true })
        }
    }, [accounts, authState.access_token])

    return <section className="edit-profile  mb-5 pt-md-5 pt-4 pb-5">
        <div className="container">
            <div className="post-content">
                <form className="row" onSubmit={(e) => { e.preventDefault(); onChangePassword() }}>
                    <div className="col-xl-5 col-lg-7">
                        <h2 className="fw-bold mb-3"><span className="text-gradient">Change Password</span></h2>
                        <div className="form-group">
                            <label className="fw-medium mb-1">Current Password</label>
                            <div className="position-relative">
                                <input type={passwordToggle1 ? 'text' : 'password'} className="form-control pe-5" placeholder="Current Password" name="currenct-password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} required />
                                <i className={`fa-solid ${passwordToggle1 ? 'fa-eye-slash' : 'fa-eye'} position-absolute top-50 translate-middle-y end-0 me-3 text-gray`} onClick={() => setPasswordToggle1(!passwordToggle1)}></i>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="fw-medium mb-1">New Password</label>
                            <div className="position-relative">
                                <input type={passwordToggle2 ? 'text' : 'password'} className="form-control pe-5" placeholder="New Password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                                <i className={`fa-solid ${passwordToggle2 ? 'fa-eye-slash' : 'fa-eye'} position-absolute top-50 translate-middle-y end-0 me-3 text-gray`} onClick={() => setPasswordToggle2(!passwordToggle2)}></i>

                            </div>
                        </div>
                        <div className="form-group">
                            <label className="fw-medium mb-1">Confirm Password</label>
                            <div className="position-relative">
                                <input type={passwordToggle3 ? 'text' : 'password'} className="form-control pe-5" placeholder="Confirm Password" name="confirm-password" value={cnfPassword} onChange={(e) => setCnfPassword(e.target.value)} required />
                                <i className={`fa-solid ${passwordToggle3 ? 'fa-eye-slash' : 'fa-eye'} position-absolute top-50 translate-middle-y end-0 me-3 text-gray`} onClick={() => setPasswordToggle3(!passwordToggle3)}></i>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-4 pt-2">
                            <button type="submit" className="btn btn-primary shadow-none rounded-pill w-100 me-2 fs-12" disabled={loading} >{loading ? <Spinner /> : 'CHANGE PASSWORD'}</button>
                            <button type="button" className="btn btn-outline-primary shadow-none rounded-pill w-100 fw-medium  fs-12" onClick={() => window.history.back()}>CANCEL</button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </section>
}