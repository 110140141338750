import Banner from "../../assets/images/ellentoken/banner.png";
import Bronze from "../../assets/images/ellentoken/bronze.svg";
import Buy from "../../assets/images/ellentoken/buy.png";
import Check from "../../assets/images/ellentoken/check.svg";
import Gold from "../../assets/images/ellentoken/gold.svg";
import Image from "../../assets/images/ellentoken/image.png";
import One from "../../assets/images/ellentoken/one.svg";
import ScrollToTop from "../../components/HOC/ScrollToTop"
import Sell from "../../assets/images/ellentoken/sell.png";
import Silver from "../../assets/images/ellentoken/silver.svg";
import Statics from "../../assets/images/ellentoken/statics.png";
// import Whitepaper from "../../assets/files/Everlens-Whitepaper-Updated.pdf";

const EllentToken = () => {
  return (
    <>
      <div className="inner-pages-wrapper">
        <section className="banner-section">
          <div className="container">
            <div className="row">
              <div className="col col-md-6">
                <div className="banner-text">
                  <h2>
                    Everlens token (ELEN) is
                    <br /> designed as a backbone of the
                    <br /> <span> Everlens </span> Marketplace
                  </h2>
                  <div className="tele-paper">
                    <a
                      href="https://t.me/everlensnft"
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-large btn-primary text-uppercase  white-paper"
                    >
                      Telegram
                    </a>
                  </div>
                </div>
              </div>
              <div className="col col-md-6 text-end">
                <div className="right-img">
                  <img src={Banner} alt="img" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="image-txt">
          <div className="main-contents">
            <div className="container">
              <div className="row">
                <div className="col col-md-6">
                  <div className="image-txt-left">
                    <img src={One} alt="img" />
                  </div>
                </div>
                <div className="col col-md-6">
                  <div className="image-txt-right">
                    <h3>
                      40% of revenues will be used for an innovative buyback mechanism. Fees
                      collected are securely stored in Everlens buyback contract called “The
                      Kraken”. These fees cannot be withdrawn and are frequently used to purchase
                      tokens back from the open market and burn them.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="native-token">
          <div className="main-contents">
            <div className="container">
              <div className="row">
                <div className="col col-md-12 text-center">
                  <div className="token-hading">
                    <h2>
                      Everlens will issue a native token (ELEN) for its marketplace with a fixed
                      supply of 1,500,000,000 units.
                    </h2>
                    <p>Token Holders will have the following advantages:</p>
                    <ul>
                      <li className="d-flex align-items-start">
                        <img src={Check} alt="img" />
                        Participate in the on-chain governance of Everlens by voting on development
                        proposals. Each token represents one vote.
                      </li>
                      <li className="d-flex align-items-start">
                        <img src={Check} alt="img" />
                        Discount on fees when holding ELEN tokens:
                      </li>
                    </ul>
                    <div className="table-img first-table table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">LEVEL</th>
                            <th scope="col">FEES</th>
                            <th scope="col">Staking (ELEN)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <p>
                               1
                              </p>
                            </td>
                            <td>3%</td>
                            <td>49,999</td>
                          </tr>
                          <tr>
                            <td>
                              <p>
                                2
                              </p>
                            </td>
                            <td>2.5%</td>
                            <td>50,000 - 99,999</td>
                          </tr>
                          <tr>
                            <td>
                              <p>
                               3
                              </p>
                            </td>
                            <td>2%</td>
                            <td>100,000 - 199,999</td>
                          </tr>
                          <tr>
                            <td>
                              <p>
                               4
                              </p>
                            </td>
                            <td>1%</td>
                            <td>200,000 and more</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <ul>
                      <li className="d-flex align-items-start">
                        <img src={Check} alt="img" />
                        All revenues generated by the Everlens platform will be used to do the
                        following :
                      </li>
                      <ul className="inner-ul">
                        <li>• 40% of fees will be allocated to the “Kraken” mechanism.</li>
                        <li>• 10% of fees will be distributed to ELEN tokens holders.</li>
                        <li>• 50% of fees will be assigned to Everlens marketing purposes.</li>
                      </ul>
                      <li className="d-flex align-items-start">
                        <img src={Check} alt="img" />
                        Everlens will feature your NFT at the top of the marketplace. All proceedings will be allocated to the Kraken mechanism.
                      </li>
                    </ul>
                    <div className="table-img secound-table table-responsive">
                      <h2 style={{ margin: "0" }}>
                        ELEN TOKEN SMART CONTRACT
                        <h6 className="tax-mechanism">Transaction Tax Mechanism</h6>
                      </h2>

                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">Liquidity Pool</th>
                            <th scope="col">Reflection</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>BUY SIDE</td>
                            <td>5%</td>
                            <td>--</td>
                          </tr>
                          <tr>
                            <td>SELL SIDE</td>
                            <td>5%</td>
                            <td>2%</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="buy-side">
                      <div className="buy-side-text">
                        <h2>
                          <img src={Buy} alt="img" />
                          Buy Side
                        </h2>
                        <h4>5% Liquidity</h4>
                        <p>
                          Each transaction contributes to providing liquidity to the pool where each
                          user can trade ELEN tokens.
                           {/* Funds allocated to the liquidity pool are locked for 5 years. */}
                        </p>
                      </div>
                    </div>
                    <div className="buy-side sell-buy">
                      <div className="buy-side-text">
                        <h2>
                          <img src={Sell} alt="img" />
                          Sell Side
                        </h2>
                        <h4>5% Liquidity</h4>
                        <p>
                          Each transaction contributes to providing liquidity to the pool where each
                          user can trade ELEN tokens. 
                          {/* Funds allocated to the liquidity pool are locked for 5 years. */}
                        </p>
                      </div>
                      {/* <div className="buy-side-text border-top">
                        <h4>2% RFI</h4>
                        <p>
                          Community Token holders are rewarded, providing each user the opportunity
                          to earn passive rewards proportional to the amount of ELEN they hold.
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="safty-token">
          <div className="main-contents">
            <div className="container">
              <div className="row">
                <div className="col col-md-12 text-center">
                  <div className="token-services">
                    <h2>
                      Safety Net Program <img src={Image} alt="img" /> Kraken mechanism
                    </h2>
                    <p>
                      When the Everlens community considers the LP to be sufficiently important, the
                      Safety Net Program will be used to "buy the dip". All the tokens bought by the
                      Safety Net Program will be permanently burned. This new procedure will be
                      implemented by the vote of the community.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="graph-bottom token-hading">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col col-md-12 text-center mb-5">
                <h2 className="suppy-heading">
                  Supply <img src={Statics} className="img-fluid" alt="img" /> Breakdown
                </h2>
              </div>

              <div className="col col-md-6  text-center">
                <div className="comman">
                  <div className="table-img table-responsive">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td>Total Token Supply</td>
                          <td style={{ color: "#b34ff3" }}>1.500 Billion</td>
                        </tr>
                        {/* <tr>
                          <td>Initial Market Cap at launch</td>
                          <td style={{ color: "#b34ff3" }}>$890,000</td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ScrollToTop(EllentToken);