import moment from "moment"
import { Fragment } from "react";
import henceforthApi from "../../utils/henceforthApi"
import { subscribeFormatter } from "../../utils/work_contant";
import profile_placeholder from "./../../assets/images/home/img_placeholder_small.svg";

export default ({ _id, placed_by, created_at, amount, quantity, elenToUsd, owner, onClick, loading, onReject }) => {

    return <div className="bid-owner p-3 d-flex justify-content-between border-pink rounded-3 mb-3 flex-md-row flex-column">
        <div className="owner-name d-flex align-items-start ">
            <div className="name-img">
                <img src={placed_by?.profile_pic ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${placed_by?.profile_pic}` : profile_placeholder} className="large-user" />
            </div>
            <div className="name-text ms-3 ">
                <p className="mb-0">Offer placed by<b className="fw-semi-bold ms-1 text-theme">@{placed_by?.name}</b></p>
                <p className="text-black mb-0">{moment(Number(created_at)).format("MMM DD, YYYY")} {moment(Number(created_at)).format("hh:mma")}</p>
            </div>
        </div>
        <div className="d-flex align-items-center  mt-3 mt-md-0 flex-wrap">
            <div className="name-text ms-2 text-end">
                <h6 className="fw-semi-bold mb-0">{subscribeFormatter(amount)} ELEN</h6>
                {elenToUsd !== 0 &&
                    <p className="text-black-50 mb-0 fs-12 text-nowrap">${subscribeFormatter(Number(Number(amount) * elenToUsd).toFixed(4))} USD</p>}
            </div>
            <div>
                {owner && <Fragment>
                    <button type="button" className="btn btn-outline-success px-4 ms-4" onClick={() => onClick(_id, amount, quantity, placed_by.address)} disabled={loading}>Accept</button>
                    <button type="button" className="btn btn-outline-danger px-4 ms-2" onClick={() => onReject(_id)}>REJECT</button></Fragment>}
            </div>

        </div>
    </div>
}