import React, {Component} from 'react';

export default function ScrollToTop(WrappedComponent){
    return class extends Component{
        componentDidMount = () => {
            if(typeof window != undefined)
            {
            window.scrollTo(0, 0)
            }
        }
        
        render(){
            return (
                <WrappedComponent {...this.props}/>
            );
        }
    } 
}