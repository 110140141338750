
import profile_placeholder from "../assets/images/home/img_placeholder.svg";
import InstagramIcon from "../assets/images/instagram_img.png";
import TiktokIcon from "../assets/images/home/tiktok_small.svg";
import All from "../assets/images/icons/all.png";
import Single from "../assets/images/icons/single_small.png"
import Multiple from "../assets/images/icons/multiple_small.png"
import { useContext, useEffect, useState } from "react";
import henceforthApi from "../utils/henceforthApi";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
import { GlobalContext } from "../context/Provider";
import { toast } from "react-toastify";
import { Spinner } from "../components/BootstrapCompo";
import { getCategoryNameViaIdAndList, subscribeFormatter, timeSince } from "../utils/work_contant";
import { ERROR_UNAUTHORIZED, EXPLORE_NFT_POST_TYPE_MULTIPLE, EXPLORE_NFT_POST_TYPE_SINGLE, EXPLORE_LANDING_MARKET_PLACE, EXPLORE_LANDING_SOCIAL, EXPLORE_NFT_TYPE_INSTAGRAM, EXPLORE_NFT_TYPE_TIKTOK } from "../context/actionTypes";

import ShareOptions from "../components/ShareOptions";
import Modals from "../components/Modals";
import ActivitiesList from "../components/profile/ActivitiesList";
import NftsMapList from "../components/profile/NftsMapList";
export default () => {
    const navigate = useNavigate()
    const location = useLocation()
    const match = useMatch("/profile/:id/:tab")
    var query = new URLSearchParams(location.search);
    const { authState, staticState, scrollToTop, elenToUsd, categories, loadMore, loading, setLoading } = useContext(GlobalContext)
    henceforthApi.setToken(authState.access_token)

    const [maxPrice, setMaxPrice] = useState(0)
    const [minPrice, setMinPrice] = useState(0)
    const [followLoading, setFollowLoading] = useState(false)
    const [reportText, setReportText] = useState("")
    const [followers, setFollowers] = useState({
        data: [],
        total_count: 0
    })
    const [following, setFollowing] = useState({
        data: [],
        total_count: 0
    })

    const [state, setState] = useState({
        account_status: "",
        admin_verified: false,
        connection_id: null,
        created_at: "",
        device_type: null,
        email: "",
        email_verified: false,
        fcm_token: null,
        following: null,
        is_blocked: false,
        is_deleted: false,
        last_seen: null,
        name: "",
        profile_pic: null,
        total_followers: 0,
        total_following: 0,
        unique_code: null,
        updated_at: "",
        user_name: "",
        user_status: 0,
        you_are_blocked: false,
        you_block_to: false,
    })
    const [resaleItems, setResaleItems] = useState({
        data: [],
        total_count: null,
        page: 0
    })
    const [soldItems, setSoldItems] = useState({
        data: [],
        total_count: null,
        page: 0
    })
    const [ownItems, setOwnItems] = useState({
        data: [],
        total_count: null,
        page: 0
    })
    const [createdItems, setCreatedItems] = useState({
        data: [],
        total_count: null,
        page: 0
    })
    const [bidsItems, setBidsItems] = useState({
        data: [],
        total_count: null,
        page: 0
    })
    const [favoritedItems, setFavoritedItems] = useState({
        data: [],
        total_count: null,
        page: 0
    })
    const [activityItems, setActivityItems] = useState({
        data: [],
        total_count: null,
        page: 0
    })

    const inialiseProfileTab = async (tab, page) => {
        let q = query.toString()
        try {
            setLoading(true)
            let apiRes = await henceforthApi.Profile.getByTab(match.params.id, tab, 10, q, staticState.explore, page)
            let data = apiRes.data
            setLoading(false)
            return data.nft_details
        } catch (error) {
            console.log("inialiseProfileTab", error);
        }
    }
    const inialiseTabData = async (tab, page) => {
        let { data, total_count } = await inialiseProfileTab(String(tab).toUpperCase(), page)
        let arrData = Array.isArray(data) ? data : []
        switch (tab) {
            case "on_sale": {
                let oldData = Array.isArray(resaleItems.data) ? resaleItems.data : []
                let newData = [...oldData, ...arrData]
                const uniqueData = Array.from(new Set(
                    newData.map(a => a._id))).map(id => {
                        return newData.find(a => a._id === id)
                    })
                setResaleItems({
                    ...resaleItems,
                    total_count: total_count ? total_count : 0,
                    data: page ? uniqueData : arrData,
                    page: page + 1
                })
                break;
            }
            case "sold": {
                let oldData = Array.isArray(soldItems.data) ? soldItems.data : []
                let newData = [...oldData, ...arrData]
                const uniqueData = Array.from(new Set(
                    newData.map(a => a._id))).map(id => {
                        return newData.find(a => a._id === id)
                    })
                setSoldItems({
                    ...soldItems,
                    total_count: total_count ? total_count : 0,
                    data: page ? uniqueData : arrData,
                    page: page + 1
                })
                break;
            }
            case "owned": {
                let oldData = Array.isArray(ownItems.data) ? ownItems.data : []
                let newData = [...oldData, ...arrData]
                const uniqueData = Array.from(new Set(
                    newData.map(a => a._id))).map(id => {
                        return newData.find(a => a._id === id)
                    })
                setOwnItems({
                    ...ownItems,
                    total_count: total_count ? total_count : 0,
                    data: page ? uniqueData : arrData,
                    page: page + 1
                })
                break;
            }
            case "created": {
                let oldData = Array.isArray(createdItems.data) ? createdItems.data : []
                let newData = [...oldData, ...arrData]
                const uniqueData = Array.from(new Set(
                    newData.map(a => a._id))).map(id => {
                        return newData.find(a => a._id === id)
                    })
                setCreatedItems({
                    ...createdItems,
                    total_count: total_count ? total_count : 0,
                    data: page ? uniqueData : arrData,
                    page: page + 1
                })
                break;
            }
            case "bids": {
                let oldData = Array.isArray(bidsItems.data) ? bidsItems.data : []
                let newData = [...oldData, ...arrData]
                const uniqueData = Array.from(new Set(
                    newData.map(a => a._id))).map(id => {
                        return newData.find(a => a._id === id)
                    })
                setBidsItems({
                    ...bidsItems,
                    total_count: total_count ? total_count : 0,
                    data: page ? uniqueData : arrData,
                    page: page + 1
                })
                break;
            }
            case "favourite": {
                let oldData = Array.isArray(favoritedItems.data) ? favoritedItems.data : []
                let newData = [...oldData, ...arrData]
                const uniqueData = Array.from(new Set(
                    newData.map(a => a._id))).map(id => {
                        return newData.find(a => a._id === id)
                    })
                setFavoritedItems({
                    ...favoritedItems,
                    total_count: total_count ? total_count : 0,
                    data: page ? uniqueData : arrData,
                    page: page + 1
                })
                break;
            }
            case "activity": {
                let oldData = Array.isArray(activityItems.data) ? activityItems.data : []
                let newData = [...oldData, ...arrData]
                const uniqueData = Array.from(new Set(
                    newData.map(a => a._id))).map(id => {
                        return newData.find(a => a._id === id)
                    })
                setActivityItems({
                    ...activityItems,
                    total_count: total_count ? total_count : 0,
                    data: page ? uniqueData : arrData,
                    page: page + 1
                })
                break;
            }
            default:
                break;
        }
    }

    const initialiseFollow = async () => {
        let items = {
            user_id: match.params.id,
            "language": "ENGLISH"
        }
        try {
            setFollowLoading(true)

            let apiRes;
            if (state.following) {
                apiRes = await henceforthApi.Follower.unfollow(items)
            } else {
                apiRes = await henceforthApi.Follower.follow(items)
            }
            setState({
                ...state,
                ...apiRes.data
            })
            await inialiseFollowers()
            setFollowLoading(false)
        } catch (error) {
            setFollowLoading(false)
            toast.error(JSON.stringify(error?.response?.body?.error_description))
        }
    }

    const initialiseProfile = async () => {
        try {
            let apiRes = await henceforthApi.Profile.getById(match.params.id)
            let data = apiRes.data
            setState({ ...data })

            await inialiseTabData("on_sale", 0)
            await inialiseTabData("sold", 0)
            await inialiseTabData("owned", 0)
            await inialiseTabData("created", 0)
            await inialiseTabData("bids", 0)
        } catch (error) {
            console.log("initialiseProfile error", error);
            if (error.response && error.response.body) {
                if (error.response.body.error == ERROR_UNAUTHORIZED) {
                    navigate(`/signin?redirect=${location.pathname}`, { replace: true })
                } else {
                    toast.error(error.response.body.error_description)
                }
            }
        }
    }
    const submitReport = async () => {
        if (reportText) {
            let items = {
                report_reason: reportText,
                language: "ENGLISH"
            }
            try {
                let apiRes = await henceforthApi.Profile.report(match.params.id, items)
                toast.success(apiRes.message)
                return apiRes
            } catch (error) {
                if (error.response && error.response.body) {
                    toast.error(error.response.body.error_description)
                } else {
                    console.log("error", error);
                }
                return null
            }
        } else {
            toast.warn("Please write your message first.")
            return null
        }

    }
    useEffect(() => {
        if (authState._id == match.params.id) {
            navigate(`/profile/on_sale`, { replace: true })
        } else {
            scrollToTop()
            initialiseProfile()
        }
    }, [match.params.id])

    useEffect(async () => {
        if (authState._id == match.params.id) {
            navigate(`/profile/on_sale`, { replace: true })
        } else {
            if (match.params.tab) {
                inialiseTabData(match.params.tab, 0)
            }
        }
    }, [match.params.tab, query.get("post_type"), query.get("category_id"), query.get("min_price"), query.get("max_price"), query.get("sort_by"), query.get("social_type")])

    const changeCategory = (value) => {
        if (value) {
            query.set("category_id", value)
        } else {
            query.delete("category_id")
        }
        navigate({ search: query.toString() })
    }
    const changeSocialType = (value) => {
        if (value) {
            query.set("social_type", value)
        } else {
            query.delete("social_type")
        }
        navigate({ search: query.toString() })
    }
    const changePostType = (value) => {
        if (value) {
            query.set("post_type", value)
        } else {
            query.delete("post_type")
        }
        navigate({ search: query.toString() })
    }
    const changeSortBy = (value) => {
        if (value) {
            query.set("sort_by", value)
        } else {
            query.delete("sort_by")
        }
        navigate({ search: query.toString() })
    }

    const enableDisablePrice = (b) => {
        if (b) {
            query.delete("min_price")
            query.delete("max_price")
            setMinPrice(0)
            setMaxPrice(0)
        } else {
            query.set("min_price", minPrice)
            query.set("max_price", maxPrice)
        }
        navigate({ search: query.toString() })
    }

    const inialiseFollowers = async (page) => {
        try {
            let apiRes = await henceforthApi.Follower.followers(match.params.id, 10, page)
            let data = apiRes.data
            setFollowers({
                ...followers,
                ...data
            })
        } catch (error) {
            toast.error(JSON.stringify(error?.response?.body?.error_description))
        }
    }

    const inialiseFollowing = async (page) => {
        try {
            let apiRes = await henceforthApi.Follower.followings(match.params.id, 10, page)
            let data = apiRes.data
            setFollowing({
                ...following,
                ...data
            })
        } catch (error) {
            toast.error(JSON.stringify(error?.response?.body?.error_description))
        }
    }
    useEffect(() => {
        if (navigator.onLine) {
            inialiseFollowers(0)
            inialiseFollowing(0)
        }
    }, [match.params.id])

    useEffect(() => {
        let total_count = 0
        let data_length = 0
        let page = 0
        switch (match.params.tab) {
            case "on_sale": {
                total_count = resaleItems.total_count
                data_length = resaleItems.data.length
                page = resaleItems.page
                break;
            }
            case "sold": {
                total_count = soldItems.total_count
                data_length = soldItems.data.length
                page = soldItems.page
                break;
            }
            case "owned": {
                total_count = ownItems.total_count
                data_length = ownItems.data.length
                page = ownItems.page
                break;
            }
            case "created": {
                total_count = createdItems.total_count
                data_length = createdItems.data.length
                page = createdItems.page
                break;
            }
            case "favourite": {
                total_count = favoritedItems.total_count
                data_length = favoritedItems.data.length
                page = favoritedItems.page
                break;
            }
            case "bids": {
                total_count = bidsItems.total_count
                data_length = bidsItems.data.length
                page = bidsItems.page
                break;
            }
            case "activity": {
                total_count = activityItems.total_count
                data_length = activityItems.data.length
                page = activityItems.page
                break;
            }
        }
        if ((window.innerHeight + document.documentElement.scrollTop) + 400 >= document.scrollingElement.scrollHeight) {
            if (data_length) {
                if (!loading) {
                    if (total_count != data_length) {
                        console.log("Loading......");
                        inialiseTabData(match.params.tab, page)
                    } else {
                        console.log("Data ended");
                    }
                }
            }
        }
    }, [loadMore])

    return <section className="activity-wrapper mt-3">
        <div className="container">
            <div className="row">
                <div className="col-xl-3 col-lg-4 position-relative mt-4">
                    <div className="border rounded-3 p-4" style={{ position: "sticky", top: "24px" }}>
                        <div className="profile-image position-relative m-auto">
                            <img src={state.profile_pic ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${state.profile_pic}` : profile_placeholder} />
                        </div>
                        <div className="text-center">
                            <h4 className="fw-bold fw-bold mt-4 mb-1 profile-name text-break">{state.name}</h4>
                            <h6 className="text-gray profile-subname text-break">@{state.user_name}</h6>
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="left-icon profile-share-icon  bg-white d-flex justify-content-center  my-3">
                                    <button className="btn border border-top border-bottom share-icon px-3 shadow-none" id="dropdownShare" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fas fa-share-alt text-muted m-0 fs-6"></i>
                                    </button>
                                    <ShareOptions />
                                </div>
                                <div>
                                    <button className="btn border border-top border-bottom share-icon dot-icon px-3 shadow-none br-rounded" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fa-solid fa-ellipsis text-muted fs-6"></i>
                                    </button>
                                    {authState.access_token ?
                                        <ul className="dropdown-menu share-dropdown dropdown-menu-end">
                                            <li data-bs-toggle="modal" data-bs-target="#reportModal"><button className="dropdown-item fw-semi-bold text-center fs-14" type="button">Report</button></li>
                                        </ul> :
                                        <ul className="dropdown-menu share-dropdown dropdown-menu-end">
                                            <li ><Link to={`/signin?redirect=${location.pathname}&action=report`}><button className="dropdown-item fw-semi-bold text-center fs-14" type="button">Report</button></Link></li>
                                        </ul>}
                                </div>
                            </div>
                            {staticState.explore == EXPLORE_LANDING_MARKET_PLACE && <>
                                <div className="border border-end-0 border-start-0 border-bottom-0">
                                    <div
                                        className="folloers d-flex justify-content-center align-items-center w-75 m-auto ">
                                        <div className="five-nine me-4 lh-sm cursor-pointer text-center" data-bs-toggle="modal" data-bs-target="#followersModal">
                                            <h6 className="text-center mb-0 fs-6 fw-bold pt-3">{followers?.total_count}</h6>
                                            <p className="text-muted"><small>Followers</small></p>
                                        </div>
                                        <div className="five-nine me-2 lh-sm me-2 cursor-pointer text-center" data-bs-toggle="modal" data-bs-target="#followingModal">
                                            <h6 className="text-center mb-0 fw-bold pt-3 fs-6">{following?.total_count}</h6>
                                            <p className="text-muted"><small>Following</small></p>
                                        </div>
                                    </div>
                                </div>
                                <button className="btn btn-outline-primary fs-12 w-100 fw-semi-bold" onClick={() => initialiseFollow()} disabled={followLoading || state.following === null || !authState?._id}>{followLoading ? <Spinner /> : state.following ? 'Unfollow' : 'Follow'}</button>
                            </>}

                            {staticState.explore == EXPLORE_LANDING_SOCIAL && state.instagram && state.instagram.user_name &&
                                <div className="border border-end-0 border-start-0 border-bottom-0">
                                    <div
                                        className="folloers d-flex justify-content-center align-items-center w-75 m-auto ">
                                        <div className="five-nine me-4 lh-sm text-center">
                                            <h6 className="text-center mb-0 fs-6 fw-bold pt-3">{subscribeFormatter(state.instagram.count ? state?.instagram?.count?.follower_count : 0)}</h6>
                                            <p className="text-muted"><small>Followers</small></p>
                                        </div>
                                        <div className="five-nine me-2 lh-sm me-2 text-center">
                                            <h6 className="text-center mb-0 fw-bold pt-3 fs-6">{subscribeFormatter(state.instagram.count ? state?.instagram?.count?.following_count : 0)}</h6>
                                            <p className="text-muted"><small>Following</small></p>
                                        </div>
                                    </div>
                                    <button className="btn btn-outline-primary fw-semi-bold mb-3 text-lowercase w-100 text-nowrap text-truncate mw-100" onClick={() => window.open(`https://www.instagram.com/${state.instagram.user_name}/`)}>
                                        <img src={InstagramIcon} className="me-2 insta-small" />{state.instagram.user_name}
                                    </button>
                                </div>}

                            {staticState.explore == EXPLORE_LANDING_SOCIAL && state.tiktok && state.tiktok.user_name &&
                                <div className="">
                                    <div
                                        className="folloers d-flex justify-content-center align-items-center w-75 m-auto ">
                                        <div className="five-nine me-4 lh-sm text-center">
                                            <h6 className="text-center mb-0 fs-6 fw-bold pt-3">{subscribeFormatter(state.tiktok.count ? state.tiktok?.count?.follower_count : 0)}</h6>
                                            <p className="text-muted"><small>Followers</small></p>
                                        </div>
                                        <div className="five-nine me-2 lh-sm me-2 text-center">
                                            <h6 className="text-center mb-0 fw-bold pt-3 fs-6">{subscribeFormatter(state.tiktok.count ? state.tiktok?.count?.following_count : 0)}</h6>
                                            <p className="text-muted"><small>Following</small></p>
                                        </div>
                                    </div>
                                    <button className="btn btn-outline-primary fw-semi-bold mb-3 text-lowercase w-100 text-nowrap text-truncate mw-100" onClick={() => window.open(`https://www.tiktok.com/@${state.tiktok?.user_name}/`)}><img src={TiktokIcon} className="me-2" />{state.tiktok.user_name}</button>
                                </div>}
                        </div>
                    </div>
                </div>
                <div className="col-xl-9 col-lg-8 nav-tabss">
                    <div className="on-salle pb-5 mb-lg-5">

                        <ul className="nav nav-pills mb-3 border-bottom profile-tabs-row" id="pills-tab" role="tablist">
                            <li className="nav-item me-2" role="presentation">
                                <button className={`nav-link ${match.params.tab == 'on_sale' ? 'active' : ''} text-secondary fs-6 fw-semi-bold bg-transparent ps-0`}
                                    id="pills-sale-tab" data-bs-toggle="pill" data-bs-target="#pills-sale" type="button"
                                    role="tab" aria-controls="pills-sale" aria-selected="true" onClick={() => navigate(`/profile/${match.params.id}/on_sale`, { replace: true })}>For Sale
                                    <small className="bg-medium-gray px-1 text-gray ms-1 fs-12 rounded-2">{resaleItems.total_count ? resaleItems.total_count : 0}</small></button>
                            </li>
                            <li className="nav-item me-2" role="presentation">
                                <button className={`nav-link ${match.params.tab == 'sold' ? 'active' : ''} text-secondary fs-6 fw-semi-bold bg-transparent ps-0`}
                                    id="pills-sold-tab" data-bs-toggle="pill" data-bs-target="#pills-sold"
                                    type="button" role="tab" aria-controls="pills-sold" aria-selected="false" onClick={() => navigate(`/profile/${match.params.id}/sold`, { replace: true })}>Sold
                                    <small className="bg-medium-gray px-1 text-gray ms-1 fs-12 rounded-2">{soldItems.total_count ? soldItems.total_count : 0}</small></button>
                            </li>
                            <li className="nav-item me-2" role="presentation">
                                <button className={`nav-link ${match.params.tab == 'owned' ? 'active' : ''} text-secondary fs-6 fw-semi-bold bg-transparent ps-0`}
                                    id="pills-owned-tab" data-bs-toggle="pill" data-bs-target="#pills-owned"
                                    type="button" role="tab" aria-controls="pills-owned" aria-selected="false" onClick={() => navigate(`/profile/${match.params.id}/owned`, { replace: true })}>Owned
                                    <small className="bg-medium-gray px-1 text-gray ms-1 fs-12 rounded-2">{ownItems.total_count ? ownItems.total_count : 0}</small></button>
                            </li>
                            <li className="nav-item me-2" role="presentation">
                                <button className={`nav-link ${match.params.tab == 'created' ? 'active' : ''} text-secondary fs-6 fw-semi-bold bg-transparent ps-0`}
                                    id="pills-created-tab" data-bs-toggle="pill" data-bs-target="#pills-created" type="button" role="tab"
                                    aria-controls="pills-created" aria-selected="false" onClick={() => navigate(`/profile/${match.params.id}/created`, { replace: true })}>Created
                                    <small className="bg-medium-gray px-1 text-gray fs-12  rounded-2">{createdItems.total_count ? createdItems.total_count : 0}</small></button>
                            </li>
                            <li className="nav-item me-2" role="presentation">
                                <button className={`nav-link ${match.params.tab == 'bids' ? 'active' : ''} text-secondary fs-6 fw-semi-bold bg-transparent ps-0`} id="pills-bid-tab"
                                    data-bs-toggle="pill" data-bs-target="#pills-bid" type="button" role="tab"
                                    aria-controls="pills-bid" aria-selected="false" onClick={() => navigate(`/profile/${match.params.id}/bids`, { replace: true })}>Bid
                                    <small className="bg-medium-gray px-1 text-gray fs-12  rounded-2">{bidsItems.total_count ? bidsItems.total_count : 0}</small></button>
                            </li>

                            <li className="nav-item me-2" role="presentation">
                                <button className={`nav-link ${match.params.tab == 'activity' ? 'active' : ''} text-secondary fs-6 fw-semi-bold bg-transparent ps-0`} id="profile-tab"
                                    data-bs-toggle="pill" data-bs-target="#profile" type="button" role="tab"
                                    aria-controls="profile" aria-selected="false" onClick={() => navigate(`/profile/${match.params.id}/activity`, { replace: true })}>Activity</button>
                            </li>
                        </ul>
                        <div className="d-flex justify-content-between mb-4 ">
                            <div className="d-flex flex-wrap">
                                <div className="dropdown my-1">
                                    <button className={`btn btn-outline-primary px-4 text-capitalize fw-medium me-2`} type="button" id="dropdownMenuCategory" data-bs-toggle="dropdown" aria-expanded="false">
                                        {getCategoryNameViaIdAndList(categories, query.get("category_id"), "Category")}
                                    </button>
                                    <ul className="dropdown-menu common-dropdown filters" aria-labelledby="dropdownMenuCategory">
                                        <li onClick={() => changeCategory("")}><a className="dropdown-item fw-medium" href="#"><img src={All} className="icon me-2" />All</a></li>
                                        {categories.map(res => {
                                            return <li key={res._id} onClick={() => changeCategory(res._id)}><a className="dropdown-item fw-medium" href="#"><img src={String(res.image).includes('https://') ? res.image : `${henceforthApi.API_FILE_ROOT_MEDIUM}${res.image}`} className="icon me-2" />{res.name} {res._id == query.get("category_id") ? <i className="fa-solid fa-check text-theme"></i> : ''}</a> </li>
                                        })}
                                    </ul>
                                </div>
                                <div className="dropdown my-1">
                                    {/* <button className="btn btn-outline-primary px-4 text-capitalize fw-medium me-2" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        Collection
                                    </button> */}
                                    <ul className="dropdown-menu common-dropdown p-0 filters" aria-labelledby="dropdownMenuButton1">
                                        <li>
                                            <div className="position-relative py-3 px-4 border-bottom">
                                                <input className="form-control w-lg-50 w-100 me-1 shadow-none search-input fs-12 s-space" type="search" placeholder="Search in collection" aria-label="Search" />
                                                <i className="fa-solid fa-magnifying-glass text-theme position-absolute top-50 translate-middle-y left-0 ms-3"></i>
                                            </div>
                                        </li>

                                        <li><a className="fw-medium py-1 px-4 mt-2 d-flex align-items-center fs-12" href="#"><span className="col-circle-1 me-3"></span>Collection One</a></li>
                                        <li><a className="fw-medium py-1 px-4 mb-2 d-flex align-items-center fs-12" href="#"><span className="col-circle-2 me-3"></span>Collection Two</a></li>
                                        <li className="d-flex px-4 py-3 border-top ">
                                            <button className="btn btn-outline-primary me-1 fw-medium fs-12 px-4" >CLEAR</button>
                                            <button className="btn btn-primary fs-12 fs-12 px-4">APPLY</button>
                                        </li>
                                    </ul>
                                </div>
                                {staticState.explore == EXPLORE_LANDING_MARKET_PLACE &&
                                    <div className="dropdown my-1">
                                        <button className="btn btn-outline-primary px-4 text-capitalize fw-medium me-2" type="button" id="dropdownMenuType" data-bs-toggle="dropdown" aria-expanded="false">
                                            {query.has("post_type") ? query.get("post_type") == EXPLORE_NFT_POST_TYPE_SINGLE ? 'Single' : query.get("post_type") == EXPLORE_NFT_POST_TYPE_MULTIPLE ? 'Multiple' : 'Type' : 'Type'}
                                        </button>
                                        <ul className="dropdown-menu common-dropdown filters" aria-labelledby="dropdownMenuType">
                                            <li onClick={() => changePostType("")}><a className="dropdown-item fw-medium fs-12 mb-1" href="#"><img src={All} className="icon me-2" />All</a></li>
                                            <li onClick={() => changePostType(EXPLORE_NFT_POST_TYPE_SINGLE)}><a className="dropdown-item fw-medium fs-12 mb-1" href="#"><img src={Single} className="icon me-2" />Single {EXPLORE_NFT_POST_TYPE_SINGLE == query.get("post_type") ? <i className="fa-solid fa-check text-theme"></i> : ''}</a></li>
                                            <li onClick={() => changePostType(EXPLORE_NFT_POST_TYPE_MULTIPLE)}><a className="dropdown-item fw-medium fs-12 mb-1" href="#"><img src={Multiple} className="icon me-2" />Multiple {EXPLORE_NFT_POST_TYPE_MULTIPLE == query.get("post_type") ? <i className="fa-solid fa-check text-theme"></i> : ''}</a></li>
                                        </ul>
                                    </div>}
                                {staticState.explore == EXPLORE_LANDING_SOCIAL &&
                                    <div className="dropdown my-1">
                                        <button className="btn btn-outline-primary px-4 text-capitalize fw-medium me-2" type="button" id="dropdownMenuType" data-bs-toggle="dropdown" aria-expanded="false">
                                            {query.has("social_type") ? query.get("social_type") == EXPLORE_NFT_TYPE_INSTAGRAM ? 'Instagram' : query.get("social_type") == EXPLORE_NFT_TYPE_TIKTOK ? 'Tiktok' : 'Social Type' : 'Social Type'}
                                        </button>
                                        <ul className="dropdown-menu common-dropdown filters" aria-labelledby="dropdownMenuType">
                                            <li onClick={() => changeSocialType("")}><a className="dropdown-item fw-medium fs-12 mb-1" href="#"><img src={All} className="icon me-2" />All</a></li>
                                            <li onClick={() => changeSocialType(EXPLORE_NFT_TYPE_INSTAGRAM)}><a className="dropdown-item fw-medium fs-12 mb-1" href="#"><img src={InstagramIcon} className="icon me-2" />Instagram {EXPLORE_NFT_TYPE_INSTAGRAM == query.get("social_type") ? <i className="fa-solid fa-check text-theme"></i> : ''}</a></li>
                                            <li onClick={() => changeSocialType(EXPLORE_NFT_TYPE_TIKTOK)}><a className="dropdown-item fw-medium fs-12 mb-1" href="#"><img src={TiktokIcon} className="icon me-2" />Tiktok {EXPLORE_NFT_TYPE_TIKTOK == query.get("social_type") ? <i className="fa-solid fa-check text-theme"></i> : ''}</a></li>
                                        </ul>
                                    </div>}
                                <div className="dropdown my-1">
                                    <button className="btn btn-outline-primary px-4 text-capitalize fw-medium me-2" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        {query.has("min_price") ? `${query.get("min_price")} - ${query.get("max_price")} ELEN ` : 'Price Range'}
                                    </button>
                                    <ul className="dropdown-menu common-dropdown p-0 filters" aria-labelledby="dropdownMenuButton1">
                                        {/* <li className="px-4 border-bottom py-3">
                                            <select className="form-control form-select text-theme fs-12 border-pink">
                                                <option value="ETH">ETH</option>
                                                <option value="WETH">WETH</option>
                                            </select>
                                        </li> */}
                                        <li><a className="fw-medium py-1 px-4 my-2 d-flex align-items-center" href="#">
                                            <input type="number" className="form-control me-2 border-pink" min={0} value={minPrice} placeholder="From" onChange={(e) => setMinPrice(e.target.value)} />
                                            <input type="number" className="form-control border-pink" min={0} value={maxPrice} placeholder="To" onChange={(e) => setMaxPrice(e.target.value)} />
                                        </a></li>
                                        <li className="d-flex px-4 py-3 border-top ">
                                            <button type="button" className="btn btn-outline-primary me-1 fw-medium fs-12 px-4" onClick={() => enableDisablePrice(true)}>CLEAR</button>
                                            <button type="button" className="btn btn-primary fs-12 px-4" onClick={() => enableDisablePrice(false)}>APPLY</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="dropdown">
                                <button className="btn btn-outline-primary px-4 text-capitalize fw-medium recently-added position-relative text-nowrap" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    {query.get("sort_by") == "RECENTLY_ADDED" ? 'Recently Added' : query.get("sort_by") == "PRICE_HIGH_TO_LOW" ? 'Price -- High to Low' : query.get("sort_by") == "PRICE_LOW_TO_HIGH" ? 'Price -- Low to High' : query.get("sort_by") == "AUCTION_ENDING_SOON" ? 'Auction Ending Soon' : 'All'}

                                    <span className="sort position-absolute start-50 translate-middle-x bg-white text-theme">Sort</span>
                                </button>
                                <ul className="dropdown-menu common-dropdown dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                    <li onClick={() => changeSortBy("RECENTLY_ADDED")}><a className="dropdown-item fw-medium added d-flex justify-content-between align-items-center" href="#"><span className="sort-text">Recently Added</span>  {query.get("sort_by") == "RECENTLY_ADDED" ? <i className="fa-solid fa-check text-theme"></i> : ''}</a></li>

                                    <li onClick={() => changeSortBy("PRICE_HIGH_TO_LOW")}><a className="dropdown-item fw-medium d-flex justify-content-between align-items-center" href="#"><span className="sort-text">Price -- High to Low</span>{query.get("sort_by") == "PRICE_HIGH_TO_LOW" ? <i className="fa-solid fa-check text-theme"></i> : ''}</a></li>

                                    <li onClick={() => changeSortBy("PRICE_LOW_TO_HIGH")}><a className="dropdown-item fw-medium d-flex justify-content-between align-items-center" href="#"><span className="sort-text">Price -- Low to High</span>{query.get("sort_by") == "PRICE_LOW_TO_HIGH" ? <i className="fa-solid fa-check text-theme"></i> : ''}</a></li>

                                    <li onClick={() => changeSortBy("AUCTION_ENDING_SOON")}><a className="dropdown-item fw-medium d-flex justify-content-between align-items-center" href="#"><span className="sort-text">Auction Ending Soon</span>{query.get("sort_by") == "AUCTION_ENDING_SOON" ? <i className="fa-solid fa-check text-theme"></i> : ''}</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="tab-content" id="pills-tabContent">
                            <div className={`tab-pane fade  ${match.params.tab == 'on_sale' ? 'show active' : ''}`} id="pills-sale" role="tabpanel"
                                aria-labelledby="pills-sale-tab">
                                <NftsMapList items={resaleItems} _id={authState._id} elenToUsd={elenToUsd} />
                            </div>

                            <div className={`tab-pane fade  ${match.params.tab == 'sold' ? 'show active' : ''}`} id="pills-sold" role="tabpanel"
                                aria-labelledby="pills-sold-tab">
                                <NftsMapList items={soldItems} _id={authState._id} elenToUsd={elenToUsd} />
                            </div>

                            <div className={`tab-pane fade  ${match.params.tab == 'owned' ? 'show active' : ''}`} id="pills-owned" role="tabpanel"
                                aria-labelledby="pills-owned-tab">
                                <NftsMapList items={ownItems} _id={authState._id} elenToUsd={elenToUsd} />
                            </div>
                            <div className={`tab-pane fade  ${match.params.tab == 'created' ? 'show active' : ''}`} id="pills-created" role="tabpanel"
                                aria-labelledby="pills-created-tab">
                                <NftsMapList items={createdItems} _id={authState._id} elenToUsd={elenToUsd} />
                            </div>
                            <div className={`tab-pane fade  ${match.params.tab == 'bids' ? 'show active' : ''}`} id="pills-bid" role="tabpanel"
                                aria-labelledby="pills-bid-tab">
                                <NftsMapList items={bidsItems} _id={authState._id} elenToUsd={elenToUsd} />
                            </div>

                            <div className={`tab-pane fade  ${match.params.tab == 'activity' ? 'show active' : ''}`} id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <div className="nft-activity mt-lg-4  mt-4">
                                    {activityItems && Array.isArray(activityItems.data) && activityItems.data.map(res => <ActivitiesList key={res._id} {...res} />)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modals.ReportModal text={reportText} setText={setReportText} onSubmit={submitReport} />
        <Modals.Followers authState={authState} {...followers} inialiseFollowers={inialiseFollowers} />
        <Modals.Following authState={authState} {...following} inialiseFollowing={inialiseFollowing} />
    </section>
}