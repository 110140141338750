// import ScrollToTop from "../../components/HOC/ScrollToTop"

import ScrollToTop from "../../components/HOC/ScrollToTop";

const PrivacyPolicy = () => {
  return (
    <div className="inner-pages-wrapper">
      <div className="container">
        <div className="WordSection1 term-condition mt-5 title-col">
          <h2 className="MsoNormal mt-4">
            <span>Everlens Privacy Policy</span>
          </h2>
          <br />
          <p className="mb-2">
            <span>
              At everlens.io, accessible from everlens.io, one of our main priorities is the privacy
              of our visitors. This Privacy Policy document contains types of information that is
              collected and recorded by everlens.io and how we use it.
            </span>
          </p>
          <p>
            <span>
              If you have additional questions or require more information about our Privacy Policy,
              do not hesitate to contact us.
            </span>
          </p>
          <p>
            <span>
              This Privacy Policy applies only to our online activities and is valid for visitors to
              our website with regards to the information that they shared and/or collect in
              everlens.io. This policy is not applicable to any information collected offline or via
              channels other than this website. Our Privacy Policy was created with the help of the
              Privacy Policy Generator.
            </span>
          </p>
          <p className="mb-2">
            <b>
              <span>Consent</span>
            </b>
          </p>
          <p>
            <span>
              By using our website, you hereby consent to our Privacy Policy and agree to its terms.
            </span>
          </p>
          <p className="mb-2">
            <b>
              <span>Information we collect</span>
            </b>
          </p>
          <p>
            <span>
              The personal information that you are asked to provide, and the reasons why you are
              asked to provide it, will be made clear to you at the point we ask you to provide your
              personal information.
            </span>
          </p>
          <p>
            <span>
              If you contact us directly, we may receive additional information about you such as
              your name, email address, phone number, the contents of the message and/or attachments
              you may send us, and any other information you may choose to provide.
            </span>
          </p>
          <p>
            <span>
              When you register for an Account, we may ask for your contact information, including
              items such as name, company name, address, email address, and telephone number.
            </span>
          </p>
          <p className="mb-2">
            <b>
              <span>How we use your informatio</span>
            </b>
            <span>n</span>
          </p>
          <p>
            <span>We use the information we collect in various ways, including to:</span>
          </p>
          <p className="mb-0">
            <span>Provide, operate, and maintain our website</span>
          </p>
          <p className="mb-0">
            <span>Improve, personalize, and expand our website</span>
          </p>
          <p className="mb-0">
            <span>Understand and analyze how you use our website</span>
          </p>
          <p className="mb-0">
            <span>Develop new products, services, features, and functionality</span>
          </p>
          <p className="mb-0">
            <span>
              Communicate with you, either directly or through one of our partners, including for
              customer service, to provide you with updates and other information relating to the
              website, and for marketing and promotional purposes
            </span>
          </p>
          <p className="mb-0">
            <span>Send you emails</span>
          </p>
          <p>
            <span>Find and prevent fraud</span>
          </p>
          <p className="mb-2">
            <b>
              <span>Log Files</span>
            </b>
          </p>
          <p>
            <span>
              everlens.io follows a standard procedure of using log files. These files log visitors
              when they visit websites. All hosting companies do this and a part of hosting
              services' analytics. The information collected by log files include internet protocol
              (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp,
              referring/exit pages, and possibly the number of clicks. These are not linked to any
              information that is personally identifiable. The purpose of the information is for
              analyzing trends, administering the site, tracking users' movement on the website, and
              gathering demographic information.
            </span>
          </p>
          <p className="mb-2">
            <b>
              <span>Advertising Partners Privacy Policies</span>
            </b>
          </p>
          <p>
            <span>
              You may consult this list to find the Privacy Policy for each of the advertising
              partners of everlens.io.
            </span>
          </p>
          <p>
            <span>
              Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or
              Web Beacons that are used in their respective advertisements and links that appear on
              everlens.io, which are sent directly to users' browser. They automatically receive
              your IP address when this occurs. These technologies are used to measure the
              effectiveness of their advertising campaigns and/or to personalize the advertising
              content that you see on websites that you visit.
            </span>
          </p>
          <p>
            <span>
              Note that everlens.io has no access to or control over these cookies that are used by
              third-party advertisers.
            </span>
          </p>
          <p className="mb-2 ">
            <b>
              <span>Third Party Privacy Policies</span>
            </b>
          </p>
          <p>
            <span>
              everlens.io's Privacy Policy does not apply to other advertisers or websites. Thus, we
              are advising you to consult the respective Privacy Policies of these third-party ad
              servers for more detailed information. It may include their practices and instructions
              about how to opt-out of certain options.
            </span>
          </p>
          <p>
            <span>
              You can choose to disable cookies through your individual browser options. To know
              more detailed information about cookie management with specific web browsers, it can
              be found at the browsers' respective websites.
            </span>
          </p>
          <p className="mb-2">
            <b>
              <span>CCPA Privacy Rights (Do Not Sell My Personal Information)</span>
            </b>
          </p>
          <p>
            <span>Under the CCPA, among other rights, California consumers have the right to:</span>
          </p>
          <p>
            <span>
              Request that a business that collects a consumer's personal data disclose the
              categories and specific pieces of personal data that a business has collected about
              consumers.
            </span>
          </p>
          <p>
            <span>
              Request that a business delete any personal data about the consumer that a business
              has collected.
            </span>
          </p>
          <p>
            <span>
              Request that a business that sells a consumer's personal data, not sell the consumer's
              personal data.
            </span>
          </p>
          <p>
            <span>
              If you make a request, we have one month to respond to you. If you would like to
              exercise any of these rights, please contact us.
            </span>
          </p>
          <p className="mb-2">
            <b>
              <span>GDPR Data Protection Rights</span>
            </b>
          </p>
          <p>
            <span>
              We would like to make sure you are fully aware of all of your data protection rights.
              Every user is entitled to the following:
            </span>
          </p>
          <p>
            <span>
              The right to access – You have the right to request copies of your personal data. We
              may charge you a small fee for this service.
            </span>
          </p>
          <p>
            <span>
              The right to rectification – You have the right to request that we correct any
              information you believe is inaccurate. You also have the right to request that we
              complete the information you believe is incomplete.
            </span>
          </p>
          <p>
            <span>
              The right to erasure – You have the right to request that we erase your personal data,
              under certain conditions.
            </span>
          </p>
          <p>
            <span>
              The right to restrict processing - You have the right to request that we restrict the
              processing of your personal data, under certain conditions.
            </span>
          </p>
          <p>
            <span>
              The right to object to processing – You have the right to object to our processing of
              your personal data, under certain conditions.
            </span>
          </p>
          <p>
            <span>
              The right to data portability – You have the right to request that we transfer the
              data that we have collected to another organization, or directly to you, under certain
              conditions.
            </span>
          </p>
          <p>
            <span>
              If you make a request, we have one month to respond to you. If you would like to
              exercise any of these rights, please contact us.
            </span>
          </p>
          <p className="mb-2">
            <b>
              <span>Children's Information</span>
            </b>
          </p>
          <p>
            <span>
              Another part of our priority is adding protection for children while using the
              internet. We encourage parents and guardians to observe, participate in, and/or
              monitor and guide their online activity.
            </span>
          </p>
          <p>
            <span>
              everlens.io does not knowingly collect any Personal Identifiable Information from
              children under the age of 13. If you think that your child provided this kind of
              information on our website, we strongly encourage you to contact us immediately and we
              will do our best efforts to promptly remove such information from our records.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};


export default ScrollToTop(PrivacyPolicy);