import WalletConnectProvider from "@walletconnect/web3-provider"
import { ethers } from "ethers"
import { toast } from "react-toastify"
import { SOCIAL_TYPE_META_MASK, SOCIAL_TYPE_WALLET_CONNECT } from "../context/actionTypes"
import henceforthAbis from "./henceforthAbis"
import henceforthApi from "./henceforthApi"
import henceforthRpc, { ERC1155_BSC_MAINNET_ADDRESS_KEY, ERC721_BSC_MAINNET_ADDRESS_KEY, WALLET_CONNECT_INFURA_ID, COLLECTION721_BSC_MAINNET_ADDRESS_KEY, COLLECTION1155_BSC_MAINNET_ADDRESS_KEY, MARKET_PLACE_BSC_MAINNET_ADDRESS_KEY, ERC721_BSC_TESTNET_ADDRESS_KEY, ERC1155_BSC_TESTNET_ADDRESS_KEY, MARKET_PLACE_BSC_TESTNET_ADDRESS_KEY, COLLECTION721_BSC_TESTNET_ADDRESS_KEY, COLLECTION1155_BSC_TESTNET_ADDRESS_KEY } from "./henceforthRpc"

const ethereumInstalled = () => {
    return window.ethereum
}

const RequestMetamaskAddAssest = async (wrapped) => {
    debugger
    let ethereum = ethereumInstalled()
    try {
        await ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: wrapped,
            },
        })
        return wrapped.address
    } catch (error) {
        return null
    }
}
const RequestMetamaskAddChain = async (rpc, wrapped) => {
    debugger
    let ethereum = ethereumInstalled()
    if (ethereum) {
        try {
            await ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [rpc],
            });
            await RequestMetamaskAddAssest(wrapped)

        } catch (addError) {
            console.error(addError);
        }
    }
}
const RequestMetamaskChangeChain = async (network) => {
    const { rpc, wrapped, ...props } = henceforthRpc(network)
    let ethereum = await ethereumInstalled()
    if (ethereum) {
        try {
            let result = await ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: rpc.chainId }],
            });
            return { result, rpc, wrapped, ...props }
        } catch (error) {
            if (error.code === 4902) {
                let result = await RequestMetamaskAddChain(rpc, wrapped)
                return { result, rpc, wrapped, ...props }
            }
            console.error(error);
        }
    } else {
        // if (authState.provider === SOCIAL_TYPE_META_MASK) {
        //     alert('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
        // }
        return { rpc, wrapped, ...props }
    }
}
const walletConnectProvider = new WalletConnectProvider({
    infuraId: WALLET_CONNECT_INFURA_ID,
    bridge: "https://bridge.walletconnect.org",
    qrcode: true,
    rpc: {
        56: 'https://bsc-dataseed.binance.org/',
        97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    },
    qrcodeModalOptions: {
        mobileLinks: [
            "rainbow",
            "metamask",
            "argent",
            "trust",
            "imtoken",
            "pillar",
        ],
    },
})

walletConnectProvider.on("accountsChanged", (accounts) => {
    console.log("accountsChanged", accounts);
});

walletConnectProvider.on("chainChanged", (chainId) => {
    console.log("chainChanged", chainId);

});

walletConnectProvider.on("disconnect", (code, reason) => {
    console.log(code, reason);
    if (window !== undefined) {
        walletConnectProvider.disconnect()
        window.location.reload()
    }
});

const loginWithWalletConnect = async () => {
    try {
        const connected = walletConnectProvider.connected
        const isConnecting = walletConnectProvider.isConnecting
        if (connected === false) {
            if (isConnecting) {
                window.location.reload()
            }
            await walletConnectProvider.enable()
        }
        const provider = new ethers.providers.Web3Provider(walletConnectProvider)
        let listAccounts = await provider.listAccounts()
        let network = await provider.getNetwork()
        return { accounts: listAccounts, eth_chainId: network.chainId, provider }

    } catch (error) {
        console.log("Error", error);
        debugger
        return null
    }
}
const loginWithMetamaskConnect = async () => {
    const ethereum = ethereumInstalled()
    if (ethereum) {
        try {
            const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
            const eth_chainId = parseInt(await ethereum.request({ method: 'eth_chainId' }), 16)
            const provider = new ethers.providers.Web3Provider(ethereum)
            return { accounts, eth_chainId, provider }

        } catch (error) {
            console.log("loginWithMetamaskConnect error", error);
            return { error }
        }
    } else {
        window.open(`https://metamask.app.link/dapp/${window.location.hostname}`)
    }
    console.log("loginWithMetamaskConnect null");
    return null
}
const getProvider = async (authProvider) => {
    try {
        const { accounts, eth_chainId, provider } = (authProvider === SOCIAL_TYPE_META_MASK) ? await loginWithMetamaskConnect() : (authProvider === SOCIAL_TYPE_WALLET_CONNECT) ? await loginWithWalletConnect() : console.log("Please connect wallet first")
        let balanceInWei = await provider.getBalance(accounts[0])
        let gasPriceInWei = await provider.getGasPrice()

        let balanceInEth = ethers.utils.formatEther(balanceInWei)
        let gasPriceInEth = ethers.utils.formatEther(gasPriceInWei)
        // setBalanceInEth(balanceInEth)
        // setGasPriceInEth(gasPriceInEth)
        // setAccounts(accounts)

        return { provider, accounts, eth_chainId, balanceInEth, gasPriceInEth, error: null }
    } catch (error) {
        return { error }
    }
}
const getContract = async (address, abi, authProvider) => {
    const { provider, accounts, eth_chainId, balance, gasPrice } = await getProvider(authProvider)
    const signer = provider.getSigner()
    const contract = new ethers.Contract(address, abi, signer);
    return { contract, accounts, provider, signer, eth_chainId, accountBalance: balance, gasPrice }
}
const importCollection = async (contractAddress, authProvider) => {
    debugger
    const abi = henceforthAbis.ERC721();
    const { contract, accounts, eth_chainId } = await getContract(contractAddress, abi, authProvider);
    let owner = "";
    let symbol = "";
    let name = "";
    let uri = "";
    try {
        owner = await contract.functions.owner()
        symbol = await contract.functions.symbol()
        name = await contract.functions.name()
        uri = await contract.functions.contractURI()
        return { owner, symbol, name, uri, accounts, eth_chainId }
    } catch (error) {
        return { error, owner, symbol, name, uri, accounts, eth_chainId }
    }
}
const accessERC20 = async (address, marketplaceAddr, authProvider, checkOfAddress) => {
    console.log('accessERC20 called');
    const abi = henceforthAbis.IERC20();
    const { contract, accounts, provider, signer, eth_chainId, accountBalance } = await getContract(address, abi, authProvider);
    const allowanceERC20Tx = await contract.allowance(checkOfAddress ? checkOfAddress : accounts[0], marketplaceAddr)
    const balanceOfERC20Tx = await contract.balanceOf(checkOfAddress ? checkOfAddress : accounts[0])
    console.log('balanceOfERC20Tx', balanceOfERC20Tx);
    return { allowanceERC20Tx, balanceOfERC20Tx, contract, provider, accountBalance, accounts, signer, eth_chainId }
}

const wrappedContract = async (actualPrice, wrapped, marketplaceAddr, authProvider) => {
    console.log('wrappedContract called');
    const etherPrice = ethers.utils.parseEther(Number(actualPrice).toFixed(18));
    // const options = { value: etherPrice }
    const { allowanceERC20Tx, balanceOfERC20Tx, contract, provider, accountBalance, accounts, signer, eth_chainId } = await accessERC20(wrapped.address, marketplaceAddr, authProvider, "")
    // const balanceOfERC20 = ethers.utils.formatEther(balanceOfERC20Tx)
    // const tex = getDiscountPercent(balanceOfERC20)
    // const { actualPrice, commission } = await calculateWrappedAmount(price, quantity, tex)
    const buyPrice = ethers.utils.formatEther(etherPrice)
    const buyRoundPrice = Number(buyPrice).toFixed(3)
    const allowancePrice = Number(ethers.utils.formatEther(allowanceERC20Tx)).toFixed(3)
    console.log('buyPrice', buyPrice);
    console.log('buyRoundPrice', buyRoundPrice);
    console.log('allowancePrice', allowancePrice);

    if (Number(buyRoundPrice) > Number(allowancePrice)) {
        // if (wrapped.address === WETH_RINKEBY_ADDRESS_KEY) {
        //     const depositERC20Tx = await contract.deposit(options)
        //     await depositERC20Tx.wait();
        // }
        console.log('contract.approve called');
        const approvalERC20Tx = await contract.approve(marketplaceAddr, etherPrice)
        await approvalERC20Tx.wait();
    }
    return { contract, accounts, provider, signer, eth_chainId, accountBalance }
}
const setApprovalForAll = async (contract, marketplace) => {
    debugger
    try {
        const contractTransaction = await contract.functions.setApprovalForAll(marketplace, true);
        return await contractTransaction.wait();
    } catch (error) {
        console.log("setApprovalForAll error", error);
        if (error.message) {
            toast.error(error.message)
        }
        return null
    }
}
const isApprovedForAll = async (contract, accounts) => {
    debugger
    const IS_STAGING = henceforthApi.IS_STAGING
    const marketplace = IS_STAGING ? MARKET_PLACE_BSC_TESTNET_ADDRESS_KEY : MARKET_PLACE_BSC_MAINNET_ADDRESS_KEY
    try {
        const contractResponse = await contract.functions.isApprovedForAll(accounts[0], marketplace);
        if (Array.isArray(contractResponse) && contractResponse.length) {
            let isApproved = contractResponse[0]
            if (isApproved) {
                return isApproved
            } else {
                return await setApprovalForAll(contract, marketplace)
            }
        } else {
            return await setApprovalForAll(contract, marketplace)
        }
    } catch (error) {
        console.log("isApprovedForAll error", error);
        return null
    }
}

const getCollection721Contract = async (ARTIFACTS, authProvider) => {
    const IS_STAGING = henceforthApi.IS_STAGING
    const abi = ARTIFACTS.abi;
    return await getContract(IS_STAGING ? COLLECTION721_BSC_TESTNET_ADDRESS_KEY : COLLECTION721_BSC_MAINNET_ADDRESS_KEY, abi, authProvider);
}
const getCollection1155Contract = async (ARTIFACTS, authProvider) => {
    const IS_STAGING = henceforthApi.IS_STAGING
    const abi = ARTIFACTS.abi;
    return await getContract(IS_STAGING ? COLLECTION1155_BSC_TESTNET_ADDRESS_KEY : COLLECTION1155_BSC_MAINNET_ADDRESS_KEY, abi, authProvider);
}

const getERC721Contract = async (ARTIFACTS, collectionAddress, authProvider) => {
    const IS_STAGING = henceforthApi.IS_STAGING
    const abi = ARTIFACTS.abi;
    return await getContract(collectionAddress ? collectionAddress : IS_STAGING ? ERC721_BSC_TESTNET_ADDRESS_KEY : ERC721_BSC_MAINNET_ADDRESS_KEY, abi, authProvider);
}
const getERC1155Contract = async (ARTIFACTS, collectionAddress, authProvider) => {
    const IS_STAGING = henceforthApi.IS_STAGING
    const abi = ARTIFACTS.abi;
    return await getContract(collectionAddress ? collectionAddress : IS_STAGING ? ERC1155_BSC_TESTNET_ADDRESS_KEY : ERC1155_BSC_MAINNET_ADDRESS_KEY, abi, authProvider);
}

const getMarketPlaceContract = async (ARTIFACTS, authProvider) => {
    const abi = ARTIFACTS.abi;
    const { rpc, wrapped, ...props } = henceforthRpc('binance')
    return await getContract(props.marketplaceAddr, abi, authProvider);
}
const getMarketPlaceContractV2 = async (ARTIFACTS, authProvider) => {
    const { rpc, wrapped, ...props } = henceforthRpc('binance')
    const abi = ARTIFACTS.abi;
    return await getContract(props.marketplaceAddrV2, abi, authProvider);
}

const metamaskContracts = {
    walletConnectProvider,
    RequestMetamaskChangeChain,
    loginWithMetamaskConnect,
    loginWithWalletConnect,
    getProvider,
    wrappedContract,
    accessERC20,
    getCollection721Contract,
    getCollection1155Contract,
    getERC721Contract,
    getERC1155Contract,
    getMarketPlaceContract,
    getMarketPlaceContractV2,
    isApprovedForAll,
    importCollection,
}
export default metamaskContracts