import { AUTH_FEV, LOGIN_SUCCESS, INITIALISE_PROFILE, SIGNUP_SUCCESS, LOGOUT_SUCCESS, DELETE_INSTAGRAM, DELETE_TIKTOK } from "../../actionTypes"

const authFav = (payload) => (dispatch) => {
    dispatch({
        type: AUTH_FEV,
        payload: payload
    })
}
const login = (payload) => (dispatch) => {
    dispatch({
        type: LOGIN_SUCCESS,
        payload: payload
    })
}
const signup = (payload) => (dispatch) => {
    dispatch({
        type: SIGNUP_SUCCESS,
        payload: payload
    })
}
const initialiseProfile = (payload) => (dispatch) => {
    dispatch({
        type: INITIALISE_PROFILE,
        payload: payload
    })
}
const deleteInstagram = (payload) => (dispatch) => {
    dispatch({
        type: DELETE_INSTAGRAM,
        payload: payload
    })
}
const deleteTiktok = (payload) => (dispatch) => {
    dispatch({
        type: DELETE_TIKTOK,
        payload: payload
    })
}
const logout = (payload) => (dispatch) => {
    dispatch({
        type: LOGOUT_SUCCESS,
        payload: payload
    })
}


export default {
    authFav,
    login,
    signup,
    initialiseProfile,
    deleteInstagram,
    deleteTiktok,
    logout,
};
