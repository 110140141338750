import moment from "moment"
import { Fragment, useEffect, useRef, useState } from "react"
import Calendar from "react-calendar"
import { EXPIRE_1_DAY, EXPIRE_3_DAY, EXPIRE_5_DAY, PICK_SPECIFIC_DATE, RIGHT_AFTER_LISTING } from "../../context/actionTypes"
import henceforthApi from "../../utils/henceforthApi"
import henceforthValidations from "../../utils/henceforthValidations"
import { Spinner } from "../BootstrapCompo"

export default ({ putOnSaleLoading, reCreateAuction, erc }) => {

    const calendarRef = useRef(null);
    const datePickerModalRef = useRef(null);
    const [price, setPrice] = useState("")
    const [quantity, setQuantity] = useState("")
    const [formData, setFormData] = useState({
        startDate: RIGHT_AFTER_LISTING,
        endDate: EXPIRE_1_DAY,
        price: "",
        royalties: "",
        quantity: "",
        startSpecificDate: 0,
        endSpecificDate: 0
    })
    const handleInput = ({ name, value }) => {
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleSelectDate = (e) => {
        if (datePickerModalRef.current) {
            const btnValue = datePickerModalRef.current.value
            const dateInUtc = moment(e).utc().valueOf()
            handleInput({ name: btnValue, value: dateInUtc })
        }
        console.log("handleSelectDate e", e);
    }
    const putOnSale = async () => {
        debugger
        let start_date = 0;
        let end_date = 0;
        const { startDate, endDate, startSpecificDate, endSpecificDate } = formData
        if (startDate == RIGHT_AFTER_LISTING) {
            start_date = moment.utc().valueOf()
        } else {
            end_date = Number(startSpecificDate)
        }
        if (endDate == EXPIRE_1_DAY) {
            end_date = moment().add(1, 'days').utc().valueOf()
        } else if (endDate == EXPIRE_3_DAY) {
            end_date = moment().add(3, 'days').utc().valueOf()
        } else if (endDate == EXPIRE_5_DAY) {
            end_date = moment().add(5, 'days').utc().valueOf()
        } else {
            end_date = Number(endSpecificDate)
        }
        reCreateAuction(3, Number(quantity ? quantity : 1), end_date, Number(price), start_date)
    }

    useEffect(() => {
        if (formData.startDate == PICK_SPECIFIC_DATE) {
            if (datePickerModalRef.current) {
                datePickerModalRef.current.value = "startSpecificDate"
                datePickerModalRef.current.click()
                if (formData.startSpecificDate) {
                    calendarRef.current.value = moment(Number(formData.startSpecificDate)).toISOString()
                }
            }
        }

    }, [formData.startDate])
    useEffect(() => {
        if (formData.endDate == PICK_SPECIFIC_DATE) {
            if (datePickerModalRef.current) {
                datePickerModalRef.current.value = "endSpecificDate"
                datePickerModalRef.current.click()
                if (formData.endSpecificDate) {
                    calendarRef.current.value = moment(Number(formData.startSpecificDate)).add(1, 'day').toISOString()
                }
            }
        }

    }, [formData.endDate])

    return <Fragment>
        <div className="modal fade" id="createAnAuction" data-bs-backdrop="static" aria-hidden="true" aria-labelledby="createAnAuctionLabel" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header border-0 p-0">

                        <h5 className="modal-title w-100 text-center text-dark fw-bold fs-4 pb-2  pt-4 d-flex justify-content-center" id="putOnSaleAuctionPriceLabel">
                            <span className="text-gradient">Timed Auction</span></h5>
                        <button id="closePutOnSaleAuctionPrice" type="button" className="btn btn-icon me-4 mt-3  shadow-none fs-6 position-absolute top-0 end-0" data-bs-dismiss="modal" aria-label="Close">
                            <i className="fa-solid fa-xmark"></i>
                        </button>
                    </div>
                    <div className="modal-body pt-1 pb-0 p-4">

                        <p className="text-center mb-2">Enter new price. Your NFT will be pushed in top of marketplace.</p>
                        <div className="position-relative my-3">
                            <input type="text" className="form-control border-pink pe-5" placeholder="Enter Price " value={price} onChange={(e) => henceforthValidations.NumberValidation(e.target.value) || (e.target.value.includes('.')) ? setPrice(e.target.value) : setPrice("")} />
                            <p className="fs-12 fw-medium position-absolute top-50 end-0 translate-middle-y me-2">ELEN</p>
                        </div>
                        {erc === 1155 &&
                            <div className="position-relative my-3">
                                <input type="text" className="form-control border-pink pe-5" placeholder="1" value={quantity} onChange={(e) => henceforthValidations.NumberValidation(e.target.value) ? setQuantity(e.target.value) : setQuantity(1)} />
                                <p className="fs-12 fw-medium position-absolute top-50 end-0 translate-middle-y me-2">COPIES</p>
                            </div>}
                        <div className="row mb-3">
                            <div className="col-md-6 pe-1">
                                <div className="form-group mb-1">
                                    <label className="fw-medium mb-1" htmlFor="">Starting Date</label>
                                    <select className="form-select fs-14 border-pink" value={formData?.startDate} name="startDate" aria-label="start date select" onChange={(e) => handleInput(e.target)}>
                                        <option value={RIGHT_AFTER_LISTING}>Right After Listing</option>
                                        <option value={PICK_SPECIFIC_DATE}>Pick Specific Date</option>
                                    </select>
                                </div>
                                {formData?.startDate == PICK_SPECIFIC_DATE ?
                                    <span className="fs-12 tags me-2 fw-medium mt-2 ">{moment(formData?.startSpecificDate).format("DD/MM/YYYY")} <button className="btn-icon">
                                        <i className="fa-solid fa-xmark ms-1" onClick={() => handleInput({ name: 'startDate', value: RIGHT_AFTER_LISTING })}></i></button></span>
                                    : ''}
                            </div>
                            <div className="col-md-6 ps-1">
                                <div className="form-group mb-1">
                                    <label className="fw-medium mb-1" htmlFor="">Expiration Date</label>
                                    <select className="form-select fs-14 border-pink" value={formData?.endDate} name="endDate" onChange={(e) => handleInput(e.target)}>
                                        <option value={EXPIRE_1_DAY}>1 Day</option>
                                        <option value={EXPIRE_3_DAY}>3 Days</option>
                                        <option value={EXPIRE_5_DAY}>5 Days</option>
                                        <option value={PICK_SPECIFIC_DATE}>Pick Specific Date</option>
                                    </select>
                                </div>
                                {formData?.endDate == PICK_SPECIFIC_DATE ?
                                    <span className="fs-12 tags me-2 fw-medium mt-2 ">{moment(formData?.endSpecificDate).format("DD/MM/YYYY")} <button className="btn-icon">
                                        <i className="fa-solid fa-xmark ms-1" onClick={() => handleInput({ name: 'endDate', value: RIGHT_AFTER_LISTING })}></i></button></span>
                                    : ''}

                            </div>
                            {/* <div className="col-md-12">
                                <p className=" fs-12 fw-semi-bold text-gray mt-2">Any Bid Placed In The Last 10 Minutes Extends The Auction By 10 Minutes.</p>
                            </div> */}
                        </div>

                        <div className="highhest-bid d-flex justify-content-between align-items-center pb-2 mt-3">
                            <div className="bid-left">
                                <p className="fs-6 text-black-50 mb-0"><small>Service fee</small></p>
                            </div>
                            <div className="bid-right">
                                <p className="fs-6 text-black mb-0 text-end fw-medium"><small>{henceforthApi.SERVICE_FEE}%</small></p>

                            </div>
                        </div>
                    </div>
                    <div className="modal-footer border-0 pb-4 pt-2 p-4">
                        <button type="button" className="btn btn-primary bg-dark shadow-none w-100 m-auto rounded-pill mb-2 text-capitalize" onClick={putOnSale} disabled={putOnSaleLoading || Number(price) <= 0}>{putOnSaleLoading ? <Spinner /> : 'PUT ON SALE'}</button>
                    </div>
                    <button ref={datePickerModalRef} className="btn btn-primary d-none" data-bs-target="#datePickerModalInAuction" data-bs-toggle="modal">Open second modal</button>
                </div>
            </div>
        </div>
        <div className="modal fade" id="datePickerModalInAuction" data-bs-backdrop="static" aria-hidden="true" aria-labelledby="datePickerModalInAuctionLabel2" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 m-auto">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <Calendar inputRef={calendarRef} onChange={handleSelectDate} minDate={formData?.endDate == PICK_SPECIFIC_DATE ? moment(formData?.startSpecificDate).add(1, 'day').toDate() : new Date()} className="calendar w-100" />
                                    <div className="d-flex justify-content-between align-items-center my-3">
                                    </div>
                                    <button className="btn btn-primary w-100" data-bs-target="#createAnAuction" data-bs-toggle="modal">Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
}