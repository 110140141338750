import moment from "moment";
import User from "../../assets/images/home/img_placeholder_small.svg";
import Instagram from "../../assets/images/instagram_img.png";
import TiktokBig from "../../assets/images/home/tiktok_big.svg"
import Tiktok from "../../assets/images/home/tiktok_small.svg"
import EverlensSmall from "../../assets/images/home/everlens_small.svg";
import Comment from "../../assets/images/home/comment.svg";
import { Link } from "react-router-dom";
import henceforthApi from "../../utils/henceforthApi";
import { Fragment, useEffect, useState } from "react";
import { EXPLORE_LANDING_MARKET_PLACE, EXPLORE_LANDING_SOCIAL, EXPLORE_NFT_TYPE_INSTAGRAM, EXPLORE_NFT_TYPE_MARKETPLACE, EXPLORE_NFT_TYPE_MARKETPLACE_COLLECTION, EXPLORE_NFT_TYPE_TIKTOK } from "../../context/actionTypes";
import { subscribeFormatter } from "../../utils/work_contant";
import staticAction from "../../context/actions/auth/staticAction";

const NftCard = ({ index, nft_type, v2_nft, placed_offer, elenToUsd, currentUser, _id, available_quantity, quantity, auction_type, created_by, current_owner, erc, description, end_date, file_ext, file_url, name, price, token_id, start_date, total_comments, total_favourites, is_favourite, initialiseFev, staticDispatch }) => {
  var countdownTimer;

  const [timerCount, setTimerCount] = useState({
    days: "0",
    hours: "0",
    minutes: "0",
    seconds: "0",
    title: ""
  })

  const initialiseInterval = (end_date, title) => {
    if (end_date) {
      console.log("end_date", new Date(Number(end_date)));
      var _second = 1000;
      var _minute = _second * 60;
      var _hour = _minute * 60;
      var _day = _hour * 24;
      var end = new Date(Number(end_date))
      function timer() {
        var now = new Date();
        var distance = end - now;
        if (distance < 0) {
          clearInterval(countdownTimer);
          console.log("initialiseInterval EXPIRED!");
          return;
        }

        var days = Math.floor(distance / _day);
        var hours = Math.floor((distance % _day) / _hour);
        var minutes = Math.floor((distance % _hour) / _minute);
        var seconds = Math.floor((distance % _minute) / _second);
        setTimerCount({
          ...timerCount,
          days,
          hours,
          minutes,
          seconds,
          title
        })
      }
      countdownTimer = setInterval(() => timer(), 1000);
    }
  }
  const initialiseExplorePlace = (nft_type) => {
    if (staticDispatch) {
      if ((nft_type === EXPLORE_NFT_TYPE_INSTAGRAM || nft_type === EXPLORE_NFT_TYPE_TIKTOK)) {
        staticAction.changeExplore(EXPLORE_LANDING_SOCIAL)(staticDispatch);
      } else {
        staticAction.changeExplore(EXPLORE_LANDING_MARKET_PLACE)(staticDispatch);
      }
    }
  }

  useEffect(() => {
    if (moment(Number(start_date)).utc().valueOf() > moment().utc().valueOf()) {
      initialiseInterval(start_date, "start in")
    } else {
      initialiseInterval(end_date, "left")
    }
  }, [])

  return (
    <div className={`live-box card ${erc === 1155 ? 'multiple' : ''}`}>
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-start">
          <div className="owner-name d-flex">
            <div className="name-img">
              <img src={current_owner?.profile_pic ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${current_owner?.profile_pic}` : User} className="user " />
            </div>
            <div className="name-text ms-2">
              <h6 className="mb-0"><Link className="mb-0 fs-14 fw-bolder" to={`/profile/${current_owner?._id ? current_owner?._id : 'XXX'}/on_sale`}>@{current_owner && current_owner?.user_name ? current_owner?.user_name : ''} </Link></h6>
              <p className="fs-12 text-black-50">Art by <Link to={`/profile/${created_by?._id}/on_sale`} className="fw-semi-bold"> {created_by && created_by.user_name ? created_by.user_name : ''}</Link></p>
            </div>
          </div>
          {(nft_type === EXPLORE_NFT_TYPE_INSTAGRAM) &&
            <button className="btn-icon mt-1 cursor-auto">
              <img src={Instagram} className="img-fluid d-inline insta-big" />
            </button>
          }
          {(nft_type === EXPLORE_NFT_TYPE_TIKTOK) &&
            <button className="btn-icon mt-1 cursor-auto">
              <img src={TiktokBig} className="img-fluid d-inline" />
            </button>
          }
          {(erc === 1155) &&
            <p className="fs-12 fw-medium">{subscribeFormatter(available_quantity)}/{subscribeFormatter(quantity)}</p>
          }
        </div>

        <div className="d-flex justify-content-center">
          {(nft_type === EXPLORE_NFT_TYPE_INSTAGRAM || nft_type === EXPLORE_NFT_TYPE_TIKTOK) &&
            <>
              {current_owner?.instagram_count?.follower_count || current_owner?.tiktok_count?.follower_count ?
                <Fragment>
                  {current_owner?.instagram_count?.follower_count !== 0 &&
                    <button className="btn-outline-secondary  fw-medium me-2 flex-grow-1 fs-12 px-2 shadow-none text-lowercase cursor-auto">
                      <img src={Instagram} className="me-2 d-inline insta-small" />
                      {subscribeFormatter(current_owner?.instagram_count?.follower_count)} followers
                    </button>}
                  {current_owner?.tiktok_count?.follower_count !== 0 &&
                    <button className="btn-outline-secondary fw-medium flex-grow-1 fs-12 px-2 shadow-none  text-lowercase cursor-auto">
                      <img src={Tiktok} className=" me-2 d-inline" />
                      {subscribeFormatter(current_owner?.tiktok_count?.follower_count)} followers
                    </button>}
                </Fragment> : <Fragment>
                  <button className="btn-outline-secondary fw-medium flex-grow-1 fs-12 px-2 shadow-none  cursor-auto" style={{ visibility: "hidden" }}>
                    <img src={Tiktok} className=" me-2 d-inline" />
                    followers
                  </button>
                </Fragment>}
            </>
          }
          {((nft_type === EXPLORE_NFT_TYPE_MARKETPLACE) || (nft_type === EXPLORE_NFT_TYPE_MARKETPLACE_COLLECTION)) &&
            <button className="btn-outline-secondary fw-medium flex-grow-1 fs-12 px-2 shadow-none text-lowercase" style={{ visibility: current_owner?.followers ? 'visible' : 'hidden' }}>
              <img src={EverlensSmall} className=" me-2 d-inline" />
              {subscribeFormatter(current_owner?.followers)} followers
            </button>
          }
        </div>
      </div>

      <div className="live-img position-relative">
        <Link to={`/token/${token_id}/${_id}/${auction_type == 1 ? 'trading' : v2_nft && price === 0 ? 'offers' : 'trading'}/last_30_days`} onClick={() => initialiseExplorePlace(nft_type)}>
          {(String(file_ext).toLowerCase().includes("video")) ? <video className="nft-image" playsInline autoPlay={(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? false : true} muted loop> <source src={henceforthApi.FILES.video(file_url)}></source></video>
            : <img src={henceforthApi.FILES.imageOriginal(file_url)} className="w-100 nft-image" />
          }
        </Link>
        {auction_type !== 1 ?
          moment(Number(end_date)).utc().valueOf() > moment().utc().valueOf() ?
            <div className="text-time d-flex position-absolute bottom-0 right-0 bg-white text-dark">
              <p className="mb-0 fw-bolder"><span className="text-gray ms-1 fw-normal fs-12">{timerCount.title == "start in" ? 'start in' : ''}</span> {String(timerCount.days).length == 1 ? `0${timerCount.days}` : timerCount.days}:{String(timerCount.hours).length == 1 ? `0${timerCount.hours}` : timerCount.hours}:{String(timerCount.minutes).length == 1 ? `0${timerCount.minutes}` : timerCount.minutes}:{String(timerCount.seconds).length == 1 ? `0${timerCount.seconds}` : timerCount.seconds}<span className="text-gray ms-1 fw-normal fs-12">{timerCount.title == "left" ? 'left' : ''}</span></p>
            </div>
            : '' : ''}
      </div>
      <div className="card-body">
        <div className="live-text">
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <h2 className="fw-bolder fs-5 mb-0 line-clamp-1 text-break">{name}</h2>
              <p className="text-gray fs-12 mb-0 line-clamp-1 text-break" >{description}</p>
            </div>
            <div className="d-flex align-items-start comment ">
              {is_favourite ?
                <p className="mb-0 fs-12 d-flex align-items-center" onClick={() => initialiseFev(index, _id, !is_favourite)}><i className="fa fa-heart text-theme me-1"></i> {total_favourites}</p> :
                <p className="mb-0 fs-12 d-flex align-items-center" onClick={() => initialiseFev(index, _id, !is_favourite)}><i className="fa fa-heart text-theme me-1 fw-normal"></i> {total_favourites}</p>}
              <p className="mb-0  fs-12 border-left ps-2 ms-2 d-flex align-items-center"><img src={Comment} className="me-1 d-inline" />{total_comments}</p>
            </div>
          </div>

          <div className="highhest-bid d-flex justify-content-between align-items-center mt-2">
            <div className="bid-left">
              <p className="mb-0 fw-bold">{price ? `${subscribeFormatter(price)} ELEN` : 'Not for sale'} </p>
              {elenToUsd !== 0 && price !== 0 &&
                <p className="fs-12 mb-0">${subscribeFormatter(Number(Number(price) * elenToUsd).toFixed(4))} USD</p>}
            </div>
            <div className="bid-right">
              {currentUser !== (current_owner ? current_owner?._id : '') ?
                (v2_nft && price === 0) ?
                  <h5 className="fs-6 fw-semi-bold mb-0 ">
                    <Link className="text-decoration-none text-theme" to={`/token/${token_id}/${_id}/offers/last_30_days`} onClick={() => initialiseExplorePlace(nft_type)}>{placed_offer ? 'Edit Offer' : 'Make Offer'}</Link>
                  </h5> :
                  available_quantity ?
                    auction_type == 1 ?
                      <h5 className="fs-6 fw-semi-bold mb-0 ">
                        <Link className="text-decoration-none text-theme" to={`/token/${token_id}/${_id}/trading/last_30_days`} onClick={() => initialiseExplorePlace(nft_type)}>Buy Now!</Link>
                      </h5> : auction_type == 3 ?
                        <h5 className="fs-6 fw-semi-bold mb-0 ">
                          <Link className="text-decoration-none text-theme" to={`/token/${token_id}/${_id}/bids/last_30_days`} onClick={() => initialiseExplorePlace(nft_type)}>Bid Now!</Link>
                        </h5> : '' : ''
                : !available_quantity ? <h5 className="fs-6 fw-semi-bold mb-0 ">
                  <Link className="text-decoration-none text-theme" to={`/token/${token_id}/${_id}/trading/last_30_days`} onClick={() => initialiseExplorePlace(nft_type)}>Put on sale</Link>
                </h5> : ''}
            </div>
          </div>
        </div>
      </div >
    </div >
  )
}

export default NftCard;