
import profile_placeholder from "../assets/images/home/img_placeholder.png";
import InstagramIcon from "../assets/images/instagram_img.png";
import TiktokIcon from "../assets/images/home/tiktok_small.svg";
import All from "../assets/images/icons/all.png";
import Single from "../assets/images/icons/single_small.png"
import Multiple from "../assets/images/icons/multiple_small.png"
import { useContext, useState, useEffect, Fragment } from "react";
import { GlobalContext } from "../context/Provider";
import henceforthApi from "../utils/henceforthApi";
import authAction from "../context/actions/auth/authAction";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Twitter from "../assets/images/icons/twitter.svg";
import Facebook from "../assets/images/icons/facebook.svg";
import Email from "../assets/images/icons/email.svg";
import Copy from "../assets/images/icons/copy.svg";


import { getCategoryNameViaIdAndList, getPrimaryWalletType, subscribeFormatter, timeSince, warningInstagramAfterImport, warningInstagramImport } from "../utils/work_contant";
import { ERROR_UNAUTHORIZED, EXPLORE_LANDING_MARKET_PLACE, EXPLORE_LANDING_SOCIAL, EXPLORE_NFT_POST_TYPE_MULTIPLE, EXPLORE_NFT_POST_TYPE_SINGLE, EXPLORE_NFT_TYPE_INSTAGRAM, EXPLORE_NFT_TYPE_TIKTOK } from "../context/actionTypes";
import Modals from "../components/Modals";
import ActivitiesList from "../components/profile/ActivitiesList";
import NftsMapList from "../components/profile/NftsMapList";
import { Spinner } from "../components/BootstrapCompo";

export default () => {
    const navigate = useNavigate()
    const location = useLocation()
    const match = useMatch('/profile/:tab')
    var query = new URLSearchParams(location.search);
    const { authState, staticState, accounts, authDispatch, scrollToTop, fileUpload, elenToUsd, categories, loadMore, loading, setLoading } = useContext(GlobalContext)
    const [importLoading, setImportLoading] = useState(false)
    const [warningMsg, setWarningMsg] = useState({
        title: '',
        subtitle: '',
        btnValue: 'Ok'
    })
    const [welcomeBackInstagramMsg, setWelcomeBackInstagramMsg] = useState({
        title: '',
        subtitle: '',
        btnValue: 'Logout'
    })
    const [followers, setFollowers] = useState({
        data: [],
        total_count: null,
        page: 0,
    })
    const [following, setFollowing] = useState({
        data: [],
        total_count: null,
        page: 0,
    })
    const [resaleItems, setResaleItems] = useState({
        data: [],
        total_count: null,
        page: 0,
    })
    const [soldItems, setSoldItems] = useState({
        data: [],
        total_count: null,
        page: 0,
    })
    const [ownItems, setOwnItems] = useState({
        data: [],
        total_count: null,
        page: 0,
    })
    const [createdItems, setCreatedItems] = useState({
        data: [],
        total_count: null,
        page: 0,
    })
    const [bidsItems, setBidsItems] = useState({
        data: [],
        total_count: null,
        page: 0,
    })
    const [collectionItems, setCollectionItems] = useState({
        data: [],
        total_count: null,
        page: 0,
    })
    const [favoritedItems, setFavoritedItems] = useState({
        data: [],
        total_count: null,
        page: 0,
    })
    const [activityItems, setActivityItems] = useState({
        data: [],
        total_count: null,
        page: 0,
    })
    const [maxPrice, setMaxPrice] = useState(0)
    const [minPrice, setMinPrice] = useState(0)

    const [state, setState] = useState({
        loading_profile_icon: false,
        loading_banner_icon: false,
        ...authState,
    })
    henceforthApi.setToken(authState.access_token)

    const updateProfile = async (items) => {
        try {
            let apiRes = await henceforthApi.Profile.edit(items)
            let data = apiRes.data

            let walletType = getPrimaryWalletType(data.wallet)
            authAction.initialiseProfile({ ...data, walletType })(authDispatch);

            setState({
                ...state,
                loading: false,
                loading_profile_icon: false,
                loading_banner_icon: false,
            })
        } catch (error) {
            toast.error(error.response.body.error_description)

            setState({
                ...state,
                loading: false,
                loading_profile_icon: false,
            })
        }
    }

    const onUpdateProfilePicture = async (file) => {
        setState({
            ...state,
            loading_profile_icon: true
        })
        let fileName = await fileUpload(file)
        let items = {
            profile_pic: fileName,
            language: "ENGLISH"
        }
        await updateProfile(items)
    }
    const onUpdateProfileBanner = async (file) => {
        setState({
            ...state,
            loading_banner_icon: true
        })
        let fileName = await fileUpload(file)
        let items = {
            cover_img: fileName,
            language: "ENGLISH"
        }
        await updateProfile(items)
    }

    useEffect(() => {
        if (authState.access_token) {
            scrollToTop()
        } else {
            navigate(`/signin?redirect=${location.pathname}`, { replace: true })
        }
    }, [accounts, authState.access_token])

    const initialiseProfile = async () => {
        try {
            let apiRes = await henceforthApi.Profile.get()
            let data = apiRes.data
            let walletType = getPrimaryWalletType(data.wallet)
            authAction.initialiseProfile({ ...data, walletType })(authDispatch);

            await inialiseTabData("on_sale", 0)
            await inialiseTabData("sold", 0)
            await inialiseTabData("owned", 0)
            await inialiseTabData("created", 0)
            await inialiseTabData("bids", 0)
        } catch (error) {
            if (error.response && error.response.body) {
                if (error.response.body.error == ERROR_UNAUTHORIZED) {
                    navigate(`/signin?redirect=${location.pathname}`, { replace: true })
                } else {
                    toast.error(error.response.body.error_description)
                }
            }
        }
    }
    useEffect(() => {
        if (navigator.onLine) {
            if (authState.access_token) {
                initialiseProfile()
            }
        }
        else
            toast.warn("Not Internet Connection...")
    }, [])

    const inialiseProfileTab = async (tab, page) => {
        let q = query.toString()
        if (authState._id) {

            try {
                setLoading(true)
                let apiRes = await henceforthApi.Profile.getByTab(authState._id, tab, 10, q, staticState.explore, page)
                setLoading(false)
                return apiRes.data.nft_details
            } catch (error) {
                console.log("inialiseProfileTab", error);
            }
        }
    }
    const inialiseTabData = async (tab, page) => {
        let { data, total_count } = await inialiseProfileTab(String(tab).toUpperCase(), page)
        let arrData = Array.isArray(data) ? data : []
        switch (tab) {
            case "on_sale": {
                let oldData = Array.isArray(resaleItems.data) ? resaleItems.data : []
                let newData = [...oldData, ...arrData]
                const uniqueData = Array.from(new Set(
                    newData.map(a => a._id))).map(id => {
                        return newData.find(a => a._id === id)
                    })
                setResaleItems({
                    ...resaleItems,
                    total_count: total_count ? total_count : 0,
                    data: page ? uniqueData : arrData,
                    page: page + 1
                })
                break;
            }
            case "sold": {
                let oldData = Array.isArray(soldItems.data) ? soldItems.data : []
                let newData = [...oldData, ...arrData]
                const uniqueData = Array.from(new Set(
                    newData.map(a => a._id))).map(id => {
                        return newData.find(a => a._id === id)
                    })
                setSoldItems({
                    ...soldItems,
                    total_count: total_count ? total_count : 0,
                    data: page ? uniqueData : arrData,
                    page: page + 1
                })
                break;
            }
            case "owned": {
                let oldData = Array.isArray(ownItems.data) ? ownItems.data : []
                let newData = [...oldData, ...arrData]
                const uniqueData = Array.from(new Set(
                    newData.map(a => a._id))).map(id => {
                        return newData.find(a => a._id === id)
                    });
                if (total_count === 0 && !authState.v2_user) {
                    setWarningMsg({
                        ...warningMsg,
                        ...warningInstagramImport,
                        btnValue: 'Ok'
                    })
                }
                setOwnItems({
                    ...ownItems,
                    total_count: total_count ? total_count : 0,
                    data: page ? uniqueData : arrData,
                    page: page + 1
                })
                break;
            }
            case "created": {
                let oldData = Array.isArray(createdItems.data) ? createdItems.data : []
                let newData = [...oldData, ...arrData]
                const uniqueData = Array.from(new Set(
                    newData.map(a => a._id))).map(id => {
                        return newData.find(a => a._id === id)
                    })
                setCreatedItems({
                    ...createdItems,
                    total_count: total_count ? total_count : 0,
                    data: page ? uniqueData : arrData,
                    page: page + 1
                })
                break;
            }
            case "bids": {
                let oldData = Array.isArray(bidsItems.data) ? bidsItems.data : []
                let newData = [...oldData, ...arrData]
                const uniqueData = Array.from(new Set(
                    newData.map(a => a._id))).map(id => {
                        return newData.find(a => a._id === id)
                    })
                setBidsItems({
                    ...bidsItems,
                    total_count: total_count ? total_count : 0,
                    data: page ? uniqueData : arrData,
                    page: page + 1
                })
                break;
            }
            case "collection": {
                let oldData = Array.isArray(collectionItems.data) ? collectionItems.data : []
                let newData = [...oldData, ...arrData]
                const uniqueData = Array.from(new Set(
                    newData.map(a => a._id))).map(id => {
                        return newData.find(a => a._id === id)
                    })
                setCollectionItems({
                    ...collectionItems,
                    total_count: total_count ? total_count : 0,
                    data: page ? uniqueData : arrData,
                    page: page + 1
                })
                break;
            }
            case "favourite": {
                let oldData = Array.isArray(favoritedItems.data) ? favoritedItems.data : []
                let newData = [...oldData, ...arrData]
                const uniqueData = Array.from(new Set(
                    newData.map(a => a._id))).map(id => {
                        return newData.find(a => a._id === id)
                    })
                setFavoritedItems({
                    ...favoritedItems,
                    total_count: total_count ? total_count : 0,
                    data: page ? uniqueData : arrData,
                    page: page + 1
                })
                break;
            }
            case "activity": {
                let oldData = Array.isArray(activityItems.data) ? activityItems.data : []
                let newData = [...oldData, ...arrData]
                const uniqueData = Array.from(new Set(
                    newData.map(a => a._id))).map(id => {
                        return newData.find(a => a._id === id)
                    })
                setActivityItems({
                    ...activityItems,
                    total_count: total_count ? total_count : 0,
                    data: page ? uniqueData : arrData,
                    page: page + 1
                })
                break;
            }
            default:
                break;
        }
    }
    useEffect(async () => {
        if (match.params.tab) {
            inialiseTabData(match.params.tab, 0)
        }
    }, [match.params.tab, query.get("post_type"), query.get("category_id"), query.get("min_price"), query.get("max_price"), query.get("sort_by"), query.get("social_type")])

    const changeCategory = (value) => {
        if (value) {
            query.set("category_id", value)
        } else {
            query.delete("category_id")
        }
        navigate({ search: query.toString() })
    }
    const changePostType = (value) => {
        if (value) {
            query.set("post_type", value)
        } else {
            query.delete("post_type")
        }
        navigate({ search: query.toString() })
    }
    const changeSocialType = (value) => {
        if (value) {
            query.set("social_type", value)
        } else {
            query.delete("social_type")
        }
        navigate({ search: query.toString() })
    }
    const changeSortBy = (value) => {
        if (value) {
            query.set("sort_by", value)
        } else {
            query.delete("sort_by")
        }
        navigate({ search: query.toString() })
    }

    const enableDisablePrice = (b) => {
        if (b) {
            query.delete("min_price")
            query.delete("max_price")
            setMinPrice(0)
            setMaxPrice(0)
        } else {
            query.set("min_price", minPrice)
            query.set("max_price", maxPrice)
        }
        navigate({ search: query.toString() })
    }

    const inialiseFollowers = async (page) => {
        if (authState._id) {
            try {
                let apiRes = await henceforthApi.Follower.followers(authState._id, 10, page)
                let data = apiRes.data
                setFollowers({
                    ...followers,
                    ...data
                })
            } catch (error) {
                toast.error(JSON.stringify(error?.response?.body?.error_description))
            }
        }
    }

    const inialiseFollowing = async (page) => {
        if (authState._id) {
            try {
                let apiRes = await henceforthApi.Follower.followings(authState._id, 10, page)
                let data = apiRes.data
                setFollowing({
                    ...following,
                    ...data
                })
            } catch (error) {
                toast.error(JSON.stringify(error?.response?.body?.error_description))
            }
        }
    }
    useEffect(() => {
        if (navigator.onLine) {
            inialiseFollowers(0)
            inialiseFollowing(0)
        }
    }, [])

    useEffect(() => {
        let total_count = 0
        let data_length = 0
        let page = 0
        switch (match.params.tab) {
            case "on_sale": {
                total_count = resaleItems.total_count
                data_length = resaleItems.data.length
                page = resaleItems.page
                break;
            }
            case "sold": {
                total_count = soldItems.total_count
                data_length = soldItems.data.length
                page = soldItems.page
                break;
            }
            case "owned": {
                total_count = ownItems.total_count
                data_length = ownItems.data.length
                page = ownItems.page
                break;
            }
            case "created": {
                total_count = createdItems.total_count
                data_length = createdItems.data.length
                page = createdItems.page
                break;
            }
            case "collection": {
                total_count = collectionItems.total_count
                data_length = collectionItems.data.length
                page = collectionItems.page
                break;
            }
            case "favourite": {
                total_count = favoritedItems.total_count
                data_length = favoritedItems.data.length
                page = favoritedItems.page
                break;
            }
            case "bids": {
                total_count = bidsItems.total_count
                data_length = bidsItems.data.length
                page = bidsItems.page
                break;
            }
            case "activity": {
                total_count = activityItems.total_count
                data_length = activityItems.data.length
                page = activityItems.page
                break;
            }
        }
        if ((window.innerHeight + document.documentElement.scrollTop) + 400 >= document.scrollingElement.scrollHeight) {
            if (data_length) {
                if (!loading) {
                    if (total_count != data_length) {
                        console.log("Loading......");
                        inialiseTabData(match.params.tab, page)
                    } else {
                        console.log("Data ended");
                    }
                }
            }
        }
    }, [loadMore])

    const importNfts = async () => {
        try {
            setImportLoading(true)
            let apiRes = await henceforthApi.Migration.nfts()
            toast.success(apiRes.message)
            setWelcomeBackInstagramMsg({
                ...welcomeBackInstagramMsg,
                ...warningInstagramAfterImport,
                btnValue: 'Logout'
            })
        } catch (error) {
            toast.error(error?.response?.body?.error_description)
        } finally {
            setImportLoading(false)
        }
    }

    return (<section className="activity-wrapper mt-3">
        <div className="container">
            <div className="row">
                <div className="col-xl-3 col-lg-4 position-relative mb-3 mt-4">
                    <div className="border rounded-3 p-4" style={{ position: "sticky", top: "24px" }}>
                        <div className="profile-image position-relative m-auto">
                            <img src={authState.profile_pic ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${authState.profile_pic}` : profile_placeholder} />
                            <div className="position-absolute bottom-0 end-0">
                                <label htmlFor="fil">
                                    <span className="btn btn-icon pe-0">
                                        <i className="fas fa-camera  border border-2  rounded-circle p-2 bg-white"></i>
                                    </span>
                                    <input className="d-none" type="file" accept="image/*" id="fil" onChange={(e) => onUpdateProfilePicture(e.target.files[0])} />
                                </label>
                            </div>
                        </div>
                        <div className="text-center">
                            <h4 className="fw-bold fw-bold mt-4 mb-1 profile-name text-break">{authState.name}</h4>
                            <h6 className="text-gray profile-subname text-break">@{authState.user_name}</h6>
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="left-icon profile-share-icon  bg-white d-flex justify-content-center  my-3">
                                    <Link to={`/profile/edit`} className="btn border border-top border-bottom share-icon px-lg-3 px-2 shadow-none">
                                        <i className="fa-solid fa-pen text-muted m-0 fs-6"></i>
                                    </Link>
                                    <button className="btn border border-top border-bottom share-icon px-3 shadow-none br-rounded" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fas fa-share-alt text-muted m-0 fs-6"></i>
                                    </button>
                                    <ul className="dropdown-menu p-2 share-dropdown dropdown-menu-end share " aria-labelledby="dropdownMenu2">
                                        <p className="fw-semi-bold text-center">Share Link</p>
                                        <div>
                                            <button className="btn-icon text-center mx-2" onClick={() => window.open(`https://www.twitter.com/share?url=${window.location.origin}/profile/${authState._id}/on_sale`)}>
                                                <img src={Twitter} className="d-block s-icon" />
                                                <span className="fs-11 text-gray">Twitter</span>
                                            </button>
                                            <button className="btn-icon text-center mx-2" onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}/profile/${authState._id}/on_sale`)}>
                                                <img src={Facebook} className="d-block s-icon" />
                                                <span className="fs-11  text-gray">Facebook</span>
                                            </button>
                                            <button className="btn-icon text-center mx-2">
                                                <a href={`mailto:info@example.com?subject=Everlens NFTs&body=${window.location.origin}/profile/${authState._id}/on_sale`}>
                                                    <img src={Email} className="d-block s-icon" />
                                                    <span className="fs-11  text-gray">Email</span>
                                                </a>
                                            </button>
                                            <button className="btn-icon text-center mx-2" onClick={() => { navigator.clipboard.writeText(`${window.location.origin}/profile/${authState._id}/on_sale`); toast.success("Copied to clipboard") }}>
                                                <img src={Copy} className="d-block s-icon" />
                                                <span className="fs-11  text-gray">Copy</span>
                                            </button>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            {staticState.explore == EXPLORE_LANDING_SOCIAL && authState?.instagram && authState?.instagram?.user_name &&
                                <div className="border-top border-end-0 border-start-0">
                                    <div
                                        className="folloers d-flex justify-content-center align-items-center w-75 m-auto" >
                                        <div className="five-nine me-4 lh-sm text-center">
                                            <h6 className="text-center mb-0 fs-6 fw-bold pt-3">{subscribeFormatter(authState?.instagram?.count ? authState?.instagram.count?.follower_count : 0)}</h6>
                                            <p className="text-muted"><small>Followers</small></p>
                                        </div>
                                        <div className="five-nine me-2 lh-sm me-2 text-center">
                                            <h6 className="text-center mb-0 fw-bold pt-3 fs-6">{subscribeFormatter(authState?.instagram.count?.following_count ? authState?.instagram.count?.following_count : 0)}</h6>
                                            <p className="text-muted"><small>Following</small></p>
                                        </div>
                                    </div>
                                    <button className="btn btn-outline-primary fw-semi-bold mb-3 text-lowercase w-100 text-nowrap text-truncate mw-100" onClick={() => window.open(`https://www.instagram.com/${authState.instagram.user_name}/`)}><img src={InstagramIcon} className="me-2 insta-small" />{authState.instagram.user_name}</button>
                                </div>
                            }
                            {staticState.explore == EXPLORE_LANDING_SOCIAL && authState?.tiktok?.status === "APPROVED" &&
                                <div className="border-top">
                                    <div
                                        className="folloers d-flex justify-content-center align-items-center w-75 m-auto " >
                                        <div className="five-nine me-4 lh-sm text-center">
                                            <h6 className="text-center mb-0 fs-6 fw-bold pt-3">{subscribeFormatter(authState?.tiktok.count ? authState?.tiktok.count?.follower_count : 0)}</h6>
                                            <p className="text-muted"><small>Followers</small></p>
                                        </div>
                                        <div className="five-nine me-2 lh-sm me-2 text-center">
                                            <h6 className="text-center mb-0 fw-bold pt-3 fs-6">{subscribeFormatter(authState?.tiktok.count ? authState?.tiktok.count?.following_count : 0)}</h6>
                                            <p className="text-muted"><small>Following</small></p>
                                        </div>
                                    </div>
                                    <button className="btn btn-outline-primary fw-semi-bold mb-3 text-lowercase w-100 text-nowrap text-truncate mw-100" onClick={() => window.open(`https://www.tiktok.com/@${authState.tiktok.user_name}/`)}><img src={TiktokIcon} className="me-2" />{authState.tiktok.user_name}</button>

                                </div>
                            }
                            {staticState.explore == EXPLORE_LANDING_MARKET_PLACE &&
                                <div className="border-top"><div
                                    className="folloers d-flex justify-content-center align-items-center w-75 m-auto ">
                                    <div className="five-nine me-4 lh-sm cursor-pointer text-center" data-bs-toggle="modal" data-bs-target="#followersModal">
                                        <h6 className="text-center mb-0 fs-6 fw-bold pt-3">{subscribeFormatter(followers?.total_count ? followers?.total_count : 0)}</h6>
                                        <p className="text-muted"><small>Followers</small></p>
                                    </div>
                                    <div className="five-nine me-2 lh-sm me-2 cursor-pointer text-center" data-bs-toggle="modal" data-bs-target="#followingModal">
                                        <h6 className="text-center mb-0 fw-bold pt-3 fs-6">{subscribeFormatter(following?.total_count ? following?.total_count : 0)}</h6>
                                        <p className="text-muted"><small>Following</small></p>
                                    </div>
                                </div></div>}
                            {authState?.instagram?.user_name && authState?.walletType?.address && (!authState.v2_user) && <Fragment>
                                <div className="border-top">
                                    <button className="btn btn-primary fw-semi-bold mb-3 text-capitalize w-100 mt-3" onClick={importNfts} disabled={importLoading}>{importLoading ? <Spinner /> : 'Import previous NFTs'}</button></div>
                            </Fragment>}

                        </div>
                    </div>
                </div>
                <div className="col-xl-9 col-lg-8 nav-tabss">
                    <div className="on-salle pb-5 mb-lg-5">

                        <ul className="nav nav-pills mb-3 border-bottom profile-tabs-row" id="pills-tab" role="tablist">
                            <li className="nav-item me-2" role="presentation">
                                <button className={`nav-link ${match.params.tab == 'on_sale' ? 'active' : ''} text-secondary fs-6 fw-semi-bold bg-transparent ps-0`}
                                    id="pills-sale-tab" data-bs-toggle="pill" data-bs-target="#pills-sale" type="button"
                                    role="tab" aria-controls="pills-sale" aria-selected="true" onClick={() => navigate(`/profile/on_sale`, { replace: true })}>For Sale
                                    <small className="bg-medium-gray px-1 text-gray ms-1 fs-12 rounded-2">{resaleItems.total_count ? resaleItems.total_count : 0}</small></button>

                            </li>
                            <li className="nav-item me-2" role="presentation">
                                <button className={`nav-link ${match.params.tab == 'sold' ? 'active' : ''} text-secondary fs-6 fw-semi-bold bg-transparent ps-0`}
                                    id="pills-sold-tab" data-bs-toggle="pill" data-bs-target="#pills-sold"
                                    type="button" role="tab" aria-controls="pills-sold" aria-selected="false" onClick={() => navigate(`/profile/sold`, { replace: true })}>Sold
                                    <small className="bg-medium-gray px-1 text-gray ms-1 fs-12 rounded-2">{soldItems.total_count ? soldItems.total_count : 0}</small></button>
                            </li>
                            <li className="nav-item me-2" role="presentation">
                                <button className={`nav-link ${match.params.tab == 'owned' ? 'active' : ''} text-secondary fs-6 fw-semi-bold bg-transparent ps-0`}
                                    id="pills-owned-tab" data-bs-toggle="pill" data-bs-target="#pills-owned"
                                    type="button" role="tab" aria-controls="pills-owned" aria-selected="false" onClick={() => navigate(`/profile/owned`, { replace: true })}>Owned
                                    <small className="bg-medium-gray px-1 text-gray ms-1 fs-12 rounded-2">{ownItems.total_count ? ownItems.total_count : 0}</small></button>
                            </li>

                            <li className="nav-item me-2" role="presentation">
                                <button className={`nav-link ${match.params.tab == 'created' ? 'active' : ''} text-secondary fs-6 fw-semi-bold bg-transparent ps-0`}
                                    id="pills-created-tab" data-bs-toggle="pill" data-bs-target="#pills-created" type="button" role="tab"
                                    aria-controls="pills-created" aria-selected="false" onClick={() => navigate(`/profile/created`, { replace: true })}>Created
                                    <small className="bg-medium-gray px-1 text-gray fs-12  rounded-2">{createdItems.total_count ? createdItems.total_count : 0}</small></button>
                            </li>
                            <li className="nav-item me-2" role="presentation">
                                <button className={`nav-link ${match.params.tab == 'bids' ? 'active' : ''} text-secondary fs-6 fw-semi-bold bg-transparent ps-0`} id="pills-bid-tab"
                                    data-bs-toggle="pill" data-bs-target="#pills-bid" type="button" role="tab"
                                    aria-controls="pills-bid" aria-selected="false" onClick={() => navigate(`/profile/bids`, { replace: true })}>Bid
                                    <small className="bg-medium-gray px-1 text-gray fs-12  rounded-2">{bidsItems.total_count ? bidsItems.total_count : 0}</small></button>
                            </li>
                            {staticState.explore == EXPLORE_LANDING_MARKET_PLACE &&
                                <li className="nav-item me-2" role="presentation">
                                    <button className={`nav-link ${match.params.tab == 'collection' ? 'active' : ''} text-secondary fs-6 fw-semi-bold bg-transparent ps-0`} id="pills-collection-tab"
                                        data-bs-toggle="pill" data-bs-target="#pills-collection" type="button" role="tab"
                                        aria-controls="pills-collection" aria-selected="false" onClick={() => navigate(`/profile/collection`, { replace: true })}>Collections</button>
                                </li>}
                            <li className="nav-item me-2" role="presentation">
                                <button className={`nav-link ${match.params.tab == 'favourite' ? 'active' : ''} text-secondary fs-6 fw-semi-bold bg-transparent ps-0`} id="pills-favourite-tab"
                                    data-bs-toggle="pill" data-bs-target="#pills-favourite" type="button" role="tab"
                                    aria-controls="pills-favourite" aria-selected="false" onClick={() => navigate(`/profile/favourite`, { replace: true })}>Favorite</button>
                            </li>

                            <li className="nav-item me-2" role="presentation">
                                <button className={`nav-link ${match.params.tab == 'activity' ? 'active' : ''} text-secondary fs-6 fw-semi-bold bg-transparent ps-0`} id="profile-tab"
                                    data-bs-toggle="pill" data-bs-target="#profile" type="button" role="tab"
                                    aria-controls="profile" aria-selected="false" onClick={() => navigate(`/profile/activity`, { replace: true })}>Activity</button>
                            </li>
                        </ul>
                        {(match.params.tab === "on_sale" || match.params.tab === "sold" || match.params.tab === "owned" || match.params.tab === "created") &&
                            <div className="d-flex justify-content-between mb-4 ">
                                <div className="d-flex flex-wrap">
                                    <div className="dropdown my-1">
                                        <button className={`btn btn-outline-primary px-4 text-capitalize fw-medium me-2`} type="button" id="dropdownMenuCategory" data-bs-toggle="dropdown" aria-expanded="false">
                                            {getCategoryNameViaIdAndList(categories, query.get("category_id"), "Category")}
                                        </button>
                                        <ul className="dropdown-menu common-dropdown filters" aria-labelledby="dropdownMenuCategory">
                                            <li onClick={() => changeCategory("")}><a className="dropdown-item fw-medium" href="#"><img src={All} className="icon me-2" />All</a></li>
                                            {categories.map(res => {
                                                return <li key={res._id} onClick={() => changeCategory(res._id)}><a className="dropdown-item fw-medium" href="#"><img src={String(res.image).includes('https://') ? res.image : `${henceforthApi.API_FILE_ROOT_MEDIUM}${res.image}`} className="icon me-2" />{res.name} {res._id == query.get("category_id") ? <i className="fa-solid fa-check text-theme"></i> : ''}</a> </li>
                                            })}
                                        </ul>
                                    </div>
                                    <div className="dropdown my-1">
                                        {/* <button className="btn btn-outline-primary px-4 text-capitalize fw-medium me-2" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        Collection
                                    </button> */}
                                        <ul className="dropdown-menu common-dropdown p-0 filters" aria-labelledby="dropdownMenuButton1">
                                            <li>
                                                <div className="position-relative py-3 px-4 border-bottom">
                                                    <input className="form-control w-lg-50 w-100 me-1 shadow-none search-input fs-12 s-space" type="search" placeholder="Search in collection" aria-label="Search" />
                                                    <i className="fa-solid fa-magnifying-glass text-theme position-absolute top-50 translate-middle-y left-0 ms-3"></i>
                                                </div>
                                            </li>

                                            <li><a className="fw-medium py-1 px-4 mt-2 d-flex align-items-center fs-12" href="#"><span className="col-circle-1 me-3"></span>Collection One</a></li>
                                            <li><a className="fw-medium py-1 px-4 mb-2 d-flex align-items-center fs-12" href="#"><span className="col-circle-2 me-3"></span>Collection Two</a></li>
                                            <li className="d-flex px-4 py-3 border-top ">
                                                <button className="btn btn-outline-primary me-1 fw-medium fs-12 px-4" >CLEAR</button>
                                                <button className="btn btn-primary fs-12 fs-12 px-4">APPLY</button>
                                            </li>
                                        </ul>
                                    </div>
                                    {staticState.explore == EXPLORE_LANDING_MARKET_PLACE &&
                                        <div className="dropdown my-1">
                                            <button className="btn btn-outline-primary px-4 text-capitalize fw-medium me-2" type="button" id="dropdownMenuType" data-bs-toggle="dropdown" aria-expanded="false">
                                                {query.has("post_type") ? query.get("post_type") == EXPLORE_NFT_POST_TYPE_SINGLE ? 'Single' : query.get("post_type") == EXPLORE_NFT_POST_TYPE_MULTIPLE ? 'Multiple' : 'Type' : 'Type'}
                                            </button>
                                            <ul className="dropdown-menu common-dropdown filters" aria-labelledby="dropdownMenuType">
                                                <li onClick={() => changePostType("")}><a className="dropdown-item fw-medium fs-12 mb-1" href="#"><img src={All} className="icon me-2" />All</a></li>
                                                <li onClick={() => changePostType(EXPLORE_NFT_POST_TYPE_SINGLE)}><a className="dropdown-item fw-medium fs-12 mb-1" href="#"><img src={Single} className="icon me-2" />Single {EXPLORE_NFT_POST_TYPE_SINGLE == query.get("post_type") ? <i className="fa-solid fa-check text-theme"></i> : ''}</a></li>
                                                <li onClick={() => changePostType(EXPLORE_NFT_POST_TYPE_MULTIPLE)}><a className="dropdown-item fw-medium fs-12 mb-1" href="#"><img src={Multiple} className="icon me-2" />Multiple {EXPLORE_NFT_POST_TYPE_MULTIPLE == query.get("post_type") ? <i className="fa-solid fa-check text-theme"></i> : ''}</a></li>
                                            </ul>
                                        </div>}
                                    {staticState.explore == EXPLORE_LANDING_SOCIAL &&
                                        <div className="dropdown my-1">
                                            <button className="btn btn-outline-primary px-4 text-capitalize fw-medium me-2" type="button" id="dropdownMenuType" data-bs-toggle="dropdown" aria-expanded="false">
                                                {query.has("social_type") ? query.get("social_type") == EXPLORE_NFT_TYPE_INSTAGRAM ? 'Instagram' : query.get("social_type") == EXPLORE_NFT_TYPE_TIKTOK ? 'Tiktok' : 'Social Type' : 'Social Type'}
                                            </button>
                                            <ul className="dropdown-menu common-dropdown filters" aria-labelledby="dropdownMenuType">
                                                <li onClick={() => changeSocialType("")}><a className="dropdown-item fw-medium fs-12 mb-1" href="#"><img src={All} className="icon me-2" />All</a></li>
                                                <li onClick={() => changeSocialType(EXPLORE_NFT_TYPE_INSTAGRAM)}><a className="dropdown-item fw-medium fs-12 mb-1" href="#"><img src={InstagramIcon} className="icon me-2" />Instagram {EXPLORE_NFT_TYPE_INSTAGRAM == query.get("social_type") ? <i className="fa-solid fa-check text-theme"></i> : ''}</a></li>
                                                <li onClick={() => changeSocialType(EXPLORE_NFT_TYPE_TIKTOK)}><a className="dropdown-item fw-medium fs-12 mb-1" href="#"><img src={TiktokIcon} className="icon me-2" />Tiktok {EXPLORE_NFT_TYPE_TIKTOK == query.get("social_type") ? <i className="fa-solid fa-check text-theme"></i> : ''}</a></li>
                                            </ul>
                                        </div>}
                                    <div className="dropdown my-1">
                                        <button className="btn btn-outline-primary px-4 text-capitalize fw-medium me-2" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            {query.has("min_price") ? `${query.get("min_price")} - ${query.get("max_price")} ELEN ` : 'Price Range'}
                                        </button>
                                        <ul className="dropdown-menu common-dropdown p-0 filters" aria-labelledby="dropdownMenuButton1">
                                            <li><a className="fw-medium py-1 px-4 my-2 d-flex align-items-center" href="#">
                                                <input type="number" className="form-control me-2 border-pink" min={0} value={minPrice} placeholder="From" onChange={(e) => setMinPrice(e.target.value)} />
                                                <input type="number" className="form-control border-pink" min={0} value={maxPrice} placeholder="To" onChange={(e) => setMaxPrice(e.target.value)} />
                                            </a></li>
                                            <li className="d-flex px-4 py-3 border-top ">
                                                <button type="button" className="btn btn-outline-primary me-1 fw-medium fs-12 px-4" onClick={() => enableDisablePrice(true)}>CLEAR</button>
                                                <button type="button" className="btn btn-primary fs-12 px-4" onClick={() => enableDisablePrice(false)}>APPLY</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="dropdown">
                                    <button className="btn btn-outline-primary px-4 text-capitalize fw-medium recently-added position-relative text-nowrap" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        {query.get("sort_by") == "RECENTLY_ADDED" ? 'Recently Added' : query.get("sort_by") == "PRICE_HIGH_TO_LOW" ? 'Price -- High to Low' : query.get("sort_by") == "PRICE_LOW_TO_HIGH" ? 'Price -- Low to High' : query.get("sort_by") == "AUCTION_ENDING_SOON" ? 'Auction Ending Soon' : 'All'}

                                        <span className="sort position-absolute start-50 translate-middle-x bg-white text-theme">Sort</span>
                                    </button>
                                    <ul className="dropdown-menu common-dropdown dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                        <li onClick={() => changeSortBy("RECENTLY_ADDED")}><a className="dropdown-item fw-medium added d-flex justify-content-between align-items-center" href="#"><span className="sort-text">Recently Added</span>  {query.get("sort_by") == "RECENTLY_ADDED" ? <i className="fa-solid fa-check text-theme"></i> : ''}</a></li>

                                        <li onClick={() => changeSortBy("PRICE_HIGH_TO_LOW")}><a className="dropdown-item fw-medium d-flex justify-content-between align-items-center" href="#"><span className="sort-text">Price -- High to Low</span>{query.get("sort_by") == "PRICE_HIGH_TO_LOW" ? <i className="fa-solid fa-check text-theme"></i> : ''}</a></li>

                                        <li onClick={() => changeSortBy("PRICE_LOW_TO_HIGH")}><a className="dropdown-item fw-medium d-flex justify-content-between align-items-center" href="#"><span className="sort-text">Price -- Low to High</span>{query.get("sort_by") == "PRICE_LOW_TO_HIGH" ? <i className="fa-solid fa-check text-theme"></i> : ''}</a></li>

                                        <li onClick={() => changeSortBy("AUCTION_ENDING_SOON")}><a className="dropdown-item fw-medium d-flex justify-content-between align-items-center" href="#"><span className="sort-text">Auction Ending Soon</span>{query.get("sort_by") == "AUCTION_ENDING_SOON" ? <i className="fa-solid fa-check text-theme"></i> : ''}</a></li>
                                    </ul>
                                </div>
                            </div>
                        }
                        <div className="tab-content" id="pills-tabContent">
                            <div className={`tab-pane fade  ${match.params.tab == 'on_sale' ? 'show active' : ''}`} id="pills-sale" role="tabpanel"
                                aria-labelledby="pills-sale-tab">
                                <NftsMapList items={resaleItems} _id={authState._id} elenToUsd={elenToUsd} />
                            </div>

                            <div className={`tab-pane fade  ${match.params.tab == 'sold' ? 'show active' : ''}`} id="pills-sold" role="tabpanel"
                                aria-labelledby="pills-sold-tab">
                                <NftsMapList items={soldItems} _id={authState._id} elenToUsd={elenToUsd} />
                            </div>

                            <div className={`tab-pane fade  ${match.params.tab == 'owned' ? 'show active' : ''}`} id="pills-owned" role="tabpanel"
                                aria-labelledby="pills-owned-tab">
                                <NftsMapList items={ownItems} _id={authState._id} elenToUsd={elenToUsd} />
                            </div>
                            <div className={`tab-pane fade  ${match.params.tab == 'created' ? 'show active' : ''}`} id="pills-created" role="tabpanel"
                                aria-labelledby="pills-created-tab">
                                <NftsMapList items={createdItems} _id={authState._id} elenToUsd={elenToUsd} />
                            </div>
                            <div className={`tab-pane fade  ${match.params.tab == 'bids' ? 'show active' : ''}`} id="pills-bid" role="tabpanel"
                                aria-labelledby="pills-bid-tab">
                                <NftsMapList items={bidsItems} _id={authState._id} elenToUsd={elenToUsd} />
                            </div>
                            <div className={`tab-pane fade  ${match.params.tab == 'collection' ? 'show active' : ''}`} id="pills-collection" role="tabpanel" aria-labelledby="pills-collection-tab">
                                <div className="container p-0">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="collection-box">
                                                <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#createCollectionModal">CREATE A COLLECTION</button>
                                                <button className="btn-outline-primary fw-medium mt-2" data-bs-toggle="modal" data-bs-target="#importModal">IMPORT AN EXISTING</button>
                                            </div>
                                        </div>
                                        {collectionItems.data.map(res => <div className="col-md-4 mt-1">
                                            <Link to={`/collection/${res._id}/items`}>
                                                <div className="collections">
                                                    <img src={henceforthApi.FILES.imageOriginal(res.img_url)} className="col-img" />
                                                    <p className="col-name text-break">{res.name}</p>
                                                </div>
                                            </Link>
                                        </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={`tab-pane fade  ${match.params.tab == 'favourite' ? 'show active' : ''}`} id="pills-favourite" role="tabpanel"
                                aria-labelledby="pills-favourite-tab">
                                <NftsMapList items={favoritedItems} _id={authState._id} elenToUsd={elenToUsd} />
                            </div>

                            <div className={`tab-pane fade  ${match.params.tab == 'activity' ? 'show active' : ''}`} id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <div className="nft-activity mt-lg-4  mt-4">
                                    {activityItems && Array.isArray(activityItems.data) && activityItems.data.map(res => <ActivitiesList key={res._id} {...res} />)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
        <Modals.CreateCollection redirect={true} />
        <Modals.ImportCollection provider={authState.provider} />
        <Modals.Followers authState={authState} {...followers} inialiseFollowers={inialiseFollowers} inialiseFollowing={inialiseFollowing} />
        <Modals.Following authState={authState} {...following} inialiseFollowers={inialiseFollowers} inialiseFollowing={inialiseFollowing} />
        <Modals.Warning title={warningMsg?.title} subtitle={warningMsg?.subtitle} onSubmit={() => setWarningMsg({ title: '', subtitle: '', btnValue: '' })} btnValue={warningMsg?.btnValue} />
        <Modals.Warning title={welcomeBackInstagramMsg?.title} subtitle={welcomeBackInstagramMsg?.subtitle} onSubmit={async () => initialiseProfile()} btnValue={welcomeBackInstagramMsg?.btnValue} />
    </section >)
}