import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import henceforthApi from "../../utils/henceforthApi"

export default ({ _id, data, setData }) => {
    const navigate = useNavigate()

    const onSubmit = async () => {
        try {
            let apiRes = await henceforthApi.NFT.burn(_id)
            toast.success(apiRes.message)
            navigate(`/`, { replace: true })
        } catch (error) {
            toast.error(error?.response?.body?.error_description)
        }
    }

    return <div className={`modal fade common-modal report-modal ${data ? 'show' : ''}`} data-bs-backdrop="static" tabIndex="-1" role={data ? 'dialog' : ''} style={{ display: data ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.5)' }} aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <button type="button" className="btn-close shadow-none" aria-label="Close" onClick={() => setData(null)}></button>
                <div className="modal-body text-center">
                    <h5 className="fw-bold mb-3"><span className="text-gradient">Burn NFT</span></h5>
                    <h6 className="text-gray mb-4 fw-normal">Are you sure you want to permanently burn this NFT.</h6>

                    <button className="btn btn-primary py-2 mt-4 w-100" onClick={onSubmit}>BURN NFT</button>
                </div>
            </div>
        </div>
    </div>
}