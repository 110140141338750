import moment from "moment"
import { useEffect, useState } from "react";
import henceforthApi from "../../utils/henceforthApi"
import { subscribeFormatter } from "../../utils/work_contant";
import profile_placeholder from "./../../assets/images/home/img_placeholder_small.svg";

export default ({ bid_placed_by, created_at, bid_amount, owner_address, creator_address, elenToUsd, voucher_id, authState }) => {
    // let countdownTimer;
    // const [timerCount, setTimerCount] = useState({
    //     days: "0",
    //     hours: "0",
    //     minutes: "0",
    //     seconds: "0",
    // })
    // const [isExpire, setIsExpire] = useState(null)

    // const initialiseInterval = (end_date) => {
    //     if (end_date) {
    //         var _second = 1000;
    //         var _minute = _second * 60;
    //         var _hour = _minute * 60;
    //         var _day = _hour * 24;
    //         var end = new Date(Number(end_date))
    //         function timer() {
    //             var now = new Date();
    //             var distance = end - now;
    //             if (distance < 0) {
    //                 clearInterval(countdownTimer);
    //                 console.log("initialiseInterval EXPIRED!");
    //                 setIsExpire(true)
    //                 return;
    //             } else {
    //                 setIsExpire(false)
    //             }

    //             var days = Math.floor(distance / _day);
    //             var hours = Math.floor((distance % _day) / _hour);
    //             var minutes = Math.floor((distance % _hour) / _minute);
    //             var seconds = Math.floor((distance % _minute) / _second);
    //             setTimerCount({
    //                 ...timerCount,
    //                 days,
    //                 hours,
    //                 minutes,
    //                 seconds,
    //             })
    //         }
    //         countdownTimer = setInterval(() => timer(), 1000);
    //     }
    // }

    // useEffect(() => {
    //     initialiseInterval(voucher_id?.end_date)
    // }, [])
    return <div className="bid-owner p-3 d-flex justify-content-between w-70  border-pink rounded-3 mb-3">
        <div className="owner-name d-flex align-items-start ">
            <div className="name-img">
                <img src={bid_placed_by.profile_pic ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${bid_placed_by.profile_pic}` : profile_placeholder} className="large-user" />
            </div>
            <div className="name-text ms-3">
                <p className="mb-0">Bid placed by<b className="fw-semi-bold ms-1 text-theme">@{bid_placed_by.name}</b></p>
                <p className="text-black mb-0">{moment(Number(created_at)).format("MMM DD, YYYY")} {moment(Number(created_at)).format("hh:mma")}</p>
            </div>
        </div>
        <div className="name-text ms-2 text-end">
            <h6 className="fw-semi-bold mb-0">{subscribeFormatter(bid_amount)} ELEN</h6>
            {elenToUsd !== 0 &&
                <p className="text-black-50 mb-0 fs-12 text-nowrap">${subscribeFormatter(Number(Number(bid_amount) * elenToUsd).toFixed(4))} USD</p>}
        </div>
    </div>
    {/* {authState?.wallet?.length !== 0 && String(voucher_id?.owner_address) === String(authState?.wallet[0].address) &&
            <button type="button" className="btn btn-primary w-100">{isExpire ? 'Place order' : `${String(timerCount.days).length == 1 ? `0${timerCount.days}` : timerCount.days} Days ${String(timerCount.hours).length == 1 ? `0${timerCount.hours}` : timerCount.hours} Hour ${String(timerCount.minutes).length == 1 ? `0${timerCount.minutes}` : timerCount.minutes} Min ${String(timerCount.seconds).length == 1 ? `0${timerCount.seconds}` : timerCount.seconds} Sec`}</button>} */}
}