import { BigNumber } from "ethers"
import henceforthApi from "./henceforthApi"

// bsctestnet
export const COLLECTION721_BSC_TESTNET_ADDRESS_KEY = '0x07cCE821B35eCDD28255EECe7Af41d4daBb60172'
export const COLLECTION1155_BSC_TESTNET_ADDRESS_KEY = '0x16c4F4608DFCfb1a407F614D591BF08B84342f74'
export const ERC721_BSC_TESTNET_ADDRESS_KEY = '0xEd656681e6eFf07FC9eB9642f6F56c3317722394'
export const ERC1155_BSC_TESTNET_ADDRESS_KEY = '0xA5b6EA3e7A9B8968345146C18a3903C75e85Dff0'
export const MARKET_PLACE_BSC_TESTNET_ADDRESS_KEY = '0x6Ce50be998B8092ec83Cf33Ee40377B2A8610B1b'
const MARKET_PLACE_BSC_TESTNET_ADDRESS_KEY_V2 = '0x0fcEDBf188a30667117408fDc1C4Ab9FeFA2bE55'

// bscmainnet
export const COLLECTION721_BSC_MAINNET_ADDRESS_KEY = '0xBfF41d385574D07bD81E33a2FF3951d3D81D8bB4'
export const COLLECTION1155_BSC_MAINNET_ADDRESS_KEY = '0x3531109380f95c8c70a0B2ef6166017b115c4b41'
export const ERC721_BSC_MAINNET_ADDRESS_KEY = '0xA13983BcE551e1f132466c5DfdAf2D7C9072642C'
export const ERC1155_BSC_MAINNET_ADDRESS_KEY = '0x9126d9ca2B63D8B0cB3018021A3075DF1FF93Cf4'
export const MARKET_PLACE_BSC_MAINNET_ADDRESS_KEY = '0xcd1C37E9a70E5cA7C4F68a5Bb694E54CCfb59d64'
const MARKET_PLACE_BSC_MAINNET_ADDRESS_KEY_V2 = '0x3d654477C39899D8Fb7635b942b2d9BF82e510e4'

const ELEN_BSC_TESTNET_ADDRESS_KEY = '0xc0Df105C6D90a6dBF0F08663cD4822f47e4697c1'
const ELEN_BINANCE_MAINNET_ADDRESS_KEY = '0xCa2483727292bA552AEc12DfEE4dc105CB1376B9'
export const WALLET_CONNECT_INFURA_ID = '107e6044aa9c4af780a7ea3e3b0460ce'

const henceforthRpc = (network) => {
    const staging = henceforthApi.IS_STAGING

    const Ethereum = () => (
        {
            rpc: {
                chainId: BigNumber.from(1)._hex,
                rpcUrl: 'https://mainnet.infura.io/v3/',
                chainName: 'Ethereum - Mainnet'
            },
            wrapped: {
                address: 'ELEN_ADDRESS_KEY',
                symbol: 'ELEN',
                decimals: 18,
                image: 'https://www.henceforthsolutions.com/assets/favicon.ico',
            }
        }
    )

    const Binance = () => (
        {
            rpc: {
                chainId: '0x38',
                rpcUrl: 'https://bsc-dataseed.binance.org/',
                chainName: "Binance - Mainnet"
            },
            wrapped: {
                address: ELEN_BINANCE_MAINNET_ADDRESS_KEY,
                symbol: 'ELEN',
                decimals: 18,
                image: 'https://www.henceforthsolutions.com/assets/favicon.ico',
            },
            collection721Addr: COLLECTION721_BSC_MAINNET_ADDRESS_KEY,
            collection1155Addr: COLLECTION1155_BSC_MAINNET_ADDRESS_KEY,
            erc721Addr: ERC721_BSC_MAINNET_ADDRESS_KEY,
            erc1155Addr: ERC1155_BSC_MAINNET_ADDRESS_KEY,
            marketplaceAddr: MARKET_PLACE_BSC_MAINNET_ADDRESS_KEY,
            marketplaceAddrV2: MARKET_PLACE_BSC_MAINNET_ADDRESS_KEY_V2,
        }
    )
    const BinanceTest = () => (
        {
            rpc: {
                chainId: BigNumber.from(97)._hex,
                rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
                chainName: "Binance Smart Chain - Testnet"
            },
            wrapped: {
                address: ELEN_BSC_TESTNET_ADDRESS_KEY,
                symbol: 'ELEN',
                decimals: 18,
                image: 'https://www.henceforthsolutions.com/assets/favicon.ico',
            },
            collection721Addr: COLLECTION721_BSC_TESTNET_ADDRESS_KEY,
            collection1155Addr: COLLECTION1155_BSC_TESTNET_ADDRESS_KEY,
            erc721Addr: ERC721_BSC_TESTNET_ADDRESS_KEY,
            erc1155Addr: ERC1155_BSC_TESTNET_ADDRESS_KEY,
            marketplaceAddr: MARKET_PLACE_BSC_TESTNET_ADDRESS_KEY,
            marketplaceAddrV2: MARKET_PLACE_BSC_TESTNET_ADDRESS_KEY_V2,
        }
    )
    const Polygon = () => (
        {
            rpc: {
                chainId: BigNumber.from(137)._hex,
                rpcUrl: 'https://polygon-rpc.com/',
                chainName: "Polygon - Mainnet"
            },
            wrapped: {
                address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
                symbol: 'WETH',
                decimals: 18,
                image: 'https://www.henceforthsolutions.com/assets/favicon.ico',
            }
        }
    )
    const PolygonTest = () => (
        {
            rpc: {
                chainId: BigNumber.from(80001)._hex,
                rpcUrl: 'https://rpc-mumbai.maticvigil.com/',
                chainName: "Mumbai Testnet"
            },
            wrapped: {
                address: 'WETH_MATIC_ADDRESS_KEY',
                symbol: 'WMATIC',
                decimals: 18,
                image: 'https://www.henceforthsolutions.com/assets/favicon.ico',
            }
        }
    )
    if (network === "ethereum") {
        return staging ? BinanceTest() : Ethereum()
    } else if (network === "binance") {
        return staging ? BinanceTest() : Binance()
    } else if (network === "polygon") {
        return staging ? PolygonTest() : Polygon()
    } else {
        return BinanceTest()
    }
}
export default henceforthRpc