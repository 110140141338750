import { useContext, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import Placeholder from "../../assets/images/home/img_placeholder_small.svg"
import { GlobalContext } from "../../context/Provider"
import henceforthApi from "../../utils/henceforthApi"
import { Spinner } from "../BootstrapCompo"

export default ({ erc, callback, redirect }) => {
    const navigate = useNavigate()
    const location = useLocation()
    var query = new URLSearchParams(location.search);
    const { authState, createCollection721, createCollection1155 } = useContext(GlobalContext)
    const [icon, setIcon] = useState(null)
    const [name, setName] = useState("")
    const [symbol, setSymbol] = useState("")
    const [description, setDescription] = useState("")
    // const [sortUrl, setSortUrl] = useState("")
    const [loading, setLoading] = useState(false)
    const [ercValue, setErcValue] = useState(erc)

    henceforthApi.setToken(authState.access_token)
    const createNewCollection = async () => {

        if (!icon) {
            toast.warn("please upload image")
            return
        }
        if (!name) {
            toast.warn("Please enter collection name")
            return
        }
        if (!symbol) {
            toast.warn("Please enter symbol name")
            return
        }
        setLoading(true)

        try {
            const { proxy, image } = ercValue === 721 ? await createCollection721(name, symbol, icon, description) : await createCollection1155(name, symbol, icon, description)
            let items = {
                address: proxy,
                img_url: image,
                name: name,
                symbol: symbol,
                erc: ercValue,
                language: "ENGLISH"
            }
            if (description) {
                items["description"] = description
            }
            // if (sortUrl) {
            //     items["short_url"] = sortUrl
            // }
            query.set("collection", proxy)
            await insertToDb(items)
        } catch (error) {
            setLoading(false)
            if (error.response && error.response && error.response.body && error.response.body.error_description) {
                if (typeof error.response.body.error_description === "string") {
                    toast.error(error.response.body.error_description)
                } else {
                    toast.error(JSON.stringify(error.response.body.error_description))
                }
            }
        }
    }
    const insertToDb = async (items) => {
        let apiRes = await henceforthApi.Collection.create(items)
        let data = apiRes.data
        try {
            callback(data._id, data.address)

            setLoading(false)
        } catch (error) {
            setLoading(false)
        } finally {
            let closeCollectionModal = document.getElementById("closeCollectionModal")
            if (closeCollectionModal) {
                closeCollectionModal.click()
            }
        }
        query.set("collection_id", data._id)
        query.set("collection", data.address)
        setLoading(false)
        setIcon(null)
        setName("")
        setSymbol("")
        setDescription("")
        // setSortUrl("")

        if (redirect) {
            navigate(`/collection/${apiRes.data._id}/items`)
        } else {
            navigate({ search: query.toString() })
        }
    }

    return <div className="modal fade common-modal collection-modal" id="createCollectionModal" tabIndex="-1" aria-labelledby="createModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <form className="modal-body" onSubmit={(e) => { e.preventDefault(); createNewCollection() }}>
                    <button id="closeCollectionModal" type="button" className="btn btn-icon me-4 mt-3  shadow-none fs-6 position-absolute top-0 end-0" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark"></i></button>
                    <h3 className="text-dark text-center title mb-4"><span className="text-gradient">Collection {ercValue && 'ERC - '}{ercValue}</span></h3>
                    <div className="d-flex align-items-center mb-2">
                        <label className="col-upload-img me-3 position-relative cursor-pointer" title="Upload an image to create your collection" >
                            <input type="file" className="d-none" accept="image/*" onChange={(e) => setIcon(e.target.files[0])} />
                            <img src={icon ? URL.createObjectURL(icon) : Placeholder} />

                            <div className="position-absolute bottom-0 end-0">
                                <i className="fas fa-camera  border border-2  rounded-circle p-1 bg-white fs-10"></i>
                            </div>
                        </label>
                        <div>
                            <p className="mb-0 ml-3 text-gray fw-medium">We recommend an image<br />
                                of at
                                least 300*300.</p>
                            <p className="mb-0 ml-3 text-gray fw-medium">Max 5MB.</p>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="fw-medium mb-1">Collection Name<span className="text-gray ms-1">(required)</span></label>
                        <input type="text" placeholder="Collection name" className="form-control" name="name" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label className="fw-medium mb-1">Symbol<span className="text-gray ms-1">(required)</span></label>
                        <input type="text" placeholder="Token symbol (ETH, BTC, etc.)" className="form-control" name="symbol" value={symbol} onChange={(e) => setSymbol(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label className="fw-medium mb-1">Description<span className="text-gray ms-1">(optional)</span></label>
                        <textarea type="text" placeholder="Say a word about your collection…"
                            className="form-control" name="description" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                    </div>
                    {!erc &&
                        <div className="form-group">
                            <label className="fw-medium mb-1">Collection Type <span className="text-gray">(ERC 721 / ERC 1155)</span></label>
                            <select value={ercValue} className="form-select" onChange={(e) => setErcValue(Number(e.target.value))} required>
                                {!ercValue &&
                                    <option value="">Select</option>}
                                <option value={721}>ERC 721</option>
                                <option value={1155}>ERC 1155</option>
                            </select>
                        </div>}
                    {/* <div className="form-group">
                        <label className="fw-medium mb-1">Short URL</label>
                        <div className="position-relative">
                            <span className="base-url-text fs-14">everlens.com/</span>
                            <input type="text" placeholder=" Short URL" className="form-control url-input" name="sortUrl" value={sortUrl} onChange={(e) => setSortUrl(String(e.target.value).trim())} />
                        </div>
                    </div> */}
                    <div className="pt-3">
                        <button type="submit" className="btn btn-primary w-100 " disabled={loading}>{loading ? <Spinner /> : 'Create Collection'}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
}