import { useContext, useEffect, useState } from "react";
import { GlobalContext, Metamask, MetamaskResource, WalletConnectResource } from "../../../context/Provider";
import { SOCIAL_TYPE_WALLET_CONNECT, SOCIAL_TYPE_META_MASK } from "../../../context/actionTypes";
import henceforthApi from "../../../utils/henceforthApi";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import authAction from "../../../context/actions/auth/authAction";
import { getPrimaryWalletType } from "../../../utils/work_contant";
import { GlobalLoading } from "../../BootstrapCompo";
import Modals from "../../Modals";
export default () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { authState, authDispatch, loading, setLoading, loginWithMetamaskConnect, loginWithWalletConnect, scrollToTop } = useContext(GlobalContext)
    henceforthApi.setToken(authState.access_token)
    const [openWalletConnectInfo, setOpenWalletConnectInfo] = useState(false)

    const connectWallet = async (provider, providerName) => {
        const message = [
            "I have read and accept the terms and conditions (http://everlens.io//) of this app.",
            "Please connect me in!"
        ].join("\n")
        const signer = provider.getSigner()
        try {
            if (providerName === SOCIAL_TYPE_WALLET_CONNECT) {
                setOpenWalletConnectInfo(true)
            }
            const signatureEthers = await signer.signMessage(message)
            let items = {
                type: providerName,
                signature: signatureEthers,
                language: "ENGLISH"
            }
            setOpenWalletConnectInfo(false)
            try {
                let apiRes = await henceforthApi.Auth.connetWallet(items)
                let data = apiRes.data
                let walletType = getPrimaryWalletType(data.wallet)
                setLoading(false)
                authAction.initialiseProfile({ ...data, walletType, provider: providerName })(authDispatch);
                toast.success(apiRes.message)
                if (!location.pathname.includes("wallet")) {
                    navigate(`/profile/on_sale`)
                }
                setLoading(false)
            } catch (error) {
                setLoading(false)
                if (error.response && error.response.body) {
                    toast.error(error.response.body.error_description)
                }
            }
        } catch (error) {
            setLoading(false)
        }
        const closeMetamaskModal = document.getElementById("closeMetamaskModal")
        if (closeMetamaskModal) {
            closeMetamaskModal.click()
        }
    }
    const connectWithMetamask = async () => {
        if (!loading) {
            setLoading(true)
            const { error, provider } = await loginWithMetamaskConnect()
            if (error) {
                if (String(error.message).includes("eth_requestAccounts")) {
                    setLoading(false)
                    return toast.warn("Metamask not connected. \n Please login your Metamask wallet.")
                }
                setLoading(false)
                return toast.error(error.message)
            }
            await connectWallet(provider, SOCIAL_TYPE_META_MASK)
        }
    }
    const connectWithWalletConnect = async () => {
        const { provider } = await loginWithWalletConnect()
        await connectWallet(provider, SOCIAL_TYPE_WALLET_CONNECT)
    }

    useEffect(() => {
        if (authState.access_token) {
            scrollToTop()
            let walletType = getPrimaryWalletType(authState.wallet)
            if (walletType) {
                navigate(`/profile/on_sale`, { replace: true }) // uncomment after work
            }
        } else {
            navigate(`/signin?redirect=${location.pathname}`, { replace: true })
        }
    }, [authState.access_token])

    return <>
        {location.pathname.includes("wallet") ? '' :
            <p className="text-gray mb-2 fw-medium">Step 3 of 3</p>}
        <div className="row my-4 py-lg-2">
            <div className="col-md-6" onClick={connectWithMetamask}>
                <div className="d-flex justify-content-center align-items-center text-center square-box" >
                    <div>
                        <div className="m-icons">
                            <img src={MetamaskResource.icon} className="img-fluid w-40 " />
                        </div>

                        <p className="mb-0 fw-medium mt-2">{MetamaskResource.title}</p>
                    </div>
                </div>
            </div>
            <div className="col-md-6" onClick={connectWithWalletConnect}>
                <div className="d-flex justify-content-center align-items-center text-center square-box" >
                    <div>
                        <div className="m-icons">
                            <img src={WalletConnectResource.icon} className="img-fluid w-40 mt-1" />
                        </div>
                        <p className="mb-0 fw-medium mt-2">{WalletConnectResource.title}</p>
                    </div>
                </div>
            </div>
        </div>
        {loading ? <GlobalLoading /> : ''}
        {<Modals.OpenWallet data={openWalletConnectInfo} setData={setOpenWalletConnectInfo} onSubmit={connectWithWalletConnect} />}

    </>
}