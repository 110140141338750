import React, { createContext, useEffect, useReducer, useState } from 'react'
import authInitialState from './initialStates/authInitialState';
import exploreInitialState from './initialStates/exploreInitialState';
import auth from './reducers/auth';
import explore from './reducers/explore';
import henceforthApi from '../utils/henceforthApi';
import { NFT_STEP_0, NFT_STEP_1, NFT_STEP_2, NFT_STEP_3, NFT_STEP_4 } from './actionTypes';

import { toast } from 'react-toastify';
import axios from 'axios';
import staticAction from './actions/auth/staticAction';
import staticReducer from './reducers/staticReducer';
import authAction from './actions/auth/authAction';
import { getFirebaseMessageToken, onMessageListener } from '../firebase';
import { getDiscountPercent, getPrimaryWalletType } from '../utils/work_contant';
import metamaskContracts from '../utils/metamaskContracts';
import henceforthIPFS from '../utils/henceforthIPFS';

import COLLECTIONS_FACTORY721_CONTRACT_ARTIFACTS from './../artifacts/Collections/CollectionFactory721.sol/CollectionsFactory721.json'
import COLLECTIONS_FACTORY1155_CONTRACT_ARTIFACTS from './../artifacts/Collections/CollectionFactory1155.sol/CollectionsFactory1155.json'
import ERC721_CONTRACT_ARTIFACTS from './../artifacts/ERC721/ERC721.sol/GenericERC721.json'
import ERC1155_CONTRACT_ARTIFACTS from './../artifacts/ERC1155/ERC1155.sol/GenericERC1155.json'
import MARKETPLACE_CONTRACT_ARTIFACTS from './../artifacts/Marketplace.sol/MarketPlace.json'
import { ethers } from 'ethers';
import henceforthMarketplaceVoucher from '../utils/henceforthMarketplaceVoucher';

export const INSTAGRAM_CLIENT_ID = '2864559007130049'
export const PURCHASE_TIME_TAX = 3
export const EVERLENS_V2_ERC721_ADDRESS = '0x22f45D1E5dE13cBce13f45D5cB447B0562D35Df7'

export const GlobalContext = createContext({});
export const downloadFile = (file_path) => {
    var a = document.createElement('a');
    a.href = file_path;
    a.target = "_blank";
    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
export const MetamaskResource = {
    icon: 'https://raw.githubusercontent.com/MetaMask/brand-resources/master/SVG/metamask-fox.svg',
    name: 'Metamask',
    title: 'Connect with metamask'
}
export const WalletConnectResource = {
    icon: 'https://raw.githubusercontent.com/WalletConnect/walletconnect-assets/master/Logo/Blue%20(Default)/Logo.svg',
    name: 'WalletConnect',
    title: 'Connect with walletConnect'
}

function GlobalProvider({ children }) {

    const [loading, setLoading] = useState(false)
    const [tikTokLoginLoading, setTikTokLoginLoading] = useState(false)
    const [fileUploadLoading, setFileUploadLoading] = useState(false)
    const [accounts, setAccounts] = useState([])
    const [categories, setCategories] = useState([])
    const [elenToUsd, setElenToUsd] = useState(0)
    const [ethToUsd, setEthInUsd] = useState(0)
    const [balance, setBalance] = useState(0)
    const [createNftStep, setCreateNftStep] = useState(NFT_STEP_0)
    const [wethStep, setWethStep] = useState(NFT_STEP_0)
    const [putOnSale, setPutOnSaleStep] = useState(NFT_STEP_0)
    const [loadMore, setLoadMore] = useState(null)

    const [creaters, setCreaters] = useState({
        data: [],
        page: 0
    })
    const [exploreFilter, setExploreFilter] = useState({
        filters: [],
        page: 0
    })
    const [notidication, setNotification] = useState({
        data: null,
        total_count: 0

    })
    const [authState, authDispatch] = useReducer(auth, authInitialState, () => {
        const localAuthState = localStorage.getItem("authState");
        return localAuthState ? JSON.parse(localAuthState) : authInitialState
    })
    const [exploreState, exploreDispatch] = useReducer(explore, exploreInitialState, () => {
        const localExploreState = localStorage.getItem("exploreState");
        return localExploreState ? JSON.parse(localExploreState) : exploreInitialState
    })
    const [staticState, staticDispatch] = useReducer(staticReducer, staticAction.initialState, () => {
        const localStaticState = localStorage.getItem("staticState");
        return localStaticState ? JSON.parse(localStaticState) : staticAction.initialState
    })

    const scrollToTop = () => {
        if (window) {
            window.scrollTo(0, 0);
        }
    }

    const fileUpload = async (file) => {
        setFileUploadLoading(true)
        const url = `${henceforthApi.API_ROOT}Upload`;
        const formDataFile = new FormData();
        formDataFile.append('file', file)
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'token': authState.access_token
            }
        }
        try {
            const apiRes = await (await axios.post(url, formDataFile, config)).data
            setFileUploadLoading(false)
            return apiRes.data.file_name
        } catch (error) {
            setFileUploadLoading(false)
            return null
        }
    }
    const uploadFileIpfs = async (file) => {
        setFileUploadLoading(true)
        const url = `${henceforthApi.API_ROOT}upload/ipfs/file`;
        const formDataFile = new FormData();
        formDataFile.append('file', file)
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'token': authState.access_token
            }
        }
        try {
            const apiRes = await (await axios.post(url, formDataFile, config)).data
            setFileUploadLoading(false)
            return apiRes.data
        } catch (error) {
            setFileUploadLoading(false)
            return null
        }
    }

    const connectTiktok = async (username) => {
        // let document_url = await fileUpload(file)
        try {
            let items = {
                type: "TIKTOK",
                username,
                language: "ENGLISH"
            }
            setTikTokLoginLoading(true)
            let apiRes = await henceforthApi.Auth.connectSocialAccount(items)
            let data = apiRes.data
            setTikTokLoginLoading(false)
            let walletType = getPrimaryWalletType(data.wallet)
            setLoading(false)
            authAction.initialiseProfile({ ...data, walletType })(authDispatch);
            toast.success(apiRes.message)
            return data
        } catch (error) {
            if (error.response && error.response.body && error.response.body.error_description) {
                toast.error(error.response.body.error_description)
            }
            setTikTokLoginLoading(false)
            return { error }
        }
    }

    onMessageListener().then(res => {
        debugger
        console.log("res", res);
        getNotification()
    }).catch(error => {
        debugger
        console.log("err", error);
        getNotification()
    })

    const getNotification = async () => {
        henceforthApi.setToken(authState.access_token)
        try {
            let apiRes = await henceforthApi.Notification.get(0)
            let data = apiRes.data
            setNotification({
                ...notidication,
                ...data
            })
        } catch (error) {
            console.log("error", error);
        }
    }

    const logOutNow = async (from) => {
        console.log('from', from);
        debugger
        try {
            setAccounts([])
            authAction.logout()(authDispatch);
            henceforthApi.Auth.logout()
            if (metamaskContracts.walletConnectProvider) {
                metamaskContracts.walletConnectProvider?.disconnect()
            }
            // if (window !== undefined) {
            //     window.location.reload()
            // }
            return true
        } catch (error) {
            return false
        }
    }
    const initialiseCategories = async () => {
        try {
            let apiRes = await henceforthApi.NFT.categories()
            let data = apiRes.data
            setCategories(data.data)
        } catch (error) {

        }
    }
    const initialiseCurrency = async () => {
        try {
            let ethusd = await henceforthApi.Common.ethusd()
            let elenusd = await henceforthApi.Common.elenusd()
            setEthInUsd(ethusd.body.USD)
            if (elenusd?.body?.everlens?.usd) {
                setElenToUsd(Number(elenusd.body.everlens.usd))
            }
        } catch (error) {

        }
    }
    const requestNotification = async () => {
        henceforthApi.setToken(authState.access_token)
        if (!("Notification" in window)) {
            console.log("This browser does not support desktop notification");
            return ""
        }
        else if (Notification.permission === "granted") {

            let { tokenId, error } = await getFirebaseMessageToken()
            if (error) {
                console.log("enableNotification error", error);
                return ""
            }

            let apiRes = await henceforthApi.Profile.fcmToken(tokenId)
            return tokenId
        }
        else if (Notification.permission !== 'denied' || Notification.permission === "default") {
            Notification.requestPermission(async (permission) => {
                if (permission === "granted") {
                    let { tokenId, error } = await getFirebaseMessageToken()
                    if (error) {
                        console.log("enableNotification error", error);
                        return ""
                    }
                    let apiRes = await henceforthApi.Profile.fcmToken(tokenId)
                    console.log("enableNotification tokenId", tokenId, apiRes);
                    return tokenId

                } else {
                    console.log("permission", permission);
                    return ""
                }
            });
        } else {
            return ""
        }
    }

    const initialiseAccounts = async (provider) => {
        const { accounts, eth_chainId, networkVersion, ethereum, balance } = await metamaskContracts.getProvider(provider)
        setAccounts(accounts)
        setBalance(balance)
    }

    useEffect(() => {
        if (authState.access_token) {
            requestNotification()
            getNotification()
            initialiseAccounts(authState.provider)
        }
    }, [authState.access_token])

    useEffect(() => {
        initialiseCategories()
        initialiseCurrency()
    }, [])

    useEffect(() => {
        localStorage.setItem("authState", JSON.stringify(authState))
    }, [authState]);
    useEffect(() => {
        localStorage.setItem("exploreState", JSON.stringify(exploreState))
    }, [exploreState]);
    useEffect(() => {
        localStorage.setItem("staticState", JSON.stringify(staticState))
    }, [staticState]);

    useEffect(() => {
        const ethereum = window.ethereum
        if (ethereum) {
            ethereum.on('accountsChanged', (e) => {
                logOutNow('accountsChanged')
            })
            ethereum.on('chainChanged', (e) => { authState.access_token && metamaskContracts.RequestMetamaskChangeChain("binance") })
            // ethereum.on('disconnect', (e) => logOutNow('disconnect'))
        }
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", setLoadMore)
        return () =>
            window.removeEventListener("scroll", setLoadMore)
    }, [])


    const createCollection721 = async (_name, _symbol, _icon, description) => {
        debugger
        try {
            let iconPath = await uploadFileIpfs(_icon)
            let iconInS3Bucket = await fileUpload(_icon)
            let items = {
                name: _name,
                symbol: _symbol,
                image: `ipfs://${iconPath}`,
                description
            }
            let metadata = (await henceforthApi.Ipfs.metadata(JSON.stringify(items))).data
            debugger
            debugger
            let contractURI = henceforthIPFS.ipfsGetFileUrl(metadata)
            let { contract, accounts } = await metamaskContracts.getCollection721Contract(COLLECTIONS_FACTORY721_CONTRACT_ARTIFACTS, authState.provider)
            setAccounts(accounts)
            const contractResFun = await contract.functions.createToken(_name, _symbol, henceforthIPFS.baseURI, contractURI, accounts);
            const contractRes = await contractResFun.wait();
            const events = contractRes.events
            var proxy = null
            if (Array.isArray(events)) {
                events.forEach(element => {
                    if (element.args) {
                        let args = element.args
                        proxy = args.proxy
                    }
                })
            }

            setLoading(false)
            debugger
            return { proxy, image: iconInS3Bucket }
        } catch (error) {
            console.log("Error", error);
            setLoading(false)
            return null
        }
    }

    const createCollection1155 = async (_name, _symbol, _icon, description) => {
        try {

            let iconPath = await uploadFileIpfs(_icon)
            debugger
            debugger
            let iconInS3Bucket = await fileUpload(_icon)

            let items = {
                name: _name,
                symbol: _symbol,
                image: `ipfs://${iconPath}`,
                description
            }
            let metadata = (await henceforthApi.Ipfs.metadata(JSON.stringify(items))).data
            let contractURI = henceforthIPFS.ipfsGetFileUrl(metadata)
            let { contract, accounts } = await metamaskContracts.getCollection1155Contract(COLLECTIONS_FACTORY1155_CONTRACT_ARTIFACTS, authState.provider)
            setAccounts(accounts)
            const contractResFun = await contract.functions.createToken(_name, _symbol, henceforthIPFS.baseURI, contractURI, accounts);
            const contractRes = await contractResFun.wait();
            const events = contractRes.events
            var proxy = null
            if (Array.isArray(events)) {
                events.forEach(element => {
                    if (element.args) {
                        let args = element.args
                        proxy = args.proxy
                    }
                })
            }
            setLoading(false)
            debugger
            return { proxy, image: iconInS3Bucket }
        } catch (error) {
            console.log("Error", error);
            setLoading(false)
            return null
        }
    }

    const createVoucher = async (auction_type, quantity, end_date, price, tokenContractAddress) => {
        debugger
        let { contract, accounts, signer } = await metamaskContracts.getMarketPlaceContract(MARKETPLACE_CONTRACT_ARTIFACTS, authState.provider)
        setAccounts(accounts)
        if (contract) {
            const ether = ethers.utils.parseEther(Number(price).toFixed(18));
            await henceforthMarketplaceVoucher.setToken(contract, signer);
            const owner_address = String(accounts[0]).toLowerCase()
            debugger
            const { signature, salt } = await henceforthMarketplaceVoucher.CreateVoucher(owner_address, auction_type, quantity, end_date, ether, String(tokenContractAddress).toLowerCase())
            let itemsVoucher = {
                owner_address,
                price: Number(price),
                auction_type,
                signature,
                end_date,
                salt,
            }
            return itemsVoucher
        } else {
            setCreateNftStep(NFT_STEP_0)
            return null
        }
    }
    const createNft = async (events, contract, auctionType, quantity, end_date, price, accounts) => {
        let _tokenId = ""
        events.forEach(element => {
            if (element.args) {
                let args = element.args
                if (args.tokenId) {
                    _tokenId = args.tokenId
                }
                if (args._tokenId) {
                    _tokenId = args._tokenId
                }
            }
        })
        setCreateNftStep(NFT_STEP_3)
        debugger
        await metamaskContracts.isApprovedForAll(contract, accounts)
        setCreateNftStep(NFT_STEP_4)
        const itemsVoucher = await createVoucher(auctionType, quantity, end_date, price, contract.address)
        setCreateNftStep(NFT_STEP_0)
        return { token_id: _tokenId._hex, token_address: contract.address, accounts, itemsVoucher }
    }

    const createNFT721 = async (metadata, royalties, collectionAddress, auctionType, end_date, price) => {
        debugger
        setCreateNftStep(NFT_STEP_2)
        let { contract, accounts } = await metamaskContracts.getERC721Contract(ERC721_CONTRACT_ARTIFACTS, collectionAddress, authState.provider)
        setAccounts(accounts)
        if (contract) {
            try {
                const contractTransaction = await contract.functions.createNFT(metadata, Number(royalties));
                const { events } = await contractTransaction.wait();
                const items = await createNft(events, contract, auctionType, 1, end_date, price, accounts)
                return { ...items, erc: 721 }
            } catch (error) {
                console.log("Error", error);
                setCreateNftStep(NFT_STEP_0)
                return null
            }
        }
    }

    const createNFT1155 = async (metadata, royalties, collectionAddress, auctionType, end_date, price, supply) => {
        debugger
        setCreateNftStep(NFT_STEP_1)
        let { contract, accounts } = await metamaskContracts.getERC1155Contract(ERC1155_CONTRACT_ARTIFACTS, collectionAddress, authState.provider)
        setAccounts(accounts)
        if (contract) {
            try {
                const contractTransaction = await contract.functions.createToken(metadata, Number(supply), Number(royalties));
                const { events } = await contractTransaction.wait();
                const items = await createNft(events, contract, auctionType, supply, end_date, price, accounts)
                return { ...items, erc: 1155 }
            } catch (error) {
                console.log("Error", error);
                setLoading(false)
                return null
            }
        }
    }
    const finaliseAuction721 = async (contract, owner_address, voucher, signature, token_address, buyer, auction_type, actualPrice) => {
        console.log('finaliseAuction721 called');
        console.log('contract', contract, 'owner_address', owner_address, 'voucher', voucher, 'signature', signature, 'token_address', token_address, buyer, auction_type, actualPrice);
        debugger
        if (auction_type == 1) {
            try {
                const contractTransaction = await contract.functions.buy721(owner_address, voucher, signature, token_address ? token_address : EVERLENS_V2_ERC721_ADDRESS)
                return await contractTransaction.wait();
            } catch (error) {
                console.log('finaliseAuction721 1 error', error);
                toast.error(error?.reason)
                return null
            }
        } else {
            try {
                const etherPrice = ethers.utils.parseEther(Number(actualPrice).toFixed(18));
                const contractTransaction = await contract.functions.bid721(buyer, owner_address, voucher, signature, etherPrice, token_address)
                return await contractTransaction.wait();
            } catch (error) {
                console.log('finaliseAuction721 2 error', error);
                toast.error(error?.reason)
                return null
            }
        }
    }
    const finaliseAuction1155 = async (contract, owner_address, voucher, signature, quantity, token_address, buyer, auction_type, actualPrice) => {
        if (auction_type == 1) {
            const contractTransaction = await contract.functions.buy1155(owner_address, voucher, signature, Number(quantity), token_address)
            try {
                return await contractTransaction.wait();
            } catch (error) {
                toast.error(error?.reason)
                return null
            }
        } else {
            const etherPrice = ethers.utils.parseEther(Number(actualPrice).toFixed(18));
            const contractTransaction = await contract.functions.bid1155(owner_address, voucher, buyer, signature, etherPrice, Number(quantity), token_address)
            try {
                return await contractTransaction.wait();
            } catch (error) {
                toast.error(error?.reason)
                return null
            }
        }
    }
    const calculateWrappedAmount = (price, quantity, tax) => {
        const priceWithQuantity = Number(price) * Number(quantity)
        const priceFee = (priceWithQuantity * Number(tax ? tax : PURCHASE_TIME_TAX)) / 100
        const actualPrice = priceFee + Number(priceWithQuantity)
        const commission = actualPrice - Number(priceWithQuantity)
        return { actualPrice, commission }
    }
    const finaliseAuction = async (owner_address, voucher, signature, price, quantity, token_address, buyer, auction_type, erc) => {
        console.log('finaliseAuction called');
        debugger
        const { wrapped, marketplaceAddr, } = await metamaskContracts.RequestMetamaskChangeChain("binance")
        console.log('wrapped', wrapped, 'marketplaceAddr', marketplaceAddr);
        setLoading(true)
        try {
            let { contract, accounts } = await metamaskContracts.getMarketPlaceContract(MARKETPLACE_CONTRACT_ARTIFACTS, authState.provider)
            console.log('metamaskContracts.getMarketPlaceContract contract', contract, 'accounts', accounts);
            const { balanceOfERC20Tx } = await initialiseElenBalance(accounts[0])
            console.log('initialiseElenBalance called');
            const balanceOfERC20 = ethers.utils.formatEther(balanceOfERC20Tx)
            console.log('balanceOfERC20', balanceOfERC20);
            const tex = getDiscountPercent(balanceOfERC20)
            const { actualPrice, commission } = await calculateWrappedAmount(price, quantity, tex)
            console.log('calculateWrappedAmount called actualPrice', actualPrice, 'commission', commission);
            if (contract) {
                await metamaskContracts.wrappedContract(actualPrice, wrapped, marketplaceAddr, authState.provider)
                if (erc == 1155) {
                    const contractResponse = await finaliseAuction1155(contract, owner_address, voucher, signature, quantity, token_address, buyer, auction_type, actualPrice)
                    setLoading(false)
                    if (contractResponse) {
                        return { ...contractResponse, commission }
                    } else {
                        return null
                    }
                } else {
                    const contractResponse = await finaliseAuction721(contract, owner_address, voucher, signature, token_address, buyer, auction_type, actualPrice)
                    setLoading(false)
                    if (contractResponse) {
                        return { ...contractResponse, commission }
                    } else {
                        return null
                    }
                }
            } else {
                return null
            }
        } catch (error) {
            setLoading(false)
            console.log("finaliseFixedPriceAuction error", error);
            if (error.data && error.data.message) {
                toast.error(error.data.message)
            }
            toast.error(JSON.stringify(error?.error?.message))
            return null
        }
    }

    const finaliseOfferAuction = async (owner_address, voucher, signature, price, quantity, token_address, placed_by_address, auction_type, erc) => {
        const { wrapped, marketplaceAddr, } = await metamaskContracts.RequestMetamaskChangeChain("binance")
        setLoading(true)
        try {
            const { balanceOfERC20Tx } = await initialiseElenBalance(placed_by_address)
            console.log('initialiseElenBalance called');
            const balanceOfERC20 = ethers.utils.formatEther(balanceOfERC20Tx)
            console.log('balanceOfERC20', balanceOfERC20);
            const tex = getDiscountPercent(balanceOfERC20)

            const { actualPrice, commission } = await calculateWrappedAmount(price, quantity, tex)
            let { contract, accounts, provider, signer } = await metamaskContracts.getMarketPlaceContract(MARKETPLACE_CONTRACT_ARTIFACTS, authState.provider)
            if (contract) {
                if (erc == 1155) {
                    const contractResponse = await finaliseAuction1155(contract, owner_address, voucher, signature, quantity, token_address, placed_by_address, auction_type, actualPrice)
                    setLoading(false)
                    if (contractResponse) {
                        return { ...contractResponse, commission }
                    } else {
                        return null
                    }
                } else {
                    const contractResponse = await finaliseAuction721(contract, owner_address, voucher, signature, token_address, placed_by_address, auction_type, actualPrice)
                    setLoading(false)
                    if (contractResponse) {
                        return { ...contractResponse, commission }
                    } else {
                        return null
                    }
                }
            } else {
                return null
            }
        } catch (error) {
            setLoading(false)
            console.log("finaliseFixedPriceAuction error", error);
            toast.error(error?.data?.message)
            toast.error(error?.message)
            return null
        }
    }

    const makeAuction = async (price, quantity, account) => {

        const { wrapped, marketplaceAddr, } = await metamaskContracts.RequestMetamaskChangeChain("binance")
        const { balanceOfERC20Tx } = await initialiseElenBalance(account)
        const balanceOfERC20 = ethers.utils.formatEther(balanceOfERC20Tx)
        console.log('balanceOfERC20', balanceOfERC20);
        const tex = getDiscountPercent(balanceOfERC20)
        console.log('tex', tex);
        try {
            const { actualPrice, commission } = calculateWrappedAmount(price, quantity, tex)
            let contractRes = await metamaskContracts.wrappedContract(actualPrice, wrapped, marketplaceAddr, authState.provider)
            if (contractRes) {

                setWethStep(NFT_STEP_0)
                setLoading(false)
                return { ...contractRes, commission }
            } else {
                return null
            }
        } catch (error) {
            setWethStep(NFT_STEP_0)
            setLoading(false)
            console.log("finaliseFixedPriceAuction error", error);
            debugger
            if (error?.reason) {
                toast.error(error?.reason)
            }
            if (error.data && error.data.message) {
                toast.error(error.data.message)
            }
            return null
        }
    }
    const putOnMarketPlace = async (auctionType, quantity, end_date, price, start_date, collectionAddress) => {
        let { contract, accounts } = await metamaskContracts.getERC721Contract(ERC721_CONTRACT_ARTIFACTS, collectionAddress, authState.provider)
        setPutOnSaleStep(NFT_STEP_1)
        await metamaskContracts.isApprovedForAll(contract, accounts)
        setPutOnSaleStep(NFT_STEP_2)
        const { auction_type, signature, salt } = await createVoucher(auctionType, quantity ? Number(quantity) : 1, end_date, price, String(contract.address).toLowerCase())
        const voucherItems = {
            auction_type,
            price: Number(price),
            quantity: quantity ? Number(quantity) : 1,
            start_date,
            end_date,
            signature,
            salt,
            language: "ENGLISH"
        }
        setPutOnSaleStep(NFT_STEP_0)

        return voucherItems
    }
    const acceptOfferContract = async (_token_id, auction_type, quantity, end_date, price, tokenContractAddress, _order_id, placed_by_address) => {
        let { contract, accounts } = await metamaskContracts.getERC721Contract(ERC721_CONTRACT_ARTIFACTS, tokenContractAddress, authState.provider)
        setPutOnSaleStep(NFT_STEP_1)
        await metamaskContracts.isApprovedForAll(contract, accounts)
        setPutOnSaleStep(NFT_STEP_2)
        let ownerOf = await contract.functions.ownerOf(_token_id)
        console.log('ownerOf', ownerOf);
        debugger
        if (String(accounts[0]).toLowerCase() !== String(ownerOf[0]).toLowerCase()) {
            try {
                let { contract, accounts, signer } = await metamaskContracts.getMarketPlaceContractV2(MARKETPLACE_CONTRACT_ARTIFACTS, authState.provider)
                let cancelAuctionResponse = await contract.functions.cancelAuction(_order_id)
                let cancelAuctionResult = await cancelAuctionResponse.wait()
            } catch (error) {
                console.log('error message', error);
                toast.error(error?.reason)
                return null
            }
        }
        const { allowanceERC20Tx, balanceOfERC20Tx } = await initialiseElenBalance(placed_by_address)
        const allowancePrice = ethers.utils.formatEther(allowanceERC20Tx)
        const balanceOfERC20 = ethers.utils.formatEther(balanceOfERC20Tx)

        if (price > Number(balanceOfERC20)) {
            return toast.warn(`User have insufficient balance`)
        }
        if (price > Number(allowancePrice)) {
            return toast.warn(`Only ${allowancePrice} ELEN allowance found of this user`)
        }
        debugger
        return await createVoucher(auction_type, quantity, end_date, price, tokenContractAddress)
    }
    const initialiseElenBalance = async (checkOfAddress) => {
        const { wrapped, marketplaceAddr, } = await metamaskContracts.RequestMetamaskChangeChain("binance")
        let contractRes = await metamaskContracts.accessERC20(wrapped.address, marketplaceAddr, authState.provider, checkOfAddress)
        return contractRes
    }

    return (
        <GlobalContext.Provider
            value={{
                ...metamaskContracts,
                loading,
                loadMore,
                fileUploadLoading,
                categories,
                notidication,
                getNotification,
                setLoading,
                fileUpload,
                uploadFileIpfs,
                createNftStep,
                wethStep,
                scrollToTop,
                balance,
                authState,
                authDispatch,
                logOutNow,
                exploreState,
                exploreDispatch,
                staticState,
                staticDispatch,
                accounts,
                elenToUsd,
                ethToUsd,
                exploreFilter,
                setExploreFilter,
                creaters,
                setCreaters,
                setCreateNftStep,
                connectTiktok,
                tikTokLoginLoading,
                createCollection721,
                createCollection1155,
                createNFT721,
                createNFT1155,
                finaliseAuction,
                finaliseOfferAuction,
                createVoucher,
                acceptOfferContract,
                makeAuction,
                putOnMarketPlace,
                initialiseElenBalance,
                calculateWrappedAmount,
            }}>
            {children}
        </GlobalContext.Provider>
    )
}

export default GlobalProvider
