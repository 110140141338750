import { Link, useLocation, useNavigate } from "react-router-dom";
import profile_placeholder from "./../../assets/images/home/img_placeholder_small.svg";
import Logo from "../../assets/images/home/logo.svg";
import { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../../context/Provider";
import henceforthApi from "../../utils/henceforthApi";
import { Spinner } from "../BootstrapCompo";
import { EXPLORE_LANDING_MARKET_PLACE, EXPLORE_LANDING_SOCIAL } from "../../context/actionTypes";

const Notification = ({ data, total_count, getNotification }) => {

    const readNotification = async (_id) => {
        debugger
        try {
            await henceforthApi.Notification.read(_id)
            await getNotification()
        } catch (error) {

        }
    }
    const markAllRead = async () => {
        try {
            await henceforthApi.Notification.readAll()
            await getNotification()
        } catch (error) {

        }
    }

    return (
        <div className="dropdown">
            <div className="w-100 ms-0" >
                <div className="position-relative">
                    <button data-bs-toggle="dropdown" aria-expanded="false" id="dropdownMenuNotification" className="btn notification-btn shadow-none p-0 fs-14" onClick={() => getNotification()}>
                        <i className="fa-solid fa-bell"></i>
                    </button>
                    {total_count ?
                        <span className="n-count position-absolute top-0 d-flex align-items-center justify-content-center text-white">{total_count}</span>
                        : ''
                    }<ul className="dropdown-menu start-50 translate-middle-x mt-2 pb-3 pt-3 notification-menu" aria-labelledby="dropdownMenuNotification" aria-expanded="false">
                        <li>
                            <div className="d-flex justify-content-between align-items-center flex-wrap mb-2 py-1 px-3">
                                <h5 className="mb-0 fw-bold mb-0 text-theme">Notifications</h5>
                                {Array.isArray(data) && data.length !== 0 &&
                                    <small className="cursor-pointer" onClick={markAllRead}>Mark all read</small>}
                            </div>
                        </li>
                        {data == null ? <li> <div className="d-flex justify-content-center m-1"><Spinner /></div></li> :
                            Array.isArray(data) && data.length ? data.map(res => {
                                return <li key={res._id} className={`${res.read ? '' : 'active'}`}>
                                    <span className="dropdown-item me-5">
                                        <div className="d-flex justify-content-between align-items-start py-2">
                                            {res.type !== "BROADCAST_PUSH" ?
                                                <Link to={`/token/${res?.nft_id?.token_id}/${res.nft_id?._id}/trading/last_30_days`} onClick={() => readNotification(res._id)}>   <h6 className="mb-0 me-2 flex-grow-1 fs-14 text-wrap">{res.message}</h6></Link> :
                                                <a href="#"><h6 className="mb-0 me-2 flex-grow-1 fs-14 text-wrap">{res.message}</h6></a>
                                            }
                                            {total_count > 5 &&
                                                <i className="fa-solid fa-xmark" onClick={() => readNotification(res._id)}></i>}
                                        </div>
                                    </span>
                                </li>
                            }) : ''}
                        <div className="d-flex justify-content-center m-1"> <small><Link to={`/profile/activity`} className="mb-0 text-muted fs-14"><small>See all</small></Link></small></div>
                    </ul>
                </div>
            </div>
        </div >
    )
}

const UserProfile = ({ name, user_name, profile_pic }) => {
    return (
        <div className="dropdown mt-lg-0">
            <div className="w-100 ">
                <button className="btn btn-outline-primary fw-medium p-1 pe-xl-3 shadow-none d-inline-flex align-items-center" data-bs-toggle="dropdown" aria-expanded="false">
                    <span className="d-flex align-items-center"><img src={profile_pic ? `${henceforthApi.API_FILE_ROOT_SMALL}${profile_pic}` : profile_placeholder} className=" small-user" /><span className="d-lg-block d-none mx-2 text-truncate">{name}</span></span>
                </button>

                <ul className="dropdown-menu top-100 dropdown-menu-end mt-2 profile-dropdown" aria-labelledby="dropdownMenuProfile">
                    <li className="border-bottom">
                        <Link to={`/profile/on_sale`}>
                            <div className="px-4">
                                <div className="d-flex align-items-center py-2">
                                    <div className="me-lg-3 me-2">
                                        <img src={profile_pic ? `${henceforthApi.API_FILE_ROOT_SMALL}${profile_pic}` : profile_placeholder} className="user" />
                                    </div>
                                    <div>
                                        <p className="fw-medium mb-0 text-truncate-1">{name}</p>
                                        <p className="text-gray mb-0 fs-12">@{user_name}</p>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/profile/info" className="text-dark text-decoration-none pb-1 px-4 pt-md-2 pt-2 d-block w-100">
                            <p className="fw-medium mb-0 text-black">My Account</p>
                        </Link>
                    </li>

                    <li>
                        <Link to="/wallet" className="text-dark text-decoration-none pt-1 px-4 pb-md-2 pb-2 d-block w-100">
                            <p className="fw-medium mb-0 text-black">Wallet</p>
                        </Link>
                    </li>

                    <li className="border-top" data-bs-toggle="modal" data-bs-target="#logOutModal">
                        <span className="text-dark text-decoration-none pt-md-2 pb-1 px-4 d-block w-100 cursor-pointer">
                            <p className="mb-0 fw-medium text-black">Log Out</p>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    )
}
const Header = () => {
    const navigate = useNavigate()
    const { authState, logOutNow, staticState, notidication, getNotification, } = useContext(GlobalContext)
    const searchDrop = useRef(null)
    const location = useLocation()
    const [searchText, setSearchText] = useState("")
    const [searchResult, setSearchResult] = useState({})

    const logout = () => {
        if (logOutNow()) {
            navigate(`/signin`)
        }
    }

    useEffect(() => {
        if (searchText) {
            // henceforthApi.Search.pagination(searchText, staticState.explore).then(res => {
            henceforthApi.Search.pagination(searchText).then(res => {
                let data = res.data
                console.log("data", data);
                setSearchResult({
                    ...searchResult,
                    ...data
                })
            }).catch(err => {
                console.log("Error", err);
            })
        }
    }, [searchText])
    const handleClickOutside = e => {
        if (searchDrop.current && !searchDrop.current.contains(e.target)) {
            console.log(searchDrop.current.contains(e.target));
            setSearchText("")
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <header className="header bg-white">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <nav className="navbar navbar-expand-lg navbar-light pt-0 pb-0">

                            <Link className="navbar-brand text-white fw-bold fs-2 me-5" to="/">
                                <img src={Logo} alt="logo" className="header-logo" />
                            </Link>
                            <div className="d-flex align-items-center">
                                {authState.access_token ? <>
                                    <div className="d-block d-lg-none me-2">
                                        <Notification {...notidication} getNotification={getNotification} />
                                    </div>

                                    <div className="d-block d-lg-none me-2">
                                        <UserProfile logOutNow={logout} {...authState} />
                                    </div>
                                </> : ''}
                                <button className="navbar-toggler mdb-select shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            </div>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent" ref={searchDrop}>
                                <div className="dropdown mt-lg-0 mt-2 w-37">
                                    <div className="position-relative mb-lg-0 mb-1">
                                        <input className="form-control w-lg-50 w-100 me-2 shadow-none search-input fs-14" type="search" placeholder="Search by creator or nft" aria-label="Search" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                                        <i className="fa-solid fa-magnifying-glass text-theme position-absolute top-50 translate-middle-y left-0 ms-4"></i>
                                    </div>
                                    {searchText &&
                                        <div className="e-search-wrapper w-lg-50 w-100">
                                            <nav className="comment-wrapper">
                                                <div className="nav nav-tabs flex-nowrap" id="nav-tab" role="tablist">
                                                    <button
                                                        className="nav-link active"
                                                        id="nav-user-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#nav-user"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="nav-user"
                                                        aria-selected="true"
                                                    >
                                                        User {searchResult.users && searchResult.users.total_count}
                                                    </button>
                                                    <button
                                                        className="nav-link"
                                                        id="nav-nft-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#nav-nft"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="nav-nft"
                                                        aria-selected="false"
                                                    >
                                                        NFT {searchResult.nfts && searchResult.nfts.total_count}
                                                    </button>
                                                </div>
                                            </nav>
                                            <div className="tab-content mt-1" id="nav-tabContent">
                                                <div
                                                    className="tab-pane fade show active"
                                                    id="nav-user"
                                                    role="tabpanel"
                                                    aria-labelledby="nav-user-tab"
                                                >
                                                    {searchResult.users && Array.isArray(searchResult.users.data) && searchResult.users.data.map(res => {
                                                        console.log("searchResult.users", res);
                                                        return <Link to={`/profile/${res._id}/on_sale/`} onClick={() => setSearchText("")}>
                                                            <div className="d-flex align-items-start px-4 py-2 s-list">
                                                                <img src={res.profile_pic ? `${henceforthApi.API_FILE_ROOT_SMALL}${res.profile_pic}` : profile_placeholder} className="user me-2" />
                                                                <div>
                                                                    <p className="fw-semi-bold mb-0 line-clamp-2">@{res.user_name}</p>
                                                                    <p className="mb-0 fs-12">
                                                                        <span>{res.total_following} Following</span>
                                                                        <span className="mx-2">•</span>
                                                                        <span>{res.total_followers} Followers</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    })}
                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="nav-nft"
                                                    role="tabpanel"
                                                    aria-labelledby="nav-nft-tab"
                                                >
                                                    {searchResult.nfts && Array.isArray(searchResult.nfts.data) && searchResult.nfts.data.map(res => {
                                                        return <Link to={`/token/${res.token_id}/${res._id}/${res.auction_type == 1 ? 'trading' : 'bids'}/last_30_days`} onClick={() => setSearchText("")}>
                                                            <div className="d-flex align-items-start px-4 py-2 s-list">
                                                                {String(res.file_ext).startsWith("video") ?
                                                                    <video src={henceforthApi.FILES.video(res.file_url)} className="user me-2" /> :
                                                                    <img src={henceforthApi.FILES.imageSmall(res.file_url)} className="user me-2" />}
                                                                <div>
                                                                    <p className="fw-semi-bold mb-0 line-clamp-2" title={res.name}>@{res.name}</p>
                                                                    <p className="mb-0 fs-12">
                                                                        <span>{res.price} ELEN</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    })}
                                                </div>
                                            </div>
                                        </div>}
                                </div>

                                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-start align-items-lg-center mt-lg-0">
                                    {location.pathname !== '/' ? (staticState.explore == EXPLORE_LANDING_MARKET_PLACE) ?
                                        <Link to="/social?type=ALL">
                                            <button className="btn btn-outline-primary fw-medium my-1">EXPLORE EVERLENS SOCIAL</button>
                                        </Link> :
                                        <li className="nav-item  px-lg-2" >
                                            <Link to="/marketplace?type=ALL">
                                                <button className="btn btn-outline-primary fw-medium my-1">EVERLENS MARKETPLACE</button>
                                            </Link>
                                        </li> : ''}

                                    {authState.access_token ?
                                        <li className="nav-item  px-lg-2 d-lg-block d-none" >
                                            <Notification {...notidication} getNotification={getNotification} />
                                        </li> : ''}

                                    <li className="nav-item px-lg-2 py-1">
                                        <Link to={location.pathname === '/' ? '/create' : staticState.explore == EXPLORE_LANDING_SOCIAL ? `/create/social` : `/create/marketplace`} className="d-inline">
                                            <button className="btn btn-primary text-uppercase shadow-none py-2">
                                                Create
                                            </button>
                                        </Link>
                                    </li>
                                    {authState.access_token ?
                                        <li className="nav-item  d-lg-block d-none">
                                            <UserProfile logOutNow={logout}  {...authState} />
                                        </li> :
                                        <li className="nav-item px-lg-2 py-1">
                                            <Link to="/signin" className="d-inline">
                                                <button className="btn btn-outline-primary text-uppercase fw-medium shadow-none"><span>Sign In</span></button>
                                            </Link>
                                        </li>}

                                </ul>
                            </div>

                        </nav>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
