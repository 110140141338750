import { Fragment, useState } from "react"
import { Web3Provider } from '@ethersproject/providers';
import snapshot from '@snapshot-labs/snapshot.js';
import henceforthApi from "../../utils/henceforthApi";
import moment from "moment";
import { Button, Form, Input, Select, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const client = new snapshot.Client712(henceforthApi.SNAPSHOT_HUB_API_ROOT);


const ProposalCreate = () => {
    const [options, setOptions] = useState(['option 1', 'option 2'])
    const [form, setForm] = useState({
        title: "Test proposal using Snapshot.js",
        type: "single-choice",
        space_name: "hammim",
        space_type: "eth",
        body: "This is the content of the proposal",
    })

    const handleForm = ({ name, value }) => {
        setForm({
            ...form,
            [name]: value
        })
    }

    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    const createVote = async () => {

        const web3 = new Web3Provider(window.ethereum);
        const blockNumber = await web3.getBlockNumber();

        const [account] = await web3.listAccounts();
        const network = await web3.getNetwork()
        console.log('network', network);
        const receipt = await client.proposal(web3, account, {
            space: `${form.space_name}.${form.space_type}`,
            type: form.type,
            title: form.title,
            body: form.body,
            choices: options,
            start: Math.round(moment().valueOf() / 1000),
            end: Math.round(moment().add(1, 'hour').valueOf() / 1000),
            snapshot: blockNumber,
            network: `${network.chainId}`,
            plugins: JSON.stringify({}),
            app: 'snapshot',
        });
        console.log('receipt', receipt);
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        },
    };

    const formItemLayoutWithOutLabel = {
        wrapperCol: {
            xs: { span: 24, offset: 0 },
            sm: { span: 20, offset: 4 },
        },
    };


    return <form onSubmit={(e) => { e.preventDefault(); createVote() }}>

        <div classname="mb-3">
            <label for="titleFormControlInput1" classname="form-label">Title</label>
            <input type="text" className="form-control" id="titleFormControlInput1" placeholder="Title" value={form.title} name="title" onChange={(e) => handleForm(e.target)} />
        </div>

        <div classname="input-group mb-3">
            <input type="text" classname="form-control" aria-label="Text input with dropdown button" value={form.space_name} name="space_name" onChange={(e) => handleForm(e.target)} />
            <button classname="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">{form.space_type}</button>
            <ul classname="dropdown-menu dropdown-menu-end">
                <li onClick={() => handleForm({ name: 'space_type', value: 'eth' })}><a classname="dropdown-item" href="#">eth</a></li>
            </ul>
        </div>

        <div classname="mb-3">
            <select className="form-select" aria-label="Default select example" value={form.type} >
                <option value={""}>Choice type</option>
                <option value="single-choice">single choice</option>
            </select>
        </div>
        <div classname="input-group mb-3">
            {options.map((res) => <p>{res}</p>)}
            <input type="text" classname="form-control" placeholder="Options" aria-label="Recipient's username" aria-describedby="basic-addon2" value={form.option} name="option" onChange={(e) => handleForm(e.target)} />
            <span classname="input-group-text" id="basic-addon2" onClick={() => {
                setOptions([...options, form.option])
            }}>Add</span>
        </div>

        <div classname="mb-3">
            <label htmlFor="exampleFormControlTextarea1" classname="form-label">Example textarea</label>
            <textarea classname="form-control" id="exampleFormControlTextarea1" name="body" onChange={(e) => handleForm(e.target)} value={form.body}  ></textarea>
        </div>
        <button className="btn btn-success" type="submit">Create</button>
    </form>
    return <Space direction="vertical" size={[10, 48]} style={{ display: 'flex' }}>
        <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: 'Please input your username!' }]}
            >
                <Input value={form.title} name="title" onChange={(e) => handleForm(e.target)} />
            </Form.Item>
            <Form.Item
                label="Type"
                name="single-choice"
                rules={[{ required: true, message: 'Please input your username!' }]}
            >
                <Select
                    defaultValue={'single-choice'}
                    onChange={(e) => handleForm({ name: 'type', value: e })}
                    options={[
                        { value: 'single-choice', label: 'Single Choice' },
                    ]}
                />
            </Form.Item>
            <Form.Item
                label="Choice"
                name="single-choice"
                rules={[{ required: true, message: 'Please input your username!' }]}
            >
                <Form.List
                    label="Choice"
                    name="names"
                    rules={[
                        {
                            validator: async (_, names) => {
                                if (!names || names.length < 2) {
                                    return Promise.reject(new Error('At least 2 passengers'));
                                }
                            },
                        },
                    ]}
                >
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field, index) => (
                                <Form.Item
                                    required={false}
                                    key={field.key}
                                >
                                    <Form.Item
                                        {...field}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please input passenger's name or delete this field.",
                                            },
                                        ]}
                                        noStyle
                                    >
                                        <Input placeholder="passenger name" style={{ width: '90%' }} />
                                    </Form.Item>
                                    {fields.length > 1 ? (
                                        <MinusCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => remove(field.name)}
                                        />
                                    ) : null}
                                </Form.Item>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    style={{ width: '100%' }}
                                    icon={<PlusOutlined />}
                                >
                                    Add field
                                </Button>
                                <Form.ErrorList errors={errors} />
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    </Space>
}
export default ProposalCreate