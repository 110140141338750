
import axios from 'axios';
import moment from 'moment';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { InstagramPlayer, Spinner } from '../components/BootstrapCompo';
import Modals from '../components/Modals';
import SocialNftPreview from '../components/nftCard/SocialNftPreview';
import authAction from '../context/actions/auth/authAction';
import { EXPIRE_1_DAY, EXPIRE_3_DAY, EXPIRE_5_DAY, EXPLORE_NFT_TYPE_INSTAGRAM, FIXED_PRICE, NFT_STEP_1, PICK_SPECIFIC_DATE, RIGHT_AFTER_LISTING, TIMED_AUCTION } from '../context/actionTypes';
import { GlobalContext } from '../context/Provider';
import henceforthApi from '../utils/henceforthApi';
import henceforthValidations from '../utils/henceforthValidations';
import Instagram from "../assets/images/instagram_img.png"
import { warningInstagramImport } from '../utils/work_contant';

export default () => {
    let allMediaArray = []

    const navigate = useNavigate()
    const location = useLocation()
    var query = new URLSearchParams(location.search);

    const { authState, authDispatch, accounts, createNFT721, scrollToTop, createNftStep, categories, elenToUsd, fileUpload, setCreateNftStep, uploadFileIpfs } = useContext(GlobalContext)

    const [selectedFile, onSelectedFile] = useState(null);
    const [auctionType, setAuctionType] = useState(FIXED_PRICE)
    const [loading, setLoading] = useState(false)

    const [showStartDate, setShowStartDate] = useState(false)
    const [showEndDate, setShowEndDate] = useState(false)

    const [specificStartDate, setSpecificStartDate] = useState(new Date())
    const [specificEndDate, setSpecificEndDate] = useState(moment().add(1, 'days').valueOf())

    const [reelsData, setReelsData] = useState({
        count: 10
    })
    const [storyData, setStoryData] = useState({
        count: 10
    })
    const [state, setState] = useState({
        tags: new Map(),
        tagArray: []
    })
    const [instagramMedia, setInstagramMedia] = useState({
        photos: [],
        album: [],
        videos: []
    })
    const [formData, setFormData] = useState({
        startDate: RIGHT_AFTER_LISTING,
        endDate: EXPIRE_1_DAY,
        price: "",
        royalties: "",
        quantity: "",
    })

    const handleInput = ({ name, value }) => {
        setFormData({
            ...formData,
            [name]: value
        })
    }

    henceforthApi.setToken(authState.access_token)

    const onCreateNFT = async () => {
        const { name, price, startDate, endDate, royalties, description, startSpecificDate, endSpecificDate, category_id, quantity } = formData
        let ipfsFilePath = "";
        let videoUrlS3Bucket = "";

        if (!selectedFile) {
            return toast.warn("Please select file")
        }
        if (!Number(price)) {
            return toast.warn("Please enter valid amount")
        }
        if (!name) {
            return toast.warn("Please enter title")
        }
        if (!category_id) {
            return toast.warn("Select NFT category")
        }
        if (!description) {
            return toast.warn("Please enter caption")
        }
        if (Number(formData.royalties) > 10) {
            toast.warn("Royalties must be less then or equal to 10%")
            return
        }
        setLoading(true)

        let start_date = 0;
        let end_date = 0;
        if (startDate == RIGHT_AFTER_LISTING) {
            start_date = moment.utc().valueOf()
        } else {
            start_date = Number(specificStartDate)
        }
        if (endDate == EXPIRE_1_DAY) {
            end_date = moment().add(1, 'days').utc().valueOf()
        } else if (endDate == EXPIRE_3_DAY) {
            end_date = moment().add(3, 'days').utc().valueOf()
        } else if (endDate == EXPIRE_5_DAY) {
            end_date = moment().add(5, 'days').utc().valueOf()
        } else {
            end_date = Number(specificEndDate)
        }
        if (startDate === PICK_SPECIFIC_DATE && endDate === PICK_SPECIFIC_DATE) {
            if (start_date > end_date) {
                return toast.warn("Invalid Date selected")
            }
        }
        setLoading(true)
        setCreateNftStep(NFT_STEP_1)
        if (selectedFile.media_type == "REELS" || selectedFile.media_type == "STORY") {
            let apiRes = await henceforthApi.INSTAGRAM.uploadIpfs(selectedFile.media_url)
            ipfsFilePath = apiRes.data
            var filename = selectedFile?.media_url?.replace(/^.*[\\\/]/, '')
            videoUrlS3Bucket = filename
        } else {
            const responseFile = await (
                await axios.get(selectedFile.media_url, { responseType: "blob" })
            ).data;
            let file = new File([responseFile], formData.name, { type: responseFile.type });
            ipfsFilePath = await uploadFileIpfs(file)
            ipfsFilePath = `ipfs://${ipfsFilePath}`
            // if (String(responseFile.type).toLowerCase().includes("video")) {
            videoUrlS3Bucket = await fileUpload(file)
            // }
        }
        if (!videoUrlS3Bucket) {
            return toast.warn("Invalid file selected, please try with another")
        }

        const collectionAddress = query.get("collection")
        const collection_id = query.get("collection_id")

        let metadataObject = {
            title: name,
            name,
            type: "object",
            description,
            image: "",
            video: ipfsFilePath,
            properties: {
                name: {
                    type: "string",
                    description: name
                },
                description: {
                    type: "string",
                    description: description
                },
                image: {
                    type: "string",
                    description: "image description now available"
                },
                video: {
                    type: "string",
                    description: "video description now available"
                }
            }
        }
        const metadata = (await henceforthApi.Ipfs.metadata(JSON.stringify(metadataObject))).data

        const { token_id, token_address, erc, itemsVoucher } = await createNFT721(metadata, royalties, collectionAddress, auctionType, end_date, price)
        const { owner_address, auction_type, signature, salt } = itemsVoucher
        try {
            const nftItems = {
                erc,
                nft_type: EXPLORE_NFT_TYPE_INSTAGRAM,
                collection_id,
                category_id,
                token_id,
                token_address,
                quantity: quantity ? Number(quantity) : 1,
                name,
                description,
                file_url: videoUrlS3Bucket,
                file_ext: selectedFile.media_type == "REELS" || selectedFile.media_type == "STORY" ? 'video/mp4' : selectedFile.media_type,
                royalties: royalties ? royalties : 0,
                tags: state.tagArray,
                language: "ENGLISH"
            }

            let apiNftRes = await henceforthApi.NFT.create(nftItems)
            let data = apiNftRes.data
            debugger
            const voucherItems = {
                nft_id: data._id,
                auction_type,
                price: Number(price),
                quantity: quantity ? Number(quantity) : 1,
                start_date,
                end_date,
                signature,
                salt,
                language: "ENGLISH"
            }
            await henceforthApi.Voucher.create(voucherItems)
            navigate(`/token/${token_id}/${voucherItems.nft_id}/${auction_type == 1 ? 'trading' : 'bids'}/last_30_days`)
        } catch (error) {
            console.log("Error", error);
            debugger
        }
        setLoading(false)
    }

    const onAddTags = () => {
        let rowData = []
        let tag = formData.tag
        console.log("onAddTags called", tag);
        if (tag) {
            let map = state.tags
            map.set(tag, tag)

            map.forEach(element => {
                console.log(element, map);
                rowData.push(element)
            });
            setState({
                ...state,
                tags: map,
                tagArray: rowData
            })
            handleInput({ name: "tag", value: "" })
        }
    }

    const onRemoveTags = (tag) => {
        console.log("onRemoveTags called");
        let rowData = []
        if (tag) {
            let map = state.tags
            map.delete(tag)

            map.forEach(element => {
                console.log(element, map);
                rowData.push(element)
            });
            setState({
                ...state,
                tags: map,
                tagArray: rowData
            })
        }
    }

    useEffect(() => {
        if (formData.startDate == PICK_SPECIFIC_DATE) {
            setShowStartDate(!showStartDate)
            if (formData.endDate == PICK_SPECIFIC_DATE) {
                setFormData({
                    ...formData,
                    endDate: EXPIRE_1_DAY
                })
            }
        } else {
            setSpecificStartDate(new Date())
        }

    }, [formData.startDate])
    useEffect(() => {
        if (formData.endDate == PICK_SPECIFIC_DATE) {
            setShowEndDate(!showEndDate)
        } else {
            setSpecificEndDate(moment().add(1, 'days').valueOf())
        }

    }, [formData.endDate])

    const getPagination = async (props) => {
        try {
            if (props.next) {
                let mediaLink = await (await axios(props.next)).data;
                allMediaArray = [...allMediaArray, ...mediaLink.data];
                let allmedia = await getPagination(mediaLink.paging)
                return allMediaArray
            } else {
                return allMediaArray
            }
        } catch (error) {
            console.log("Error", error);
            debugger
            return allMediaArray
        }

    }
    const getAlbumImages = async (media_id, access_token) => {
        let photos = []
        let apiRes = await axios.get(
            `https://graph.instagram.com/${media_id}/children?fields=id,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=${access_token}`
        );
        let data = await apiRes.data.data
        if (Array.isArray(data)) {
            data.map(res => {
                photos.push({ ...res, media_type: 'image/*' });
            })
        }
        return photos
    }
    const initialiseInstagramData = async () => {
        if (authState.email_verified) {
            if (authState.instagram && authState.instagram.user_name) {
                initialiseCollection()
                try {
                    let photos = []
                    let album = []
                    let videos = []

                    let apiRes = await axios.get(
                        `https://graph.instagram.com/me/media?fields=id,caption&access_token=${authState.instagram.access_token}`
                    );  
                    let data = await apiRes.data.data
                    let allmedia = await getPagination(apiRes.data.paging)
                    const totalMedia = [...data, ...allmedia]
                    if (Array.isArray(data)) {
                        await Promise.all(
                            totalMedia.map(async (res, i) => {
                                let resMedia = await axios.get(
                                    `https://graph.instagram.com/${res.id}?fields=id,media_type,media_url,username,timestamp&access_token=${authState.instagram.access_token}`
                                )
                                let media = resMedia.data

                                switch (media.media_type) {
                                    case "IMAGE":
                                        photos.push({ ...media, ...res, media_type: 'image/*' });
                                        break;
                                    case "CAROUSEL_ALBUM":
                                        let albumList = await getAlbumImages(res.id, authState.instagram.access_token)
                                        album = [...album, ...albumList]
                                        break;
                                    case "VIDEO":
                                        videos.push({ ...media, ...res, media_type: 'video/*' });
                                        break;
                                    default:
                                        break;
                                }
                                return media;
                            })
                        );
                    }
                    setInstagramMedia({
                        ...instagramMedia,
                        photos,
                        album,
                        videos
                    })
                } catch (error) {
                    let errorMsg = error.response.data.error.message
                    if (String(errorMsg).includes("Session has expired")) {
                        deleteInstagram(authState.instagram._id)
                    }
                    toast.error(errorMsg)
                }
            } else {
                toast.warn("Connect instagram account first")
                navigate(`/profile/edit`, { replace: true })
            }
        }
        else {
            toast.warn("Please verify your email first.")
            navigate(`/profile/edit?redirect=${location.pathname}`, { replace: true })
        }
    }

    const deleteInstagram = async (id) => {
        try {
            debugger
            let apiRes = await henceforthApi.Profile.deleteSocial(id)
            let data = apiRes.data
            authAction.deleteInstagram({ ...data, })(authDispatch);
            toast.warn("Connect instagram account first")
            navigate(`/profile/edit`, { replace: true })
        } catch (error) {
            console.log("error", error);
            debugger
        }
    }
    const initialiseReels = async () => {
        try {
            let { count } = reelsData
            setReelsData({
                ...reelsData,
                loading: true
            })
            let apiRes = await henceforthApi.INSTAGRAM.reels(authState.instagram.user_name, count)
            setReelsData({
                ...reelsData,
                loading: false,
                count: count + 10,
                ...apiRes
            })
        } catch (error) {
            setReelsData({
                ...reelsData,
                loading: false,
            })
            debugger
            console.log("error", error);
        }
    }
    const initialiseStory = async () => {
        try {
            let { count } = storyData
            setStoryData({
                ...storyData,
                loading: true
            })
            let data = await henceforthApi.INSTAGRAM.stories(authState.instagram.user_name, count)
            setStoryData({
                ...storyData,
                loading: false,
                count: count + 10,
                ...data
            })
        } catch (error) {
            setStoryData({
                ...storyData,
                loading: false,
            })
            debugger
            console.log("error", error);
        }
    }

    const initialiseCollection = async () => {
        try {
            let apiRes = await henceforthApi.Collection.get("", 721, 0, 10)
            let data = apiRes.data
            let collection = data?.collection
            if (Array.isArray(collection)) {
                collection.forEach(element => {
                    if (element.default) {
                        query.set("collection_id", element._id)
                        query.set("collection", element.address)
                        navigate({ search: query.toString() }, { replace: true })
                    }
                });
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        if (authState.access_token) {
            if (authState.provider) {
                scrollToTop()
                initialiseInstagramData()
            } else {
                toast.warn("Please connect wallet first.")
                navigate(`/wallet`, { replace: true })
            }
        } else {
            navigate(`/signin?redirect=${location.pathname}`, { replace: true })
        }
    }, [authState.access_token, authState.provider, authState.email_verified])
    return <Fragment>
        {!authState.v2_user && <div className="alert alert-warning" role="alert">
            {warningInstagramImport.subtitle}
        </div>}
        <section className="edit-profile mb-5 pt-md-5 pt-4 pb-5">

            <div className="container">
                <div className="post-content">
                    <div className="row">
                        <div className="col-xl-7 col-lg-6">
                            <h2 className="fw-bold mb-3"><span className="text-gradient">Create New Post From Instagram</span></h2>

                            <div className="profile">
                                <div className="form-group mb-3 ">
                                    {selectedFile ?
                                        <div className="border-dashed rounded-3 d-block position-relative text-center">
                                            <button type="button" className="btn border-0  shadow-none fs-6 position-absolute top-0 end-0 cross" style={{ zIndex: 100 }} onClick={() => onSelectedFile(null)}><i className="fa-solid fa-xmark"></i></button>
                                            <div className="upload-player"><InstagramPlayer {...selectedFile} /></div>
                                        </div> :
                                        <label className="text-center p-4 border-dashed rounded-3 d-block ">
                                            <img src={Instagram} className="img-fluid mb-2 insta-big" />
                                            <p className='text-dark fw-medium'>Select file from Instgram</p>
                                            <span className="btn btn-primary fs-12" data-bs-toggle="modal"
                                                data-bs-target="#selectFileModal">CHOOSE FILE</span>
                                        </label>}

                                </div>

                                <ul className="nav nav-pills mb-3 common-tabs" id="pills-tab" role="tablist">
                                    <li className="nav-item w-100" role="presentation">
                                        <button className="nav-link active  w-100 fs-12 " id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={() => setAuctionType(FIXED_PRICE)}>FIXED PRICE</button>
                                    </li>
                                    <li className="nav-item w-100" role="presentation">
                                        <button className="nav-link  w-100 mt-2 fs-12" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={() => setAuctionType(TIMED_AUCTION)}>TIMED AUCTION</button>
                                    </li>

                                </ul>
                                <div className="form-group mb-3">
                                    <label className="fw-medium mb-1" htmlFor="">{auctionType == FIXED_PRICE ? 'Price' : 'Floor Price'}</label>
                                    <div className="position-relative">
                                        <input type="text" className="form-control border-pink border pe-5"
                                            placeholder='e.g. "0.5 ELEN" ' name="price" value={formData.price} onChange={(e) => handleInput(e.target)} />
                                        <span className="fw-medium fs-12 position-absolute top-50 translate-middle-y end-0 me-3">ELEN</span>
                                    </div>
                                </div>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

                                    </div>
                                    <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                        <div className="row mb-3">
                                            <div className="col-md-6 pe-1">
                                                <div className="form-group mb-1">
                                                    <label className="fw-medium mb-1" htmlFor="">Starting Date</label>
                                                    <select className="form-select fs-14 border-pink" value={formData.startDate} name="startDate" aria-label="start date select" onChange={(e) => handleInput(e.target)}>
                                                        <option value={RIGHT_AFTER_LISTING}>Right After Listing</option>
                                                        <option value={PICK_SPECIFIC_DATE}>Pick Specific Date</option>
                                                    </select>
                                                </div>
                                                {formData.startDate == PICK_SPECIFIC_DATE ?
                                                    <span className="fs-12 tags me-2 fw-medium mt-2 ">{moment(specificStartDate).format("DD/MM/YYYY")}
                                                        <button className="btn-icon" >
                                                            <i className="fa-solid fa-xmark ms-1" onClick={() => handleInput({ name: 'startDate', value: RIGHT_AFTER_LISTING })}></i>
                                                        </button>
                                                    </span>
                                                    : ''}
                                            </div>
                                            <div className="col-md-6 ps-1">
                                                <div className="form-group mb-1">
                                                    <label className="fw-medium mb-1" htmlFor="">Expiration Date</label>
                                                    <select className="form-select fs-14 border-pink" value={formData.endDate} name="endDate" onChange={(e) => handleInput(e.target)}>
                                                        <option value={EXPIRE_1_DAY}>1 Day</option>
                                                        <option value={EXPIRE_3_DAY}>3 Days</option>
                                                        <option value={EXPIRE_5_DAY}>5 Days</option>
                                                        <option value={PICK_SPECIFIC_DATE}>Pick Specific Date</option>
                                                    </select>
                                                </div>
                                                {formData.endDate == PICK_SPECIFIC_DATE ?
                                                    <span className="fs-12 tags me-2 fw-medium mt-2 ">{moment(specificEndDate).format("DD/MM/YYYY")}
                                                        <button className="btn-icon">
                                                            <i className="fa-solid fa-xmark ms-1" onClick={() => handleInput({ name: 'endDate', value: RIGHT_AFTER_LISTING })}></i>
                                                        </button>
                                                    </span>
                                                    : ''}

                                            </div>
                                            {/* <div className="col-md-12">
                                            <p className=" fs-12 fw-semi-bold text-gray mt-2">Any Bid Placed In The Last 10 Minutes Extends The Auction By 10 Minutes.</p>
                                        </div> */}
                                        </div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="fw-medium mb-1" htmlFor="">Title</label>
                                        <input type="text" className="form-control border-pink border"
                                            placeholder="Title" name="name" value={formData.name} onChange={(e) => handleInput(e.target)} required />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="fw-medium mb-1" htmlFor="">Select Category</label>
                                        <select className="form-select fs-14 border-pink" name="category_id" value={formData.category_id} onChange={(e) => handleInput(e.target)} required>
                                            <option value={""}>Select Category</option>
                                            {categories.map(res => { return <option value={res._id}>{res.name}</option> })}

                                        </select>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="fw-medium mb-1" htmlFor="">Caption (max 150 characters)</label>
                                        <textarea type="text" className="form-control border-pink border caption fw-normal"
                                            placeholder="Caption" name="description" value={formData.description} onChange={(e) => handleInput(e.target)} required
                                        ></textarea>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="fw-medium mb-1" htmlFor="">Royalties</label>
                                        <div className="position-relative">
                                            <input type="number" className="form-control border-pink border pe-5" max={10}
                                                placeholder="Royalties" name='royalties' value={formData.royalties} min={0} onChange={(e) => henceforthValidations.NumberValidation(e.target.value) ? handleInput(e.target) : console.log(e.target.value)} autoComplete="off" />
                                            <span className="fw-medium fs-12 position-absolute top-50 translate-middle-y end-0 me-3">%</span>
                                        </div>
                                        <p className="mb-0 fs-12 fw-semi-bold text-gray mt-2">Suggested: Max 10% allowed.</p>
                                    </div>

                                    <div className="form-group mb-3">
                                        <form onSubmit={(e) => { e.preventDefault(); onAddTags() }}>
                                            <label className="fw-medium mb-1" htmlFor="">Tags (Optional)</label>
                                            <div className="position-relative">
                                                <input type="text" className="form-control border-pink border pe-5"
                                                    placeholder="Tag" name='tag' value={formData.tag} onChange={(e) => handleInput(e.target)} />
                                                <button type='submit' className="fw-medium fs-12 btn-icon position-absolute top-50 translate-middle-y end-0 me-3">Add</button>
                                            </div>
                                            <div className="mt-2">
                                                {state.tagArray.map(res => {
                                                    return <span className="fs-12 tags me-2 mb-2 fw-medium">{res} <button className="btn-icon"><i className="fa-solid fa-xmark ms-1" onClick={() => onRemoveTags(res)}></i></button></span>
                                                })}
                                            </div>
                                        </form>
                                    </div>

                                </div>
                                <div className="d-flex justify-content-between align-items-center mt-4 pt-2">
                                    <button type="button" className="btn btn-primary shadow-none rounded-pill w-100 me-2 fs-12" disabled={loading} onClick={onCreateNFT}> {loading ? <Spinner /> : 'MINT NOW'}</button>
                                    <button type="button"
                                        className="btn btn-outline-primary shadow-none rounded-pill w-100 fw-medium fs-12" onClick={() => window.history.back()}>CANCEL</button>
                                </div>
                            </div>

                        </div>
                        <div className="col-xl-5  col-lg-6 mt-lg-0 mt-4">
                            <div className="preview-box-content rounded-3 border-dashed">
                                <h6 className="fw-medium mb-3">Preview</h6>
                                <div className="preview-box rounded-3 position-relative">
                                    <div className="multiple-preview w-100">
                                        <SocialNftPreview selectedFile={selectedFile} {...formData} elenToUsd={elenToUsd} authState={authState} contentType={auctionType} />
                                    </div>
                                </div>
                                {/* <div className="preview-box rounded-3 border"></div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modals.StartDatePicker data={showStartDate} setData={setShowStartDate} onChange={setSpecificStartDate} />
            <Modals.EndDatePicker data={showEndDate} setData={setShowEndDate} onChange={setSpecificEndDate} minDate={specificStartDate} />
            <Modals.NftCreateStep createNftStep={createNftStep} />
            <Modals.InstagramFiles {...instagramMedia} selectedFile={selectedFile} onSelectedFile={onSelectedFile} initialiseReels={initialiseReels} reelsData={reelsData} initialiseStory={initialiseStory} storyData={storyData} />
        </section>
    </Fragment>
}