import { useState } from "react"
import { toast } from "react-toastify"
import henceforthValidations from "../../utils/henceforthValidations"
import { getDiscountPercent, subscribeFormatter } from "../../utils/work_contant"
import { Spinner } from "../BootstrapCompo"

export default ({ loading, elenBalance, initialisePurchase, name, erc, details, setDetails, calculateWrappedAmount }) => {

    const [quantity, setQuantity] = useState("")

    const onSubmit = async (details, quantity) => {
        let price = calculateWrappedAmount(Number(details?.price), quantity ? quantity : 1, getDiscountPercent(elenBalance)).actualPrice
        if (price > elenBalance) {
            return toast.warn('You have insufficient balance')
        }
        let res = await initialisePurchase(details, quantity)
        if (res) {
            setQuantity("")
        }
    }

    return <div className={`modal fade ${details ? 'show' : ''}`} tabIndex="-1" data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true" role={details ? 'dialog' : ''} style={{ display: details ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.5)' }} >
        <div className="modal-dialog modal-dialog-centered">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 m-auto">
                        <div className="modal-content">
                            <div className="modal-header border-0 p-0 ">
                                <h5 className="modal-title w-100 text-center text-dark fw-bold fs-4 pb-2  pt-4 d-flex justify-content-center " id="exampleModalLabel">
                                    <span className="text-gradient">Checkout</span></h5>
                                <button id="closeSingleNftBuy" type="button" className="btn border-0 shadow-none fs-6 position-absolute top-0 end-0" onClick={() => { setQuantity(""); setDetails(null) }}>
                                    <i className="fa-solid fa-xmark"></i>
                                </button>
                            </div>
                            <div className="modal-body pt-1 pb-0 p-4">
                                <p className="text-center mb-2">You are about to buy <strong>{name}</strong> from <strong>@{details?.owner_id?.user_name}</strong></p>
                                {erc === 1155 &&
                                    <div className="position-relative my-3">
                                        <input type="text" className="form-control border-pink pe-5" placeholder="Enter quantity" value={quantity} onChange={(e) => henceforthValidations.NumberValidation(e.target.value) ? setQuantity(e.target.value) : setQuantity(1)} />
                                        <p className="fs-12 fw-medium position-absolute top-50 end-0 translate-middle-y me-2">COPIES</p>
                                    </div>}

                                <div className="highhest-bid d-flex justify-content-between align-items-center  border-bottom pb-2 mt-3">
                                    <div className="bid-left">
                                        <p className="fs-6 text-black-50 mb-0"><small>Your bidding balance</small></p>
                                        {erc === 1155 &&
                                            <p className="fs-6 text-black-50 mb-0"><small>Available copies</small></p>}
                                        <p className="fs-6 text-black-50 mb-0"><small>Service fee</small></p>
                                    </div>
                                    <div className="bid-right">
                                        <p className="fs-6 text-black mb-0 text-end fw-medium"><small>{subscribeFormatter(Number(details?.price) * (quantity ? Number(quantity) : 1))} ELEN</small></p>
                                        {erc === 1155 && <p className="fs-6 text-black mb-0 fw-medium"><small>{details?.left_quantity}</small></p>}
                                        <p className="fs-6 text-black mb-0 fw-medium"><small>{getDiscountPercent(elenBalance)}%</small></p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer border-0 pb-4 pt-2 p-4">
                                <div className={`bid-left w-100 d-flex justify-content-between align-items-center ${elenBalance === null ? 'pb-4' : ''}`}>
                                    <p className="fs-6 text-black-50 mb-0"><small>You will pay</small></p>
                                    <h6 className="mb-0 fs-6  fw-medium"><small>{subscribeFormatter(calculateWrappedAmount(Number(details?.price), quantity ? quantity : erc === 721 ? 1 : 0, getDiscountPercent(elenBalance)).actualPrice)} ELEN</small></h6>
                                </div>
                                {elenBalance !== null &&
                                    <div className="bid-left w-100 d-flex justify-content-between align-items-center pb-4">
                                        <p className="fs-6 text-black-50 mb-0"><small>Your wallet balance</small></p>
                                        <h6 className="mb-0 fs-6  fw-medium"><small>{subscribeFormatter(Number(elenBalance).toFixed(2))} ELEN</small></h6>
                                    </div>}
                                <button type="button" className="btn btn-primary bg-dark shadow-none w-100 m-auto rounded-pill mx-3 mb-2 text-capitalize" onClick={() => onSubmit(details, Number(quantity ? quantity : erc === 721 ? 1 : 0))} disabled={loading}>{loading ? <Spinner /> : 'Buy'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}