import Binance from "../../assets/images/howItWork/five.png";
import twoo from "../../assets/how_it_works/How_do_you _buy _an_NFT_on_Everlens/two.png";
import three from "../../assets/how_it_works/How_do_you _buy _an_NFT_on_Everlens/three.png";
import FOUR from "../../assets/how_it_works/How_do_you _buy _an_NFT_on_Everlens/four.png";
import CREATEtwo from "../../assets/how_it_works/How_do_you_create_a_Collection_in_Everlens/two.png";
import Create from "../../assets/how_it_works/How_do_you_create_a_Collection_in_Everlens/one.png";
import CREATEthree from "../../assets/how_it_works/How_do_you_create_a_Collection_in_Everlens/three.png";
import CREATEfour from "../../assets/how_it_works/How_do_you_create_a_Collection_in_Everlens/four.png";
import Createfive from "../../assets/how_it_works/How_do_you_create_a_Collection_in_Everlens/five.png";
import Createsix from "../../assets/how_it_works/How_do_you_create_a_Collection_in_Everlens/six.png";
import CLICKOne from "../../assets/how_it_works/How_do_you_mint_an_NFT_on_Everlens/one.png";
import CLICKTwo from "../../assets/how_it_works/How_do_you_mint_an_NFT_on_Everlens/two.png";
import CLICKThree from "../../assets/how_it_works/How_do_you_mint_an_NFT_on_Everlens/three.png";
import CLICKFour from "../../assets/how_it_works/How_do_you_mint_an_NFT_on_Everlens/four.png";
import CLICKFive from "../../assets/how_it_works/How_do_you_mint_an_NFT_on_Everlens/five.png";
import CLICKSix from "../../assets/how_it_works/How_do_you_mint_an_NFT_on_Everlens/six.png";
import CLICKSeven from "../../assets/how_it_works/How_do_you_mint_an_NFT_on_Everlens/seven.png";
import CLICKEight from "../../assets/how_it_works/How_do_you_mint_an_NFT_on_Everlens/eight.png";
import CLICKNINE from "../../assets/how_it_works/How_do_you_mint_an_NFT_on_Everlens/nine.png";
import CLICKTEN from "../../assets/how_it_works/How_do_you_mint_an_NFT_on_Everlens/ten.png";
import CLICKTENELEVEN from "../../assets/how_it_works/How_do_you_mint_an_NFT_on_Everlens/eleven.png";
import CLICKTENTWELWVE from "../../assets/how_it_works/How_do_you_mint_an_NFT_on_Everlens/twelve.png";
import CLICKTHIRTEEN from "../../assets/how_it_works/How_do_you_mint_an_NFT_on_Everlens/thirteen.png";
import CLICKFORTEEN from "../../assets/how_it_works/How_do_you_mint_an_NFT_on_Everlens/fourteen.png";
import CLICKFIFTEEN from "../../assets/how_it_works/How_do_you_mint_an_NFT_on_Everlens/fifteen.png";
import CLICKSIXTEEN from "../../assets/how_it_works/How_do_you_mint_an_NFT_on_Everlens/sixteen.png";
import CLICKSEVENTEEN from "../../assets/how_it_works/How_do_you_mint_an_NFT_on_Everlens/seventeen.png";
import CLICKEIGHTEEN from "../../assets/how_it_works/How_do_you_mint_an_NFT_on_Everlens/eighteen.png";
import CLICKNINTEEN from "../../assets/how_it_works/How_do_you_mint_an_NFT_on_Everlens/ninteen.png";
import CLICKTOWENTEEN from "../../assets/how_it_works/How_do_you_mint_an_NFT_on_Everlens/twenteen.png";
import staredONE from "../../assets/how_it_works/How_to get started on Everlens/one.png";
import staredTwo from "../../assets/how_it_works/How_to get started on Everlens/two.png";
import staredTHREE from "../../assets/how_it_works/How_to get started on Everlens/three.png";
import staredfour from "../../assets/how_it_works/How_to get started on Everlens/four.png";
import staredFIVE from "../../assets/how_it_works/How_to get started on Everlens/five.png";
import staredsix from "../../assets/how_it_works/How_to get started on Everlens/six.png";
import staredSeven from "../../assets/how_it_works/How_to get started on Everlens/seven.png";
import staredEight from "../../assets/how_it_works/How_to get started on Everlens/eight.png";
import staredNINE from "../../assets/how_it_works/How_to get started on Everlens/nine.png";
import staredTEEN from "../../assets/how_it_works/How_to get started on Everlens/ten.png";
import staredEleven from "../../assets/how_it_works/How_to get started on Everlens/eleven.png";
import staredTwelve from "../../assets/how_it_works/How_to get started on Everlens/twelve.png";
import staredThteen from "../../assets/how_it_works/How_to get started on Everlens/thirteen.png";
import staredForteen from "../../assets/how_it_works/How_to get started on Everlens/fouteen.png";
import staredFifteen from "../../assets/how_it_works/How_to get started on Everlens/fifteen.png";
import oldonE from "../../assets/how_it_works/How_to_import_old_NFTs/one.png";
import oldtwO from "../../assets/how_it_works/How_to_import_old_NFTs/two.png";
import oldthreE from "../../assets/how_it_works/How_to_import_old_NFTs/three.png";

import thirtynine from "../../assets/images/howItWork/thirtynine.png";
import six from "../../assets/images/howItWork/six.png";
import twentyfive from "../../assets/images/howItWork/twentyfive.png";
import twentyfour from "../../assets/images/howItWork/twentyfour.png";
import twentythree from "../../assets/images/howItWork/twentythree.png";
import twentytwo from "../../assets/images/howItWork/twentytwo.png";
import twentyone from "../../assets/images/howItWork/twentyone.png";
import twenty from "../../assets/images/howItWork/twenty.png";
import eighteen from "../../assets/images/howItWork/eighteen.png";
import seventeen from "../../assets/images/howItWork/seventeen.png";
import nineteen from "../../assets/images/howItWork/nineteen.png";
import sixteen from "../../assets/images/howItWork/sixteen.png";
import fifteen from "../../assets/images/howItWork/fifteen.png";
import elevn from "../../assets/images/howItWork/eleven.png";
import thirteen from "../../assets/images/howItWork/thirteen.png";
import fourteen from "../../assets/images/howItWork/fourteen.png";
import twelve from "../../assets/images/howItWork/twelve.png";
import BinanceSmartThree from "../../assets/images/howItWork/eight.png";
import BinanceSmartTwo from "../../assets/images/howItWork/nine.png";
import BinanceTwo from "../../assets/images/howItWork/seven.png";
import EverlenseOne from "../../assets/images/howItWork/ten.png";
import MetaFour from "../../assets/images/howItWork/four.png";
import MetaOne from "../../assets/images/howItWork/one.png";
import MetaThree from "../../assets/images/howItWork/three.png";
import MetaTwo from "../../assets/images/howItWork/two.png";
import ScrollToTop from "../../components/HOC/ScrollToTop"

const HowItWorks = () => {
  return (
    <div className="inner-pages-wrapper">
      <div className="meta-how">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="meta-mask accordion accordion-flush" id="accordionFlushExample">
                {/* one */}
                <div class="accordion-item">
                  <h2 class="accordion-header pb-0" id="flush-headingOne">
                    <button class="accordion-button shadow-none bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                      <h2 className="mt-0">How to get started with MetaMask ?</h2>
                    </button>
                  </h2>
                  <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div>
                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 1:</span> Install MetaMask in your browser{" "}
                          <a
                            target="blank"
                            href="https://metamask.io/download.html"
                            style={{ color: "#B24BF3;" }}
                          >
                            https://metamask.io/download.html
                          </a>
                        </h3>
                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 2:</span> Step 2: Create an account in MetaMask Click 
                          on the “Create a wallet” button.
                        </h3>
                        <div className="meta-image text-center">
                          <img src={MetaOne} className="img-fluid" alt="img" />
                        </div>
                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 3:</span> Create a Password of at 
                          least 8 characters and then click on “Create”.
                        </h3>
                        <p className="warning">
                          <strong style={{ color: "red" }}>
                            IMPORTANT : Back up and write down your password on
                             a piece of paper since you lose it the only way to recover is from your secret phrase.
                          </strong>
                        </p>
                        <div className="meta-image text-center">
                          <img src={MetaTwo} className="img-fluid" alt="img" />
                        </div>
                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 4:</span>
                           Write down your Secret Backup Phrase on a piece of paper and store it in a safe and secure location.
                        </h3>
                        <p className="warning">
                          <strong style={{ color: "red" }}>
                            IMPORTANT : If you lose your secret phrase you will not be able to recover it,
                             metamask does not store any copy. Moreover anybody with this secret phrase can access
                              your assets without your password.
                          </strong>
                        </p>
                        <div className="meta-image text-center">
                          <img src={MetaThree} className="img-fluid" alt="img" />
                        </div>
                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 5:</span>
                           Select each phrase in order to make sure it is correct then click “Confirm”.
                        </h3>
                        <div className="meta-image text-center">
                          <img src={MetaFour} className="img-fluid" alt="img" />
                        </div>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 6:</span> Congratulations! 🎉 You have created your MetaMask account!
                        </h3>
                      </div>

                    </div>
                  </div>
                </div>
                {/* first-how-it-end */}


                {/* <h2 className="mt-0">How to get started with MetaMask ?</h2> */}
                {/* <h3>
                  <span style={{ color: "#B24BF3" }}>Step 1:</span> Install MetaMask in your browser{" "}
                  <a
                    target="blank"
                    href="https://metamask.io/download.html"
                    style={{ color: "#B24BF3;" }}
                  >
                    https://metamask.io/download.html
                  </a>
                </h3>
                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 2:</span> Step 2: Create an account in MetaMask Click on the “Create a wallet” button
                </h3>
                <div className="meta-image text-center">
                  <img src={MetaOne} className="img-fluid" alt="img" />
                </div>
                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 3:</span> Create a Password of at least 8 characters and then click on “Create”.
                </h3>
                <p className="warning">
                  <strong style={{ color: "red" }}>
                    IMPORTANT : Back up and write down your password on a piece of paper since you lose it the only way to recover is from your secret phrase.
                  </strong>
                </p>
                <div className="meta-image text-center">
                  <img src={MetaTwo} className="img-fluid" alt="img" />
                </div>
                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 4:</span> Write down your Secret Backup Phrase on a piece of paper and store it in a safe and secure location.
                </h3>
                <p className="warning">
                  <strong style={{ color: "red" }}>
                    IMPORTANT : If you lose your secret phrase you will not be able to recover it, metamask does not store any copy. Moreover anybody with this secret phrase can access your assets without your password.
                  </strong>
                </p>
                <div className="meta-image text-center">
                  <img src={MetaThree} className="img-fluid" alt="img" />
                </div>
                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 5:</span> Select each phrase in order to make sure it is correct then click “Confirm”.
                </h3>
                <div className="meta-image text-center">
                  <img src={MetaFour} className="img-fluid" alt="img" />
                </div>

                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 6:</span> Congratulations! 🎉 You have created your MetaMask account!
                </h3> */}

                {/* two */}

                <div class="accordion-item">
                  <h2 class="accordion-header pb-0" id="flush-headingTwo">
                    <button class="accordion-button shadow-none bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                      <h2 className="m-0">How to get started with Binance Smart Chain?</h2>
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div>
                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 1:</span>
                           To get started on the Binance Smart Chain, you first need a wallet that
                            supports Binance’s BEP20 tokens, which are modeled on Ethereum’s ERC20 tokens. 
                            Here, you have two options:
                        </h3>
                        <ul>
                          <li>
                            1. You can either install the Binance Chain Wallet, which works as a simple Google Chrome extension,
                          </li>
                          <li>2. Or you can tweak your MetaMask wallet to support the BSC Mainnet.</li>
                        </ul>
                        <br />
                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 2:</span> 
                          In order to connect your MetaMask wallet to the Binance Smart Chain Mainnet:
                        </h3>
                        <p>Open your MetaMask browser extension at the top-right of your screen.</p>
                        <div className="meta-image text-center no-one">
                          <img src={Binance} className="img-fluid" alt="img" />
                          <img src={six} className="img-fluid" alt="img" />
                        </div>
                        <p>
                          Click the Networks tab in the top-center corner of your wallet and select Custom RPC.
                        </p>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 3:</span>
                           Now that you’ve opened the Network tab, fill each parameter with the following
                            information and click Save:
                        </h3>
                        <ul>
                          <li>
                            • Network Name: <strong>Binance Smart Chain</strong>
                          </li>
                          <li>
                            {" "}
                            • New RPC URL:{" "}
                            <a
                              style={{ color: "#212529" }}
                              target="blank"
                              href="https://bsc-dataseed.binance.org/"
                            >
                              <strong>https://bsc-dataseed.binance.org/ </strong>
                            </a>
                          </li>
                          <li>
                            • ChainID: <strong>56 </strong>
                          </li>
                          <li>
                            • Symbol: <strong>ELEN</strong>
                          </li>
                          <li>
                            • Block Explorer URL:{" "}
                            <a style={{ color: "#212529" }} target="blank" href="https://bscscan.com">
                              <strong>https://bscscan.com</strong>
                            </a>
                          </li>
                        </ul>

                        <div className="meta-image text-center">
                          <img src={BinanceTwo} className="img-fluid" alt="img" />
                        </div>
                        <p>That’s it 🎉 Your MetaMask wallet is all set up to be used on BSC.</p>
                        <p>
                          The third and final step to get you started on the Binance Smart Chain is to acquire some
                           ELEN tokens on the Binance exchange. This step is necessary because the ELEN tokens are your primary
                            gateway to the BSC ecosystem and the only means to pay transaction fees on the network.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* second-how-it-end */}

                {/* <h3>
                  <span style={{ color: "#B24BF3" }}>Step 1:</span> To get started on the Binance Smart Chain, you first need a wallet that supports Binance’s BEP20 tokens, which are modeled on Ethereum’s ERC20 tokens. Here, you have two options:
                </h3>
                <ul>
                  <li>
                    1. You can either install the Binance Chain Wallet, which works as a simple Google Chrome extension,
                  </li>
                  <li>2. Or you can tweak your MetaMask wallet to support the BSC Mainnet.</li>
                </ul>
                <br />
                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 2:</span> In order to connect your MetaMask wallet to the Binance Smart Chain Mainnet:
                </h3>
                <p>Open your MetaMask browser extension at the top-right of your screen.</p>
                <div className="meta-image text-center no-one">
                  <img src={Binance} className="img-fluid" alt="img" />
                  <img src={six} className="img-fluid" alt="img" />
                </div>
                <p>
                  Click the Networks tab in the top-center corner of your wallet and select Custom RPC
                </p>

                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 3:</span> Now that you’ve opened the Network tab, fill each parameter with the following information and click Save:
                </h3>
                <ul>
                  <li>
                    • Network Name: <strong>Binance Smart Chain</strong>
                  </li>
                  <li>
                    {" "}
                    • New RPC URL:{" "}
                    <a
                      style={{ color: "#212529" }}
                      target="blank"
                      href="https://bsc-dataseed.binance.org/"
                    >
                      <strong>https://bsc-dataseed.binance.org/ </strong>
                    </a>
                  </li>
                  <li>
                    • ChainID: <strong>56 </strong>
                  </li>
                  <li>
                    • Symbol: <strong>ELEN</strong>
                  </li>
                  <li>
                    • Block Explorer URL:{" "}
                    <a style={{ color: "#212529" }} target="blank" href="https://bscscan.com">
                      <strong>https://bscscan.com</strong>
                    </a>
                  </li>
                </ul>

                <div className="meta-image text-center">
                  <img src={BinanceTwo} className="img-fluid" alt="img" />
                </div>
                <p>That’s it 🎉 Your MetaMask wallet is all set up to be used on BSC.</p>
                <p>
                  The third and final step to get you started on the Binance Smart Chain is to acquire some ELEN tokens on the Binance exchange. This step is necessary because the ELEN tokens are your primary gateway to the BSC ecosystem and the only means to pay transaction fees on the network.
                </p> */}
                {/* Three */}

                <div class="accordion-item">
                  <h2 class="accordion-header pb-0" id="flush-headingThree">
                    <button class="accordion-button shadow-none bg-transparent collapsed shadow-none bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                      <h2 className="m-0">How to fund your Binance Smart Chain wallet with ELEN tokens?</h2>
                    </button>
                  </h2>
                  <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div>
                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 1:</span> 
                          On your MetaMask wallet or Binance Chain Wallet, click on your address to copy it.
                        </h3>
                        {/* <p>
                  Choose the tokens to withdraw : Select <strong>ELEN.</strong>
                </p>
                <p>
                  Next, select the <strong>BEP-20 (BSC) network.</strong>
                </p> */}
                        <div className="meta-image text-center">
                          <img src={BinanceSmartThree} className="img-fluid" alt="img" />
                        </div>
                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 2:</span> Go to your Binance account
                          <a target="blank" href="https://www.binance.com/en" style={{ color: "#B24BF3" }}>
                            {" "}
                            <strong> withdrawal page.</strong>
                          </a>
                        </h3>

                        <p>
                          Choose the tokens to withdraw : Select <strong>ELEN.</strong>
                        </p>
                        <p>
                          Next, select the <strong>BEP-20 (BSC) network.</strong>
                        </p>

                        <div className="meta-image text-center">
                          <img src={BinanceSmartTwo} className="img-fluid" alt="img" />
                        </div>

                        <p>
                          Click on the Submit button.
                        </p>
                        <p>
                          Congratulations! 🎉 You have funded your Binance Smart Chain wallet!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* three-how-it-end */}

                {/* <h2 className="mt-0">How to fund your Binance Smart Chain wallet with ELEN tokens?</h2> */}
                {/* <h3>
                  <span style={{ color: "#B24BF3" }}>Step 1:</span> On your MetaMask wallet or Binance Chain Wallet, click on your address to copy it.
                </h3> */}
                {/* <p>
                  Choose the tokens to withdraw : Select <strong>ELEN.</strong>
                </p>
                <p>
                  Next, select the <strong>BEP-20 (BSC) network.</strong>
                </p> */}
                {/* <div className="meta-image text-center">
                  <img src={BinanceSmartThree} className="img-fluid" alt="img" />
                </div>
                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 2:</span> Go to your Binance account
                  <a target="blank" href="https://www.binance.com/en" style={{ color: "#B24BF3" }}>
                    {" "}
                    <strong> withdrawal page.</strong>
                  </a>
                </h3>

                <p>
                  Choose the tokens to withdraw : Select <strong>ELEN.</strong>
                </p>
                <p>
                  Next, select the <strong>BEP-20 (BSC) network.</strong>
                </p>

                <div className="meta-image text-center">
                  <img src={BinanceSmartTwo} className="img-fluid" alt="img" />
                </div>

                <p>
                  Click on the Submit button.
                </p>
                <p>
                  Congratulations! 🎉 You have funded your Binance Smart Chain wallet!
                </p> */}

                {/* four*/}

                <div class="accordion-item">
                  <h2 class="accordion-header pb-0" id="flush-headingelen">
                    <button class="accordion-button shadow-none bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseelem" aria-expanded="false" aria-controls="flush-collapseelem">
                      <h2 className="m-0">How to add ELEN to the visible assets in Metamask?</h2>
                    </button>
                  </h2>
                  <div id="flush-collapseelem" class="accordion-collapse collapse" aria-labelledby="flush-headingelen" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div>
                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 1: </span>
                          Open your Metamask wallet and make sure you have selected Binance Smart Chain Network.
                        </h3>

                        <div className="meta-image text-center">
                          <img src={EverlenseOne} className="img-fluid" alt="img" />
                        </div>
                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 2: </span>Go to assets scroll down and click on Import token.
                        </h3>

                        <div className="meta-image text-center">
                          <img src={elevn} className="img-fluid" alt="img" />
                        </div>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 3: </span>Paste Elen contract address :
                        </h3>

                        <p>
                          0xca2483727292ba552aec12dfee4dc105cb1376b9.
                        </p>
                        <p>
                          (You can also find it on Coingecko : <a href="https://www.coingecko.com/fr/pi%C3%A8ces/everlens">
                             https://www.coingecko.com/fr/pi%C3%A8ces/everlens)</a>
                        </p>

                        <div className="meta-image text-center">
                          <img src={twelve} className="img-fluid" alt="img" />
                        </div>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 4: </span> Click import.
                        </h3>

                        <div className="meta-image text-center">
                          <img src={thirteen} className="img-fluid" alt="img" />
                        </div>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 5: </span> 
                          Congratulations, you are now able to see ELEN in the list of tokens.
                        </h3>

                        <div className="meta-image text-center">
                          <img src={fourteen} className="img-fluid" alt="img" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <h2 className="mt-0">How to add ELEN to the visible assets in Metamask?</h2> */}
                {/* <h3>
                  <span style={{ color: "#B24BF3" }}>Step 1: </span>Open your Metamask wallet and make sure you have selected Binance Smart Chain Network.
                </h3>

                <div className="meta-image text-center">
                  <img src={EverlenseOne} className="img-fluid" alt="img" />
                </div>
                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 2: </span>Go to assets scroll down and click on Import token
                </h3>

                <div className="meta-image text-center">
                  <img src={elevn} className="img-fluid" alt="img" />
                </div>

                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 3: </span>Paste Elen contract address :
                </h3>

                <p>
                  0xca2483727292ba552aec12dfee4dc105cb1376b9
                </p>
                <p>
                  (You can also find it on Coingecko : <a href="https://www.coingecko.com/fr/pi%C3%A8ces/everlens"> https://www.coingecko.com/fr/pi%C3%A8ces/everlens)</a>
                </p>

                <div className="meta-image text-center">
                  <img src={twelve} className="img-fluid" alt="img" />
                </div>

                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 4: </span> Click import
                </h3>

                <div className="meta-image text-center">
                  <img src={thirteen} className="img-fluid" alt="img" />
                </div>

                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 5: </span> Congratulations, you are now able to see ELEN in the list of tokens
                </h3>

                <div className="meta-image text-center">
                  <img src={fourteen} className="img-fluid" alt="img" />
                </div> */}
                {/* four-how-it-end */}
                {/* five */}
                <div class="accordion-item">
                  <h2 class="accordion-header pb-0" id="flush-headingelen">
                    <button class="accordion-button shadow-none bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseelem" aria-expanded="false" aria-controls="flush-collapseelem">
                      <h2 className="m-0">How to buy ELEN?</h2>
                    </button>
                  </h2>
                  <div id="flush-collapseelem" class="accordion-collapse collapse" aria-labelledby="flush-headingelen" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div>
                        <p>In order to buy and sell NFTs on Everlens, you will need to buy some ELEN.</p>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 1: </span>
                           Visit the <span style={{ color: "#B24BF3" }}>PancakeSwap website.</span> 
                           In the top right corner you'll see the Connect Wallet button. Click it.
                        </h3>

                        <div className="meta-image text-center">
                          <img src={fifteen} className="img-fluid" alt="img" />
                        </div>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 2: </span> 
                          A window will appear asking you to choose a wallet to connect to. Click MetaMask.
                        </h3>

                        <div className="meta-image text-center">
                          <img src={sixteen} className="img-fluid" alt="img" />
                        </div>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 3: </span> 
                          A window will open in metamask, choose the account you want to connect then click next then connect.
                        </h3>

                        <div className="meta-image text-center">
                          <img src={seventeen} className="img-fluid" alt="img" />
                        </div>

                        <div className="meta-image text-center">
                          <img src={eighteen} className="img-fluid" alt="img" />
                        </div>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 4: </span>
                           Go to the exchange page here Choose the token you want to trade against ELEN in the dropdown menu. 
                           The default setting is ELEN. Click on select a currency :
                        </h3>

                        <div className="meta-image text-center">
                          <img src={nineteen} className="img-fluid" alt="img" />
                        </div>

                        <p>Then paste ELEN contract address and add the token :</p>

                        <p>0xca2483727292ba552aec12dfee4dc105cb1376b9.</p>

                        <div className="meta-image text-center">
                          <img src={twenty} className="img-fluid" alt="img" />
                        </div>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 5: </span> 
                           Hit the settings button and set the slippage 5%.
                        </h3>

                        <p>Note : if you are selling you need to set the slippage 7%.</p>

                        <p>This is because of our transaction tax mechanism, you can 
                          find more info here <a href="https://staging.everlens.io/ELEN-token">https://staging.everlens.io/ELEN-token</a>
                          </p>


                        <div className="meta-image text-center">
                          <img src={twentyone} className="img-fluid" alt="img" />
                        </div>


                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 6: </span> 
                          Select the amount of ELEN tokens you want to buy and click on 
                          SWAP (remember you need at least 100 ELEN to mint on everlens).
                        </h3>


                        <div className="meta-image text-center">
                          <img src={twentytwo} className="img-fluid" alt="img" />
                        </div>


                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 7: </span> If the details are correct confirm the swap.
                        </h3>


                        <div className="meta-image text-center">
                          <img src={twentythree} className="img-fluid" alt="img" />
                        </div>


                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 8: </span> Confirm the transaction on metamask.
                        </h3>

                        <div className="meta-image text-center">
                          <img src={twentyfour} className="img-fluid" alt="img" />
                        </div>

                        <p><strong>Note: </strong> if this is the first time you use Pancakeswap 
                        you will need to grant Pancakeswap the right to spend your ELEN.</p>
                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 9: </span> Congratulation you have now purchased ELEN 🎉🎉.
                        </h3>

                        <div className="meta-image text-center">
                          <img src={twentyfive} className="img-fluid" alt="img" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <h2 className="m-0">How to buy ELEN?</h2> */}

                {/* <p>In order to buy and sell NFTs on Everlens, you will need to buy some ELEN</p>

                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 1: </span> Visit the <span style={{ color: "#B24BF3" }}>PancakeSwap website.</span> In the top right corner you'll see the Connect Wallet button. Click it.
                </h3>

                <div className="meta-image text-center">
                  <img src={fifteen} className="img-fluid" alt="img" />
                </div>

                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 2: </span> A window will appear asking you to choose a wallet to connect to. Click MetaMask.
                </h3>

                <div className="meta-image text-center">
                  <img src={sixteen} className="img-fluid" alt="img" />
                </div>

                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 3: </span> A window will open in metamask, choose the account you want to connect then click next then connect
                </h3>

                <div className="meta-image text-center">
                  <img src={seventeen} className="img-fluid" alt="img" />
                </div>

                <div className="meta-image text-center">
                  <img src={eighteen} className="img-fluid" alt="img" />
                </div>

                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 4: </span> Go to the exchange page here Choose the token you want to trade against ELEN in the dropdown menu. The default setting is ELEN. Click on select a currency :
                </h3>

                <div className="meta-image text-center">
                  <img src={nineteen} className="img-fluid" alt="img" />
                </div>

                <p>Then paste ELEN contract address and add the token :</p>

                <p>0xca2483727292ba552aec12dfee4dc105cb1376b9</p>

                <div className="meta-image text-center">
                  <img src={twenty} className="img-fluid" alt="img" />
                </div>

                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 5: </span>  Hit the settings button and set the slippage 5%
                </h3>

                <p>Note : if you are selling you need to set the slippage 7%</p>

                <p>This is because of our transaction tax mechanism, you can find more info here <a href="https://staging.everlens.io/ELEN-token">https://staging.everlens.io/ELEN-token</a></p>


                <div className="meta-image text-center">
                  <img src={twentyone} className="img-fluid" alt="img" />
                </div>


                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 6: </span> Select the amount of ELEN tokens you want to buy and click on SWAP (remember you need at least 100 ELEN to mint on everlens)
                </h3>


                <div className="meta-image text-center">
                  <img src={twentytwo} className="img-fluid" alt="img" />
                </div>


                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 7: </span> If the details are correct confirm the swap
                </h3>


                <div className="meta-image text-center">
                  <img src={twentythree} className="img-fluid" alt="img" />
                </div>


                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 8: </span> Confirm the transaction on metamask
                </h3>

                <div className="meta-image text-center">
                  <img src={twentyfour} className="img-fluid" alt="img" />
                </div>

                <p><strong>Note: </strong> if this is the first time you use Pancakeswap you will need to grant Pancakeswap the right to spend your ELEN</p>
                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 9: </span> Congratulation you have now purchased ELEN 🎉🎉
                </h3>

                <div className="meta-image text-center">
                  <img src={twentyfive} className="img-fluid" alt="img" />
                </div> */}
                {/* five-how-it-end */}
                {/* six */}
                <div class="accordion-item">
                  <h2 class="accordion-header pb-0" id="flush-headingstartever">
                    <button class="accordion-button shadow-none bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapstarted" aria-expanded="false" aria-controls="flush-collapstarted">
                      <h2 className="mt-0">How to get started on Everlens?</h2>
                    </button>
                  </h2>
                  <div id="flush-collapstarted" class="accordion-collapse collapse" aria-labelledby="flush-headingstartever" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div>
                        <div className="mini-heading mb-3">
                          <span style={{ color: "#B24BF3" }}>Everlens Marketplace </span>
                        </div>
                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 1:
                          </span> Go on <span style={{ color: "#B24BF3" }}>everlens, </span>
                          then click on the  <span style={{ color: "#B24BF3" }}>Sign In</span> button in the header.
                        </h3>

                        {/* <p>Make sure you one at least 0.1 ELEN and 100 ELEN</p> */}

                        <div className="meta-image text-center">
                          {/* <img src={twentysix} className="img-fluid" alt="img" /> */}
                          <img src={staredONE} className="img-fluid" alt="img" />
                        </div>

{/* 
                        <div className="meta-image text-center">
                          <img src={twentyseven} className="img-fluid" alt="img" />
                        </div> */}

                        <h3 className="m-0">
                          <span style={{ color: "#B24BF3" }}>
                            Step 2: </span>If you already have an account on Everlens then fill the Username & Password,
                          and click on <span style={{ color: "#B24BF3" }}>Sign In</span> button.
                        </h3>
                        <h3>
                          If you don't have an account on Everlens then click on <span style={{ color: "#B24BF3" }}>Sign Up</span>.
                        </h3>

                        <div className="meta-image text-center">
                          {/* <img src={twentyeight} className="img-fluid" alt="img" /> */}
                          <img src={staredTwo} className="img-fluid" alt="img" />
                        </div>

                        {/* <div className="meta-image text-center">
                          <img src={twentynine} className="img-fluid" alt="img" />
                        </div> */}

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 3: </span>  Fill all details & click on the
                          <span style={{ color: "#B24BF3" }}> Sign Up</span> button.
                        </h3>

                        <div className="meta-image text-center">
                          {/* <img src={thirty} className="img-fluid" alt="img" /> */}
                          <img src={staredTHREE} className="img-fluid" alt="img" />
                        </div>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 4: </span>
                          A metamask window should open asking you to connect,
                           choose the right account then click next then connect.
                        </h3>
                        <div className="meta-image text-center">
                          {/* <img src={thirtyone} className="img-fluid" alt="img" /> */}
                          <img src={staredfour} className="img-fluid" alt="img" />
                        </div>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 5: </span>
                          If you want to buy or sell NFTs only on <span style={{ color: "#B24BF3" }}>Everlens Marketplace </span>
                          then you can <span style={{ color: "#B24BF3" }}>Skip</span>  this step.
                        </h3>
                        <div className="meta-image text-center">
                          <img src={staredFIVE} className="img-fluid" alt="img" />
                        </div>
                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 6: </span>
                          Next you can choose whether you want to continue with etc <span style={{ color: "#B24BF3" }}> Metamask </span>
                          or want to connect a <span style={{ color: "#B24BF3" }}> Wallet</span>.

                        </h3>
                        <div className="meta-image text-center">
                          <img src={staredsix} className="img-fluid" alt="img" />
                        </div>
                        <h3>
                          If you continue with <span style={{ color: "#B24BF3" }}> Metamask </span>,
                          then a window should open asking you to <span style={{ color: "#B24BF3" }}> Sign</span>.
                        </h3>
                        <div className="meta-image text-center">
                          <img src={staredSeven} className="img-fluid" alt="img" />
                        </div>

                        <h3>
                          If you choose to connect a<span style={{ color: "#B24BF3" }}> Wallet </span>,
                          then QR code will be generated and you will have to scan it with the wallet
                           you want to connect to Everlens.
                        </h3>
                        <div className="meta-image text-center">
                        <img src={staredEight} className="img-fluid" alt="img" />
                        </div>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 7: </span>
                          Congratulations, you have now linked your wallet 🎉🎉.
                        </h3>
                        <div className="meta-image text-center">
                          <img src={staredNINE} className="img-fluid" alt="img" />
                        </div>

                        <div className="mini-heading mb-3 mb-3">
                          <span style={{ color: "#B24BF3" }}>Everlens Social </span>
                        </div>
                        <h3 className="mb-0 p-0">
                          To buy and sell NFTs from Instagram or TikTok, 
                          you will have to add your Instagram or TikTok accounts on Everlens.

                        </h3>
                        <p>You can add both social accounts or only one.</p>

                        {/* instagaram */}
                        <p className="mb-3">
                          <strong className="fw-bold">
                            For Instagram
                          </strong>
                        </p>
                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 1: </span>
                          You can add your Instagram account while  <span style={{ color: "#B24BF3" }}>  signing up </span>
                          or in the  <span style={{ color: "#B24BF3" }}>Edit Profile</span>  section.
                        </h3>
                        <div className="meta-image text-center">
                          <img src={staredTEEN} className="img-fluid" alt="img" />
                        </div>
                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 2: </span>
                          Authorize Instagram access.
                        </h3>
                        <div className="meta-image text-center">
                          <img src={staredEleven} className="img-fluid" alt="img" />
                        </div>
                        {/* instagram-end */}

                        {/* tiktok-start */}
                        <p className="mb-3">
                          <strong className="fw-bold">
                            For TikTok
                          </strong>
                        </p>
                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 1: </span>
                          You can add your TikTok account    <span style={{ color: "#B24BF3" }}>
                            during the signup process</span> or in the <span style={{ color: "#B24BF3" }}> Edit Profile </span>section.
                        </h3>
                        You can add your TikTok account during the signup process or in the Edit Profile.
                        <div className="meta-image text-center">
                          <img src={staredTwelve} className="img-fluid" alt="img" />
                        </div>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 2: </span>
                          User needs to enter their <span style={{ color: "#B24BF3" }}>TikTok username</span>.
                        </h3>
                        <div className="meta-image text-center">
                          <img src={staredThteen} className="img-fluid" alt="img" />
                        </div>
                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 3: </span>
                          Everlens will generate a TikTok video that you will have to  
                           <span style={{ color: "#B24BF3" }}> download </span> 
                           by clicking on the <span style={{ color: "#B24BF3" }}> Download button</span>.
                        </h3>

                        <div className="meta-image text-center">
                          <img src={staredForteen} className="img-fluid" alt="img" />
                        </div> 

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 4: </span>
                          Publish this TikTok video on your account and provide the URL of that video.
                           Don't worry once the sign up process has been completed you will be able to delete the video.
                        </h3>
                        <div className="meta-image text-center">
                          <img src={staredFifteen} className="img-fluid" alt="img" />
                        </div> 
                        <h3>
                        <span style={{ color: "#B24BF3" }}>Step 5: </span>
                          Everlens will verify your TikTok account, this process may take
                           <span style={{ color: "#B24BF3" }}>24-48 hrs</span>.
                        </h3>
                        {/* tiktok-end */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <h2 className="mt-0">How to get started on Everlens?</h2> */}

                {/* <h3>
                  <span style={{ color: "#B24BF3" }}>Step 1: </span> Go on <span style={{ color: "#B24BF3" }}>everlens, </span>a metamask window should open asking you to connect, choose the right account then click next then connect.
                </h3>

                <p>Make sure you one at least 0.1 ELEN and 100 ELEN</p>

                <div className="meta-image text-center">
                  <img src={twentysix} className="img-fluid" alt="img" />
                </div>


                <div className="meta-image text-center">
                  <img src={twentyseven} className="img-fluid" alt="img" />
                </div>

                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 2: </span> Click on connect profile, then continue with instagram
                </h3>

                <div className="meta-image text-center">
                  <img src={twentyeight} className="img-fluid" alt="img" />
                </div>

                <div className="meta-image text-center">
                  <img src={twentynine} className="img-fluid" alt="img" />
                </div>

                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 3: </span> Authorize instagram access
                </h3>

                <div className="meta-image text-center">
                  <img src={thirty} className="img-fluid" alt="img" />
                </div>

                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 4: </span> Congratulations, you have now linked your wallet and instagram account 🎉🎉
                </h3>


                <div className="meta-image text-center">
                  <img src={thirtyone} className="img-fluid" alt="img" />
                </div> */}
                {/* six-how-it-end */}


                {/* seven */}
                <div class="accordion-item">
                  <h2 class="accordion-header pb-0" id="flush-headingweb">
                    <button class="accordion-button shadow-none bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseweb" aria-expanded="false" aria-controls="flush-collapseweb">
                      <h2 className="mt-0">How do you mint an NFT on Everlens?</h2>
                    </button>
                  </h2>
                  <div id="flush-collapseweb" class="accordion-collapse collapse" aria-labelledby="flush-headingweb" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div>
                        <div className="mini-heading mb-3">
                          <span style={{ color: "#B24BF3" }}>Everlens Marketplace </span>
                        </div>
                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 1: </span> Click on 
                           <span style={{ color: "#B24BF3" }}>Create</span>.
                        </h3>

                        <div className="meta-image text-center">
                      
                          <img src={CLICKOne} className="img-fluid" alt="img" />
                        </div>


                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 2: </span>
                          Next, you will have to choose...
                        </h3>

                        <div className="meta-image text-center">
                          <img src={CLICKTwo} className="img-fluid" alt="img" /> 
                        </div>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 3: </span> 
                           If you want to highlight the uniqueness and individuality of 
                           your item, then click on <span style={{ color: "#B24BF3" }}>Single</span>.
                        </h3>
                        <div className="meta-image text-center">
                        <img src={CLICKThree} className="img-fluid" alt="img" /> 
                        </div>
                        <h3>
                          And if you want to share your item with a large number of your community...
                        </h3>
                        <div className="meta-image text-center">
                        <img src={CLICKFour} className="img-fluid" alt="img" /> 
                        </div>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 4: </span> Choose and upload the file.
                        </h3>
                        <div className="meta-image text-center">
                          <img src={CLICKFive} className="img-fluid" alt="img" />
                        </div>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 5: </span>
                           If you are minting Single NFT then choose the selling method.
                        </h3>
                        <p>→ Fixed price : Anybody willing to pay the fixed price will be able to purchase the NFT.</p>
                        <div className="meta-image text-center">
                          <img src={CLICKSix} className="img-fluid" alt="img" />
                        </div>

                        <p>→ Auction : The highest bidder at the end of the specified date will win the NFT.</p>
                        <div className="meta-image text-center">
                          <img src={CLICKSeven} className="img-fluid" alt="img" />
                        </div>
                        <h3>And if you are minting multiple NFTs then enter the number of copies you want.</h3>
                        <div className="meta-image text-center">
                          <img src={CLICKEight} className="img-fluid" alt="img" />
                        </div>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 6: </span>  Fill the details.
                        </h3>

                        <div className="meta-image text-center">
                          <img src={CLICKNINE} className="img-fluid" alt="img" />
                        </div>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 7: </span> 
                           Select the collection in which you want to mint your NFT.
                        </h3>
                        <div className="meta-image text-center">
                          <img src={CLICKTEN} className="img-fluid" alt="img" />
                        </div>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 8: </span>
                          Click on  <span style={{ color: "#B24BF3" }}>Mint Now</span>,
                           then approve transactions in your metamask: 3 approvals are required.
                        </h3>
                        <div className="meta-image text-center">
                          <img src={CLICKTENELEVEN} className="img-fluid" alt="img" />
                        </div>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 9: </span> 
                           Congratulations, your post is now minted and available for sell,
                          you can find it under my profile.
                        </h3>
                        <div className="meta-image text-center">
                          <img src={CLICKTENTWELWVE} className="img-fluid" alt="img" />
                        </div>

                        <div className="mini-heading mb-3">
                          <span style={{ color: "#B24BF3" }}>Everlens Social </span>
                        </div>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 1: </span> Click on 
                           <span style={{ color: "#B24BF3" }}>Create</span>.
                        </h3>
                        <div className="meta-image text-center">
                          <img src={CLICKTHIRTEEN} className="img-fluid" alt="img" />
                        </div>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 2:  </span>
                          Next you will have to choose whether you want to mint an NFT from your
                          <span style={{ color: "#B24BF3" }}> Instagram</span>  or
                          <span style={{ color: "#B24BF3" }}>  TikTok account</span>.
                        </h3>
                        <div className="meta-image text-center">
                          <img src={CLICKFORTEEN} className="img-fluid" alt="img" />
                        </div>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 3:  </span>
                          Choose a post from TikTok or Instagram.
                        </h3>
                        <div className="meta-image text-center">
                          <img src={CLICKFIFTEEN} className="img-fluid" alt="img" />
                        </div>
                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 4:  </span>
                          Choose the selling method.
                        </h3>
                        <p>→ Fixed price : Anybody willing to pay the fixed price will be able to purchase the NFT.</p>
                        <div className="meta-image text-center">
                          <img src={CLICKSIXTEEN} className="img-fluid" alt="img" />
                        </div>

                        <p>→ Auction : The highest bidder at the end of the specified date will win the NFT.</p>
                        <div className="meta-image text-center">
                          <img src={CLICKSEVENTEEN} className="img-fluid" alt="img" />
                        </div>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 5:  </span>
                          Fill the details.
                        </h3>
                        <div className="meta-image text-center">
                          <img src={CLICKEIGHTEEN} className="img-fluid" alt="img" />
                        </div>


                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 6:  </span>
                          Click on   <span style={{ color: "#B24BF3" }}>Mint Now</span>,
                           then approve transactions in your metamask: 3 approvals are required.
                        </h3>
                        <div className="meta-image text-center">
                          <img src={CLICKNINTEEN} className="img-fluid" alt="img" />
                        </div>


                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 7:  </span>
                          Congratulations, your post is now minted and available for sell, you can find it under my profile.
                        </h3>
                        <div className="meta-image text-center">
                          <img src={CLICKTOWENTEEN} className="img-fluid" alt="img" />
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                {/* <h2 className="mt-0">How do you mint an NFT on Everlens?</h2> */}

                {/* <h3>
                  <span style={{ color: "#B24BF3" }}>Step 1: </span> Click on new post
                </h3>

                <div className="meta-image text-center">
                  <img src={thirtytwo} className="img-fluid" alt="img" />
                </div>


                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 2: </span> Choose and upload the file from your instagram account
                </h3>

                <div className="meta-image text-center">
                  <img src={thirtythree} className="img-fluid" alt="img" />
                </div>

                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 3: </span>  Fill the details
                </h3>

                <div className="meta-image text-center">
                  <img src={thirtyfour} className="img-fluid" alt="img" />
                </div>

                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 4: </span>  Choose the selling method
                </h3>

                <p>→ Fixed price : Anybody willing to pay the fixed price will be able to purchase the NFT</p>

                <div className="meta-image text-center">
                  <img src={thirtyfive} className="img-fluid" alt="img" />
                </div>

                <p>→ Auction : The highest bidder at the end of the specified date will win the NFT</p>

                <div className="meta-image text-center">
                  <img src={thirtysix} className="img-fluid" alt="img" />
                </div>

                <p>In both case, the royalty % is the percentage you will earn on the resell of your NFT on the secondary market</p>

                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 5: </span>  Click on create Item, then approve transactions in your metamask: 3 approvals are required
                </h3>

                <div className="meta-image text-center">
                  <img src={thirtyseven} className="img-fluid" alt="img" />
                </div>

                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 6: </span>  Congratulations, your post is now minted and available for sell, you can find it under my profile
                </h3>

                <div className="meta-image text-center">
                  <img src={thirtyeight} className="img-fluid" alt="img" />
                </div> */}
                {/* seven-how-it-end */}
                {/* eight*/}
                <div class="accordion-item">
                  <h2 class="accordion-header pb-0" id="flush-headinnftbuy">
                    <button class="accordion-button shadow-none bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapnftbuy" aria-expanded="false" aria-controls="flush-collapnftbuy">
                      <h2 className="mt-0">How do you buy an NFT on Everlens?</h2>
                    </button>
                  </h2>
                  <div id="flush-collapnftbuy" class="accordion-collapse collapse" aria-labelledby="flush-headinnftbuy" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div>
                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 1: </span>
                          Explore and find an NFT you like then click on it.
                        </h3>

                        <div className="meta-image text-center">
                          <img src={thirtynine} className="img-fluid" alt="img" />
                        </div>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 2: </span>
                          If it is an auction, place your bid. If it is a direct buy click on purchase.
                          If it is a collection, enter the number of copies you want to purchase and then click on buy.
                        </h3>

                        <div className="meta-image text-center">
                          <img src={twoo} className="img-fluid" alt="img" />
                        </div>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 3: </span>
                          {/* Click on checkout and proceed to payment, you will need to confirm in your wallet */}
                          Add "your purchase" after "confirm
                          Click on buy and proceed to payment, you will need to confirm your purchase in your wallet.
                        </h3>

                        <div className="meta-image text-center">
                          {/* <img src={fourtyone} className="img-fluid" alt="img" /> */}
                          <img src={three} className="img-fluid" alt="img" />
                        </div>

                        <h3>
                          <span style={{ color: "#B24BF3" }}>Step 4: </span>
                          Congratulation your are now the owner of the NFT, you can visualise it into the owned section of your profile.
                        </h3>

                        <div className="meta-image text-center">
                          {/* <img src={fourtytwo} className="img-fluid" alt="img" /> */}
                          <img src={FOUR} className="img-fluid" alt="img" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                {/* <h2 className="mt-0">How do you buy an NFT on Everlens?</h2> */}
                {/* <h3>
                  <span style={{ color: "#B24BF3" }}>Step 1: </span>  Explore and find an NFT you like then click on it
                </h3>

                <div className="meta-image text-center">
                  <img src={thirtynine} className="img-fluid" alt="img" />
                </div>

                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 2: </span>  If this is an auction, place your bid, if it is a direct buy click on purchase
                </h3>

                <div className="meta-image text-center">
                  <img src={fourty} className="img-fluid" alt="img" />
                </div>

                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 3: </span>  Click on checkout and proceed to payment, you will need to confirm in your wallet
                </h3>

                <div className="meta-image text-center">
                  <img src={fourtyone} className="img-fluid" alt="img" />
                </div>

                <h3>
                  <span style={{ color: "#B24BF3" }}>Step 4: </span>  Congratulation your are now the owner of the NFT, you can visualise it into the owned section of your profile
                </h3>

                <div className="meta-image text-center">
                  <img src={fourtytwo} className="img-fluid" alt="img" />
                </div> */}
                {/* eight-how-it-end */}


                {/* 10how-it-start */}
                <div class="accordion-item">
                  <h2 class="accordion-header pb-0" id="flush-headingcollectionOne">
                    <button class="accordion-button collapsed shadow-none bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsecolleOne" aria-expanded="false" aria-controls="flush-collapsecolleOne">
                      <h2 className="mt-0">How do you create a Collection in Everlens?</h2>
                    </button>
                  </h2>
                  <div id="flush-collapsecolleOne" class="accordion-collapse collapse" aria-labelledby="flush-headingcollectionOne" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <h3>
                        <span style={{ color: "#B24BF3" }}>Step 1: </span>
                        You can create a collection from your
                        <span style={{ color: "#B24BF3" }}> Profile </span> and also while
                        <span style={{ color: "#B24BF3" }}> Minting an NFT</span> by clicking on the
                        <span style={{ color: "#B24BF3" }}> Create </span> Button.
                      </h3>

                      <div className="meta-image text-center">
                        <img src={Create} className="img-fluid" alt="img" />
                      </div>

                    
                      <div className="meta-image text-center">
                        <img src={CREATEtwo} className="img-fluid" alt="img" />
                      </div>

                      <h3>
                        <span style={{ color: "#B24BF3" }}>Step 2: </span>
                        Next you will have to fill in all the details and add an image to create the collection.
                      </h3>
                      <div className="meta-image text-center">
                        <img src={CREATEthree} className="img-fluid" alt="img" />
                      </div>


                      <h3>
                        <span style={{ color: "#B24BF3" }}>Step 3: </span>
                        Click on  <span style={{ color: "#B24BF3" }}>Create Collection</span>, then approve 
                        transactions in your metamask: 1 approval is required.
                      </h3>

                      <div className="meta-image text-center">
                        <img src={CREATEfour} className="img-fluid" alt="img" />
                      </div>

                      <h3>
                        <span style={{ color: "#B24BF3" }}>Step 4: </span>
                        Congratulations, you can check your collection under my profile or while Minting an NFT.
                      </h3>

                      <div className="meta-image text-center">
                        <img src={Createfive} className="img-fluid" alt="img" />
                      </div>
                      
                      <div className="meta-image text-center">
                        <img src={Createsix} className="img-fluid" alt="img" />
                      </div>
                    </div>
                  </div>
                </div>
                {/* 10how-it-end */}
                {/* 11how-it-start */}
                <div class="accordion-item">
                  <h2 class="accordion-header pb-0" id="flush-headingoldnfts">
                    <button class="accordion-button collapsed shadow-none bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapol-nftOne" aria-expanded="false" aria-controls="flush-collapol-nftOne">
                      <h2 className="mt-0">How to import old NFTs?</h2>
                    </button>
                  </h2>
                  <div id="flush-collapol-nftOne" class="accordion-collapse collapse" aria-labelledby="flush-headingoldnfts" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <h3>
                        <span style={{ color: "#B24BF3" }}>Step 1: </span> 
                         Connect the same metamask account you used for #EverlensV2.
                      </h3>

                      <div className="meta-image text-center">
                        <img src={oldonE} className="img-fluid" alt="img" />
                      </div>


                      <h3>
                        <span style={{ color: "#B24BF3" }}>Step 2: </span>
                        ⚠️Warning ⚠️ If you mint an NFT on #EverlensV3 before importing
                        your NFTs, you will never be able to import your old NFTs on #EverlensV3.
                      </h3>
                      <h3>
                        <span style={{ color: "#B24BF3" }}>Step 3: </span>
                        After connecting your account, go to your profile page, you will see a button to import previous NFTs.
                      </h3>
                      <div className="meta-image text-center">
                        <img src={oldtwO} className="img-fluid" alt="img" />
                      </div>

                      <h3>
                        <span style={{ color: "#B24BF3" }}>Step 4: </span>
                        After clicking on the button, you will be prompted to log out and log back in instantly.
                      </h3>

                      <h3>
                        <span style={{ color: "#B24BF3" }}>Step 5: </span>
                        Once you’re logged in, you will see your NFTs on the “Created” tab on your profile. Imported NFTs can only
                         be sold if someone makes an offer on the NFT. You will then choose whether to accept the offer or not.
                      </h3>
                      <div className="meta-image text-center">
                        <img src={oldthreE} className="img-fluid" alt="img" />
                      </div>
                    </div>
                  </div>
                </div>
                {/* 11-hot-send */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrollToTop(HowItWorks);
