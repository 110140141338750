
import { Link, useLocation, useNavigate } from "react-router-dom";
import Single from "../assets/images/home/single.png";
import Multiple from "../assets/images/home/multiple.png";
import { useContext, useEffect } from "react";
import { GlobalContext } from "../context/Provider";
import henceforthApi from "../utils/henceforthApi";

export default () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { authState, staticState, accounts, scrollToTop, } = useContext(GlobalContext)
    henceforthApi.setToken(authState.access_token)
    useEffect(() => {
        if (authState.access_token) {
            scrollToTop()
        } else {
            navigate(`/signin?redirect=${location.pathname}`, { replace: true })
        }
    }, [accounts, authState.access_token])

    useEffect(() => {
        const closeSelectFile = document.getElementsByClassName("modal-backdrop")
        if (closeSelectFile.length) {
            closeSelectFile[0].parentNode.removeChild(closeSelectFile[0]);
        }
    }, [])

    return <section className="edit-profile mb-5 pt-md-5 pt-4 pb-5">
        <div className="container">
            <div className="row">
                <div className="col-xl-6 col-lg-8">
                    <div className="w-85 ms-xl-auto m-auto row">
                        <h2 className="fw-bold mb-3">
                            <span className="text-gradient">Create New Post</span></h2>
                        <div className="col-md-6 pe-md-2 mb-3">
                            <Link to="/create/marketplace/single">
                                <div className="d-flex justify-content-center align-items-center text-center square-box rounded-3 p-3" >
                                    <div>
                                        <img src={Single} className="img-fluid w-40" />
                                        <p className="mb-2 fw-semi-bold mt-2">Single NFT</p>
                                        <p className="fs-12">Highlight the uniqueness and individuality of your NFT.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-6 ps-md-2">
                            <Link to="/create/marketplace/multiple">
                                <div className="d-flex justify-content-center align-items-center text-center square-box rounded-3 p-3" >
                                    <div>
                                        <img src={Multiple} className="img-fluid w-40" />
                                        <p className="mb-2 fw-semi-bold mt-2">Multiple NFTs</p>
                                        <p className="fs-12">Create a large number of copies of your NFT.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}