import profile_placeholder from "../../assets/images/home/img_placeholder_small.svg";
import InstagramSvg from "../../assets/images/instagram_img.png";
import TiktokSvg from "../../assets/images/home/tiktok_big.svg";
import Tiktok from "../../assets/images/home/tiktok_small.svg"
import InstagramSmall from "../../assets/images/home/insta_small.png";
import Comment from "../../assets/images/home/comment.svg";
import henceforthValidations from "../../utils/henceforthValidations";
import { InstagramPlayer } from "../BootstrapCompo";
import { FIXED_PRICE } from "../../context/actionTypes";
import henceforthApi from "../../utils/henceforthApi";
import { subscribeFormatter } from "../../utils/work_contant";

export default ({ nftType, selectedFile, contentType, authState, elenToUsd, name, price, quantity, description }) => {
  return (
    <div className="live-box  card">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-start">
          <div className="owner-name d-flex">
            <div className="name-img">
              <img src={authState.profile_pic ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${authState.profile_pic}` : profile_placeholder} className="user " alt="icon" />
            </div>
            <div className="name-text ms-2">
              <h6 className="mb-0 fs-14 fw-bolder">@{authState.user_name}</h6>
              <p className="fs-12 text-black-50">Art by <span className="fw-semi-bold">{authState.user_name}</span></p>
            </div>
          </div>
          <button className="btn-icon mt-1 cursor-auto">
            {nftType == "tiktok" ?
              <img src={TiktokSvg} alt="Instagram" className="img-fluid d-inline" /> :
              <img src={InstagramSvg} alt="Instagram" className="img-fluid d-inline insta-big" />}
          </button>
          {Number(quantity) ? <p className="fs-12 fw-medium">{quantity}</p> : ''}
        </div>

        <div className="d-flex justify-content-center">
          <button className="btn-outline-secondary  fw-medium me-2 flex-grow-1 fs-12 px-2 shadow-none text-lowercase  cursor-auto">
            <img src={InstagramSvg} className="me-2 d-inline insta-small" />
            {authState.instagram ? subscribeFormatter(authState?.instagram?.count ? authState?.instagram?.count?.follower_count : 0) : 0} followers
          </button>

          <button className="btn-outline-secondary fw-medium flex-grow-1 fs-12 px-2 shadow-none  text-lowercase  cursor-auto">
            <img src={Tiktok} className=" me-2 d-inline" />
            {authState.tiktok ? subscribeFormatter(authState?.tiktok?.count ? authState?.tiktok?.count?.follower_count : 0) : 0} followers
          </button>
        </div>
      </div>

      <div className="live-img position-relative">
        <div className="nft-player nft-image">
          <InstagramPlayer {...selectedFile} />
        </div>
      </div>
      <div className="card-body">
        <div className="live-text">
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <h2 className="fw-bolder fs-5 mb-0 line-clamp-1 text-break">{name}</h2>
              <p className="text-gray fs-12 mb-0 line-clamp-3 text-break">{description}</p>
            </div>
            <div className="d-flex align-items-start comment ">
              {/* <p className="mb-0"><i className="fa fa-heart text-theme me-1"></i> 14</p> */}
              <p className="mb-0 fs-12 d-flex align-items-center"><i className="fa fa-heart text-theme me-1 fw-normal"></i>0</p>
              <p className="mb-0  fs-12 border-left ps-2 ms-2 d-flex align-items-center"><img src={Comment} className="me-1 d-inline" />0</p>
            </div>
          </div>
          <div className="highhest-bid d-flex justify-content-between align-items-center mt-3">
            <div className="bid-left">
              <p className="mb-0 fw-bold d-flex align-items-center"><span className="text-truncate-1 text-break">{subscribeFormatter(price)}</span> &nbsp;ELEN</p>
              <p className="fs-12 mb-0">${Number(Number(price) * elenToUsd).toFixed(2)} USD</p>

            </div>
            <div className="bid-right">
              <h5 className="fs-6 fw-semi-bold mb-0 "><span className="text-decoration-none text-theme">{contentType == FIXED_PRICE ? 'Buy Now' : 'Bid Now'}</span></h5>
            </div>
          </div>
        </div>
      </div >
    </div >
  )
}