import { Link } from "react-router-dom";
import henceforthApi from "../../utils/henceforthApi";
import henceforthValidations from "../../utils/henceforthValidations";
import { timeSince } from "../../utils/work_contant";

export default (res) => (<Link to={res.nft_id ? `/token/${res?.nft_id?.token_id}/${res?.nft_id?._id}/trading/last_30_days` : `#`}>
    <div className="profile-name border p-3 rounded-2 mb-3">
        <div className=" d-flex justify-content-start align-items-center">
            {res.nft_id?._id && <div className="me-3">
                {(String(res.nft_id?.file_ext).includes("video")) ?
                    <video src={henceforthApi.FILES.video(res?.nft_id?.file_url)} className="square-profile rounded-3 object-fit-cover" playsInline /> :
                    <img src={henceforthApi.FILES.imageMedium(res?.nft_id?.file_url)} className="square-profile rounded-3 object-fit-cover" />}
            </div>}
            <div>
                <h6 className="mb-0 text-theme fw-semi-bold text-uppercase">{res.nft_id?.name}</h6>
                <p className="mb-0 text-muted"><span className="me-4">{henceforthValidations.capitalizeFirstLetter(res.message)}</span>
                    {/* <span>
                <strong className="text-dark  d-inline-flex align-items-center">
                    <span
                        className="d-inline-block bg-dark rounded-circle mx-1 big-circle "></span>
                    0x7dfbee423...254f</strong> <span className="mx-1">to</span>
                <strong className="text-dark d-inline-flex align-items-center"><span
                    className="d-inline-block bg-dark rounded-circle mx-1 big-circle"></span>
                    0x7dfbee423...254f</strong>
            </span> */}
                </p>
                <p className="text-muted mb-0 mt-1"><small>{timeSince(new Date(Number(res.created_at)))}</small></p>
            </div>
        </div>
    </div>
</Link>)