export default ({ title, subtitle, btnValue, onSubmit }) => <div className={`modal fade  ${title ? 'show' : ''}`} data-bs-backdrop="static" tabIndex="-1" role={title ? 'dialog' : ''} style={{ display: title ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.5)' }} aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
        <div className="container">
            <div className="col-md-8 m-auto">
                <div className="modal-content">
                    <div className="modal-body border-0 text-center">
                        <button type="button" className="btn border-0  shadow-none fs-6 position-absolute top-0 end-0 border-0" onClick={onSubmit} >
                            <i className="fa-solid fa-xmark"></i>
                        </button>
                        <div>
                            <h5 className="modal-title w-100 text-center text-dark fw-bold fs-4 pb-2 d-flex justify-content-center">
                                <span className="text-gradient">{title}</span></h5>
                            <p>{subtitle}</p>
                            <div className="d-flex align-items-center mt-4">
                                <button type="button" className="btn btn-primary bg-dark shadow-none w-100 m-auto rounded-pill mb-2 text-capitalize flex-grow-1 me-1" onClick={onSubmit}>{btnValue}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>