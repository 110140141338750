import moment from "moment";
import { Fragment, useState } from "react";
import { Calendar } from "react-calendar";
import { toast } from "react-toastify";

export default ({ data, setData, onChange, minDate }) => {
    const [state, setState] = useState(null)

    const handleSubmit = () => {
        if (!state) {
            return toast.warn("Please select date first")
        }
        const dateInUtc = moment(state).utc().valueOf()
        if (minDate > dateInUtc) {
            return toast.warn("Invalid date selected")
        }
        onChange(dateInUtc)
        setData(false)
    }

    return <div className={`modal fade common-modal ${data ? 'show' : ''}`} data-bs-backdrop="static" tabIndex="-1" role={data ? 'dialog' : ''} style={{ display: data ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.5)' }} aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="container">
                <div className="row">
                    <div className="col-md-10 m-auto">
                        <div className="modal-content">
                            <div className="modal-header border-0 p-0 ">
                                <button type="button" className="btn border-0 shadow-none fs-6 position-absolute top-0 end-0 z-index-1" onClick={() => setData(null)} >
                                    <i className="fa-solid fa-xmark"></i>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Calendar onChange={setState} minDate={moment(minDate).add(1, 'day').toDate()} className="calendar w-100" />
                                <div className="d-flex justify-content-between align-items-center my-3">
                                    {/* <p className="text-gray mb-0">Select Time</p>
                                    <p className="fw-semi-bold mb-0">12:58 PM  <i className="fa-solid fa-clock fw-medium text-theme ms-2"></i></p> */}
                                </div>
                                <button className='btn btn-primary w-100' onClick={handleSubmit}>Apply</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}