import { Link, Route, Routes } from "react-router-dom";
import React, { Fragment } from 'react'
import Logo from "../../assets/images/home/logo.svg";
import SignUpStep1 from "../layout/signup/SignUpStep1";
import SignUpStep2 from "../layout/signup/SignUpStep2";
import SignUpStep3 from "../layout/signup/SignUpStep3";

export default () => {
  return (
    <Fragment>
      <div className="auth-page position-relative min-vh-100">
        <div className="container-fluid h-100">
          <div className="row h-100 align-items-center">
            <div className="col-md-5">
              <div className="s-logo position-absolute">
                <Link to="/">
                  <img src={Logo} className="img-fluid header-logo" />
                </Link>
              </div>
              <div className="signup-form">
                <h3 className="fw-bold">Sign Up</h3>
                <Routes>
                  <Route path="/" element={<SignUpStep1 />} />
                  <Route path="/step-2" element={<SignUpStep2 />} />
                  <Route path="/step-3" element={<SignUpStep3 />} />
                </Routes>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="modal fade common-modal" id="verifiedModal" tabIndex="-1" aria-labelledby="commonModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="modal-body text-center">
              <h5 className="fw-semi-bold">Your email is verified successfully</h5>
              <button className="btn btn-primary py-2 mt-4" data-bs-toggle="modal" data-bs-target="#unverifiedModal">Done</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade common-modal" id="unverifiedModal" tabIndex="-1" aria-labelledby="commonModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="modal-body text-center">
              <h5 className="fw-semi-bold">Sorry, we are unable to verify your <br /> email</h5>
              <button className="btn btn-primary py-2 mt-4">Resend</button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}