import "./assets/style/main.scss";
import { Routes, Route } from "react-router-dom";
import { Fragment, useContext } from 'react'
import Home from "./pages/Home";
import Layout from "./components/layout";
import NftDetails from "./pages/NftDetails";
import EditProfile from "./pages/EditProfile";
import ProfileActivity from "./pages/ProfileActivity";
import MyWallet from "./pages/MyWallet";
import Login from "./components/activity/Login";
import Signup from "./components/activity/Signup";
import ForgotPassword from "./components/activity/ForgotPasword";
import ResetPassword from "./components/activity/ResetPassword";
import SocialHome from "./pages/SocialHome";
import MarketplaceHome from "./pages/MarketplaceHome";
import OtherUserActivity from "./pages/OtherUserActivity";
import { MyAccount } from "./pages/MyAccount";
import ChangePassword from "./pages/ChangePassword";
import CreatePostOptions from "./pages/CreatePostOptions";
import CreatePostSingle from "./pages/CreatePostSingle";
import CreatePostMultiple from "./pages/CreatePostMultiple";
import { ToastContainer } from "react-toastify";
import { GlobalContext } from "./context/Provider";
import CreatePostInstagram from "./pages/CreatePostInstagram";
import CreatePostTiktok from "./pages/CreatePostTiktok";
import Modals from "./components/Modals";
import CollectionLayout from "./pages/CollectionLayout";
import CreateSocialOptions from "./pages/CreateSocialOptions";
import CreateMarketplaceOptions from "./pages/CreateMarketplaceOptions";
import HowItWorks from "./pages/how-it-works";
import EllentToken from "./pages/elenToken";
import ContactUs from "./pages/contactUs";
import TermsCondition from "./pages/terms-condition";
import PrivacyPolicy from "./pages/privacy-policy";
import ProposalCreate from "./components/proposal/ProposalCreate";
import ProposalsPage from "./components/proposal/Proposals";

function App() {

  const { logOutNow } = useContext(GlobalContext)
  return <Fragment> <Routes>
    <Route path="*" element={
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/social" element={<SocialHome />} />
          <Route path="/marketplace" element={<MarketplaceHome />} />
          <Route path="/token/:token/:id/:tab/:graph_type" element={<NftDetails />} />
          <Route path="/wallet" element={<MyWallet />} />
          <Route path="/create/marketplace/multiple" element={<CreatePostMultiple />} />
          <Route path="/create/social/instagram" element={<CreatePostInstagram />} />
          <Route path="/create/social/tiktok" element={<CreatePostTiktok />} />
          <Route path="/create/marketplace/single" element={<CreatePostSingle />} />
          <Route path="/create" element={<CreatePostOptions />} />
          <Route path="/create/marketplace" element={<CreateMarketplaceOptions />} />
          <Route path="/create/social" element={<CreateSocialOptions />} />
          <Route path="/profile/:id/:tab" element={<OtherUserActivity />} />
          <Route path="/profile/edit" element={<EditProfile />} />
          <Route path="/profile/info" element={<MyAccount />} />
          <Route path="/profile/:tab" element={<ProfileActivity />} />
          <Route path="/password/change" element={<ChangePassword />} />
          <Route path="/collection/:id/:tab" element={<CollectionLayout />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/ELEN-token" element={<EllentToken />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/terms-condition" element={<TermsCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/proposal/create" element={<ProposalCreate />} />
          <Route path="/proposals/:page" element={<ProposalsPage />} />
        </Routes>
      </Layout>} />

    <Route path="/signin" element={<Login />} />
    <Route path="/signup/*" element={<Signup />} />
    <Route path="/password/forgot" element={<ForgotPassword />} />
    <Route path="/password/reset/:unique_code" element={<ResetPassword />} />

  </Routes>
    <Modals.logOut logout={logOutNow} />
    <ToastContainer autoClose={2000} />

  </Fragment>
}

export default App;
