const baseURI = 'https://everlens-io.infura-ipfs.io/ipfs/'

const ipfsGetFileUrl = (token) => {
    return `${baseURI}${token}`
}
const getWithIPFS = (token) => {
    if (String(token).startsWith("https://")) {
        return token
    }
    let urlArr = String(token).split("ipfs://")
    if (urlArr.length === 2) {
        return `${baseURI}${urlArr[1]}`
    } else {
        return ipfsGetFileUrl(token)
    }
}

const henceforthIPFS = {
    ipfsGetFileUrl,
    baseURI,
    getWithIPFS,
}
export default henceforthIPFS