export const APP_NAME = "Everlens";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const AUTH_AGE_VERIFY = "AUTH_AGE_VERIFY";
export const INITIALISE_PROFILE = "INITIALISE_PROFILE";
export const DELETE_INSTAGRAM = "DELETE_INSTAGRAM";
export const DELETE_TIKTOK = "DELETE_TIKTOK";

export const STATIC_DATA_SUCCESS = "STATIC_DATA_SUCCESS";
export const PAGE_LIMIT_SUCCESS = "PAGE_LIMIT_SUCCESS";
export const FIXED_PRICE = 1;
export const TIMED_AUCTION = 3;
export const RIGHT_AFTER_LISTING = "RIGHT_AFTER_LISTING";
export const PICK_SPECIFIC_DATE = "PICK_SPECIFIC_DATE";
export const EXPIRE_1_DAY = "EXPIRE_1_DAY";
export const EXPIRE_3_DAY = "EXPIRE_3_DAY";
export const EXPIRE_5_DAY = "EXPIRE_5_DAY";
export const EXPLORE_ADD = "EXPLORE_ADD";
export const EXPLORE_UPDATE = "EXPLORE_UPDATE";
export const EXPLORE_FEV = "EXPLORE_FEV";
export const EXPLORE_LIVE_AUCTION = "LIVE_AUCTIONS";
export const EXPLORE_ENDED_AUCTIONS = "ENDED_AUCTIONS";
export const EXPLORE_ENDED_BUY_IT_NOW = "ENDED_BUY_IT_NOW";
export const EXPLORE_TOP_SELLERS = "EXPLORE_TOP_SELLERS";
export const EXPLORE_TRENDING = "EXPLORE_TRENDING";
export const EXPLORE_HOT_COLLECTIONS = "EXPLORE_HOT_COLLECTIONS";

export const EXPLORE_NFT_TYPE_MARKETPLACE = "MARKETPLACE";
export const EXPLORE_NFT_TYPE_MARKETPLACE_COLLECTION = "MARKETPLACE_COLLECTION";
export const EXPLORE_NFT_TYPE_INSTAGRAM = "INSTAGRAM";
export const EXPLORE_NFT_TYPE_TIKTOK = "TIKTOK";
export const EXPLORE_NFT_TYPE_SOCIAL = "SOCIAL";
export const EXPLORE_NFT_POST_TYPE_SINGLE = "SINGLE";
export const EXPLORE_NFT_POST_TYPE_MULTIPLE = "MULTIPLE";

export const AUTH_FEV = "AUTH_FEV";

export const FIXED_PRICE_AUCTION_ID = 1;
export const TIMED_AUCTION_AUCTION_ID = "0x02";

export const EXPLORE_LANDING_SOCIAL = "SOCIAL";
export const EXPLORE_LANDING_MARKET_PLACE = "MARKETPLACE";
export const EXPLORE_CHANGE_LANDING = "EXPLORE_CHANGE_LANDING";

export const NFT_STEP_0 = 0;
export const NFT_STEP_1 = 1;
export const NFT_STEP_2 = 2;
export const NFT_STEP_3 = 3;
export const NFT_STEP_4 = 4;
export const NFT_STEP_5 = 5;
export const WALLET_ADDRESS_SLICE_FROM = 5;

export const REDIRECT_CONTENT_CREATE = "content/create";

export const CHAT_STATUS = "CHAT_STATUS";
export const SOCIAL_TYPE_PROVIDER = "PROVIDER";
export const SOCIAL_TYPE_META_MASK = "META_MASK";
export const SOCIAL_TYPE_WALLET_CONNECT = "WALLET_CONNECT";
export const SOCIAL_TYPE_EVERLENS_WALLET = "EVERLENS_WALLET";

// NFT TYPES
export const ERROR_UNAUTHORIZED = "UNAUTHORIZED";
// NFT TYPES
export const NFT_OWN = "own";
export const NFT_SOLD = "sold";
export const NFT_RE_SALE = "onsale";
// social network
export const NFT_INSTAGRAM = "https://www.instagram.com/yourlink";
export const NFT_TWITTER = "https://www.twitter.com/yourlink";
export const NFT_MEDIUM = "https://medium.com/yourlink";
export const NFT_TIK_TOK = "https://www.tiktok.com/@yourlink";
export const NFT_REDDIT = "https://www.reddit.com/user/yourlink/";

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyA99Nb43lboHvwPVS0Fc3InVBIe7-ffTCk",
  authDomain: "everlensv3-d65ab.firebaseapp.com",
  projectId: "everlensv3-d65ab",
  storageBucket: "everlensv3-d65ab.appspot.com",
  messagingSenderId: "467462240321",
  appId: "1:467462240321:web:cb5a8f95882dc25a8e2ec0",
  measurementId: "G-CJC0EQZD9M"
};
export const FIREBASE_VA_API_KEY = 'BK_cixtME06MQhqy7hoQ_ZXdQNSCGDBKi507oeGy2X1xihofivANMIFOjW_PSHfVY1v0RvrMl4JbtGa2spBE5AU'
