import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import henceforthIPFS from './henceforthIPFS';

const superagent = superagentPromise(_superagent, global.Promise);
const IS_STAGING = (window.origin.includes('staging') || window.origin.includes('localhost'))
const API_ROOT = `https://${IS_STAGING ? `staging.` : ``}everlens.io:3000/`;
const SNAPSHOT_HUB_API_ROOT = `https://${IS_STAGING ? `testnet` : `hub`}.snapshot.org/`;
const INSTAGRAM_API_ROOT = 'https://graph.instagram.com/'; //live

const SERVICE_FEE = IS_STAGING ? 3 : 3;

const BUCKET_ROOT = `https://${IS_STAGING ? `everlensv3.` : `everlensv2.`}s3.eu-west-3.amazonaws.com/Uploads/`;

const API_FILE_ROOT_MEDIUM = `${BUCKET_ROOT}Images/Medium/`;
const API_FILE_ROOT_ORIGINAL = `${BUCKET_ROOT}Images/Original/`;
const API_FILE_ROOT_SMALL = `${BUCKET_ROOT}Images/Small/`;
const API_FILE_ROOT_AUDIO = `${BUCKET_ROOT}Audio/`;
const API_FILE_ROOT_VIDEO = `${BUCKET_ROOT}Video/`;
const API_FILE_ROOT_DOCUMENTS = `${BUCKET_ROOT}Documents/`;

const encode = encodeURIComponent;
const responseBody = res => res.body;

let token = null;
const tokenPlugin = req => {
  if (token) {
    // req.set('authorization', `Bearer ${token}`);
    req.set('token', token);
  }
}

const requests = {
  del: url =>
    superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  get: url =>
    superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  put: (url, body) =>
    superagent.put(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
  patch: (url, body) =>
    superagent.patch(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody)
};
const instagramApi = {
  del: url =>
    superagent.del(`${INSTAGRAM_API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  get: url =>
    superagent.get(`${INSTAGRAM_API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  put: (url, body) =>
    superagent.put(`${INSTAGRAM_API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
  patch: (url, body) =>
    superagent.patch(`${INSTAGRAM_API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
  post: (url, body) =>
    superagent.post(`${INSTAGRAM_API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody)
};
const snapshotApi = {
  del: url =>
    superagent.del(`${SNAPSHOT_HUB_API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  get: url =>
    superagent.get(`${SNAPSHOT_HUB_API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  put: (url, body) =>
    superagent.put(`${SNAPSHOT_HUB_API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
  patch: (url, body) =>
    superagent.patch(`${SNAPSHOT_HUB_API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
  post: (url, body) =>
    superagent.post(`${SNAPSHOT_HUB_API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody)
};

const Auth = {
  login: info =>
    requests.post('User/login', info),
  signInUserByMetamask: info =>
    requests.post('User/wallet_login', info),
  signUpAsUser: info =>
    requests.post('User/signup_as_user', info),
  signUp: info =>
    requests.post('User/signup', info),
  signUpAsCreater: info =>
    requests.post('User/sigup_as_creater', info),
  forgotPassword: info =>
    requests.put('User/password', info),
  logout: () =>
    requests.put('User/logout', {}),
  checkOtp: info =>
    requests.post('check-email-otp', info),
  resendOtp: info =>
    requests.post('User/otp', info),
  emailVerification: info =>
    requests.post('User/verify', info),
  ageVerification: info =>
    requests.post('User/age_verification', info),
  resetPassword: info =>
    requests.post('User/password', info),
  connetWallet: info =>
    requests.post('User/connect/wallet', info),
  connectSocialAccount: info =>
    requests.post('User/connect/social_account', info),
  changePassword: info =>
    requests.put('User/password/change', info),
  forgotChangePassword: info =>
    requests.post('User/set_new_password', info),
  profile: () =>
    requests.get(`user/detail`),
};
const Dashboard = {
  get: () =>
    requests.get(`admin/dashboard`),
};
const Activity = {
  create: info =>
    requests.post('nft', info),
  facilityActivity: (id) =>
    requests.get(`facility/activity?room_type_id=${id}`),
  getDetails: (id) =>
    requests.get(`nft/${id}`),
};
const Search = {
  pagination: (search, nft_type) =>
    requests.get(`Nft/search?search=${search}${nft_type ? `&nft_type=${nft_type}` : ''}&limit=10&pagination=0&language=ENGLISH`),
  reels: (user_id, amount) =>
    requests.get(`reels/getReels?user_id=${user_id}&amount=${amount ? amount : 10}`),
  getById: (id) =>
    requests.get(`profile?id=${id}`),
};
const Social = {
  addEdit: info =>
    requests.post(`Profile/add_edit_social_media`, info),
};
const Profile = {
  edit: info =>
    requests.put('User/profile', info),
  report: (_id, info) =>
    requests.put(`User/report/${_id}`, info),
  deactivateAccount: info =>
    requests.put('Profile/deactivate_account', info),
  get: () =>
    requests.get(`User/profile?language=ENGLISH`),
  getById: (id) =>
    requests.get(`User/profile/${id}?language=ENGLISH`),
  getByTab: (id, type, limit, q, nft_type, pagination) =>
    requests.get(`User/profile/details?user_id=${id}&type=${type}&nft_type=${nft_type}&language=ENGLISH&pagination=${pagination ? pagination : 0}&limit=${limit}${q ? `&${q}` : ''}`),
  getPublicPhotos: (page) =>
    requests.get(`Profile/list_photos?language=ENGLISH&type=PUBLIC&pagination=${page ? page : 0}&limit=12`),
  getPrivatePhotos: (page) =>
    requests.get(`Profile/list_photos?language=ENGLISH&type=PRIVATE&pagination=${page ? page : 0}&limit=12`),
  addPhotos: info =>
    requests.post('Profile/add_photos', info),
  movePhotosToPublic: _id =>
    requests.put('Profile/move_photos', { _id, type: "PUBLIC", language: "ENGLISH" }),
  movePhotosToPrivate: _id =>
    requests.put('Profile/move_photos', { _id, type: "PRIVATE", language: "ENGLISH" }),
  connetWallet: info =>
    requests.post('User/wallet/connect', info),
  setPrimaryWallet: info =>
    requests.put('User/wallet/set_primary', info),
  fcmToken: fcm_token =>
    requests.put('User/fcm', {
      device_type: "Web",
      fcm_token,
      language: "ENGLISH"
    }),
  getWallet: () =>
    requests.get(`User/wallet/listing?language=ENGLISH`),
  deleteWallet: _id =>
    requests.del(`User/wallet/${_id}`),
  deleteSocial: _id =>
    requests.del(`User/social_account/${_id}?language=ENGLISH`),
};
const NFT = {
  get: () =>
    requests.get(`Profile/my_profile?language=ENGLISH&limit=10`),
  categories: () =>
    requests.get(`Nft/categories?language=ENGLISH`),
  deactivateAccount: info =>
    requests.put('User/content/edit', info),
  create: info =>
    requests.post('V2/Nft', info),
  reCreate: info =>
    requests.post('Nft/sell', info),
  addToFev: info =>
    requests.post('V2/Nft/favourite', info),
  auctionReCreate: info =>
    requests.post('Nft/aution/sell', info),
  buy: info =>
    requests.post('V2/Nft/buy', info),
  addComment: info =>
    requests.post('Nft/comment', info),
  deleteComment: _id =>
    requests.del(`Nft/comment/${_id}?language=ENGLISH`),
  finaliseUnlimitedAuction: info =>
    requests.put('Nft/aution/buy', info),
  removeFromFev: info =>
    requests.put('V2/Nft/favourite', info),
  report: info =>
    requests.put('Nft/report', info),
  putOnSale: _id =>
    requests.put(`Nft/put_on_sale/${_id}`, {}),
  removeFromSale: _id =>
    requests.del(`V2/Nft/voucher/${_id}?language=ENGLISH`),
  placeBid: info =>
    requests.post('V2/Nft/bid', info),
  placeOffers: info =>
    requests.post('V2/Nft/offer', info),
  updateOffers: info =>
    requests.put('V2/Nft/offer/update', info),
  acceptOffers: info =>
    requests.put('V2/Nft/offer', info),
  placeOrder: info =>
    requests.put('V2/Nft/bid', info),
  lock: info =>
    requests.post('User/lock', info),
  unlock: (_id, info) =>
    requests.post(`User/unlock/${_id}`, info),
  explore: (nft_type, pagination, q) =>
    requests.get(`V2/Nft/explore?language=ENGLISH${nft_type ? `&nft_type=${encode(nft_type)}` : ''}&pagination=${pagination ? pagination : 0}&limit=8${q ? `&${q}` : ''}`),
  weeklySelection: (nft_type) =>
    requests.get(`V2/Nft/weekly_selection?language=ENGLISH&nft_type=${nft_type}`),
  exploreLiveAuction: (search, pagination) =>
    requests.get(`Nft/live_auctions?language=ENGLISH${search ? `&search=${encode(search)}` : ''}&pagination=${pagination ? pagination : 0}&limit=8`),
  exploreTopSellers: (search, pagination) =>
    requests.get(`Nft/top_sellers?language=ENGLISH${search ? `&search=${encode(search)}` : ''}&pagination=${pagination ? pagination : 0}&limit=8`),
  exploreTrending: (search, pagination) =>
    requests.get(`Nft/trending?language=ENGLISH${search ? `&search=${encode(search)}` : ''}&pagination=${pagination ? pagination : 0}&limit=8`),
  getById: _id =>
    requests.get(`V2/Nft/${_id}?language=ENGLISH`),
  getOwnersById: (_id, pagination, limit) =>
    requests.get(`V2/Nft/${_id}/owners?pagination=${pagination ? pagination : 0}&limit=${limit ? limit : 10}&language=ENGLISH`),
  getBidsById: (_id, pagination, limit) =>
    requests.get(`V2/Nft/${_id}/bids?pagination=${pagination ? pagination : 0}&limit=${limit ? limit : 10}&language=ENGLISH`),
  getOfferById: (_id, pagination, limit) =>
    requests.get(`V2/Nft/offer?_id=${_id}&pagination=${pagination ? pagination : 0}&limit=${limit ? limit : 10}&language=ENGLISH`),
  getCommentsById: (_id, pagination, limit) =>
    requests.get(`V2/Nft/${_id}/comments?pagination=${pagination ? pagination : 0}&limit=${limit ? limit : 10}&language=ENGLISH`),
  getTradingById: (_id, pagination, limit) =>
    requests.get(`V2/Nft/${_id}/trading_history?pagination=${pagination ? pagination : 0}&limit=${limit ? limit : 10}&language=ENGLISH`),
  getGraphById: (_id, graph_type) =>
    requests.get(`V2/Nft/${_id}/graph?graph_type=${graph_type ? graph_type : "LAST_30_DAYS"}&language=ENGLISH`),
  burn: _id =>
    requests.del(`V2/Nft/${_id}?language=ENGLISH`),
};
const Creator = {
  profile: (_id) =>
    requests.get(`Profile/other_user/profile?language=ENGLISH&user_id=${_id}`),
  pagination: (pagination, search) =>
    requests.get(`User/creator/list_all?language=ENGLISH${search ? `&search=${search}` : ''}&pagination=${pagination ? pagination : 0}&limit=10`),
};
const Common = {
  contentUs: items =>
    requests.post(`User/contact_us`, items),
  profile: (_id) =>
    requests.get(`Profile/other_user/profile?language=ENGLISH&user_id=${_id}`),
  TERMS_AND_CONDITIONS: (_id) =>
    requests.get(`User/policies/listing?language=ENGLISH&type=TERMS_AND_CONDITIONS`),
  ethusd: () =>
    superagent.get(`https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=ETH,USD`),
  elenusd: () =>
    superagent.get(`https://api.coingecko.com/api/v3/simple/price?ids=everlens&vs_currencies=usd`),
  getPublicPhotos: (user_id, page) =>
    requests.get(`Profile/other_user/public_photos?language=ENGLISH&type=PUBLIC&pagination=${page ? page : 0}&limit=12&user_id=${user_id}`),
  getPrivatePhotos: (user_id, page) =>
    requests.get(`Profile/other_user/photos?language=ENGLISH&type=PRIVATE&pagination=${page ? page : 0}&limit=12&user_id=${user_id}`),
};
const Tips = {
  searchUserPagination: (search, pagination) =>
    requests.get(`Profile/tips/search_users?language=ENGLISH&search=${encode(search)}&pagination=${pagination}&limit=9`),
  searchCreatorPagination: (search, pagination) =>
    requests.get(`Profile/tips/search_creators?language=ENGLISH&search=${search}&pagination=${pagination}&limit=9`),
  getTipRequest: (pagination) =>
    requests.get(`Profile/tips/received_requests?language=ENGLISH&pagination=${pagination}&limit=9`),
  getTipRequestCreatorSide: (pagination) =>
    requests.get(`Profile/tips/sent_requests?language=ENGLISH&pagination=${pagination}&limit=9`),
  getTipHistory: (pagination) =>
    requests.get(`Profile/tips/history?language=ENGLISH&pagination=${pagination}&limit=9`),
  createTipRequest: info =>
    requests.post('Profile/tips/send_request', info),
  createDirectTip: info =>
    requests.post('Profile/tips/send', info),
  acceptTipRequest: info =>
    requests.put('Profile/tips/manage_request', info),
  cancelTipRequest: _id =>
    requests.put('Profile/tips/manage_request', { _id, status: "CANCEL", language: "ENGLISH" }),
  rejectTipRequest: _id =>
    requests.put('Profile/tips/manage_request', { _id, status: "REJECT", language: "ENGLISH" }),
};

const Chat = {
  profile: (_id) =>
    requests.get(`Profile/other_user/profile?language=ENGLISH&user_id=${_id}`),
  users: (pagination) =>
    requests.get(`Chat/list_users?language=ENGLISH&pagination=${pagination}&limit=10`),
  chats: (pagination, search) =>
    requests.get(`Chat/list_chat_users?language=ENGLISH&pagination=${pagination}&limit=10${search ? `&search=${search}` : ''}`),
  chatHistory: (_id, pagination) =>
    requests.get(`Chat/history?_id=${_id}&pagination=${pagination}&limit=10`),
}
const Notification = {
  get: (pagination) =>
    requests.get(`User/notifications?limit=10&pagination=${pagination}&language=ENGLISH`),
  read: _id =>
    requests.put(`User/notifications/read`, { _id, language: 'ENGLISH' }),
  readAll: () =>
    requests.put(`User/notifications`, {}),
}
const Follower = {
  follow: (items) =>
    requests.post(`User/follow`, items),
  unfollow: (items) =>
    requests.put(`User/follow`, items),
  followers: (user_id, limit, pagination) =>
    requests.get(`User/followers?user_id=${user_id}&limit=${limit ? limit : 10}&pagination=${pagination ? pagination : 0}&language=ENGLISH`),
  followings: (user_id, limit, pagination) =>
    requests.get(`User/followings?user_id=${user_id}&limit=${limit ? limit : 10}&pagination=${pagination ? pagination : 0}&language=ENGLISH`),
}
const INSTAGRAM = {
  getMediaIds: (access_token) =>
    instagramApi.get(`me/media?fields=id,caption&access_token=${access_token}`),
  reels: (user_name, limit) =>
    requests.get(`Instagram/videos?user_name=${user_name}&limit=${limit}&language=ENGLISH`),
  stories: (user_name, limit) =>
    requests.get(`Instagram/stories?user_name=${user_name}&limit=${limit}&language=ENGLISH`),
  uploadIpfs: (file_url) =>
    requests.post(`Upload/ipfs`, { file_url }),
}
const TIKTOK = {
  videos: (name, limit) =>
    requests.get(`Tiktok/videos?user_name=${name}&limit=${limit}&language=ENGLISH`),
  uploadIpfs: (file_url) =>
    requests.post(`Upload/ipfs/tiktok`, { file_url }),
  verification: (video_url) =>
    requests.put(`User/connect/social_account`, { video_url, language: 'ENGLISH' }),

}

const Collection = {
  create: (items) =>
    requests.post(`Collection`, items),
  report: (items) =>
    requests.post(`Collection/report`, items),
  update: (items) =>
    requests.put(`Collection`, items),
  get: (search, erc, pagination, limit) =>
    requests.get(`Collection?limit=${limit ? limit : 10}${search ? `${`&search=${search}`}` : ''}&pagination=${pagination ? pagination : 0}&erc=${erc}&language=ENGLISH`),
  getItems: (_id, pagination, limit, q) =>
    requests.get(`Collection/${_id}/items?limit=${limit ? limit : 10}&pagination=${pagination ? pagination : 0}&language=ENGLISH${q ? `&${q}` : ''}`),
  getActivities: (_id, pagination, limit, q) =>
    requests.get(`Collection/${_id}/activities?limit=${limit ? limit : 10}&pagination=${pagination ? pagination : 0}&language=ENGLISH${q ? `&${q}` : ''}`),
  getById: (_id, q) =>
    requests.get(`Collection/${_id}?language=ENGLISH${q ? `&${q}` : ''}`),
}
const Voucher = {
  create: (items) =>
    requests.post(`V2/Nft/voucher`, items),
}
const Ipfs = {
  metadata: (metadata) =>
    requests.post(`upload/ipfs/metadata`, { metadata }),
}
const Migration = {
  nfts: () =>
    requests.post(`Migration/nfts`, {}),
}
const Snapshot = {
  proposals: (items) =>
    snapshotApi.post(`graphql`, items),
}

const FILES = {
  audio: (filename) => filename.startsWith('http') ? filename : filename?.includes(".") ? `${API_FILE_ROOT_AUDIO}${filename}` : henceforthIPFS.getWithIPFS(filename),
  video: (filename) => filename.startsWith('http') ? filename : filename?.includes(".") ? `${API_FILE_ROOT_VIDEO}${filename}` : henceforthIPFS.getWithIPFS(filename),
  imageOriginal: (filename) => filename.startsWith('http') ? filename : filename?.includes(".") ? `${API_FILE_ROOT_ORIGINAL}${filename}` : henceforthIPFS.getWithIPFS(filename),
  imageMedium: (filename) => filename.startsWith('http') ? filename : filename?.includes(".") ? `${API_FILE_ROOT_MEDIUM}${filename}` : henceforthIPFS.getWithIPFS(filename),
  imageSmall: (filename) => filename.startsWith('http') ? filename : filename?.includes(".") ? `${API_FILE_ROOT_SMALL}${filename}` : henceforthIPFS.getWithIPFS(filename),
}

const henceforthApi = {
  token,
  Chat,
  Common,
  Collection,
  Follower,
  Notification,
  Tips,
  Ipfs,
  Auth,
  Profile,
  Creator,
  Migration,
  NFT,
  Search,
  Social,
  Snapshot,
  Dashboard,
  Activity,
  INSTAGRAM,
  TIKTOK,
  Voucher,
  API_ROOT,
  SERVICE_FEE,
  API_FILE_ROOT_SMALL,
  API_FILE_ROOT_MEDIUM,
  API_FILE_ROOT_ORIGINAL,
  API_FILE_ROOT_VIDEO,
  API_FILE_ROOT_DOCUMENTS,
  FILES,
  IS_STAGING,
  SNAPSHOT_HUB_API_ROOT,
  encode,
  setToken: _token => { token = _token; }
};

export default henceforthApi