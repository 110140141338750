
import InstagramSvg from "../assets/images/home/insta_big.svg";
import TiktokSvg from "../assets/images/home/tiktok_big.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Single from "../assets/images/home/single.png";
import Multiple from "../assets/images/home/multiple.png";
import { useContext, useEffect } from "react";
import { GlobalContext } from "../context/Provider";
import henceforthApi from "../utils/henceforthApi";
import { EXPLORE_LANDING_MARKET_PLACE, EXPLORE_LANDING_SOCIAL } from "../context/actionTypes";

export default () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { authState, staticState, accounts, scrollToTop, } = useContext(GlobalContext)
    henceforthApi.setToken(authState.access_token)
    useEffect(() => {
        if (authState.access_token) {
            scrollToTop()
        } else {
            navigate(`/signin?redirect=${location.pathname}`, { replace: true })
        }
    }, [accounts, authState.access_token])

    useEffect(() => {
        const closeSelectFile = document.getElementsByClassName("modal-backdrop")
        if (closeSelectFile.length) {
            closeSelectFile[0].parentNode.removeChild(closeSelectFile[0]);
        }
    }, [])

    return <section className="edit-profile mb-5 pt-md-5 pt-4 pb-5">
        <div className="container">
            <div className="row">
                <div className="col-xl-6 col-lg-8">
                    <div className="w-85 ms-xl-auto m-auto row">
                        <h2 className="fw-bold mb-3">
                            <span className="text-gradient">Create New Post</span></h2>
                        <div className="col-md-12">
                            <Link to="/create/social">
                                <div className="border-1 rounded-2 p-3 mb-3 ps-4" >
                                    <div>
                                        <p className="mb-0 fw-semi-bold ">Everlense Social</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-12">
                            <Link to="/create/marketplace">
                                <div className="border-1 rounded-2 p-3 mb-3 ps-4" >
                                    <div>
                                        <p className="mb-0 fw-semi-bold">Everlens Marketplace</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}