const SIGNING_DOMAIN_NAME = "everlens.io";
const SIGNING_DOMAIN_VERSION = "3";

let _contract = null;
let _signer = null;
let _domain = null;

const CreateVoucher = async (
  owner,
  auctionType,
  quantity,
  endTime,
  minPrice,
  tokenContract,
) => {
  const voucher = {
    owner,
    minPrice,
    auctionType,
    quantity,
    endTime: Math.round(endTime / 1000),
    tokenContract,
    salt: new Date().getTime(),
  };
  const domain = await _signingDomain();
  const types = {
    MarketplaceVoucher: [
      { name: "owner", type: "address" },
      { name: "minPrice", type: "uint256" },
      { name: "auctionType", type: "uint256" },
      { name: "quantity", type: "uint256" },
      { name: "endTime", type: "uint256" },
      { name: "tokenContract", type: "address" },
      { name: "salt", type: "uint256" },
    ],
  };
  // const TypesDataEncoder = ethers.utils._TypedDataEncoder.from(types)
  // const payload = ethers.utils._TypedDataEncoder.getPayload(domain, types, voucher);
  // const encodedData = ethers.utils._TypedDataEncoder.encode(domain, types, voucher);
  // const hash = ethers.utils._TypedDataEncoder.hash(domain, types, voucher);
  const signature = await _signer._signTypedData(domain, types, voucher);
  return {
    ...voucher,
    signature,
    endTime
  };
}


const _signingDomain = async () => {
  if (_domain != null) {
    return _domain;
  }
  const chainId = await _contract.getChainID();
  _domain = {
    name: SIGNING_DOMAIN_NAME,
    version: SIGNING_DOMAIN_VERSION,
    verifyingContract: _contract.address,
    chainId,
  };
  return _domain;
}

const henceforthMarketplaceVoucher = {
  CreateVoucher,
  setToken: async (contract, signer) => { _contract = contract; _signer = signer }
};
export default henceforthMarketplaceVoucher