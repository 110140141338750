
export default {
    provider: "",
    onsale: {
        total_count: 0,
        data: []
    }, sold: {
        total_count: 0,
        data: []
    }, owned: {
        total_count: 0,
        data: []
    }, created: {
        total_count: 0,
        data: []
    }, bids: {
        total_count: 0,
        data: []
    }, favourite: {
        total_count: 0,
        data: []
    }, activity: {
        total_count: 0,
        data: []
    },
    walletType: {
        type: ""
    },

}