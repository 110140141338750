import { EXPLORE_CHANGE_LANDING } from "../actionTypes";

export default (state, { type, payload }) => {
    switch (type) {
        case EXPLORE_CHANGE_LANDING: {
            return {
                ...state,
                explore: payload,
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}