// import ScrollToTop from "../../components/HOC/ScrollToTop"

import ScrollToTop from "../../components/HOC/ScrollToTop";

const TermsCondition = () => {
  return (
    <div className="inner-pages-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="term-heading title-col">
              <h2 className="mt-5">Everlens Terms of Use and Condition</h2>
            </div>
            <br />
            <div className="term-condition">
              <p>BELOW ARE THE TERMS AND CONDITIONS GOVERNING THE EVERLENS OFFERINGS,
                INCLUDING:</p>

              <ul>
                <li>ACCESSING, DOWNLOADING OR USING ANY OF THE EVERLENS APPS IS
                  FORBIDDEN TO UNITED STATES CITIZENS;</li>
                <br />

                <li>
                  CREATING ANY COLLECTIBLE ON THE EVERLENS APPS OR TRADING,
                  BUYING, SELLING, TRANSFERRING OR RECEIVING ANY COLLECTIBLE
                  THAT WAS CREATED ON A EVERLENS APP IS FORBIDDEN TO UNITED
                  STATES CITIZENS.
                </li>
                <br />

                <li>
                  USING, SENDING TOKENS TO, RECEIVING TOKENS FROM, SENDING
                  MESSAGES TO OR OTHERWISE TRANSACTING WITH THE EVERLENS DEX
                  SYSTEM;

                </li>
                <br />

                <li>
                  {" "}
                  USING, TRANSFERRING, VOTING, BUYING, SELLING OR RECEIVING THE
                  ELEN TOKEN; OR

                </li>
                <br />

                <li>
                  PARTICIPATING IN THE EVERLENS DAO OR ACCESSING OR USING
                  EVERLENS GOVERNANCE MODULE.
                </li>
              </ul>
              <p className="mt-4">
                These terms and conditions (these “
                <strong>
                  <i>Terms</i>
                </strong>
                ”) have been established by EVERLENS Company to
                govern the Offerings. By engaging in or undertaking any of the aforementioned activities, you will be
                deemed to be a“
                <strong>
                  <i>User</i>
                </strong>
                ”who is legally bound by these Terms.
              </p>
              <p>Please contact us at team@everlens.io for any questions or issues</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container term-condition">
        <div className="row">
          <div className="col-md-12">
            <div>
              <h4 className="text-left mt-4 mb-3">
                <strong>AGREEMENT</strong>
              </h4>
            </div>
            <div>
              <p>
                <strong>1.CERTAIN DEFINED TERMS AND RELATED INFORMATION</strong>
              </p>
              <p><strong>1.1 NFTs and Collectibles.</strong></p>
              <p>
                <strong>(a) </strong> “
                <strong>
                  <i>NFTs</i>
                </strong>
                ” means Ethereum-based tokens complying with the ERC-721 standard, ERC-1155
                standard or other similar “non-fungible” token standard. NFTs are intended to be “non-fungible”
                tokens representing a unique Collectible; however, certain NFTs may be fungible with one another
                (i.e., all such NFTs are associated with the same Collectible Metadata) while being non-fungible with
                other NFTs (i.e., such NFTs are associated with different Collectible Metadata).

              </p>
              <p>
                <strong>(b) </strong> <u> Collectibles</u>
              </p>
              <p>
                <strong>(i) </strong> “
                <strong>
                  <i>Collectible</i>
                </strong>
                ”means the association on Ethereum of an NFT with a Uniform Resource Identifier
                (“URI”) identifying an appropriately configured JSON file conforming to the ERC-721 Metadata JSON
                Schema, ERC-1155 Metadata URI JSON Schema or a similar JSON schema, as applicable (such
                JSON file, the
                <strong>“Collectible ID”).</strong>
              </p>


              <p><strong>(ii)</strong> The Collectible ID of a Collectible specifies the properties of the Collectible, including the name
                and description of the Collectible (the <strong> “Collectible Descriptors”)</strong>, a URI identifying any image file
                associated with the Collectible (the <strong>“Collectible Image”</strong>) and potentially other “metadata” associated
                with the Collectible (the Collectible Descriptors, Collectible Image and such other metadata,
                collectively, the <strong> “Collectible Metadata”</strong>). The Collectible Metadata for Collectibles created through the
                EVERLENS Applications are typically stored on IPFS through an IPFS node operated by
                EVERLENS Company. The Collectible Metadata for Collectibles created outside the EVERLENS
                Applications may be stored in other ways, depending on how such Collectibles were created.
              </p>

              <p><strong>(iii)</strong> There can be no guarantee or assurance of the uniqueness, originality or quality of any
                Collectible or Collectible Metadata. In the absence of an express legal agreement between the
                creator of a Collectible and purchasers of the Collectible, there cannot be any guarantee or
                assurance that the purchase or holding of the Collectible confers any license to or ownership of the
                Collectible Metadata or other intellectual property associated with the Collectible or any other right or
                entitlement, notwithstanding that User may rightfully own or possess the NFT associated with the
                Collectible.</p>

              <p><strong>(iv)</strong> EVERLENS Company may from time to time remove certain Collectibles from the EVERLENS
                Apps or restrict the creation of Collectibles on the EVERLENS Apps in EVERLENS Company’s sole
                and absolute discretion, including in connection with any belief by EVERLENS Company that such
                Collectible violates these Terms or the terms and conditions or privacy policy of the EVERLENS
                Apps. EVERLENS Company does not commit and shall not be liable for any failure to support,
                display or offer or continue to support, display or offer any Collectible for trading through the
                EVERLENS Apps.
              </p>

              <p><strong>1.2 Offerings.</strong></p>
              <p>
                <strong>(a) </strong> “
                <strong>
                  <i>Offerings</i>
                </strong>
                ” means the EVERLENS Platform and all uses thereof, the EVERLENS Apps, the
                EVERLENS DEX System, ELEN, the EVERLENS DAO and the EVERLENS Governance Module.
              </p>


              <p>
                <strong>(b) </strong> “
                <strong>
                  <i>ELEN</i>
                </strong>
                ” means the tokens with string constant public name “EVERLENS” and string constant
                public symbol “ELEN”, the supply and balances of which are tracked by the smart contract deployed
                to address 0xfca59cd816ab1ead66534d82bc21e7515ce441cf on Ethereum. Each ELEN entitles the
                holder to one vote per proposal in the EVERLENS DAO.
              </p>







              <p>
                <strong>(c) </strong> “
                <strong>
                  <i>EVERLENS Apps</i>
                </strong>
                ” means the consumer software applications created, operated and made
                publicly available by EVERLENS Company for transactions involving Collectibles and the
                EVERLENS DEX System, other than the EVERLENS DEX System itself. On the date these Terms
                were first published, the EVERLENS Apps consisted of the world wide web application hosted at
                https://app.EVERLENS.io/ (the “EVERLENS Website”). In the future, EVERLENS Company may
                release one or more mobile applications ( the
                <strong> “EVERLENS Mobile Apps”)</strong>.
              </p>


              <p>
                <strong>(d) </strong> “
                <strong>
                  <i>EVERLENS Company</i>
                </strong>
                ” means EVERLENS Inc., a Mauritius corporation.
              </p>


              <p>
                <strong>(e) </strong> “
                <strong>
                  <i>EVERLENS DAO</i>
                </strong>
                ” means the unincorporated association of ELEN holders
              </p>


              <p>
                <strong>(f) </strong> “
                <strong>
                  <i>EVERLENS DEX System</i>
                </strong>
                ” means the bytecodes (aka “smart contracts”) for creating, buying,
                selling and transferring NFTs and Collectibles that are officially supported in the EVERLENS Apps,
                as they may be modified or supplemented from time to time pursuant to Section 3.4. On the date
                these Terms were first published, the EVERLENS DEX System comprised the bytecodes deployed
                to the following Ethereum network addresses listed below https://EVERLENS.io/contracts.txt.
              </p>



              <p>
                <strong>(g) </strong> “
                <strong>
                  <i>EVERLENS Governance Module</i>
                </strong>
                ” means the websites, forums, technologies and methods
                established from time to time by EVERLENS Company for the coordination, voting and other official
                activities of ELEN holders in connection with the EVERLENS DAO.
              </p>



              <p>
                <strong>(h) </strong> “
                <strong>
                  <i>EVERLENS Platform</i>
                </strong>
                ” means the EVERLENS Apps and EVERLENS DEX System, collectively
              </p>



              <p>
                <strong>1.3 </strong>
                “<strong>Ethereum </strong>” means the Ethereum mainnet and the consensus blockchain for such mainnet
                (networkID:1, chainID:1) as recognized by the official Go Ethereum Client, or, if applicable, the
                network and blockchain generally recognized as the legitimate successor thereto.
              </p>




              <h5 className="mt-4 mb-4">
                <strong>2. CERTAIN GENERAL TERMS </strong>
              </h5>

              <p>
                <strong>2.1 User Responsible for Accounts / Addresses.</strong> Users are responsible for all matters relating to their
                accounts (if any) on the EVERLENS Apps or the blockchain accounts or addresses through which
                they interact with the Offerings, and for ensuring that all uses thereof comply fully with these Terms.
                Users are responsible for protecting the confidentiality of their login information and passwords (if
                applicable) for the EVERLENS Apps or the private keys controlling the relevant blockchain accounts
                or addresses through which they interact with the Offerings.
              </p>



              <p>
                <strong> 2.2 EVERLENS May Discontinue Offerings.</strong> EVERLENS Company shall have the right at any time to
                change or discontinue any or all aspects or features of the Offerings.
              </p>



              <p>
                <strong>2.3 EVERLENS May Deny Access to or Use of the Offerings.</strong>EVERLENS Company reserves the
                right to terminate a User’s access to or use of any or all of the Offerings at any time, without or
                without notice, for violation of these Terms or for any other reason, or based on the discretion of
                EVERLENS Company. EVERLENS Company reserves the right at all times to disclose any
                information as it deems necessary to satisfy any applicable law, regulation, legal process or
                governmental request, or to edit, refuse to post or to remove any information or materials, in whole
                or in part, in EVERLENS’s Company sole discretion. Collectibles or other materials uploaded to the
                Offerings may be subject to limitations on usage, reproduction and/or dissemination; Users are
                responsible for adhering to such limitations if you acquire a Collectible. Users must always use
                caution when giving out any personally identifiable information through any of the Offerings.
                EVERLENS Company does not control or endorse the content, messages or information found in
                any Offerings and EVERLENS Company specifically disclaims any liability with regard to the
                Offerings and any actions resulting from any User’s participation in any Offerings
              </p>



              <p>
                <strong>2.4 Monitoring</strong>. EVERLENS Company shall have the right, but not the obligation, to monitor the
                content of the Offerings, to determine compliance with this TOU and any operating rules established
                by EVERLENS Company and to satisfy any law, regulation or authorized government request.
                EVERLENS Company shall have the right in its sole discretion to edit, refuse to post or remove any
                material submitted to or posted through the Offerings. Without limiting the foregoing, EVERLENS
                Company shall have the right to remove any material that EVERLENS Company, in its sole
                discretion, finds to be in violation of the provisions hereof or otherwise objectionable.
              </p>




              <p>
                <strong>2.5 Copyright Notice.</strong> “EVERLENS” and its logos are trademarks of EVERLENS Company. All rights
                reserved. All other trademarks appearing in the Offerings are the property of their respective owners.
              </p>




              <p>
                <strong> 2.6 Privacy Policy.</strong> To access the Offerings, a User must explicitly consent to EVERLENS Company’s
                privacy and data security practices, which can be found by visiting our privacy policy at
                https://EVERLENS.io/privacy.
              </p>




              <p>
                <strong>3. THE EVERLENS DEX SYSTEM AND EVERLENS APPS</strong>
              </p>

              <p>
                <strong> 3.1 Nature of EVERLENS DEX System.</strong> The EVERLENS DEX System is a public software utility
                deployed on Ethereum, which is accessible directly through any Ethereum node or indirectly through
                any compatible Ethereum “wallet” application which interacts with such a node. Through the
                EVERLENS DEX System, any person may create, buy, sell and transfer Collectibles.
              </p>



              <p>
                <strong> 3.2 Relationship of EVERLENS DEX System and EVERLENS Apps.</strong>{" "}
                Interacting with the EVERLENS DEX System does not require use of the EVERLENS Apps, but the EVERLENS Apps provide a
                potentially more convenient and user-friendly method of reading and displaying data (including
                Collectible Metadata) from the EVERLENS DEX System and generating standard transaction
                messages compatible with the EVERLENS DEX System. Interacting with the EVERLENS DEX
                System through the EVERLENS Apps requires use of an independent, User-operated Ethereum
                wallet application through which the user may broadcast the transaction message to Ethereum for
                processing by Ethereum nodes. The EVERLENS Apps may be used to generate standard
                transaction messages for interacting with the EVERLENS DEX System and transmitting those
                messages to the wallet application. Through the wallet application, a User may broadcast the
                transaction message to Ethereum for processing by Ethereum nodes. Assuming normal operation of
                the wallet and the relevant Ethereum nodes, the Ethereum nodes should utilize such transaction
                messages to make function calls against the relevant bytecode and ultimately include the results of
                such computations in an Ethereum transaction block, thus effectuating an interaction with the
                EVERLENS DEX System.
              </p>





              <p>
                <strong>   3.3 License to Use the EVERLENS Platform. </strong>Each User, subject to and conditioned upon such
                User’s acceptance of and adherence to these Terms, is hereby granted a nontransferable, personal,
                non-sub- licensable license to use the EVERLENS DEX System and EVERLENS Apps for their
                intended purposes.

              </p>





              <p>
                <strong>  3.4 Alterations to EVERLENS DEX System.</strong>  EVERLENS Company may from time to time alter the
                list of smart contracts which are included in the EVERLENS DEX System by adding or removing
                bytecode addresses from the official list of smart contracts supported by the EVERLENS Apps,
                including pursuant to upgrades, forks, security incident responses or chain migrations. In the event
                any bytecode is removed from the EVERLENS DEX System, users would no longer be able to
                interact with or read the data that is associated such bytecode through the EVERLENS Apps. It is
                intended (though cannot be guaranteed) that Users would continue to be able to interact with such
                bytecode directly through any Ethereum node or indirectly through any compatible Ethereum wallet
                application, but such interactions may require technical expertise beyond those of most Users.
              </p>



              <p>
                <strong> 3.5 Content.</strong> All content on the EVERLENS Platform is created by Users. EVERLENS Company
                makes no representations or warranties as to the quality, origin, or ownership of any content found in
                the Offerings. EVERLENS Company shall not be liable for any errors, misrepresentations, or
                omissions in, of, and about, the content, nor for the availability of the content. EVERLENS Company
                shall not be liable for any losses, injuries, or damages from the purchase, inability to purchase,
                display, or use of content.
              </p>




              <p>
                <strong> 3.6 </strong> Notices for Claims of Copyright Infringement.
              </p>

              <p>
                <strong>(a)</strong> <u> Takedown Requests.</u> EVERLENS Company will respond to notices of alleged copyright
                infringement under Mauritius. If a User or other person believes that their intellectual property rights
                have been infringed, please notify us immediately. For EVERLENS Company to respond, the
                complaint must provide the following information:
              </p>




              <div className="left-space">
                <p>
                  <strong>(i)</strong> a physical or electronic signature of the copyright owner or of a person authorized to act on behalf
                  of the copyright owner;
                </p>





                <p>
                  <strong>(ii)</strong>  a clear identification of the copyrighted work that is claimed to be infringing;
                </p>



                <p>
                  <strong>(iii)</strong> identification of the online material that is claimed to be infringing, including, if possible, a URL
                  representing a link to the material on the EVERLENS website;
                </p>



                <p>
                  <strong>(iv)</strong> information sufficient to permit EVERLENS Company to contact the complainant, such as email
                  address, physical address, and/or telephone number;
                </p>





                <p>
                  <strong>(v) </strong> a statement that the complainant has a good-faith belief that the relevant material is being used
                  in a way that is not authorized by the copyright owner, its agent, or under the law; and
                </p>



                <p>
                  <strong>(vi) </strong> a statement, made under penalty of perjury, that the above information is accurate, and the
                  complainant is the copyright owner or is authorized to act on behalf of the copyright owner.
                </p>





                <p>
                  <strong> (b) </strong> <u>Submission of Takedown Requests.</u> Users may submit their takedown requests using the
                  following contact information:
                </p>



                <p>EVERLENS, Inc.
                </p>

                {/* <p>
                  Attn. DMCA Notice 1209 Orange Street Corporation Trust CenterWilmington, DE 19801
                </p> */}

                <p>Email:contact@everlens.io</p>

                <p>
                  <strong> (c) </strong><u>Counter-Notices</u> If a User believes that a User’s material has been removed by mistake or
                  misidentification, the User may provide EVERLENS Company with a written counter- notification
                  containing the following information:
                </p>





                <div className="left-space">
                  <p>
                    <strong>(i) </strong>the User’s name, address, telephone number, and email address;

                  </p>

                  <p>
                    <strong>(ii) </strong> a description of the material that was removed, along with the URL where the content was posted
                    in the Offerings prior to its removal;
                  </p>

                  <p>
                    <strong>(iii) </strong> the following statement: “I swear, UNDER PENALTY OF PERJURY, that I have a good-faith
                    belief that the material was removed or disabled due to a mistake or misidentification of the material
                    to be removed or disabled”;
                  </p>

                  <p>
                    <strong>(iv) </strong> a statement that the User consents to the jurisdiction of Mauritius in which your address is
                    located, or if the User’s address is outside of the Mauritius, any jurisdiction in which the User may be
                    properly served, and that the User will accept service of process from the person (or their agent)
                    who filed the original notice; and
                  </p>

                  <p>
                    <strong>(v) </strong> the User’s electronic or physical signature.
                  </p>
                </div>
                <p>
                  Upon receipt of a valid counter notification, EVERLENS Company will forward it to the party who
                  submitted the original notification. The original party (or their representative) will then have ten (10)
                  days to notify us that he or she has filed legal action relating to the allegedly infringing material. If
                  EVERLENS

                </p>

                <p>
                  Company does not receive any such notification within 10 days, EVERLENS Company may restore
                  the material to the Offerings.

                </p>

                <p>
                  <strong>(d) </strong> <u> Repeat Infringers. </u>EVERLENS Company reserves the right to terminate the accounts or block
                  usage of the Offerings of any party who is a repeat infringer or who is repeatedly charged with
                  infringement
                </p>



                <h5 className="mt-4 mb-4">
                  <strong>4. </strong> 4. FEES, COMMISSIONS, ROYALTIES AND OTHER CHARGES]

                </h5>

                <p>
                  <strong>4.1</strong> Fees.Creating, buying, selling or transferring Collectibles may be subject to fees, commissions,
                  royalties and other charges (“Fees”) established from time to time in the sole discretion of
                  EVERLENS Company, a Collectible creator or participants in the Ethereum ecosystem. On the date
                  of initial publication of these Terms, Fees include: (a) service fees established by and payable to
                  EVERLENS Company; (b) commissions on secondary sales of Collectibles, established by and
                  payable to the creators of such Collectibles; and (c) “gas” (fees paid to Ethereum miners in ETH
                  through Users’ independent wallet applications as consideration for mining the Users’ transactions).
                  Service fees may be adjusted from time to time in the sole discretion of EVERLENS Company.
                  Commissions are set in the sole discretion of the Collectible creator and may range from 0% to
                  100%; in the event that a User acquires a Collectible with a 100% commission, the User will be
                  unable to collect any proceeds of a sale of that Collectible on the EVERLENS Platform. “Gas” fees
                  are set by the User through the User’s independent Ethereum wallet application, based on the
                  market conditions on Ethereum.
                </p>



                <p>
                  <strong>4.2 </strong> <strong> Forms of Payment. </strong>Fees may be paid or payable solely in the manner determined in the sole
                  discretion of EVERLENS Company. On the date of initial publication of these Terms, all Fees must
                  be paid in ETH.
                </p>

                <p>
                  <strong>4.3 </strong> <strong>Finality; No Refunds.</strong> All transactions involving the Offering are final. All Fees relating are
                  non-refundable except at the sole discretion of EVERLENS Company (for service fees and other
                  fees within its control) or applicable third parties (Collectibles creators, Ethereum miners, etc.).
                </p>



                <h5 className="mt-4 mb-4">
                  <strong>5. </strong> ACCEPTABLE & PROHIBITED USES OF THE OFFERINGS
                </h5>

                <p>
                  <strong>5.1 </strong> <strong>Acceptable Uses. </strong> The EVERLENS Platform and other Offerings are reserved exclusively for
                  lawful consumer entertainment and artistic purposes (the <strong>“Permitted Uses”)</strong>.
                </p>



                <p>
                  <strong>5.2 </strong> <strong>Prohibited Uses.</strong> Users must not, directly or indirectly:
                </p>

                <div className="left-space">
                  <p>
                    <strong>(a) </strong> employ any device, scheme or artifice to defraud, or otherwise materially mislead, EVERLENS
                    Company, the EVERLENS DAO or any member of the EVERLENS Community, including by
                    impersonating or assuming any false identity;
                  </p>

                  <p>
                    <strong>(b) </strong> engage in any act, practice or course of business that operates or would operate as a fraud or
                    deceit upon the EVERLENS Company, the EVERLENS DAO or any member of the EVERLENS
                    Community;
                  </p>
                  <p>
                    <strong>(c) </strong>  violate, breach or fail to comply with any applicable provision of these Terms or any other terms
                    of service, privacy policy, trading policy or other contract governing the use of any the Offerings or
                    any relevant NFTs or Collectibles;
                  </p>
                  <p>
                    <strong>(d) </strong> use the Offerings by or on behalf of a competitor of the EVERLENS Company or competing
                    platform or service for the purpose of interfering with the Offerings to obtain a competitive
                    advantage;
                  </p>
                  <p>
                    <strong>(e) </strong> engage or attempt to engage in or assist any hack of or attack on the EVERLENS Apps,
                    EVERLENS DEX System, EVERLENS DAO or any member of the EVERLENS Community,
                    including any “sybil attack”, “DoS attack” or “griefing attack” or theft of Collectibles, ELEN or funds,
                    or upload files that contain viruses, Trojan horses, worms, time bombs, cancelbots, corrupted files,
                    or any other similar software or programs that may damage the operation of another’s computer or
                    property or interfere with the Offerings;
                  </p>
                  <p>
                    <strong>(f) </strong> create, buy, sell or use any Collectible that infringes or in a manner infringing the copyright,
                    trademark, patent, trade secret or other intellectual property or other proprietary rights of others, or
                    upload, or otherwise make available, files that contain images, photographs, software or other
                    material protected by intellectual property laws (including, copyright or trademark laws) or rights of
                    privacy or publicity unless the applicable User owns or controls the rights thereto or has received all
                    necessary consent to do the same;

                  </p>
                  <p>
                    <strong>(g) </strong> commit any violation of applicable laws, rules or regulations;
                  </p>
                  <p>
                    <strong>(h) </strong> use the Offerings in connection with surveys, contests, pyramid schemes, chain letters, junk
                    email, spamming, or any duplicative or unsolicited messages (commercial or otherwise);

                  </p>
                  <p>
                    <strong>(i) </strong> defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of
                    privacy and publicity) of other;
                  </p>
                  <p>
                    <strong>(j) </strong>  publish, post, distribute or disseminate any profane, obscene, pornographic indecent or unlawful
                    content, pictures, topic, name, material or information;
                  </p>
                  <p>
                    <strong>(k) </strong> engage in or knowingly facilitate any “front-running,” “wash trading,” “pump and dump trading,”
                    “ramping,” “cornering” or fraudulent, deceptive or manipulative trading activities, including:
                  </p>
                  <div className="left-to-space">
                    <p>
                      <strong>(i) </strong> trading a Collectible at successively lower or higher prices for the purpose of creating or inducing
                      a false, misleading or artificial appearance of activity in such Collectible, unduly or improperly
                      influencing the market price for such Collectible or establishing a price which does not reflect the
                      true state of the market in such Collectible;
                    </p>

                    <p>
                      <strong>(ii) </strong> for the purpose of creating or inducing a false or misleading appearance of activity in a Collectible
                      or creating or inducing a false or misleading appearance with respect to the market in a Collectible:
                    </p>

                    <p>
                      <strong>(A) </strong> executing or causing the execution of any transaction in a Collectible which involves no material
                      change in the beneficial ownership thereof; or
                    </p>

                    <p>
                      <strong>(B) </strong> entering any order for the purchase or sale of a Collectible with the knowledge that an order of
                      substantially the same size, and at substantially the same price, for the sale of such Collectible, has
                      been or will be entered by or for the same or different parties; or
                    </p>

                    <p>
                      <strong>(iii) </strong>  participating in, facilitating, assisting or knowingly transacting with any pool, syndicate or joint
                      account organized for the purpose of unfairly or deceptively influencing the market price of a
                      Collectible;

                    </p>
                  </div>
                  <p>
                    <strong>(I) </strong> utilize the Offerings to transact in securities, commodities futures, trading of commodities on a
                    leveraged, margined or financed basis, binary options (including prediction-market transactions), real
                    estate or real estate leases, equipment leases, debt financings, equity financings or other similar
                    transactions;
                  </p>
                  <p>
                    <strong>(m) </strong> utilize the Offerings to buy, sell or advertise personal, professional or business services;
                  </p>
                  <p>
                    <strong>(n) </strong> utilize the Offerings to engage in token-based or other financings of a business, enterprise,
                    venture, DAO, software development project or other initiative, including ICOs, IEOs, “yield farming”
                    or other token-based fundraising events; or
                  </p>
                  <p>
                    <strong>(o) </strong> utilize the Offerings primarily as a general-purpose communications or messaging or social
                    networking platform.

                  </p>
                </div>
                <p>
                  The foregoing matters are referred to herein as  <strong>“Prohibited Uses”</strong>.
                </p>
                <h5 className="mt-4 mb-4">
                  <strong>6. </strong> THE EVERLENS DAO & ELEN VOTING
                </h5>
                <p>
                  <strong>6.1 </strong> <strong>Purposes of EVERLENS DAO. </strong> The EVERLENS DAO was established by EVERLENS Company
                  to foster community among the artists, collectors, traders and other users of the EVERLENS
                  Platform (the “EVERLENS Community”) and provide them with a voice in how the EVERLENS
                  Platform is run, maintained, marketed, improved and otherwise governed
                  <strong>(“Governance Decisions”)</strong>.
                </p>


                <p>
                  <strong>6.2 </strong> <strong>Purposes of ELEN. </strong>  ELEN are intended to be utilized by the EVERLENS Community to
                  participate in the EVERLENS DAO, including by voting on proposed Governance Decisions
                  (“Governance Proposals”). ELEN may from time to time be given additional uses as determined by
                  EVERLENS Company (in non-binding consultation with the EVERLENS DAO) (during the Signaling
                  Era (as defined below)) or by the EVERLENS DAO alone (during the Autonomous Era (as defined
                  below)).
                </p>


                <p>
                  <strong>6.3 </strong> <strong>Evolution of EVERLENS DAO. </strong> The EVERLENS DAO is intended to increase its autonomy and
                  responsibility for influencing Governance Decisions over time. The anticipated evolution of the
                  EVERLENS DAO will be divided into two main periods or ‘eras’
                </p>




                <div className="left-space">
                  <p>
                    <strong>(a) </strong> <u>Signaling Era.</u>The <strong>“Signaling Era”</strong>{" "}
                    began on the date ELEN were first distributed to the
                    EVERLENS Community and currently remains ongoing. During the Signaling Era, EVERLENS
                    Company will retain ultimate operational and governance authority over the EVERLENS Platform
                    while consulting in its discretion with the EVERLENS DAO regarding potential Governance
                    Decisions (expressed as Governance Proposals made by EVERLENS Company to the EVERLENS
                    DAO) expected to have a material direct impact on the interests of the EVERLENS Community.
                    During the Signaling Era, the actions and determinations of the EVERLENS DAO will be treated as
                    non-binding, advisory signals to EVERLENS Company regarding the will and interests of the
                    EVERLENS Community, but will not be obligated to support or comply with the actions and
                    determinations of the EVERLENS DAO. Nevertheless, EVERLENS Company currently anticipates
                    abiding by the EVERLENS DAO’s majority- supported determinations regarding Governance
                    Proposals that are made by EVERLENS Company during the Signaling Era, provided that the
                    resulting Governance Decisions would not reasonably be expected to result in a violation of
                    applicable legal requirement or have a material adverse impact on the EVERLENS Platform or
                    EVERLENS Company. During the Signaling Era, ELEN holders may also initiate their own
                    Governance Proposals (i.e., independent of EVERLENS Company) to the EVERLENS DAO.
                    EVERLENS Company will in good faith consider the merits of any such Governance Proposal that
                    receives majority support of the EVERLENS DAO during the Signaling Era, but EVERLENS
                    Company cannot currently anticipate the likelihood that EVERLENS Company will choose to support
                    or give effect to such independent Governance Proposals.
                  </p>



                  <p>
                    <strong>(b) </strong> <u>Autonomous Era.</u> During The “Autonomous Era” to the maximum extent reasonably practicable,
                    except as may be limited by applicable legal requirements, the EVERLENS DAO will have exclusive
                    ultimate authority to make Governance Decisions, including the determination of the smart contracts
                    utilized in the EVERLENS DEX System, the amount and types of fees to be charged to users of the
                    EVERLENS Platform and the use of such fees for funding the operation and maintenance by
                    EVERLENS Company of the EVERLENS Apps or the operation and maintenance by other entities
                    or persons of alternative applications for interacting with the EVERLENS DEX System. The transition
                    to the Autonomous Era will occur at a time to be determined in the sole discretion of EVERLENS
                    Company, subject to confirmation by the EVERLENS DAO that it is prepared to assume all
                    responsibility for future Governance Decisions. EVERLENS Company’s plan for transitioning to the
                    Autonomous Era currently remains under active research and development, but EVERLENS
                    Company currently anticipates that at least the following technologies, measures, conditions and
                    agreements must be initialized and tested before the Autonomous Era can begin:
                  </p>




                  <div className="left-to-space">
                    <p>
                      <strong>(i) </strong> broad distribution of ELEN among a diverse cross-section of members of the EVERLENS
                      Community;

                    </p>
                    <p>
                      <strong>(ii) </strong> the establishment and publication of legally binding EVERLENS DAO Bylaws setting forth the
                      principles, rules, procedures, standards of conduct and other relevant agreements and
                      understandings determining the rights and obligations of ELEN holders, the voting of ELEN and the
                      operation, coordination and decision-making of the EVERLENS DAO;

                    </p>
                    <p>
                      <strong>(iii) </strong> the completion of an initial compliance plan for abiding by tax laws and other laws, rules and
                      regulations applicable to the EVERLENS DAO;
                    </p>
                    <p>
                      <strong>(iv) </strong> upgrading the EVERLENS Governance Module to include a system of EVERLENS DAO Smart
                      Contracts facilitating trust-reduced compliance with the EVERLENS DAO Bylaws and escrowing of
                      fees generated by the EVERLENS DEX System; and
                    </p>
                    <p>
                      <strong>(v) </strong>the negotiation and signing of written commercial agreements between the EVERLENS DAO, on
                      the one hand, and EVERLENS Company and/or other technology companies, on the other hand,
                      through which the EVERLENS DAO can provide funding and direction to such companies for the
                      development, maintenance and operation of the EVERLENS Apps, the licensing of relevant
                      intellectual property such as branding-related trade dress and logos currently owned by EVERLENS
                      Company, and/or other applications and infrastructure necessary or useful for interacting with the
                      EVERLENS DEX System.

                    </p>
                  </div>
                  <p>
                    <strong>(c) </strong> <strong>Current EVERLENS Governance Module.</strong> As of the date of publication of these Terms, the
                    EVERLENS Governance Module is configured as follows:
                  </p>
                  <div className="left-to-space">
                    <p>
                      <strong>(i) </strong> potential Governance Proposals that may be of interest to the EVERLENS DAO are typically first
                      discussed and refined on gov.EVERLENS.io;
                    </p>
                    <p>
                      <strong>(ii) </strong> when ready to be formally considered by the EVERLENS DAO, a Governance Proposal is
                      entered and made available for voting on https://snapshot.page/#/EVERLENS/
                    </p>
                    <p>
                      <strong>(iii) </strong> during the voting period (typically seven days), eligible ELEN holders may vote their eligible
                      ELEN on the Governance Proposal through https://snapshot.page/#/EVERLENS/, with each whole
                      eligible ELEN entitling the holder to a single vote on each Governance Proposal (and each fractional
                      eligible ELEN entitling the holder to a corresponding fractional vote);

                    </p>
                    <p>
                      <strong>(iv) </strong> eligibility of ELEN to vote on each Governance Proposal is based on a “snapshot” of ELEN
                      account balances on Ethereum at a specific block of the Ethereum blockchain prior to
                      commencement of the voting period for such Governance Proposal;
                    </p>
                    <p>
                      <strong>(v) </strong> the official determination of the EVERLENS DAO on the Governance Proposal will be the
                      determination supported by a simple majority of the ELEN that were actually voted on such
                      Governance Proposal;

                    </p>
                    <p>
                      <strong>(vi) </strong> there is no quorum (minimum number of ELEN voting) required for the EVERLENS DAO to
                      make a determination on a Governance Proposal; and
                    </p>
                    <p>
                      <strong>(vii) </strong> voters are only pseudonymously identifiable by Ethereum address; accordingly, there is no
                      reasonably practicable method of verifying the identities or interests of voters and there are no
                      express rules regarding recusal of voters or disqualification of votes based on conflicts of interest or
                      other similar factors; however, EVERLENS Company may take any evidence of conflicts of interest
                      or other issues regarding specific voters that comes to the attention of EVERLENS Company into
                      account in determining whether and how to implement determinations of the EVERLENS DAO in
                      Governance Decisions.

                    </p>
                  </div>
                  <h5 className="mt-4 mb-4">
                    <strong>7. </strong> SUPPLY, ALLOCATION AND DISTRIBUTION OF ELEN
                  </h5>
                  <p>
                    <strong>7.1 </strong> <strong>1 EVERLENS Supply. </strong> The total number of ELEN is fixed at 1,500,000,000 ELEN <strong>(the “Total Supply”)</strong>.
                  </p>
                  <p>
                    <strong>7.2 </strong>{" "}
                    <strong> Overview of ELEN Allocation and Distribution.
                    </strong>
                  </p>
                  <div className="left-space">
                    <p>
                      <strong>(a) </strong> EVERLENS Company has published its intention to allocate the TotalSupply as follows:

                    </p>
                    <div className="left-to-space">
                      <p>
                        <strong>(i) </strong> 30% of the TotalSupply (the “ReserveSupply”) will be allocated to EVERLENS Company’s
                        affiliates, founders, employees and other personnel (including grant recipients or technology
                        development (sub-)contractors, if applicable) and equity investors;
                      </p>
                      <p>
                        <strong>(ii) </strong> 10% of the TotalSupply (the “AirdropSupply”) will be allocated to bona fide active users the of
                        EVERLENS Apps (based on their trading volume prior to announcement of ELEN) and holders of
                        Collectibles eligible to trade on the EVERLENS Apps that were previously involved in sales
                        documented on Dune Analytics; and
                      </p>
                      <p>
                        <strong>(iii) </strong>  60% of the TotalSupply (the “MiningSupply”) will be allocated to users who engage in bona fide,
                        good faith, arm’s-length trading of Collectibles on the EVERLENS Apps, based on the transaction
                        value of such trading.

                      </p>
                    </div>
                    <p>
                      <strong>(b) </strong> All questions concerning the interpretation and implementation of such allocation principles and
                      the eligibility of a given person to receive ELEN or the specific amount of ELEN to be received by an
                      eligible person out of the ReserveSupply, AirdropSupply, MiningSupply or otherwise, as applicable,
                      shall be determined: (i) during the Signaling Era, in the sole and absolute discretion of EVERLENS
                      Company or a special purpose vehicle or foundation established by the EVERLENS Company to
                      hold ELEN; and (ii) during the Autonomous Era, by the EVERLENS DAO
                    </p>
                  </div>
                  <p>
                    <strong>7.3 </strong>{" "}
                    <strong>Certain Eligibility Requirements of ELEN Recipients.</strong> Notwithstanding anything to the contrary set
                    forth in Section 7.2(a), and without limiting the generality of Section 7.2(b), EVERLENS Company
                    shall not be required or obligated to distribute any ELEN (out of the AirDropSupply, MiningSupply, or
                    otherwise) to, or honor the voting or other rights of ELEN held by, any person who, as determined in
                    the sole and absolute discretion of EVERLENS Company, directly or indirectly, in connection with the
                    use of the EVERLENS Platform, EVERLENS Apps, EVERLENS DEX System, EVERLENS DAO,
                    ELEN or any transactions involving NFTs or Collectibles, has violated these Terms, including any
                    use of the Offerings set forth in Section 5.2. Examples of disqualifying events include engaging in
                    front- running,” “wash trading,” “pump and dump trading,” “ramping,” “cornering” or other fraudulent,
                    deceptive or manipulative trading activities, or engaging in or facilitating ICOs or other financing
                    transactions, for the purpose of increasing such user’s ELEN rewards or otherwise
                  </p>

                  <h5 className="mt-4 mb-4">
                    <strong>8. </strong> REPRESENTATIONS AND WARRANTIES OF ELEN RECIPIENTS
                  </h5>
                  <p>
                    Each User hereby represents and warrants to EVERLENS Company that the following statements
                    and information are accurate and complete at all relevant times. In the event that any such
                    statement or information becomes untrue as to a User, User shall immediately divest and cease
                    using all ELEN and cease accessing and using all other Offerings.

                  </p>
                  <p>
                    <strong>8.1 </strong> <strong>Status. </strong> If User is an individual, User is of legal age in the jurisdiction in which User resides (and
                    in any event is older than thirteen years of age) and is of sound mind. If User is a business entity,
                    User is duly organized, validly existing and in good standing under the laws of the jurisdiction in
                    which it is organized, and has all requisite power and authority for a business entity of its type to
                    carry on its business as now conducted.

                  </p>
                  <p>
                    <strong>8.2 </strong> <strong> Power and Authority. </strong>User has all requisite capacity, power and authority to accept the terms and
                    conditions of these Terms and to carry out and perform its obligations under these Terms. These
                    Terms constitute a legal, valid and binding obligation of User enforceable against User in accordance
                    with its terms
                  </p>
                  <p>
                    <strong>8.3 </strong> <strong> No Conflict; Compliance with law. </strong> User agreeing to these Term and buying, selling holding, using
                    or receiving ELEN does not constitute, and would not reasonably be expected to result in (with or
                    without notice, lapse of time, or both) a breach, default, contravention or violation of any law
                    applicable to User, or contract or agreement to which User is a party or by which User is bound.
                  </p>
                  <p>
                    <strong>8.4 </strong> <strong> Absence of Sanctions. </strong> User is not, (and, if User is an entity, User is not owned or controlled by
                    any other person who is), and is not acting on behalf of any other person who is, identified on any list
                    of prohibited parties under any law or by any nation or government, state or other political
                    subdivision thereof, any entity exercising legislative, judicial or administrative functions of or
                    pertaining to government such as the lists maintained by the United Nations Security Council, the
                    U.S. government (including the U.S. Treasury Department’s Specially Designated Nationals list and
                    Foreign Sanctions Evaders list), the European Union (EU) or its member states, and the government
                    of a User home country. User is not, (and, if User is an entity, User is not owned or controlled by any
                    other person who is), and is not acting on behalf of any other person who is, located, ordinarily
                    resident, organized, established, or domiciled in Cuba, Iran, North Korea, Sudan, Syria, the Crimea
                    region (including Sevastopol) or any other country or jurisdiction against which the U.S. maintains
                    economic sanctions or an arms embargo. The tokens or other funds a User use to participate in the
                    EVERLENS Platform or acquire Collectibles or ELEN are not derived from, and do not otherwise
                    represent the proceeds of, any activities done in violation or contravention of any law.
                  </p>
                  <p>
                    <strong>8.5 </strong>{" "}
                    <strong> No Claim, Loan, Ownership Interest or Investment Purpose. </strong> User understands and agrees that
                    the User’s purchase, sale, holding, receipt and use of ELEN and the other Offerings does not: 11(a)
                    represent or constitute a loan or a contribution of capital to, or other investment in EVERLENS
                    Company or any business or venture; (b) provide User with any ownership interest, equity, security,
                    or right to or interest in the assets, rights, properties, revenues or profits of, or voting rights
                    whatsoever in, EVERLENS Company or any other business or venture; and (c) create or imply or
                    entitle User to the benefits of any fiduciary or other agency relationship between EVERLENS
                    Company or any of its directors, officers, employees, agents or affiliates, on the on hand, and User,
                    on the other hand. User is not entering into these Terms or buying, selling, holding receiving or using
                    ELEN for the purpose of making an investment with respect to EVERLENS Company or its
                    securities, but solely wishes to use the EVERLENS Platform for its intended purposes and
                    participate in the EVERLENS DAO in order to participate in the protection and improvement of the
                    use and enjoyment of the EVERLENS Platform for such purposes. User understands and agrees
                    that EVERLENS Company will not accept or take custody over any Collectibles, ELEN,
                    cryptocurrencies or other assets of User and has no responsibility or control over the foregoing.
                  </p>
                  <p>
                    <strong>8.6 </strong> <strong> Non-Reliance. </strong>  User is knowledgeable, experienced and sophisticated in using and evaluating
                    blockchain and related technologies and assets, including Ethereum, NFTs, Collectibles and “smart
                    contracts” (bytecode deployed to Ethereum or another blockchain). User has conducted its own
                    thorough independent investigation and analysis of the EVERLENS Platform, ELEN and the other
                    matters contemplated by these Terms, and has not relied upon any information, statement, omission,
                    representation or warranty, express or implied, written or oral, made by or on behalf of EVERLENS
                    Company in connection therewith, except as expressly set forth by EVERLENS Company in these
                    Terms.

                  </p>
                  <h5 className="mt-4 mb-4">
                    <strong>9. </strong> RISKS, DISCLAIMERS AND LIMITATIONS OF LIABILITY
                  </h5>
                  <p>
                    <strong>9.1 </strong>{" "}
                    <strong>No Consequential, Incidental or Punitive Damages. </strong>
                    Notwithstanding anything to the contrary
                    contained in these Terms, EVERLENS Company shall not be liable to any person, whether in
                    contract, tort (including pursuant to any cause of action alleging negligence), warranty or otherwise,
                    for special, incidental, consequential, indirect, punitive or exemplary damages (including but not
                    limited to lost data, lost profits or savings, loss of business or other economic loss) arising out of or
                    related to these Terms, whether or not EVERLENS Company has been advised or knew of the
                    possibility of such damages, and regardless of the nature of the cause of action or theory asserted.
                  </p>
                  <p>
                    <strong>9.2 </strong> <strong>Limitation of Liability. </strong> EVERLENS Company’s liability for damages to each User shall in all cases
                    be limited to, and under no circumstances shall exceed, EVERLENS Company’s service fees
                    actually received by EVERLENS Company from such User.
                  </p>
                  <p>
                    <strong>9.3 </strong> <strong> Disclaimer of Representations.</strong> The Offerings are being provided on an “AS IS” and “AS
                    AVAILABLE” basis. To the fullest extent permitted by law, EVERLENS Company is not making, and
                    hereby disclaims, any and all information, statements, omissions, representations and warranties,
                    express or implied, written or oral, equitable, legal or statutory, in connection with the Offerings and
                    the other matters contemplated by these Terms, including any representations or warranties of title,
                    non- infringement, merchantability, usage, security, uptime, reliability, suitability or fitness for any
                    particular purpose, workmanship or technical quality of any code or software used in or relating to
                    the Offerings. User acknowledges and agrees that use of the Offerings is at the User’s own risk.
                  </p>
                  <p>
                    <strong>9.4 </strong>{" "}
                    <strong>
                      {" "}
                      No Responsibility for Collectibles; No Guarantee of Uniqueness or IP.
                    </strong>
                    EVERLENS Company has
                    no responsibility for the Collectibles created or traded by Users on the EVERLENS Platform.
                    EVERLENS Company does not investigate and cannot guarantee or warrant the authenticity,
                    originality, uniqueness, marketability, legality or value of any Collectible created or traded by Users
                    on the EVERLENS Platform.

                  </p>
                  <p>
                    <strong>9.5 </strong> <strong> No Professional Advice or Liability. </strong> All information provided by or on behalf of EVERLENS
                    Company is for informational purposes only and should not be construed as professional, accounting
                    or legal advice. Users should not take or refrain from taking any action in reliance on any information
                    contained in these Terms or provided by or on behalf of EVERLENS Company. Before Users make
                    any financial, legal, or other decisions involving the Offerings, Users should seek independent
                    professional advice from persons licensed and qualified in the area for which such advice would be
                    appropriate.

                  </p>
                  <p>
                    <strong>9.6 </strong> <strong> Limited Survival Period for Claims.</strong> Any claim or cause of action a User may have or acquire in
                    connection with the Offerings or any of the other matters contemplated by these Terms shall survive
                    for the shorter of, and may be brought against EVERLENS Company solely prior to: (a) the
                    expiration of the statute of limitations applicable thereto; and (b) the date that is six months after the
                    date on which the facts and circumstances giving rise to such claim or cause of action first arose.
                  </p>
                  <p>
                    <strong>9.7 </strong> <strong> Third-Party Offerings and Content.</strong>
                    References, links or referrals to or connections with or
                    reliance on third-party resources, products, services or content, including smart contracts developed
                    or operated by third parties, may be provided to Users in connection with the Offerings. In addition,
                    third parties may offer promotions related to the Offerings. EVERLENS Company does not endorse
                    or assume any responsibility for any activities of or resources, products, services, content or
                    promotions owned, controlled, operated or sponsored by third parties. If Users access any such
                    resources, products, services or content or participate in any such promotions, Users do so solely at
                    their own risk. Each User hereby expressly waives and releases EVERLENS Company from all
                    liability arising from User’s use of any such resources, products, services or content or participation
                    in any such promotions. User further acknowledges and agrees that EVERLENS Company shall not
                    be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused
                    by or in connection with use of or reliance on any such resources, products, services, content or
                    promotions from third parties.
                  </p>
                  <p>
                    <strong>9.8 </strong>{" "}
                    <strong>  Certain Uses and Risks of Blockchain Technology.</strong>
                  </p>
                  <div className="left-space">
                    <p>
                      <strong>(a) </strong> <u> (a) Use of Blockchain Technology.</u> EVERLENS Company utilizes experimental cryptographic
                      technologies and blockchain technologies, including tokens, cryptocurrencies, stablecoins, “smart
                      contracts,” consensus algorithms, voting systems and distributed, decentralized or peer-to-peer
                      networks or systems in performing the Offerings. Each User acknowledges and agrees that such
                      technologies are novel, experimental, and speculative, and that therefore there is significant
                      uncertainty regarding the operation and effects and risks thereof and the application of existing law
                      thereto
                    </p>
                    <p>
                      <strong>(b) </strong> <u> Certain Risks of Blockchain Technology.</u> The technology utilized in delivering the Offerings
                      depends on public peer-to-peer networks such as Ethereum that are not under the control or
                      influence of EVERLENS Company and are subject to many risks and uncertainties. Such
                      technologies include the EVERLENS DEX System, which EVERLENS Company may have limited or
                      no ability to change, other than ceasing to support certain “smart contracts” and adding support for
                      new “smart contracts”. Users are solely responsible for the safekeeping of the private key associated
                      with the blockchain address used to participate in the Offerings. EVERLENS Company will not be
                      able to restore or issue any refund in respect of any Collectibles or ELEN due to lost private keys. If
                      a User are not able to spend or use a Collectible or ELEN due to loss or theft of the corresponding
                      private key or otherwise, a User will be unable to exercise their rights with respect to such Collectible
                      or ELEN.

                    </p>
                    <p>
                      <strong>(c) </strong> <u> Certain Risks of Smart Contract Technology. </u>
                      Collectibles, ELEN and other digital assets relevant
                      to the EVERLENS Platform depend on the EVERLENS DEX System or other smart contracts
                      deployed to Ethereum, some of which may be coded or deployed by persons other than EVERLENS
                      Company. Once deployed to Ethereum, the code of smart contracts, including the EVERLENS
                      System, cannot be modified. In the event that the EVERLENS System or other smart contracts are
                      adversely affected by malfunctions, bugs, defects, malfunctions, hacking, theft, attacks, negligent
                      coding or design choices, or changes to the protocol rules of Ethereum, Users may be exposed to a
                      risk of total loss and forfeiture of all Collectibles, ELEN and other relevant digital assets. EVERLENS
                      Company assumes no liability or responsibility for any of the foregoing matters, except as otherwise
                      expressly provided by these Terms or required by applicable law
                    </p>
                    <p>
                      <strong>(d) </strong> <u> Asset Prices. </u> The fiat-denominated prices and value in public markets of assets such as ETH,
                      NFTs, Collectibles and ELEN have historically been subject to dramatic fluctuations and are highly
                      volatile. As relatively new products and technologies, blockchain-based assets are not widely
                      accepted as a means of payment for goods and services. A significant portion of demand for these
                      assets is generated by speculators and investors seeking to profit from the short- or long-term
                      holding of blockchain assets. The market value of any ETH, NFT, Collectible or ELEN may decline
                      below the price for which a User acquires such asset through the EVERLENS Platform or on any
                      other platform. User acknowledges and agrees that the costs and speeds of transacting with
                      cryptographic and blockchain-based systems such as Ethereum are variable and may increase or
                      decrease dramatically at any time, resulting in prolonged inability to access or use any ETH, NFTs,
                      Collectibles, ELEN or other digital assets associated with the EVERLENS Platform.

                    </p>
                    <p>
                      <strong>(e) </strong> <u> Regulatory Uncertainty. </u> Blockchain technologies and digital assets are subject to many legal and
                      regulatory uncertainties, and the EVERLENS Platform, NFTs, Collectibles and ELEN could be
                      adversely impacted by one or more regulatory or legal inquiries, actions, suits, investigations, claims,
                      fines or judgments, which could impede or limit the ability of User to continue the use and enjoyment
                      of such assets and technologies.

                    </p>
                    <p>
                      <strong>(f) </strong> <u> Cryptography Risks. </u>  Cryptography is a progressing field. Advances in code cracking or technical
                      advances such as the development of quantum computers may present risks to Ethereum, the
                      EVERLENS Platform, NFTs, Collectibles and ELEN, including the theft, loss or inaccessibility
                      thereof.

                    </p>
                    <p>
                      <strong>(g) </strong> <u> Fork Handling. </u> Ethereum, the EVERLENS DEX System, the NFTs, the Collectibles and ELEN
                      may be subject to “forks.” Forks occur when some or all persons running the software clients for a
                      particular blockchain system adopt a new client or a new version of an existing client that: (i)
                      changes the protocol rules in backwards-compatible or backwards-incompatible manner that affects
                      which transactions can be added into later blocks, how later blocks are added to the blockchain, or
                      other matters relating to the future operation of the protocol; or (ii) reorganizes or changes past
                      blocks to alter the history of the blockchain. Some forks are “contentious” and thus may result in two
                      or more persistent alternative versions of the protocol or blockchain, either of which may be viewed
                      as or claimed to be the legitimate or genuine continuation of the original. EVERLENS Company may
                      not be able to anticipate, control or influence the occurrence or outcome of forks, and does not
                      assume any risk, liability or obligation in connection therewith. Without limiting the generality of the
                      foregoing, EVERLENS Company does not assume any responsibility to notify a User of pending,
                      threatened or completed forks. EVERLENS Company will respond to any forks as EVERLENS
                      Company determines in its sole and absolute discretion, and EVERLENS Company shall not have
                      any duty or obligation or liability to a User if such response (or lack of such response) acts to a User
                      detriment. Without limiting the generality of the foregoing, EVERLENS Company’s possible and
                      permissible responses to a fork may include: (i) honoring the EVERLENS DEX System, Collectibles,
                      NFTs and ELEN on both chains; (ii) honoring the EVERLENS DEX System, Collectibles, NFTs and
                      ELEN on only one of the chains; (iii) honoring the EVERLENS DEX System, Collectibles, NFTs and
                      ELEN in different respects or to a different extent on both chains; or (iv) any other response or policy
                      or procedure, as determined by EVERLENS Company in its sole and absolute discretion.
                      EVERLENS Company reserves the right to only allow one NFT to be associated with the relevant
                      metadata forming the Collectible, notwithstanding that copies of such NFT may exist on both chains
                      following a fork. A User assume full responsibility to independently remain apprised of and informed
                      about possible forks, and to manage a User own interests in connection therewith.

                    </p>
                    <p>
                      <strong>(h) </strong> <u> Essential Third-Party Software Dependencies.</u>
                      The EVERLENS DEX System and other smart
                      contracts deployed to Ethereum are public software utilities which are accessible directly through
                      any Ethereum node (such as Infura) or indirectly through any compatible Ethereum “wallet”
                      application (such as the web browser plugin Metamask) which interacts with such a node. Interacting
                      with the EVERLENS DEX System does not require use of the EVERLENS Apps, but the
                      EVERLENS Apps provide a convenient and user- friendly method of reading and displaying data
                      (including Collectible Metadata) from the EVERLENS DEX System and generating standard
                      transaction messages compatible with the EVERLENS DEX System Because the EVERLENS
                      Platform does not provide Ethereum wallet software or Ethereum nodes, such software constitutes
                      an essential third-party or user dependency without which the EVERLENS Platform cannot be
                      utilized and NFTs, Collectibles, ELEN and other relevant digital assets cannot be traded or used.
                      Furthermore, Collectible Descriptors defining the art and text associated with a Collectible must be
                      stored off of Ethereum. Collectibles created on EVERLENS have their Collectible Descriptors stored
                      on the IPFS system through an IPFS node operated by EVERLENS Company, but EVERLENS
                      Company cannot guarantee continued operation of such IPFS node or the integrity and persistence
                      of data on IPFS.

                    </p>
                  </div>
                  <p>
                    <strong>9.9 </strong> <strong> Legal Limitations on Disclaimers.</strong> Some jurisdictions do not allow the exclusion of certain
                    warranties or the limitation or exclusion of certain liabilities and damages. Accordingly, some of the
                    disclaimers and limitations set forth in these Terms may not apply in full to specific Users. The
                    disclaimers and limitations of liability provided in these terms shall apply to the fullest extent
                    permitted by applicable law
                  </p>
                  <p>
                    <strong>9.10 </strong> <strong> Officers, Directors, Etc. </strong> All provisions of these Terms which disclaim or limit obligations or
                    liabilities of EVERLENS Company shall also apply, mutatis mutandis, to the officers, directors,
                    members, employees, independent contractors, agents, stockholders, debtholders and affiliates of
                    EVERLENS Company.

                  </p>
                  <p>
                    <strong>9.11 </strong> <strong> Indemnification </strong> n Each User shall defend, indemnify, compensate, reimburse and hold harmless
                    EVERLENS Company (and each of its officers, directors, members, employees, agents and
                    affiliates) from any claim, demand, action, damage, loss, cost or expense, including without limitation
                    reasonable attorneys’ fees, arising out or relating to (a) User’s use of, or conduct in connection with,
                    the Offerings; (b) User’s violation of these Terms or any other applicable policy or contract of
                    EVERLENS Company; or (c) your violation of any rights of any other person or entity
                  </p>

                  <h5 className="mt-4 mb-4">
                    <strong>10. </strong> GOVERNING LAW; TEAM RESOLUTION.
                  </h5>

                  <p>
                    <strong>10.1 </strong> <strong> Governing law. </strong> These Terms shall be governed by and construed and interpreted in accordance
                    with the laws of the state of Mauritius irrespective of the choice of laws principles of the state of
                    Mauritius, as to all matters, including matters of validity, construction, effect, enforceability,
                    performance and remedies. Although the Offerings may be available in other jurisdictions, each User
                    hereby acknowledges and agrees that such availability shall not be deemed to give rise to general or
                    specific personal jurisdiction over EVERLENS Company in any forum outside the state of Mauritius.

                  </p>
                  <p>
                    <strong>10.2 </strong> <strong> Settlement Negotiations. </strong>  If a User has a potential legal team, claim or cause of action against
                    EVERLENS Company, the User shall first (prior to initiating any litigation proceedings) contact
                    EVERLENS Company by sending an email to team@EVERLENS.io describing the nature of the
                    potential team, claim or cause of action and providing all relevant documentation and evidence
                    thereof. If so elected by EVERLENS Company, User shall use commercially reasonable efforts to
                    negotiate a settlement of any such legal team, claim or cause of action within 60 days of the delivery
                    of such email. Any such team, claim or 15cause of action that is not finally resolved by a binding,
                    written settlement agreement within such 60 days shall be brought and resolved exclusively in
                    accordance with the following provisions of this <u>Section 10.</u>
                  </p>
                  <p>
                    <strong>10.3 </strong>{" "}
                    <strong>  Agreement to Binding, Exclusive Arbitration. </strong>
                  </p>
                  <div className="left-space">
                    <p>
                      <strong>(a) </strong> <u> Mandatory Binding Arbitration. </u>Except as set
                      forth in <u>Section 10.2,</u> all claims, teams and
                      controversies directly or indirectly arising out of or in connection with or directly or indirectly relating
                      to these Terms or any of the matters or transactions contemplated by these Terms (for the avoidance
                      of doubt, including any claim seeking to invalidate, or alleging that, all or any part of these Terms is
                      unenforceable, void or voidable) (such claims, teams and controversies, collectively, “Teams”) shall
                      be finally settled by binding arbitration, rather than in court. The arbitrator, and not any federal, state
                      or local court, agency or other governmental authority, shall have exclusive authority to resolve all
                      Teams.

                    </p>
                    <p>
                      <strong>(b) </strong> <u> Waiver of Jury Trial. </u> The parties hereby acknowledge, represent and warrant that they
                      understand that: (i) there is no judge or jury in arbitration, and, absent this mandatory provision, the
                      parties would have the right to sue in court and have a jury trial concerning Teams; (ii) in some
                      instances, the costs of arbitration could exceed the costs of litigation; (iii) the right to discovery may
                      be more limited in arbitration than in court; and (iv) court review of an arbitration award is limited.
                      The Federal Arbitration Act and federal arbitration law apply to these Terms. Each of the parties
                      hereto hereby irrevocably waives any and all right to trial by jury in any action, suit or other legal
                      proceeding arising out of or related to these Terms or the transactions contemplated hereby
                    </p>
                  </div>
                  <p>
                    <strong>10.4 </strong> <strong> Arbitration Procedures. </strong> EVERLENS Company or any User may initiate an arbitration
                    proceeding by delivering written notice to the other, whereupon the parties shall reasonably
                    cooperate to select an arbitrator and submit the relevant Team to such arbitrator. In the event the
                    parties are unable to agree on the selection of an arbitrator within 15 days from the filing of a
                    demand for arbitration, the American Arbitration Association (the “AAA”) shall appoint the arbitrator.
                    Any such arbitration shall be administered by the AAA in accordance with the provisions of its
                    Commercial Arbitration Rules and the supplementary procedures for consumer related teams of the
                    AAA excluding any rules or procedures governing or permitting class actions. EVERLENS Company
                    will not seek attorneys’ fees and costs in arbitration unless the arbitrator determines the claims are
                    frivolous. The arbitrator shall be empowered to grant whatever relief would be available in a court
                    under law or in equity. The arbitrator’s award shall be written and reasoned, and binding on the
                    parties, and may be entered by any party as a judgment in any court of competent jurisdiction
                  </p>
                  <p>
                    <strong>10.5 </strong> <strong> Seat of Arbitration. </strong> The seat of arbitration shall be in Mauritius. The arbitrator may choose to
                    have the arbitration of any Team conducted by telephone, based on written submissions, or at a
                    mutually agreed location; provided, however, that EVERLENS Company may opt to transfer the
                    venue of any arbitration hearing to Mauritius in the event that it agrees to pay any additional fees or
                    costs a User may reasonably incur as a result of the change in venue, as determined by the
                    arbitrator, and, subject to the foregoing, a User hereby agree to submit to the personal jurisdiction of
                    any federal or state court in Mauritius, in order to compel arbitration, to stay proceedings pending
                    arbitration, or to confirm, modify, vacate or enter judgment on the award entered by the arbitrator.
                  </p>
                  <p>
                    <strong>10.6 </strong> <strong> Confidentiality of Arbitration. </strong> Except to the extent necessary to enforce their respective rights
                    under these Terms or as otherwise required by applicable law, the parties undertake to maintain
                    confidentiality as to the existence and events of the arbitration proceedings and as to all
                    submissions, correspondence and evidence relating to the arbitration proceedings. This provision
                    shall survive the termination of the arbitral proceedings.

                  </p>
                  <p>
                    <strong>10.7 </strong> <strong> Class Action Waiver. </strong>
                  </p>
                  <div className="left-space">
                    <p>
                      <strong>(a) </strong> <u> No Class Actions Permitted. </u> All Users hereby agree that any arbitration or other permitted action
                      with respect to any Team shall be conducted in their individual capacities only and not as a class
                      action or other representative action, and the Users expressly waive their right to file a class action
                      or seek relief on a class basis. USERS SHALL BRING CLAIMS AGAINST EVERLENS COMPANY
                      OTHER ONLY IN THEIR INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
                      MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
                    </p>
                    <p>
                      <strong>(b) </strong>{" "}
                      <u> Agreements if Class Action Waiver Unenforceable. </u> If any court or arbitrator makes a final, binding
                      and non-appealable determination that the class action waiver set forth in this Section 10.7 is void or
                      unenforceable for any reason or that an arbitration can proceed on a class basis, then the arbitration
                      provision set forth above shall be deemed null and void with respect to any Team that would thus be
                      required to be resolved by arbitration on a class basis, and the parties shall be deemed to have not
                      agreed to arbitrate such Team. In the event that, as a result of the application of the immediately
                      preceding sentence or otherwise, any Team is not subject to arbitration, the parties hereby agree to
                      submit to the personal and exclusive jurisdiction of and venue in the federal and state courts located
                      in Mauritius and to accept service of process by mail with respect to such Team, and hereby waive
                      any and all jurisdictional and venue defenses otherwise available with respect to such Team.
                    </p>
                  </div>
                  <p>
                    <strong>10.8 </strong> <strong> Mauritius End-User Consumer Rights. </strong> In accordance with the laws if a User is a Mauritius
                    resident, the User may file grievances and complaints regarding the Offerings with the Mauritius
                    Department of Consumer Affairs, Consumer Information Division.
                  </p>

                  <h5 className="mt-4 mb-4">
                    <strong>11. </strong> MISCELLANEOUS
                  </h5>

                  <p>
                    <strong>11.1 </strong> <strong> Headings. </strong> The headings and captions contained in these Terms are for convenience of
                    reference only, shall not be deemed to be a part of these Terms and shall not be referred to in
                    connection with the construction or interpretation of these Terms.
                  </p>
                  <p>
                    <strong>11.2 </strong> <strong> Successors and Assigns. </strong> These Terms shall inure to the benefit of EVERLENS Company, the
                    Users, and their respective permitted successors, permitted assigns, permitted transferees and
                    permitted delegates and shall be binding upon all of the foregoing persons and any person who may
                    otherwise succeed to any right, obligation or liability under these Terms by operation of law or
                    otherwise. A User shall not share or provide a copy of, or transfer to, any person any ELEN or the
                    private key associated with any ELEN without notifying such person that such person shall be bound
                    by and become a party to these Terms by virtue of thereof (or if the transferor has a reasonable
                    belief that the transferee is aware of these Terms). A User shall not assign any of a User rights or
                    delegate any of a User liabilities or obligations under these Terms to any other person without
                    EVERLENS Company’s advance written consent. EVERLENS Company may freely assign, transfer
                    or delegate its rights, obligations and liabilities under these Terms to the maximum extent permitted
                    by applicable law.

                  </p>
                  <p>
                    <strong>11.3 </strong> <strong> Severability. </strong> In the event that any provision of these Terms, or the application of any such
                    provision to any person or set of circumstances, shall be determined by an arbitrator or court of
                    competent jurisdiction to be invalid, unlawful, void or unenforceable to any extent: (a) the remainder
                    of these Terms, and the application of such provision to persons or circumstances other than those
                    as to which it is determined to be invalid, unlawful, void or unenforceable, shall not be impaired or
                    otherwise affected and shall continue to be valid and enforceable to the fullest extent permitted by
                    law; and (b) EVERLENS Company shall have the right to modify these Terms so as to effect the
                    original intent of the parties as closely as possible in an acceptable manner in order that the
                    transactions contemplated hereby be consumed as originally contemplated to the fullest extent
                    possible.

                  </p>
                  <p>
                    <strong>11.4 </strong> <strong> Force Majeure. </strong> EVERLENS Company shall not incur any liability or penalty for not performing
                    any act or fulfilling any duty or obligation hereunder or in connection with the matters contemplated
                    hereby by reason of any occurrence that is not within its control (including any provision of any
                    present or future law or regulation or any act of any governmental authority, any act of God or war or
                    terrorism, any epidemic or pandemic, or the unavailability, disruption or malfunction of the Internet,
                    the World Wide Web or any other electronic network, the Ethereum network or blockchain or
                    EVERLENS DEX System or any aspect thereof, or any consensus attack, or hack, or
                    denial-of-service or other attack on the foregoing or any aspect thereof, or on the other software,
                    networks and infrastructure that enables EVERLENS Company to provide the Offerings.), it being
                    understood that EVERLENS Company shall use commercially reasonable efforts, consistent with
                    accepted practices in the industries in which EVERLENS Company operates, as applicable, to
                    resume performance as soon as reasonably practicable under the circumstances.

                  </p>
                  <p>
                    <strong>11.5 </strong> <strong> Amendments and Modifications. </strong> These Terms may only be amended, modified, altered or
                    supplemented by or with the written consent of EVERLENS Company. EVERLENS Company
                    reserves, the right, in its sole and absolute discretion, to amend, modify, alter or supplement these
                    Terms from time to time. The most current version of these Terms will be posted on EVERLENS
                    Company’s website. Any changes or modifications will be effective immediately upon the modified
                    Agreement being posted to EVERLENS Company’s website. A User shall be responsible for
                    reviewing and becoming familiar with any such modifications. A User hereby waive any right a User
                    may have to receive specific notice of such changes or modifications. Use of the Offerings by a User
                    after any modification of these Terms constitutes a User acceptance of the modified terms and
                    conditions. If a User do not agree to any such modifications, a User must immediately stop using the
                    Offerings.

                  </p>
                  <p>
                    <strong>11.6 </strong> <strong> No Implied Waivers. </strong> No failure or delay on the part of EVERLENS Company in the exercise of
                    any power, right, privilege or remedy under these Terms shall operate as a waiver of such power,
                    right, privilege or remedy; and no single or partial exercise of any such power, right, privilege or
                    remedy shall preclude any other or further exercise thereof or of any other power, right, privilege or
                    remedy. EVERLENS Company shall not be deemed to have waived any claim arising out of these
                    Terms, or any power, right, privilege or remedy under these Terms, unless the waiver of such claim,
                    power, right, privilege or remedy is expressly set forth in a written instrument duly executed and
                    delivered on behalf of EVERLENS Company, and any such waiver shall not be applicable or have
                    any effect except in the specific instance in which it is given.

                  </p>
                  <p>
                    <strong>11.7 </strong> <strong> Entire Agreement. </strong> These Terms constitutes the entire agreement between the parties relating to
                    the subject matter hereof and supersede all prior or contemporaneous agreements and
                    understandings, both written and oral, between the parties with respect to the subject matter hereof
                  </p>
                  <p>
                    <strong>11.8 </strong> <strong> Rules of Interpretation.</strong>
                  </p>
                  <div className="left-space">
                    <p>
                      <strong>(a) </strong> “hereof,” “herein,” “hereunder,” “hereby” and words of similar import will, unless otherwise stated,
                      be construed to refer to these Terms as a whole and not to any particular provision of these Terms;
                    </p>
                    <p>
                      <strong>(b) </strong> “include(s)” and “including” shall be construed to be followed by the words “without limitation”;
                    </p>
                    <p>
                      <strong>(c) </strong> “or” shall be construed to be the “inclusive or” rather than “exclusive or” unless the context
                      requires otherwise;
                    </p>
                    <p>
                      <strong>(d) </strong> any rule of construction to the effect that ambiguities are to be resolved against the drafting party
                      shall not be applied in the construction or interpretation of these Terms;
                    </p>
                    <p>
                      <strong>(e) </strong> section titles, captions and headings are for convenience of reference only and have no legal or
                      contractual effect.;
                    </p>
                    <p>
                      <strong>(f) </strong> whenever the context requires: the singular number shall include the plural, and vice versa; the
                      masculine gender shall include the feminine and neuter genders; the feminine gender shall include
                      the masculine and neuter genders; and the neuter gender shall include the masculine and feminine
                      genders; and
                    </p>
                    <p>
                      <strong>(g) </strong> except as otherwise indicated, all references in these Terms to “Sections,” “clauses,” etc., are
                      intended to refer to Sections of Sections, clauses, etc. of these Terms
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrollToTop(TermsCondition);
