import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { downloadFile } from "../../context/Provider"
import henceforthApi from "../../utils/henceforthApi"
import { Spinner } from "../BootstrapCompo"

export default ({ _id, data, setData, connectSocialAccount }) => {
    const [username, setUsername] = useState("")
    const [videoUrl, setVideoUrl] = useState("")
    const [loading, setLoading] = useState(false)

    const connect = async () => {
        setLoading(true)
        if (!username) {
            setLoading(false)
            return toast.warn("Please enter username.")
        }
        try {
            let data = await connectSocialAccount(username)
            let tiktok = data.tiktok
            if (tiktok) {
                console.log("data", tiktok);
                setData(tiktok)
                setUsername(username)
                downloadFile(henceforthApi.FILES.video(tiktok?.s3_video_url));
            }
            debugger
        } catch (error) {
            toast.error(error?.response?.body?.error_description)
        } finally {
            setLoading(false)
        }
    }
    const requestVerification = async (url) => {
        if (!url) {
            return toast.warn("Please enter url")
        }
        try {
            setLoading(true)
            let apiRes = await henceforthApi.TIKTOK.verification(url)
            toast.success(apiRes.message)
            window.location.reload()
        } catch (error) {
            toast.error(error?.response?.body?.error_description)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (data?.user_name) {
            setUsername(data?.user_name)
        } else {
            setUsername("")
        }
        if (data?.video_url) {
            setVideoUrl(data?.video_url)
        } else {
            setVideoUrl("")
        }
    }, [data])

    return <div className={`modal fade common-modal report-modal ${data ? 'show' : ''}`} data-bs-backdrop="static" tabIndex="-1" role={data ? 'dialog' : ''} style={{ display: data ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.5)' }} aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <form onSubmit={(e) => { e.preventDefault(); connect() }}>
                    <button type="button" className="btn-close shadow-none" onClick={() => setData(null)} aria-label="Close"></button>
                    <div className="modal-body ">
                        <h5 className="fw-bold mb-3 text-center"><span className="text-gradient">Tiktok Id</span></h5>
                        {data?.user_name ?
                            <p className="text-center">Please download your Everlens TikTok video and enter the URL</p> :
                            <p className="text-center">Please enter your TikTok Username</p>}
                        <div className="form-group">
                            <label className="mb-1 text-black fw-medium">TikTok Username</label>
                            <input type="text" className="form-control" placeholder="TikTok Username" name="tiktok_username" onChange={(e) => setUsername(e.target.value)} value={username} disabled={data?.user_name} />
                        </div>
                        {data?.s3_video_url &&
                            <div className="d-flex align-items-center justify-content-between my-4">
                                <p className="text-dark fw-medium mb-0">Everlens TikTok Video</p>
                                <button type="button" className="btn btn-outline-primary fw-medium" onClick={() => downloadFile(henceforthApi.FILES.video(data?.s3_video_url))}>DOWNLOAD</button>
                            </div>}

                        {data?.user_name &&
                            <div className="form-group">
                                <label className="mb-1 text-black fw-medium">Copy and paste the link of your TikTok video below</label>
                                <input type="text" className="form-control" placeholder="TikTok URL" name="tiktok_url" onChange={(e) => setVideoUrl(e.target.value)} value={videoUrl} />
                            </div>}
                        <div>
                            {data?.user_name ?
                                <button type="button" className="btn btn-primary mt-2 w-100" disabled={loading} onClick={() => requestVerification(videoUrl)}>{loading ? <Spinner /> : data?.video_url ? 'VERIFY AGAIN' : 'VERIFY'}</button> :
                                <button className="btn btn-primary mt-2 w-100" disabled={loading}> {loading ? <Spinner /> : ''} {loading ? ' Generating Tiktok Video ... ' : 'ADD TIKTOK ID'}</button>}
                        </div>
                        {!data?.user_name &&
                            <div className="tiktok-info mt-4 pt-2">
                                <h6 className="text-dark mb-3">How does TikTok verification work?</h6>
                                <ul className="fs-14 ps-4">
                                    <li className="mb-2">User needs to enter their TikTok username. </li>
                                    <li className="mb-2">Everlens will generate a video that you will have to post on your TikTok account.</li>
                                    <li className="mb-2">You will need to copy the link of this video and provide it in the next step.</li>
                                    <li className="mb-2">Everlens will verify your TikTok account, the process takes up to 48hrs.</li>
                                    <li className="mb-2">Once your account is approved, you will be free to mint any of your TikToks!</li>
                                </ul>
                                <p className="text-dark fw-medium">For any query, you can <a href="/contact-us" target="_blank" className="text-theme text-decoration-underline">Contact Us</a></p>
                            </div>}
                    </div>
                </form>
            </div>
        </div>
    </div>
}