import { useContext, useState } from "react";
import { Link, useMatch, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../assets/images/home/logo.svg";
import authAction from "../../context/actions/auth/authAction";
import { GlobalContext } from "../../context/Provider";
import henceforthApi from "../../utils/henceforthApi";
import henceforthValidations from "../../utils/henceforthValidations";
import { getPrimaryWalletType } from "../../utils/work_contant";
import { Spinner } from "../BootstrapCompo";
export default () => {
  const navigate = useNavigate()
  let match = useMatch("/password/reset/:unique_code")

  const { authDispatch } = useContext(GlobalContext)

  const [password, setPassword] = useState("")
  const [cnfPassword, setCnfPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [passwordToggle1, setPasswordToggle1] = useState(false)
  const [passwordToggle2, setPasswordToggle2] = useState(false)


  const resetPassword = async () => {
    if (!password) {
      return toast.warn("Please enter password")
    } else if (!henceforthValidations.strongPassword(password)) {
      return toast.warn("Please enter stronger password. Try a mix of letters, numbers and symbols.")
    } else if (!cnfPassword) {
      return toast.warn("Please enter confirm password")
    } else if (password !== cnfPassword) {
      return toast.warn("Password and confirm password don't match.")
    }
    let items = {
      unique_code: match.params.unique_code,
      password: password,
      language: "ENGLISH"
    }
    try {
      setLoading(true)
      let apiRes = await henceforthApi.Auth.resetPassword(items)
      let data = apiRes.data
      toast.success(apiRes.message)
      setLoading(false)
      let walletType = getPrimaryWalletType(data.wallet)
      setLoading(false)
      authAction.login({ ...data, walletType })(authDispatch);
      navigate(`/profile/on_sale`)
    } catch (error) {
      setLoading(false)
      if (error.response && error.response.body) {
        toast.error(error.response.body.error_description)
      }
    }
  }
  return (
    <>
      <div className="auth-page position-relative min-vh-100 d-flex align-items-start">
        <div className="container-fluid h-100">
          <div className="row h-100 align-items-center">
            <div className="col-md-5">
              <div className="s-logo position-absolute">
                <Link to="/">
                  <img src={Logo} className="img-fluid header-logo" />
                </Link>

              </div>
              <form className="signup-form" onSubmit={(e) => { e.preventDefault(); resetPassword() }}>
                <h3 className="fw-bold mt-4">Enter New Password</h3>
                <p className="text-gray mb-4 fw-medium">Please enter your new password</p>
                <div className="form-group">
                  <label className="fw-medium mb-1">Password</label>
                  <div className="position-relative">
                    <input type={passwordToggle1 ? 'text' : 'password'} className="form-control pe-5" placeholder="Password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    <i className={`${passwordToggle1 ? 'fa-eye-slash' : 'fa-eye'} fa-solid fa-eye position-absolute top-50 translate-middle-y end-0 me-3 text-gray`} onClick={() => setPasswordToggle1(!passwordToggle1)}></i>
                  </div>

                </div>
                <div className="form-group">
                  <label className="fw-medium mb-1">Confirm Password</label>
                  <div className="position-relative">
                    <input type={passwordToggle2 ? 'text' : 'password'} className="form-control" placeholder="Confirm Password" name="confirm-password" value={cnfPassword} onChange={(e) => setCnfPassword(e.target.value)} />
                    <i className={`${passwordToggle2 ? 'fa-eye-slash' : 'fa-eye'} fa-solid fa-eye position-absolute top-50 translate-middle-y end-0 me-3 text-gray`} onClick={() => setPasswordToggle2(!passwordToggle2)}></i>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary w-100 text-uppercase py-2 mt-3" disabled={loading} >{loading ? <Spinner /> : 'Change Password'}</button>
                <div className="text-center">
                  <p className="text-gray mt-4"><Link to="/signin" replace={true} className="text-theme" >Back to Login</Link></p>
                </div>
              </form>
            </div>
          </div>
        </div>

      </div>

    </>
  )
}