import { EXPLORE_ADD, EXPLORE_CHANGE_LANDING, EXPLORE_LIVE_AUCTION, EXPLORE_TOP_SELLERS, EXPLORE_LANDING_SOCIAL } from "../../actionTypes"

const changeExplore = (payload) => (dispatch) => {
    dispatch({
        type: EXPLORE_CHANGE_LANDING,
        payload
    })
}
const initialState = {
    explore: EXPLORE_LANDING_SOCIAL
}

export default {
    initialState,
    changeExplore,
};
